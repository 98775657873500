import domHelper from 'Helpers/domHelper';


var minutes = 1000 * 60,
    host = window.location.host;

function init() {
  _ifSessionCookie() ? _isLessThirtyMinsOrNewCampaign() : _setNewSessionCookie();
}

function _ifSessionCookie() {
  return domHelper.getCookie('jnq_ch'); // campaign hash
}

function _isLessThirtyMinsOrNewCampaign() {
  var durationOfNoInteraction = Number(domHelper.getCookie('jnq_doni')); //duration of no interaction
  var currentTime = Date.now();
  var campaignHash = _ifSessionCookie();
  var startSessionTime = Number(domHelper.getCookie('jnq_sd'));
  var sessionDuration = startSessionTime + (currentTime - durationOfNoInteraction) / 1000;
  var referrer;

  if (document.referrer.search(host) >= 0) {
    referrer = _ifSessionCookie();
  } else {
    referrer = document.referrer;
  }

  if (campaignHash.search(referrer) >= 0 && (currentTime - durationOfNoInteraction) / minutes <= 30) {
    domHelper.setCookie('jnq_doni', currentTime); //duration of no interaction
    domHelper.setCookie('jnq_sd', sessionDuration); //session duration
    dataLayer.push({new_user_session: false});
    dataLayer.push({session_duration_seconds: sessionDuration});
  } else {
    _setNewSessionCookie();
  }
}

function _setNewSessionCookie() {
  var referrer;

  if (!document.referrer || document.referrer.search(host) >= 0) {
    referrer = host;
  } else {
    referrer = document.referrer;
  }

  domHelper.setCookie('jnq_ch', referrer, 0); // campaign hash
  domHelper.setCookie('jnq_doni', Date.now());
  domHelper.setCookie('jnq_sd', 0);
  dataLayer.push({new_user_session: true});
}

export default {
  init: init
};



