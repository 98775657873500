import React, { useEffect } from 'react';

export const Modal = ({ children, onClose }) => {
  useEffect(() => {
    document.body.classList.add('no-scroll');

    return () => document.body.classList.remove('no-scroll');
  }, []);

  return (
    <div className="jnq-modal jnq-modal--email-popup modal--localization fade in" style={{ display: 'block' }}>
      <div className="jnq-modal__dialog">
        <button type="button" className="jnq-modal__close" onClick={onClose}>
          <i className="i-close" />
        </button>
        <div className="jnq-modal__body">{children}</div>
      </div>
    </div>
  );
};
