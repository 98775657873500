/**
 * Add to wishlist trigger module.
 * @module common/wishlist/addToWishlist
 */

// TODO: refactor and comment methods

import j from 'Helpers/domHelper';
import global from 'global';
import Wishlist from 'Common/headerPopovers/wishlist';
import Toast from 'Common/toast';
import ToastDesktop from 'Common/toastDesktop/toastDesktop';
import pubsub from 'pubsub';
import {
  URL_WISHLIST_ADD,
  URL_WISHLIST_REMOVE,
  URL_WISHLIST_SELECTION_ADD,
  URL_WISHLIST_SELECTION_REMOVE
} from 'Constants/url';
import { PAGE_PDP, PAGE_BUNDLE_PDP, PAGE_BUNDLE_CATALOG, PAGE_WISHLIST, FILTER_BY_DESIGN } from 'Constants/page';
import { EVENT_WISHLIST_REMOVE, EVENT_WISHLIST_ADD } from 'Constants/event';
import axios from 'axios';
import { wishlistActions } from '@juniqe/navigation';
import store from '../../reduxStore';

let url;
let productId;
let productSelectionId;
let addToWishlistTriggerEl;
let isCarousel = false;

function init(triggerEl, fromCarousel) {
  addToWishlistTriggerEl = triggerEl;
  isCarousel = Boolean(fromCarousel);

  productId = j.closest(addToWishlistTriggerEl, '.js-product').getAttribute('data-product-id');

  productSelectionId = j.closest(addToWishlistTriggerEl, '.js-product').getAttribute('data-product-selection-id');

  if (productSelectionId) {
    productId = productSelectionId;
  }

  url = j.hasClass(addToWishlistTriggerEl, 'is-active')
    ? productSelectionId
      ? URL_WISHLIST_SELECTION_REMOVE
      : URL_WISHLIST_REMOVE
    : productSelectionId
    ? URL_WISHLIST_SELECTION_ADD
    : URL_WISHLIST_ADD;

  _wishlistRequest(url, productSelectionId); // or productId
}

function _wishlistRequest(url, isSelection) {
  var onWishlistAction =
    url === URL_WISHLIST_ADD || url === URL_WISHLIST_SELECTION_ADD
      ? _addToWishlistNotification
      : _removeFromWishlistNotification;

  if (isSelection) {
    j.post(url + productId).then(function () {
      onWishlistAction(productId);
    });
  } else {
    axios({
      url,
      method: 'post',
      data: { productEntityId: Number(productId) },
      headers: { 'X-Requested-With': 'juniqe' },
      withCredentials: true
    }).then(() => {
      onWishlistAction(productId);
      if (url === URL_WISHLIST_REMOVE) {
        const currentProduct = document.querySelector(`.js-catalog-product[data-product-id="${productId}"]`);
        if (currentProduct) {
          let finalSimple = currentProduct.querySelector('.final-simple');
          finalSimple && (currentProduct.querySelector('.final-simple').innerHTML = '');
          j.removeClass(currentProduct.querySelector('.product__price'), 'is-hidden');
        }
      }

      store.dispatch(wishlistActions.getWishList());
    });
  }
}

function _addToWishlistNotification(productId) {
  // Fire DY event
  const wishlistCounterElement = document.querySelector('#wish-list-cart .js-header__amount');
  if (wishlistCounterElement) {
    const wishlistCount = Number(wishlistCounterElement.innerHTML);
    // TODO save the counter as a data-attribute in body tag and get it form there
    DY.API('event', {
      name: 'Add to Wishlist',
      properties: {
        dyType: 'add-to-wishlist-v1',
        productId: productId,
        total: wishlistCount + 1
      }
    });
  }

  pubsub.publish(EVENT_WISHLIST_ADD, productId);
  _wishlistNotification('data-wishlist-add-text', 'is-active', 'i-wishlist i-wishlist--add-toast');
}

function _removeFromWishlistNotification() {
  _wishlistNotification('data-wishlist-remove-text', '', 'i-trash-toast');
  pubsub.publish(EVENT_WISHLIST_REMOVE, productId);
}

function _displayDesktopToastNotification(attrName) {
  let config = {};
  if (isCarousel) {
    config = {
      imageUrl: j
        .closest(addToWishlistTriggerEl, '.js-product')
        .querySelectorAll('.rec-item__picture, .product__img')[0]
        .getAttribute('src')
    };
  } else {
    if (PAGE_PDP || FILTER_BY_DESIGN) {
      // Slider on pdp or filter by design catalog page: get the first image in the slider nav
      config = {
        imageUrl: document.querySelector('.pdp-slider__container img').getAttribute('src')
      };
    } else if (PAGE_BUNDLE_PDP) {
      // bundle product pdp page
      config = {
        imageUrl: document.getElementById('imgUrl').value,
        isImageStretched: true
      };
    } else if (PAGE_BUNDLE_CATALOG) {
      // bundle catalog page
      config = {
        imageUrl: j
          .closest(addToWishlistTriggerEl, '.selections-card')
          .querySelector('.selections-card__img')
          .getAttribute('src'),
        isImageStretched: true
      };
    } else if (PAGE_WISHLIST) {
      if (productSelectionId) {
        config = {
          imageUrl: j
            .closest(addToWishlistTriggerEl, '.selections-card')
            .querySelector('.selections-card__img')
            .getAttribute('src'),
          isImageStretched: true
        };
      } else {
        config = {
          imageUrl: j.closest(addToWishlistTriggerEl, '.product').querySelector('.product__img').getAttribute('src')
        };
      }
    } else if (FILTER_BY_DESIGN) {
      // filter by design catalog page: get the image form the slider
      config = {
        imageUrl: document.querySelector('.pdp-slider__wrap img').getAttribute('src')
      };
    } else {
      // regular catalog page
      config = {
        imageUrl: j.closest(addToWishlistTriggerEl, '.product').querySelector('.product__img').getAttribute('src')
      };
    }
  }

  config.isIconActive = !j.hasClass(addToWishlistTriggerEl, 'is-active');
  config.deadline = 2;
  config.text = addToWishlistTriggerEl.getAttribute(attrName);
  config.link = '/wishlist';
  config.iconClass = 'i-wishlist i-wishlist--add';
  new ToastDesktop(config);
}

function _wishlistNotification(attrName, isActive, icon) {
  // prepare wishlist notification object
  if (global.isDesktop) _displayDesktopToastNotification(attrName);
  Wishlist.refresh().then(function () {
    let wishlistElements;
    if (productSelectionId) {
      wishlistElements = document.querySelectorAll(
        '[data-product-selection-id="' + productId + '"] .js-add-to-wishlist'
      );
    } else {
      wishlistElements = document.querySelectorAll('[data-product-id="' + productId + '"] .js-add-to-wishlist');
    }

    [].forEach.call(wishlistElements, el => j.toggleClass(el, 'is-active'));

    if (!global.isDesktop) {
      let addToWishlist = new Toast({
        content: `<div class="pdp__wishlist-toast ${isActive}">
              <i class="${icon} pdp__wishlist-toast-icon"></i>
              <span class="pdp__wishlist-toast-text">${addToWishlistTriggerEl.getAttribute(attrName)}</span>
            </div>`,
        position: 'top',
        deadline: 2,
        type: 'addToWishList'
      });
    }
  });
}

export default {
  init
};
