import PubSub from 'pubsub';
import domHelper from 'Helpers/domHelper';
import leftNav from 'Common/leftNav';
import {EVENT_CATEGORY_LP_REDESIGN_ON, EVENT_CATEGORY_LP_REDESIGN_OFF} from 'Constants/event';

PubSub.subscribe(EVENT_CATEGORY_LP_REDESIGN_ON, (options) => {
  document.addEventListener('DOMContentLoaded', () => {
    let wallArtLoader = document.querySelector('.wallart-loader');
    let wallArtContent = document.querySelector('.wallart-content');
    let redesignHtml = options.html || '';

    // replace html in /wandbilder
    if (wallArtContent) {
      wallArtContent.innerHTML = redesignHtml;
      domHelper.removeClass(wallArtContent, 'is-hidden');
      domHelper.addClass(wallArtLoader, 'is-hidden');

      // Update dataLayer
      let dataLayer = window.dataLayer;
      let dataLayerLength = dataLayer.length;
      let datalayerElement;

      for(let index = 0 ; index < dataLayerLength; index++) {
        datalayerElement = dataLayer[index];

        if(datalayerElement.categoryType === 'catalog_landing_page') {
          datalayerElement.categoryType = 'catalog_landing_page__redesigned';
        }
      }

      dataLayer.push({
        'event': 'structEvent',
        'eventCategory': 'mkt-userInteraction',
        'eventAction': 'a_b_test',
        'eventLabel': 'Wallart_LP_v2',
        'eventProperty': '',
        'eventValue': ''
      });

      leftNav.moveNavigation();
    }
  });
});

PubSub.subscribe(EVENT_CATEGORY_LP_REDESIGN_OFF, () => {
  let wallArtLoader = document.querySelector('.wallart-loader');
  let wallArtContent = document.querySelector('.wallart-content');

  domHelper.removeClass(wallArtContent, 'is-hidden');
  domHelper.addClass(wallArtLoader, 'is-hidden');
});