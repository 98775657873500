export const EVENT_CLICK = 'ontouchend' in document.documentElement ? 'touchend' : 'click';
export const EVENT_MOUSEDOWN = 'ontouchstart' in document.documentElement ? 'touchstart' : 'mousedown';

export const EVENT_DY_REFER_FRIEND = 'dy-refer-friend';
export const EVENT_DY_INIT_ATC = 'dy.atcProvideOptions';
export const EVENT_DY_SEARCH_FEEDBACK = 'dy-product-result-feedback';
export const EVENT_DY_RECOMMENDATIONS_INIT = 'dy.recommendation.init';
export const EVENT_DY_PROMOBAR_INIT = 'dy.promobarInit';
export const EVENT_DY_PROMOBAR_INIT_V5 = 'dy.promobarInitV5';
export const EVENT_DY_INIT_RETURNING_VISITORS = 'dy.returningVisitorsNotificationInit';
export const EVENT_DY_ASK_FOR_SIGNUP = 'dy.askForSignup';

export const EVENT_PROMOBAR_RENDER = 'promobar.render';
export const EVENT_ATC_OVERLAY_RENDER = 'atcoverlay.render';
export const EVENT_AJAX_CART_OVERLAY_RENDER = 'ajaxCart.render';
export const EVENT_DY_EMAIL_THE_CART_AJAX = 'dy.ajax-cart-overlay-email-the-cart';
export const EVENT_COOKIES_DISCLAIMER_INIT = 'dy.cookies-disclaimer.init';
export const EVENT_RECO_POSITION_ON_TOP = 'dy.pdpRecoPositionOnTop';
export const EVENT_ADD_FINAL_SIMPLE_TO_CART = 'add-final-simple-to-cart';
export const EVENT_PDP_SUPER_ATTRIBUTE_CHANGE = 'pdp-super-attribute-change';
export const EVENT_LOAD_MORE_PRODUCTS = 'loadMoreItems';

export const EVENT_NEW_CHECKOUT_PAGE = 'dy.activate-new-checkout';
export const EVENT_OLD_CHECKOUT_PAGE = 'dy.activate-old-checkout';
export const EVENT_STOP_NEW_CHECKOUT_PAGE = 'dy.deactivate-new-checkout';

export const EVENT_MINICART_LOADED = 'cartPopover.loaded';
export const EVENT_WISHLIST_REMOVE = 'wishlist.remove';
export const EVENT_WISHLIST_ADD = 'wishlist.remove';
export const EVENT_CART_ADD = 'cart:add';
export const EVENT_CATEGORY_LP_REDESIGN_ON = 'dy.category-lp-redesign.on';
export const EVENT_CATEGORY_LP_REDESIGN_OFF = 'dy.category-lp-redesign.off';
export const EVENT_LARGE_POPOVER = 'dy.large-popover';
export const EVENT_MEDIUM_POPOVER = 'dy.medium-popover';
export const EVENT_MEDIUM_POPOVER_HIDE = 'dy.medium-popover-hide';
export const EVENT_DY_CLOSE_POPOVER = 'dy_closed';
export const EVENT_DY_PROMOBAR_INIT_V6_GENERAL = 'dy.promobarInitV6General';
export const EVENT_DY_PROMOBAR_INIT_V7_GENERAL = 'dy.promobarInitV7General';
export const EVENT_DY_PROMOBAR_INIT_V7_GENERAL_GOOGLE_PRICE = 'dy.promobarInitV7generalGooglePrice';
export const EVENT_DY_PROMOBAR_INIT_V6_NPS = 'dy.promobarInitV6Nps';
export const EVENT_DY_PROMOBAR_INIT_V6_EMAIL = 'dy.promobarInitV6Email';
export const EVENT_DY_PROMOBAR_INIT_V6_CATALOG_EMAIL = 'dy.promobarInitV6CatalogEmail';

export const EVENT_DY_REDIRECT_TO_MINI_PDP = 'dy.redirectToMiniPdp';
export const EVENT_DY_MOBILE_NAV_BAR_POSITION = 'dy.mobileNavBarPosition';

export const EVENT_DY_PRICE_DISCOUNT = 'dy.priceDiscount';
export const EVENT_DY_PRODUCT_TYPE_CHANGE = 'dy.productTypeChangeAttribute';
export const EVENT_DY_FRAME_TYPE_CHANGE = 'dy.frameTypeChangeAttribute';

export const EVENT_INSERT_CONTENT_IN_CATEGORY_PAGES = 'dy.insertContentInCategoryPages';
export const EVENT_INSERT_CONTENTS_IN_CATEGORY_PAGES = 'dy.insertContentsInCategoryPages';
export const EVENT_INSERT_CONTENT_AFTER_PRICE_IN_CATEGORY_PAGES = 'dy.insertContentAfterPriceInCategoryPages';

export const EVENT_DY_RETAILS_DISPLAY = 'dy.retailDisplay';

export const EVENT_DY_TWO_CLICK_ATC = 'dy.twoClickATC';
export const EVENT_DY_GENERIC_AB_TESTS = 'dy.genericABtests';
export const EVENT_DY_NAVBAR_AB_TEST = 'dy.navbarABtest';
export const EVENT_DY_CAROUSEL_LOADED = 'dy.recoCarousel';
