'use strict';

export const PAGE_REFER_FRIEND = window._pageMeta.pageId === 'refer_a_friend';
export const PAGE_FAQ = window._pageMeta.pageId === 'faq';
export const PAGE_NEWSLETTER_SUCCESS = window._pageMeta.pageId === 'newsletter_success';
export const PAGE_CART = window.location.pathname.indexOf('/checkout/cart') >= 0;
export const PAGE_CHECKOUT = window.location.pathname.indexOf('/checkout/onepage') >= 0;

export const PAGE_RETURNS_THANKS = window._pageMeta.pageId === 'returns_thanks';
export const PAGE_RETURNS_SUCCESS = window._pageMeta.pageId === 'returns_success';
export const PAGE_RETURNS_LOGIN = window._pageMeta.pageId === 'returns_login';
export const PAGE_RETURNS_ITEMS = window._pageMeta.pageId === 'returns_items';
export const PAGE_NOT_FOUND = window._pageMeta.pageId === 'not_found';
export const PAGE_RETURNS = PAGE_RETURNS_THANKS || PAGE_RETURNS_SUCCESS || PAGE_RETURNS_LOGIN || PAGE_RETURNS_ITEMS;

export const PAGE_MYACCOUNT_INDEX = window._pageMeta.pageId === 'account_index';
export const PAGE_MYACCOUNT_EDIT = window._pageMeta.pageId === 'account_edit';
export const PAGE_MYACCOUNT_ADDRESS = window._pageMeta.pageId === 'account_address';
export const PAGE_MYACCOUNT_RESET_PASSWORD = window._pageMeta.pageId === 'account_reset_password';
export const PAGE_MYACCOUNT_RESET_PASSWORD_SUCCESS = window._pageMeta.pageId === 'account_reset_password_success';
export const PAGE_MYACCOUNT_REFERRERS = window._pageMeta.pageId === 'account_referrers';
export const PAGE_MYACCOUNT_ORDER_VIEW = window._pageMeta.pageId === 'account_order_view';
export const PAGE_MYACCOUNT_ORDER_HISTORY = window._pageMeta.pageId === 'account_order_history';
export const PAGE_MYACCOUNT_NEWSLETTER = window._pageMeta.pageId === 'account_newsletter_manage';
export const PAGE_MYACCOUNT_LOGIN = window._pageMeta.pageId === 'account_login';
export const PAGE_MYACCOUNT = PAGE_MYACCOUNT_INDEX || PAGE_MYACCOUNT_EDIT || PAGE_MYACCOUNT_ADDRESS ||
    PAGE_MYACCOUNT_RESET_PASSWORD || PAGE_MYACCOUNT_RESET_PASSWORD_SUCCESS || PAGE_MYACCOUNT_REFERRERS ||
    PAGE_MYACCOUNT_ORDER_VIEW || PAGE_MYACCOUNT_ORDER_HISTORY || PAGE_MYACCOUNT_NEWSLETTER || PAGE_MYACCOUNT_LOGIN;
export const PAGE_PDP = window._pageMeta.pageId === 'pdp';
export const PAGE_BUNDLE_PDP = window._pageMeta.pageId === 'bundle_pdp';
export const PAGE_BUNDLE_CATALOG = window._pageMeta.pageId === 'bundle_catalog';
export const PAGE_WISHLIST = window._pageMeta.pageId === 'wishlist';
export const PAGE_CATALOG = window._pageMeta.pageId === 'catalog';
export const PAGE_ARTIST = window._pageMeta.pageId === 'artist';
export const PAGE_GIFTING = window._pageMeta.pageId === 'gifting';
export const FILTER_BY_DESIGN = window._pageMeta.pageId === 'filter_by_design';