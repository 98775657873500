import j from 'Helpers/domHelper';

const POSITIONS = {
  top: 'toast--top',
  bottom: 'toast--btm'
};
const TYPES = {
  addToCart: 'toast--add-to-cart',
  addToCartMobile: 'toast--add-to-cart-mobile',
  addToWishList: 'toast--add-to-wishlist',
  addToWishListMobile: 'toast--add-to-wishlist-mobile',
  backToCart: 'toast--back-to-cart',
  backToWishList: 'toast--back-to-wishlist',
  emailTheCart: 'toast--email-the-cart'
};

class Toast {
  constructor(options) {
    this.content = options.content || '';
    this.position = options.position || 'top';
    this.type = options.type || '';
    this.link = options.link;
    this.backgroundColor = options.backgroundColor || '';
    this.destroyCallback = options.destroyCallback;

    this._render();
    this._safeDestroy = this.destroy.bind(this);
    if (options.deadline) {
      this.timeoutRef = setTimeout(this._safeDestroy, options.deadline * 1000);
    }
  }

  destroy(e) {
    e && e.preventDefault();
    e && e.stopPropagation();

    if (this.destroyCallback) this.destroyCallback();

    clearTimeout(this.timeoutRef);
    j.remove(this.wrapper);
  }

  _render() {
    let nodeOptions = {
      'class': `toast ${POSITIONS[this.position]} ${this.type ? TYPES[this.type] : ''} ${this.link ? 'toast--link' : ''}`.trim(),
      'style': this.backgroundColor ? `background-color: ${this.backgroundColor}` : ''
    };

    if (this.link) {
      nodeOptions.href = this.link.url;

      if (this.link.target) {
        nodeOptions.target = this.link.target
      }
    }

    this.wrapper = j.newNode(this.link ? 'a' : 'div' , nodeOptions);
    this.body = j.newNode('div', {
      'class': 'toast__body'
    }, this.content);

    this.wrapper.appendChild(this.body);
    document.body.appendChild(this.wrapper);
  }
}

export default Toast;