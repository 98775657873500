import React from 'react';
import ReactDOM from 'react-dom';
import domHelper from 'Helpers/domHelper';
import { LOCAL_CURRENCY } from 'Constants/misc';
import global from 'global';
import HeadedProductsList from './components/HeadedProductsList';
import ProductsList from './components/ProductsList';
import MultiProductsList from './components/MultiProductsLists';

import { getPriceDiscountCache } from '../../dy/priceDiscount/priceDiscount';

export const prepareDataFromBackend = item => ({
  id: item.id,
  typeId: item.typeId,
  orientation: item.orientation,
  url: item.url,
  image: item.image,
  isCurrentProduct: item.isCurrentProduct,
  productTypeSwitcherImages: item.productTypeSwitcherImages || [],
  newProductTypeSwitcherImages: item.newProductTypeSwitcherImages || [],
  designName: item.design.displayed ? item.design.name : undefined,
  price: item.price.displayed
    ? LOCAL_CURRENCY.displayPrice(
        `${item.price.frseoProductTypeomLabel ? item.price.fromLabel : ''} ${item.price.value}`
      )
    : undefined,
  artist: item.artist.displayed ? item.artist.name : undefined,
  productType: item.productType.displayed ? item.productType.name : undefined,
  seoProductType: item.seoProductType && item.seoProductType.displayed ? item.seoProductType.name : undefined,
  productTypeCompactDisplay: false
});

const wallArtId = 189;

let overlayElement;
let overlayElementInner;
let pdpBreadcrumps;
let pdpHeaderAside;
let pdpSlider;

function _cacheDom() {
  overlayElement = document.querySelector('.pdp-slider__product-type-preview');
  overlayElementInner = document.querySelector('.pdp-slider__product-type-preview__inner');
  pdpBreadcrumps = document.querySelector('.pdp__bc');
  pdpHeaderAside = document.querySelector('.pdp__header-aside');
  pdpSlider = document.querySelector('.pdp-slider');
}

_cacheDom();

function _onProductMouseIn(event, el) {
  pdpSlider = pdpSlider || document.querySelector('.pdp-slider');
  domHelper.addClass(pdpSlider, 'pdp-slider--has-preview');
  domHelper.removeClass(overlayElement, 'is-hidden');
  let imgWithWrapper;
  if (window.pdpGalleryIndex === 0) {
    imgWithWrapper = event.querySelector('.rec-item__image').cloneNode(true);
    imgWithWrapper.querySelectorAll('img').forEach(img => {
      const src = img.getAttribute('src');
      img.setAttribute('data-echo', src.replace('x386', 'x600'));
      img.setAttribute('src', '/app/assets/images/blank.gif');
    });
  } else {
    // try to get image with the same index
    let targetImgUrl = el.productTypeSwitcherImages[window.pdpGalleryIndex];
    let switcherImageIndex = window.pdpGalleryIndex;
    while (!targetImgUrl && switcherImageIndex !== 0) {
      switcherImageIndex--;
      targetImgUrl = el.productTypeSwitcherImages[switcherImageIndex];
    }
    imgWithWrapper = document.createElement('div');
    domHelper.addClass(imgWithWrapper, 'rec-item__image');
    imgWithWrapper.innerHTML = `<img data-echo="${targetImgUrl}" src="/app/assets/images/blank.gif" class="rec-item__picture">`;
  }
  overlayElementInner.innerHTML = '';
  overlayElementInner.appendChild(imgWithWrapper);
  domHelper.addClass(pdpBreadcrumps, 'is-hidden');
  if (pdpHeaderAside) {
    pdpHeaderAside.style.display = 'none';
  }

  window.$EchoJS.init({
    offset: window.innerHeight * 2, // also load images positioned on two next screens
    mobileMQBreakpoint: global.breakpoints.phone,
    debounce: false,
    throttle: 500
  });
}

function _onProductMouseOut(event, el) {
  pdpSlider = pdpSlider || document.querySelector('.pdp-slider');
  domHelper.removeClass(pdpSlider, 'pdp-slider--has-preview');
  domHelper.addClass(overlayElement, 'is-hidden');
  overlayElementInner.innerHTML = '';
  domHelper.removeClass(pdpBreadcrumps, 'is-hidden');
  if (pdpHeaderAside) {
    pdpHeaderAside.style.display = 'flex';
  }
}

function trackClickEvent(targetId) {
  // Send carousel container ID attribute as an event name to DY
  DY.API('event', {
    name: targetId
  });
}

export const insertProductCarousel = (data, targetNode) => {
  ReactDOM.render(
    <ProductsList
      title={data.title}
      premiumPosterTypeId={data.premiumPosterTypeId}
      handleProductClick={function (event) {
        if (data.targetId) {
          trackClickEvent(data.targetId);
        }
      }}
      products={data.products}
    />,
    targetNode
  );
};

export const insertSameDesignCarousel = (data, targetNode, premiumPosterTypeId) => {
  // render product carousel only for desktop
  if (global.isDesktop && window._pageMeta.productsSameDesign.activeVerticalId === wallArtId) {
    _cacheDom();
    const wallArtProducts = data.verticals.filter(vertical => Number(vertical.id) === wallArtId)[0].items;
    if (document.querySelector('.pdp__product-related')) {
      ReactDOM.render(
        <ProductsList
          scrollToActive
          perPageAmount={3}
          products={wallArtProducts}
          handleProductMouseIn={_onProductMouseIn}
          handleProductMouseOut={_onProductMouseOut}
          showSeeSimilarDesigns={false}
        />,
        document.querySelector('.pdp__product-related')
      );
    }
  } else if (targetNode) {
    ReactDOM.render(
      <MultiProductsList
        activeProductId={window._pageMeta.productsSameDesign.activeVerticalId}
        title={data.title}
        verticals={data.verticals}
      />,
      targetNode
    );
  }
};

export const insertSameDesignCarouselToCatalog = (data, targetNode, premiumPosterTypeId) => {
  if (global.isDesktop && window._pageMeta.productsSameDesign.activeVerticalId === wallArtId) {
    _cacheDom();
    const wallArtProducts = data.verticals.filter(vertical => Number(vertical.id) === wallArtId)[0].items;
    ReactDOM.render(
      <ProductsList
        scrollToActive
        perPageAmount={3}
        products={wallArtProducts}
        handleProductMouseIn={_onProductMouseIn}
        handleProductMouseOut={_onProductMouseOut}
        showSeeSimilarDesigns={false}
      />,
      document.querySelector('.pdp__product-related')
    );
  }
};

export const insertHeadedProductCarousel = (data, targetNode) => {
  const priceDiscount = getPriceDiscountCache();
  const handleProductMouseIn = () => {};
  const handleProductMouseOut = () => {};
  const handleProductClick = () => {
    if (data.targetId) {
      trackClickEvent(data.targetId);
    }
  };

  ReactDOM.render(
    <HeadedProductsList
      {...data}
      handleProductMouseIn={handleProductMouseIn}
      handleProductMouseOut={handleProductMouseOut}
      handleProductClick={handleProductClick}
      priceDiscount={priceDiscount}
      targetNode={targetNode}
    />,
    targetNode
  );
};
