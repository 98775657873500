'use strict';

import PubSub from 'pubsub';
import headerFastPopover from 'Common/headerPopovers/headerFastPopover';
import Promise from 'bluebird';
import global from 'global';
import returningVisitorsDesktop from './returningVisitorsDesktop';
import Toast from 'Common/toast';
import {
    EVENT_DY_INIT_RETURNING_VISITORS
} from 'Constants/event';
import {
  URL_CART_PAGE,
  URL_WISHLIST_PAGE
} from 'Constants/url';

function _runNotification(data) {
  if (global.isDesktop) {
    returningVisitorsDesktop.init();
  }

  Promise.all([
    headerFastPopover().fetchData({
      moduleName: 'cart',
      tplUrl: '/cart/get'
    }),
    headerFastPopover().fetchData({
      moduleName: 'wishlist',
      tplUrl: '/wishlist/get'
    })
  ]).then(function (res) {
    let runComponent = (data.type === 'cart' && res[0].numberOfItems > 0)
        ? 'cart'
        : (data.type === 'wishlist' && res[1].numberOfItems > 0)
            ? 'wishlist'
            : 'none';
    let message = (runComponent === 'cart') ? res[0].productsLeftMessage : res[1].productsLeftMessage;
    let url;
    let method;

    switch (runComponent) {
      case 'cart':
        method = returningVisitorsDesktop.showCartOverlay.bind(returningVisitorsDesktop);
        url = URL_CART_PAGE;
        break;
      case 'wishlist':
        method = returningVisitorsDesktop.showWishListOverlay.bind(returningVisitorsDesktop);
        url = URL_WISHLIST_PAGE;
        break;
    }

    if (runComponent !== 'none') {
      if (global.isDesktop) {
        method(data.timeout);
      }
      else {
        let toast = new Toast({
          content: `<div class="return__cart-toast">
            <i class="i-cart-hover return__cart-toast-icon"></i>
            <span class="return__cart-toast-text">${message}</span>
          </div>`,
          position: 'bottom',
          type: data.icon,
          backgroundColor: data.bgColor,
          link: {
            url: url
          },
          deadline: data.timeout / 1000
        });
      }
    }
  });
}

PubSub.subscribe(EVENT_DY_INIT_RETURNING_VISITORS, _runNotification);