'use strict';

import PubSub from 'pubsub';
import FUNC from 'Helpers/funcHelper';
import {
  EVENT_MINICART_LOADED
} from 'Constants/event';
import {
  LOCAL_CURRENCY
} from 'Constants/misc';

const _onMiniCartUpdate = data => {
  FUNC.waitForGlobalVar('DY').then(DY => {
    // placeholder for future possible events
  });
};

PubSub.subscribe(EVENT_MINICART_LOADED, _onMiniCartUpdate);