import domHelper from 'Helpers/domHelper';
import 'global';

let btn;
let isVisible;

function init() {
  _createBtn();
  _bindEvents();
}

function _createBtn() {
  btn = domHelper.newNode('button', {
    id: 'btn-up',
    class: 'btn--up'
  },
  '<span class="btn--up__icon icon-arrow-up-white">');

  document.body.appendChild(btn);
  isVisible = true;
}

function _bindEvents() {
  btn.addEventListener('click', _scrollTop);
}

function _scrollTop(e) {
  e.preventDefault();
  window.scrollTo(0, 0);
}

function _showBtn() {
  if (!isVisible) {
    domHelper.removeClass(btn, 'is-hidden');
    isVisible = true;
  }
}

function _hideBtn() {
  if (isVisible) {
    domHelper.addClass(btn, 'is-hidden');
    isVisible = false;
  }
}

export default {
  init: init,
  showBtn: _showBtn,
  hideBtn: _hideBtn
};

