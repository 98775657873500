'use strict';

import global from 'global';
import DOM from 'Helpers/domHelper';
import HTTP from 'Helpers/httpHelper';
import upsellingTpl from './upselling.mst.html';
import {
  URL_UPSELL_REQUEST_OPTIONS,
  URL_CART_REPLACE_OPTIONS
} from 'Constants/url';
import {
  CLASS_UPSELL_CHOSEN_ELEMENT
} from 'Constants/class';
import {
  ID_UPSELLING_SIZE,
  ID_UPSELLING_TYPE,
  CURRENCIES_LIST
} from 'Constants/misc';
import AjaxCart from 'Common/headerPopovers/ajaxCart';
import newCartConfig from 'DynamicYield/newCartConfig/newCartConfig';

const ATTRIBUTE_ID_SIZE = '150';
const ATTRIBUTE_ID_FRAME = '180';
const UPSELLING_OPTIONS_LIST_SIZE = 3;

let entity;
let form;
let productOptions;
let productId;
let superAttributes = [];
let upsellingHtml;
let currentProduct;
let currentProductNode;
let selectedProductNode;
let originalProduct;
let upsellType;
let upsellingContainer;
let isProductType;
let upsellButtons;
let atcOptions;
let originalOptions;
let overlay;
let originalItem;

function init(options) {
  upsellType = options.promoContent;
  atcOptions = options;

  if (upsellType === ID_UPSELLING_TYPE || upsellType === ID_UPSELLING_SIZE) {
    _cacheDom();
    _getUpsellingOptions();
  }
}

function _cacheDom() {
  entity = document.querySelector('#entity-id');
  form = entity.querySelector('#add-to-cart-form');
  productOptions = form.querySelectorAll('.pdp__options');
  overlay = document.querySelector('.cart-overlay__inner');

  productId = parseInt(entity.getAttribute('data-product-id'));
  isProductType = upsellType === ID_UPSELLING_TYPE;
}

function _cacheCompiledDom() {
  upsellButtons = upsellingContainer.querySelectorAll('.upselling-item__add-button, .upselling-item__undo-button, .upselling-item__option');
}

function _bindEvents() {
  [].forEach.call(upsellButtons, (btn) => {
    btn.addEventListener('click', _replaceElementInCart)
  });
}

function _formatUpsellingResponse(res) {
  const updatedSimpleId = (res.updated[0] || res.inserted[0]).childProductIds[0];
  const updatedProduct = res.cart.items.filter(product => product.product.childProductIds.includes(updatedSimpleId))[0];
  res.imageUrl = `https://rndr.staging.juniqe.com/media/catalog/product/cache/x145${updatedProduct.simples[updatedSimpleId].ConfigurableSimpleProductDescription.thumbnail}`;
  res.sizeLabel = updatedProduct.sku.split("-").reverse()[0];
  res.priceLabel = CURRENCIES_LIST[updatedProduct.pricePerItem.currency].displayPrice(updatedProduct.pricePerItem.amount);
  res.productType = {name: updatedProduct.itemName};
  return res;
}

function _getProductIdFromAttributes(attrs) {
  return Number(attrs.filter(attr => attr.productId)[0].productId);
}

function _upsellProductNewCart(newCartConfig, addItem, currentProductNode, isUpsell) {
  const addSimpleId = _getProductIdFromAttributes(addItem.superAttributes);
  const removeSimpleId = _getProductIdFromAttributes(currentProduct.superAttributes);
  if (addSimpleId === removeSimpleId) return;
  let payload = {
    products: [
      {
        parentProductId: addItem.confProdId,
        childProductIds: [addSimpleId],
        deltaQuantity: 1
      },
      {
        parentProductId: currentProduct.confProdId,
        childProductIds: [removeSimpleId],
        deltaQuantity: -1
      }
    ]
  };
  // TODO: figure out correct way of passing
  HTTP.ajax(newCartConfig.apiUrl, {
    type: 'POST',
    contentType: 'application/json',
    data: JSON.stringify(payload),
    success: res => _onReplaceSuccess(addItem, currentProductNode, isUpsell, _formatUpsellingResponse(res)),
    error: (data) => {
      console.warn(data);
    }
  });
}

function _replaceElementInCart(e) {
  let isUpsellSimple = DOM.hasClass(e.currentTarget, 'upselling-item__add-button');
  let isUpsellColor = DOM.hasClass(e.currentTarget, 'upselling-item__option') || DOM.closest(e.currentTarget, '.upselling-item__option');
  let isUpsell = isUpsellSimple || isUpsellColor;
  let isUndo = DOM.hasClass(e.currentTarget, 'upselling-item__undo-button');

  if (isUpsell || isUndo) {
    e.preventDefault();

    currentProductNode = DOM.closest(e.currentTarget, '.upselling-item');
    let productData = DOM.parseJsonFromData(e.currentTarget.getAttribute('data-product'));
    let addItem = isUpsell ? productData : originalProduct;
    let config = newCartConfig();
    if (config) {
      _upsellProductNewCart(config, addItem, currentProductNode, isUpsell);
    } else {
      let data = {
        add: addItem,
        remove: currentProduct
      };
      HTTP.ajax(URL_CART_REPLACE_OPTIONS, {
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify(data),
        success: res => _onReplaceSuccess(addItem, currentProductNode, isUpsell, res),
        error: (data) => {
          console.warn(data);
        }
      });
    }
  }
}

function _onReplaceSuccess(addItem, addItemNode, isUpsell, res) {
  // update cart
  AjaxCart.refresh();

  if (selectedProductNode) {
    DOM.removeClass(selectedProductNode, CLASS_UPSELL_CHOSEN_ELEMENT);
  }
  let allUpsellOptions = [].slice.call(document.querySelectorAll('.upselling-item'));
  if (isUpsell) {
    allUpsellOptions.forEach(item => DOM.addClass(item, 'upselling-item--not-chosen'));
    DOM.addClass(addItemNode, CLASS_UPSELL_CHOSEN_ELEMENT);
    selectedProductNode = addItemNode;
  } else {
    allUpsellOptions.forEach(item => DOM.removeClass(item, 'upselling-item--not-chosen'));

    if (!isProductType) {
      DOM.removeClass(document.querySelector('.atc-upselling__option:first-child'), 'upselling-item--not-chosen');
    }
  }

  if (isProductType) {
    // update image
    let image = overlay.querySelector('.cart-overlay__image');
    let newImage = addItemNode.querySelector('.upselling-item__img--preview');
    newImage.querySelector('img').setAttribute('src', res.imageUrl);
    newImage = newImage.innerHTML;
    let originalImage = originalOptions.imageCode;
    image.innerHTML = isUpsell ? newImage : originalImage;
  }
  else {
    // update size value
    let originalValue = originalItem.sizeLabel;
    let newValue = res.sizeLabel;
    overlay.querySelector(`[data-option-id="${ATTRIBUTE_ID_SIZE}"] .cart-overlay__option-value`).innerHTML = isUpsell ? newValue : originalValue;
  }
  // update price and title
  let cartOverlayPriceElem = document.querySelector('.cart-overlay__option-value'),
      cartOverlayTitleElem = document.querySelector('.cart-overlay__product-description');
  if (isUpsell) {
    cartOverlayPriceElem.innerHTML = res.priceLabel;
    cartOverlayTitleElem.innerText = res.productType.name;
  } else {
    cartOverlayPriceElem.innerHTML = originalItem.priceLabel;
    cartOverlayTitleElem.innerText = originalItem.productType.name;
  }

  currentProduct = addItem;
}

function _getUpsellingOptions() {
  superAttributes = getSelectedSimple();

  if (superAttributes.length) {
    requestUpsell();
    originalProduct = {
      confProdId: productId,
      superAttributes: superAttributes
    };
    currentProduct = originalProduct;
  }
}

function getSelectedSimple() {
  let selectedOptions = form.querySelectorAll('input[type="radio"]:checked');

  if (selectedOptions.length === productOptions.length) {
    return [].map.call(selectedOptions, (el) => {
      return {
        productId: document.querySelector("[data-field-sublabel='sizechart']").querySelector(":checked").getAttribute("data-jnq-product-option-id"),
        id: el.getAttribute('data-super-attribute'),
        value: parseInt(el.getAttribute('value'))
      }
    });
  }
}

function requestUpsell() {
  let data = {
    confProdId: productId,
    superAttributes: superAttributes,
    upsellType: upsellType
  };

  if (upsellType === ID_UPSELLING_TYPE) {
    data.productTypeIds = atcOptions.productTypes;
  }

  HTTP.ajax(URL_UPSELL_REQUEST_OPTIONS, {
    type: 'POST',
    contentType: 'application/json',
    data: JSON.stringify(data),
    success: _parseUpsellingOptions,
    error: (data) => {
      console.warn(data);
    }
  })
}

function _parseUpsellingOptions(data) {
  upsellingContainer = document.querySelector('#dy-overlay-upselling');
  originalOptions = DOM.parseJsonFromData(upsellingContainer.getAttribute('data-original-option'));
  originalItem = data.selectedItem;

  if (data.items.length) {
    data.originalProduct = DOM.stringifyJsonForData(originalProduct);

    // truncate items size to maximum ones
    data.items = data.items.slice(0, isProductType ? UPSELLING_OPTIONS_LIST_SIZE : Number(atcOptions.maxSizeItems));

    if (!isProductType) {
      let virImage = document.querySelector('.pdp-slider__image--room-view');
      let defaultImage = document.querySelector('.pdp-slider__image--small-view');

      data.items.unshift({
        confProdId: originalProduct.confProdId,
        image: virImage ? virImage.src : defaultImage.src,
        label: originalItem.sizeLabel,
        priceDifferenceLabel: `<i class="i-check-green"></i> ${data.translations.chosenSize}`,
        superAttributes: originalProduct.superAttributes
      })
    }

    if (isProductType) {
      data.isProductType = true;
    }

    data.items.forEach((el, i) => {
      if (isProductType) {
        let size = data.items[i].superAttributes.filter((el) => el.id === ATTRIBUTE_ID_SIZE)[0].value;
        let frames = data.items[i].superAttributes.filter((el) => el.id === ATTRIBUTE_ID_FRAME);
        let type = el.image.split('.').slice(-2)[0].split('_').pop();

        data.items[i].isCanvas = el.productTypeId === 53;

        if (frames.length) {
          data.items[i].compiledProducts = frames.map((el) => {
            return {
              code: el.value,
              name: data.translations.frameColorVariations.filter((key) => key.id === el.value)[0].value,
              productData: DOM.stringifyJsonForData({
                confProdId: data.items[i].confProdId,
                superAttributes: [
                  {
                    id: ATTRIBUTE_ID_SIZE,
                    value: size
                  },
                  {
                    id: ATTRIBUTE_ID_FRAME,
                    value: el.value,
                    productId: el.productId
                  }
                ]
              })
            }
          });
          data.items[i].isCompiled = true;
        }
      }
      data.items[i].productData = DOM.stringifyJsonForData({
        confProdId: el.confProdId,
        superAttributes: el.superAttributes
      });
    });
    data.upsellingType = upsellType;
    data.translations.chosenText = isProductType ? data.translations.upgraded : data.translations.chosenSize;
    upsellingHtml = upsellingTpl(data);
    upsellingContainer.innerHTML = upsellingHtml;

    EchoJS.init({
      offset: window.innerHeight * 2, // also load images positioned on two next screens
      mobileMQBreakpoint: global.breakpoints.phone,
      debounce: false
    });

    _cacheCompiledDom();
    _bindEvents();

  }
}

export default {
  init
}