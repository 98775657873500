'use strict';

import global from 'global';
import PubSub from 'pubsub';
import DOM from 'Helpers/domHelper';
import React from 'react';
import {
  ID_CANVAS
} from 'Constants/misc';
import {
  CLASS_WISHLIST_TOGGLE_ELEMENT,
  CLASS_WISHLIST_TOGGLE_CAROUSEL_ELEMENT,
  CLASS_SEE_SIMILAR_DESIGNS
} from 'Constants/class';
import {
  EVENT_DY_REDIRECT_TO_MINI_PDP
} from 'Constants/event';
import {
  getDefaultUrl
} from 'DynamicYield/productViewPage/productViewPage';

let addSimilarParam = true;

PubSub.subscribe(EVENT_DY_REDIRECT_TO_MINI_PDP, function () {
  addSimilarParam = false;
});

export default class ProductPane extends React.Component {
  componentDidMount() {
    let waitForWishlistInitData = setInterval(() => {
      if (window._wishlist) {
        clearInterval(waitForWishlistInitData);

        if (window._wishlist.items.indexOf(Number(this.props.id)) >= 0) {
          DOM.addClass(this.favButton, 'is-active');
        }
      }
    }, 100);
  }

  redrawCanvas() {
    DOM.redraw(this.canvasImage)
  }

  render() {
    // Check if the current page is a selection page
    let showSimilarDesigns = this.props.premiumPosterTypeId && this.props.premiumPosterTypeId === this.props.typeId;
    let seeSimilarDesignsLink = (showSimilarDesigns) ? (this.props.url + '?similar') : '';
    const seeSimilarDesignsHtml = (showSimilarDesigns)
      ? (<button className={'see-more-like ' + CLASS_SEE_SIMILAR_DESIGNS} data-href={seeSimilarDesignsLink}>
          <span className="see-more-like__link">
            <span className="hide-mobile hide-tablet show-desktop">{window._i18n.carousel.filterByDesign}</span>
            <span className="show-mobile hide-desktop">{window._i18n.carousel.filterByDesignMobile}</span>
          </span>
        </button>)
      : null
    const artistInfo = (this.props.artist)
      ? (<span className="rec-item__data-row rec-item__data-row--artist">{window._i18n.carousel.byArtistLabel} {this.props.artist}</span>)
      : null;
    const productTypeInfo = (this.props.productType && !this.props.productTypeCompactDisplay)
      ? (<span className="rec-item__data-row rec-item__data-row--product-type">{this.props.productType}</span>)
      : null;
    const designNameInfo = (this.props.designName)
      ? (<span className="rec-item__data-row rec-item__data-row--design-name">
          {(this.props.productType && this.props.productTypeCompactDisplay)
            ? `${this.props.designName}, ${this.props.productType}`
            : this.props.designName}
        </span>)
      : null;
    const priceInfo = (this.props.price)
      ? (<span className="rec-item__data-row rec-item__data-row--price">{this.props.price}</span>)
      : null;
    const primaryImage = (this.props.typeId !== ID_CANVAS)
      ? (<img className="rec-item__picture" src={(typeof this.props.image === 'string') ? this.props.image : this.props.image.main.url} />)
      : (<div className="canvas" ref={el => {this.canvasImage = el;}}>
        <div className="canvas__container">
          <img onLoad={this.redrawCanvas.bind(this)} src={this.props.image.main.url} className="product__img"/>
        </div>
        <div className="canvas__side">
          <img src={this.props.image.canvasSide} className="canvas__side-img"/>
        </div>
      </div>);
    const secondaryImage = (this.props.image.secondary && global.isDesktop)
      ? (<img className="rec-item__picture rec-item__picture--secondary" src={this.props.image.secondary.url} />)
      : null;
    const image = (<div className={`rec-item__image ${this.props.typeId ? `rec-item__image--${this.props.typeId}` : ''} ${this.props.image.secondary ? 'rec-item__image--secondary': ''} rec-item__image-o${this.props.orientation}`}>
      {primaryImage}
      {secondaryImage}
    </div>);

    return (<a className={`rec-item js-product ${this.props.isCurrentProduct ? 'rec-item--active' : ''}`}
               onMouseEnter={(e) => this.props.handleProductMouseIn(e.currentTarget, this.props.el)}
               onMouseLeave={(e) => this.props.handleProductMouseOut(e.currentTarget, this.props.el)}
               onClick={(e) => this.props.handleProductClick(e.currentTarget, this.props.el)}
               href={getDefaultUrl({
                 url: this.props.url,
                 id: this.props.id
               })}
               data-product-id={this.props.id}>

      {image}
      <div className="rec-item__description">
        <div className="rec-item__data">
          {designNameInfo}
          {artistInfo}
          {productTypeInfo}
          {priceInfo}
        </div>
        <button className={`${CLASS_WISHLIST_TOGGLE_ELEMENT} ${CLASS_WISHLIST_TOGGLE_CAROUSEL_ELEMENT} product__wishlist-action product__wishlist-action--carousel rec-item__add-to-favorites`}
                ref={el => {this.favButton = el;}}
                data-wishlist-add-text={window._i18n.carousel.addedToWishlist}
                data-wishlist-remove-text={window._i18n.carousel.removedFromWishlist}
                aria-label={window._i18n.carousel.addedToWishlist}>
          <span className="wishlist-tooltip">
            <i className="i-wishlist"></i>
            <span className="wishlist-tooltip__text wishlist-tooltip__text--top">
              <span className="wishlist-tooltip__add">{window._i18n.carousel.addToWishlist}</span>
              <span className="wishlist-tooltip__remove">{window._i18n.carousel.addedToWishlist}</span>
            </span>
          </span>
        </button>
      </div>
      {seeSimilarDesignsHtml}
    </a>);
  }
}
