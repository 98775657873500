import domHelper from 'Helpers/domHelper';
import funcHelper from 'Helpers/funcHelper';
import suggestionTpl from './searchTypeahead/suggestion.mst.html';

var menuWrapper,
    nav,
    menu,
    menuLength,
    hamburgerButton,
    closeButtons,
    backButtons,
    toggleMobileLinks,
    backButtonsLength,
    header,
    headerForm,
    prevSearchValue = null,
    toggleMobileLinksLength;

function init(pathNamePdp) {
  _cacheDom();
  _bindEvents();
  _initMenu(pathNamePdp);
  _updateMenu();
}

function _cacheDom() {
  let {searchModel} = window;
  nav = document.getElementById('nav');
  headerForm = document.querySelector( '#header__typeahead-form');
  headerForm.setAttribute('action', window.searchModel.searchPath);

  if(nav) {
    menuWrapper = document.querySelector('.js-menu__wrp');
    menu = nav.getElementsByClassName('js-menu__categories');
    menuLength = menu.length;
    hamburgerButton = document.getElementById('hamburger-button');
    header = document.getElementById('header');
    closeButtons = document.getElementsByClassName('js-menu__mob-close');
    backButtons = document.getElementsByClassName('js-nav-back');
    backButtonsLength = backButtons.length;
    toggleMobileLinks = nav.querySelectorAll('[data-toggle_mob]');
    toggleMobileLinksLength = toggleMobileLinks.length;
  }
}

function _bindEvents() {
  if(nav) {
    hamburgerButton.addEventListener('click', _triggerMobileNavigation);

    for (var i = 0; i < menuLength; i++) {
      menu[i].addEventListener('click', _onClick);
    }

    for (var i = 0; i < closeButtons.length; i++) {
      closeButtons[i].addEventListener('click', _hideMobileNavigation)
    }

    for (var i = 0; i < backButtonsLength; i++) {
      backButtons[i].addEventListener('click', _moveBack);
    }


    nav.addEventListener('click', function (e) {
      if (!domHelper.closest(e.target, '#menu-collapse') && !domHelper.closest(e.target, '#hamburger-button') && !(e.target.getAttribute('id') === "hamburger-button")) {
        _hideMobileNavigation();
      }
    });

    for (var i = 0; i < toggleMobileLinksLength; i++) {
      toggleMobileLinks[i].addEventListener('click', _calculateMenuHeight)
    }

    headerForm.removeEventListener('submit', onSearchFormSubmit);
    headerForm.addEventListener('submit', onSearchFormSubmit);
    document.querySelector('#menu-collapse .side-bar-search__input').addEventListener('keyup', funcHelper.debounce(searchChangeHandler, 200));
  }
}

function _onClick(e) {
let proceed = !!e.target.hasAttribute('data-mobile-clickable') || !!domHelper.closest(e.target, '[data-mobile-clickable]');

  if (!proceed) {
    e.preventDefault();
  }
}

function _triggerMobileNavigation(e) {
  e && e.preventDefault();
  if (domHelper.hasClass(nav, 'is-open')) {
    _hideMobileNavigation()
  } else {
    _showMobileNavigation();
  }
}

function _initMenu(pathNamePdp) {
  const pathName = pathNamePdp ? pathNamePdp : window.location.pathname;
  let navSection;
  let sideBarLink;
  const sidebarNavigation = document.querySelectorAll(`[data-navigation-list='${pathName}']`)[0];
  const sideBarContainer = document.getElementsByClassName("side-bar-links__container")[0];
  const indentLeft = 15;

  if(!!pathName.lastIndexOf("/")) {
    const indexOfString = pathName.lastIndexOf("/");
    const currentPathName = pathName.slice(0, indexOfString);

    sideBarLink = document.querySelectorAll(`[data-tab-link='${currentPathName}']`)[0];
    navSection = document.querySelectorAll(`[data-nav-section='${currentPathName}']`)[0];
  } else {
    sideBarLink = document.querySelectorAll(`[data-tab-link='${pathName}']`)[0];
    navSection = document.querySelectorAll(`[data-nav-section='${pathName}']`)[0];
  }

  if(!navSection) {
    navSection = document.getElementsByClassName('nav-section')[0]
  }

  if(!sideBarLink) {
    sideBarLink = document.getElementsByClassName('side-bar-links__link')[0]
  }

  if(sidebarNavigation) {
    domHelper.addClass(sidebarNavigation, "side-bar-navigation__list_active");
  }

  domHelper.addClass(navSection, "nav-section_active");
  domHelper.addClass(sideBarLink, "side-bar-links__link_active");

  sideBarContainer.scrollTo((sideBarLink.offsetLeft - indentLeft), 0);
}

function _updateMenu() {
  const sideBarLinksItem = document.getElementsByClassName("side-bar-links__item")[0];
  const sideBarClassNameActive = "side-bar-links__link_active";
  const navSectionClassNameActive = "nav-section_active";
  const sideBarContainer = document.getElementsByClassName("side-bar-links__container")[0];
  const indentLeft = 15;

  sideBarLinksItem.addEventListener("click", function(event) {
    event.preventDefault();

    const eventTarget = event.target;
    const linkAttr = eventTarget.getAttribute('data-tab-link');
    const navSection = document.getElementsByClassName("nav-section");
    const sideBarLinksLink = document.getElementsByClassName("side-bar-links__link");
    const navSectionActive =  document.querySelectorAll(`[data-nav-section='${linkAttr}']`)[0];

    domHelper.forEach(sideBarLinksLink, function(item) {
      domHelper.removeClass(item, sideBarClassNameActive);
    });

    domHelper.forEach(navSection, function(item) {
      domHelper.removeClass(item, navSectionClassNameActive);
    });

    domHelper.addClass(eventTarget, sideBarClassNameActive);
    domHelper.addClass(navSectionActive, navSectionClassNameActive);
    sideBarContainer.scrollTo(eventTarget.offsetLeft - indentLeft, 0);
  })
}

function _showMobileNavigation() {
  domHelper.addClass(nav, 'is-open');
  domHelper.addClass(header, 'is-shown');
  domHelper.addClass(header, 'show-side-bar');
  document.body.style.top = -domHelper.getPageScrollTop() + 'px';
  document.body.setAttribute('data-scroll', domHelper.getPageScrollTop());
  domHelper.addClass(document.body, 'stop-scrolling');
}

function _hideMobileNavigation() {
  domHelper.removeClass(nav, 'is-open');
  domHelper.removeClass(header, 'is-shown');
  domHelper.removeClass(header, 'show-side-bar');
  domHelper.removeClass(document.body, 'stop-scrolling');
  document.body.style.top = '';
  document.documentElement.scrollTop = document.body.getAttribute('data-scroll');
  document.body.removeAttribute('data-scroll');
}

function _moveBack(e) {
  e.preventDefault();
  var el = domHelper.closest(e.target, 'ul');

  // if we have wrapper
  if (domHelper.hasClass(el.parentNode, 'js-menu-category-wrapper')) {
    el = el.parentNode;
  }

  if (el.parentNode.parentNode.children.length <= 3) { //if true it means that there is no first level menu only second
    domHelper.removeClass(el.parentNode.parentNode, 'in'); // so let's close parent of the parent
  } else {
    domHelper.removeClass(domHelper.closest(e.target, 'ul'), 'in');
  }
  _resetMenuHeight();
}

function _calculateMenuHeight(e) {
  var id = e.currentTarget.getAttribute('data-target');
  var nextContainer = document.getElementById(id.slice(1));

  if (nextContainer) {
    if (nextContainer.children.length <= 3) { //if true it means that there is no first level menu only second
      var list = nextContainer.querySelector('ul');

      if (list && menu[0].offsetHeight <= list.offsetHeight) {
        menu[0].style.height = list.offsetHeight + 'px';
      }
    } else if (menu[0].offsetHeight <= nextContainer.offsetHeight) {
      menu[0].style.height = nextContainer.offsetHeight + 'px';
    }
  }
  menuWrapper.classList.add('menu__wrp--open');
}

function _resetMenuHeight() {
  menu[0].style.height = '';
  menuWrapper.classList.remove('menu__wrp--open');
}

function searchChangeHandler(e) {
  // calculate proper height
  const searchBox = e.target.closest('.search-box');
  const searchBoxBounds = searchBox.getBoundingClientRect();
  const autocompleteBox = document.querySelector('#menu-collapse .suggestions-autocomplete--mobile');
  const searchValue = e.target.value;
  // calculate search box height
  autocompleteBox.style.maxHeight = (window.innerHeight - searchBoxBounds.height - searchBoxBounds.y - 5) + 'px';
  if (!searchValue || searchValue.length < 3) {
    autocompleteBox.innerHTML = '';
    prevSearchValue = null;
    return;
  }
  // don't perform any search in case search is still open and
  if (searchValue === prevSearchValue) {
    return;
  }
  domHelper.ajax(`/suggestions?prefix=${searchValue}`, {
    type: 'GET',
    success: function (data) {
      let resultStr = '';
      data.forEach((item) => resultStr += suggestionTpl(item));
      autocompleteBox.innerHTML = resultStr;
      prevSearchValue = searchValue;
    }
  });
}

function onSearchFormSubmit(event) {
  let searchInput = headerForm.querySelector('[name="q"]');

  if(searchInput) {
    DY.API("event", {
      name: "Keyword Search",
      properties: {
        dyType: "keyword-search-v1",
        keywords: searchInput.value
      }
    });
  }
}

export default {
  init: init,
  hideMobileNavigation: _hideMobileNavigation,
  triggerMobileNavigation: _triggerMobileNavigation
};

