import tpl from './helpPopover.mst.html';
import domHelper from 'Helpers/domHelper';
import global from 'global';

class HelpPopover {
  constructor(config) {
    this.elemToInsert = config.placeholder;
    this.elemToInsert.innerHTML = tpl(config);
    // cache elements
    this.elemHeader = this.elemToInsert.querySelector('.help-popover__title');
    this.elemContent = this.elemToInsert.querySelector('.help-popover__container');
    if (global.isMobile) {
      this._bindMobileEvents();
    } else {
      this._bindDesktopEvents();
    }
  }
  _bindMobileEvents() {
    this.elemHeader.addEventListener('click', () => {
      domHelper.toggleClass(this.elemContent, 'is-hidden');
    });
    let cartOverlayInner = document.querySelector('.cart-overlay__inner');
    if (cartOverlayInner) {
      cartOverlayInner.addEventListener('click', e => {
        if (!domHelper.closest(e.target, '.help-popover')) {
          this._hidePopover();
        }
      });
    }
  }
  _hidePopover() {
    domHelper.addClass(this.elemContent, 'is-hidden');
  }
  _bindDesktopEvents() {
    this.elemToInsert.addEventListener('mouseover', () => {
      clearTimeout(this.popoverTimeout);
      domHelper.removeClass(this.elemContent, 'is-hidden');
    });
    this.elemToInsert.addEventListener('mouseout', () => {
      this.popoverTimeout = setTimeout(() => {
        domHelper.addClass(this.elemContent, 'is-hidden');
      }, 100);
    });
  }
}

export default HelpPopover;