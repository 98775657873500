import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import PubSub from 'pubsub';
import { EVENT_DY_PROMOBAR_INIT_V6_CATALOG_EMAIL } from 'Constants/event';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { EmailPopup, Popup } from '@juniqe/email-popup';
import { theme } from '../../../common/theme';
import store from '../../../reduxStore';

const FloatingEmailPopup = ({ dyData }) => {
  return (
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <Popup />
        <EmailPopup dyCampaignData={dyData} />
      </MuiThemeProvider>
    </Provider>
  );
};

const renderPopup = dyData => {
  if (!dyData) return;
  const container = document.getElementById('floating-email-popup-root');
  ReactDOM.render(<FloatingEmailPopup dyData={dyData} />, container);
};

PubSub.subscribe(EVENT_DY_PROMOBAR_INIT_V6_CATALOG_EMAIL, renderPopup);
