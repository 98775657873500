var iframes,
    id = 0;

// This method is called by Youtube script
window.onYouTubeIframeAPIReady = function () {
  for (var iframeID in iframes) {
    iframes[iframeID].player = new YT.Player(iframeID, {
      videoId: iframes[iframeID].videoID,
      height: 200,
      events: {
        onStateChange: _onYTPlayerStateChange
      }
    });
  }

  $('.js-youtube-thumbnail').on('click', function (e) {
    var iframeID = e.currentTarget.getAttribute('data-frame-id');

    $(e.currentTarget).hide();
    $('#' + iframeID).show();

    iframes[iframeID].player.playVideo();
  });
};

function _onYTPlayerStateChange(event) {
  var iframe;
  // if paused
  if (event.data === 2) {
    iframe = event.target.getIframe();
    $(iframe).hide();
    iframes[iframe.id].thumbnail.show();
  }
}

function handle(slides) {
  var slidesWithIframes = [].filter.call(slides, function (slide) {
    var el = slide.children[0];
    return el.tagName === 'IFRAME' && /youtube/.test(el.src);
  });

  if (slidesWithIframes.length) {
    iframes = slidesWithIframes.reduce(function (acc, slide) {
      slide = $(slide);
      var iframeID = _getIframeID();
      var $wrapper = $('<div id="' + iframeID + '">');
      var $iframe = $(slide.children()[0]);
      slide.prepend($wrapper);
      $wrapper.append($iframe);
      $wrapper.hide();

      var videoID = _getVideoID($iframe);

      var $thumbnail = _createThumbnail(iframeID, videoID);
      slide.append($thumbnail);

      acc[iframeID] = {
        iframe: $iframe,
        thumbnail: $thumbnail,
        videoID: videoID
      };
      return acc;
    }, {});

    _callYoutube();
  }
}

function _getIframeID() {
  return 'jn-player-' + id++;
}

function _getVideoID($iframe) {
  var videoID = $iframe.attr('src').split('/');
  videoID = videoID[videoID.length - 1];
  var ampersandPosition = videoID.indexOf('?');
  if (ampersandPosition !== -1) {
    videoID = videoID.substring(0, ampersandPosition);
  }

  return videoID;
}

function _createThumbnail(iframeID, videoID) {
  var $thumbnail = $('<div class="js-youtube-thumbnail relative" data-frame-id="' + iframeID + '">');
  var $thumbnailImg = $('<img src="http://img.youtube.com/vi/' + videoID + '/0.jpg">');
  var $playIcon = $('<span class="icon-video-play"><i class="icon icon-back-to-top-arrow"></i></span>');

  $thumbnail.append($thumbnailImg);
  $thumbnail.append($playIcon);

  return $thumbnail;
}

function _callYoutube() {
  if (!_callYoutube.done) {
    var tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    _callYoutube.done = true;
  }
}

export default {
  handle: handle
};

