// DY config default value (in case experiment is down)
let config = {
  maxPages: 8
};

const getConfig= () => {
  return config;
};

export default {
  getConfig
};
