import PubSub from 'pubsub';
import {EVENT_DY_MOBILE_NAV_BAR_POSITION} from 'Constants/event'

function positionNavBar(params) {
    const {position} = params;
    const classNameNavBarMobile = `nav-bar-${position}`;
    const bodyElement = document.getElementsByTagName("body")[0];

    bodyElement.classList.add(classNameNavBarMobile)
}

PubSub.subscribe(EVENT_DY_MOBILE_NAV_BAR_POSITION, (params) => positionNavBar(params));
