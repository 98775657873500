const CURRENCY_CODES = {
  'com': 'EUR',
  'de': 'EUR',
  'fr': 'EUR',
  'nl': 'EUR',
  'ch': 'CHF',
  'uk': 'GPB',
  'se': 'SEK',
  'dk': 'DKK'
};

let domain = window.location.hostname.split('.').pop();

export default {
  shopDomain: domain,
  shopCurrencyCode: CURRENCY_CODES[domain]
}