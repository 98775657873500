const isDev = window.location.host.includes('staging') || window.location.host.includes('localhost');

export default {
  dyNavbarABtest: false, // If AB test enabled "header--hidden" class can be added to the header element in main.scala.html to avoid flickering,
  // TODO: can we do this through scala templates conditions and general application.conf?
  isDev,
  emailCollectionUrl: isDev ? 'https://emails.stage.juniqe.com/collect' : 'https://emails.prod.juniqe.com/collect',
  unsubscribeReasonUrl: isDev
    ? 'https://cart-api.stage.juniqe.com/feedback/unsubscribe'
    : 'https://cart-api.prod.juniqe.com/feedback/unsubscribe'
};
