import domHelper from 'Helpers/domHelper';
import global from 'global';

let leftNavVisible = false;
let leftNavHeaders;
let mainNode = document.querySelector('#main');

function init() {
    _moveNavigation();
    _bindEvents();
}

function _bindEvents() {
  if (global.isPhone || global.isTablet) {
    mainNode.addEventListener('click', _toggleLeftNav);
  }
}

function _moveNavigation() {
  if (global.isPhone || global.isTablet) {
    let sidebarNavigation = main.querySelector('.gifting-content__navigation');
    let navigationPlaceholder = main.querySelector('.navigation-placeholder');

    if(sidebarNavigation && navigationPlaceholder) {
      navigationPlaceholder.appendChild(sidebarNavigation);
    }
  }
}

function _toggleLeftNav(e) {
  let target = e.target;
  let isAnchor = target.matches('a') && !domHelper.hasClass(target, 'left-nav__title');
  let hasLeftNavParent = !!target.closest('.left-nav');
  // Check if the clicked element is not a link
  if(hasLeftNavParent && !isAnchor) {
    e.preventDefault();
    leftNavHeaders = mainNode.querySelectorAll('.left-nav__header');

    let thisNav = domHelper.closest(target, '.left-nav');

    if(thisNav) {
      [].forEach.call(leftNavHeaders, (el) => {
        domHelper.removeClass(el, 'show-nav');
      });

      if (!leftNavVisible) {
        domHelper.addClass(thisNav, 'show-nav');
        leftNavVisible = true;
      }
      else {
        domHelper.removeClass(thisNav, 'show-nav');
        leftNavVisible = false;
      }
    }
  }
}

export default {
  init: init,
  moveNavigation: _moveNavigation,
};