import global from 'global';
import Modal from 'Common/modal/modal';
import redirectDomainMobileOverlay from "../redirectDomainPopup/redirectDomainMobileOverlay.mst.html";

function init() {
    if (global.isMobile) {
        [].forEach.call(document.querySelectorAll('#redirect-domain-popup'), (redirectDomainPopup) => {
            redirectDomainPopup.addEventListener('click', () => {
                showModal();
            });
        });
    }
}

function getLinks(){
    return document.querySelectorAll("#header-domain__dropdown-desktop")[0].innerHTML
}

function mobileDropDownTitle() {
    return document.querySelectorAll(".header__tooltip--domain .header__tooltip--title strong")[0].textContent;
}

function showModal(){
    let modal;

    function _onModalCreate(){
        let btnClose = document.querySelector('.overlay-header__close-btn');
        if (btnClose) {
            btnClose.addEventListener('click', () => {
                modal.close();
            });
        }
    }

    const bodyTpl = redirectDomainMobileOverlay({
        label: mobileDropDownTitle(),
        htmlContent: getLinks()
    });

    modal = new Modal({
        className: 'modal--redirect',
        heading: bodyTpl.label,
        body: bodyTpl,
        onCreate: _onModalCreate
    });
    modal.open();
}

export default {
    init
}

