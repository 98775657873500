'use strict';

import global from 'global';
import PubSub from 'pubsub';
import DOM from 'Helpers/domHelper';
import React from 'react';
import { ID_CANVAS } from 'Constants/misc';
import { CLASS_WISHLIST_TOGGLE_CAROUSEL_ELEMENT, CLASS_WISHLIST_TOGGLE_ELEMENT } from 'Constants/class';
import { EVENT_DY_REDIRECT_TO_MINI_PDP } from 'Constants/event';
import { getDefaultUrl } from 'DynamicYield/productViewPage/productViewPage';
import { calcDiscountPrice, calcDiscountRemainingTime } from '../../../dy/priceDiscount/priceDiscount';

let addSimilarParam = true;

PubSub.subscribe(EVENT_DY_REDIRECT_TO_MINI_PDP, function () {
  addSimilarParam = false;
});

export default class HeadedProductPane extends React.Component {
  constructor(props) {
    super(props);
    this.imgRef = React.createRef();
    this.redrawElement = this.redrawElement.bind(this);
  }

  componentDidMount() {
    let waitForWishlistInitData = setInterval(() => {
      if (window._wishlist) {
        clearInterval(waitForWishlistInitData);

        if (window._wishlist.items.indexOf(Number(this.props.id)) >= 0) {
          DOM.addClass(this.favButton, 'is-active');
        }
      }
    }, 100);
  }

  redrawCanvas() {
    DOM.redraw(this.canvasImage);
  }

  redrawElement(elRef) {
    if (elRef.current) {
      DOM.redraw(elRef.current);
    }
  }

  getImageWithSecondaryImage() {
    const primaryImage =
      this.props.typeId === ID_CANVAS ? (
        <div
          className="canvas"
          ref={el => {
            super.canvasImage = el;
          }}
        >
          <div className="canvas__container">
            <img
              className="product__img product__img--primary"
              onLoad={this.redrawCanvas.bind(this)}
              src={this.props.image.main.url}
              alt={this.props.image.main.alt}
            />
          </div>
          <div className="canvas__side">
            <img className="canvas__side-img" src={this.props.image.canvasSide} />
          </div>
        </div>
      ) : (
        <img
          className="qa_category_image product__img product__img--primary"
          src={typeof this.props.image === 'string' ? this.props.image : this.props.image.main.url}
        />
      );

    const secondaryImage = (
      <img
        className="product__img product__img--secondary product__img--secondary-vir rec-item-product__img--secondary-vir"
        src={this.props.image.secondary.url}
        alt={this.props.image.secondary.alt}
      />
    );

    return (
      <span className="product__figure-wrapper">
        <span className="product__figure-container">
          <figure className="product__figure qa_product__figure">
            {primaryImage}
            {secondaryImage}
          </figure>
        </span>
      </span>
    );
  }

  getImageWithoutSecondaryImage() {
    const primaryImage =
      this.props.typeId === ID_CANVAS ? (
        <div
          className="canvas"
          ref={el => {
            super.canvasImage = el;
          }}
        >
          <div className="canvas__container">
            <img
              onLoad={this.redrawCanvas.bind(this)}
              src={this.props.image.main.url}
              alt={this.props.image.main.alt}
              className="product__img"
            />
          </div>
          <div className="canvas__side">
            <img className="canvas__side-img" src={this.props.image.canvasSide} />
          </div>
        </div>
      ) : (
        <img
          ref={this.imgRef}
          className="rec-item__picture"
          onLoad={() => this.redrawElement(this.imgRef)} // Safari fix - images rendered with incorrect size sometimes
          src={typeof this.props.image === 'string' ? this.props.image : this.props.image.main.url}
        />
      );

    return (
      <div
        className={`rec-item__image rec-headed-item__image ${
          this.props.typeId ? `rec-item__image--${this.props.typeId}` : ''
        } ${this.props.image.secondary ? 'rec-item__image--secondary' : ''} rec-item__image-o${this.props.orientation}`}
        style={{ overflow: 'hidden' }}
      >
        {primaryImage}
      </div>
    );
  }

  getItemPrice(oldPrice, priceDiscount) {
    if (!oldPrice) return null;

    const labels = [];
    const fromLabel = window._i18n.pdpEmbedded.from;

    if (priceDiscount) {
      const discountAmount = Number(priceDiscount.discount);

      if (discountAmount > 0) {
        const { oldPriceAsNumber, newPriceWithCurrency } = calcDiscountPrice(oldPrice, priceDiscount);
        labels.push(<span className="modal--filterByDesign-mobile-new-price">{fromLabel}</span>);
        labels.push(<span className="modal--filterByDesign-mobile-new-price">{newPriceWithCurrency}</span>);
        labels.push(<span className="modal--filterByDesign-mobile-old-price">{oldPriceAsNumber}</span>);
        labels.push(
          <span className="flex pdp__product-info__discount-description">
            {calcDiscountRemainingTime(priceDiscount)}
          </span>
        );
      } else {
        labels.push(<span className="modal--filterByDesign-mobile-bold">{oldPrice}</span>);
        labels.push(
          <span className="flex pdp__product-info__discount-description">
            {calcDiscountRemainingTime(priceDiscount)}
          </span>
        );
      }
    } else {
      labels.push(<span className="modal--filterByDesign-mobile-bold">{oldPrice}</span>);
    }

    return (
      <div className="modal--filterByDesign-mobile-price-container">
        {labels
          .reduce(
            (acc, cv, i) => [...acc, <span key={`space-${i}`}>&nbsp;</span>, <span key={`item-${i}`}>{cv}</span>],
            []
          )
          .splice(1)}
      </div>
    );
  }

  render() {
    const { price, priceDiscount, handleProductMouseIn, handleProductMouseOut, handleProductClick } = this.props;

    const artistInfo = this.props.artist ? (
      <span className="rec-item__data-row rec-item__data-row--artist">
        {window._i18n.carousel.byArtistLabel} {this.props.artist}
      </span>
    ) : null;
    const productTypeInfo =
      this.props.productType && !this.props.productTypeCompactDisplay ? (
        <span className="rec-item__data-row rec-item__data-row--product-type">{this.props.productType}</span>
      ) : null;
    const designNameInfo = this.props.designName ? (
      <span className="rec-item__data-row rec-item__data-row--design-name rec-headed-item__data-row--design-name">
        {this.props.productType && this.props.productTypeCompactDisplay
          ? `${this.props.designName}, ${this.props.productType}`
          : this.props.designName}
      </span>
    ) : null;
    const priceInfo = this.getItemPrice(price, priceDiscount);
    const image =
      this.props.image.secondary && global.isDesktop
        ? this.getImageWithSecondaryImage()
        : this.getImageWithoutSecondaryImage();

    const wishlistButton = (
      <span
        className={`rec-item__wishlist-button ${
          this.props.type === 'artist' ? 'rec-headed-item__add-to-favorites-container' : ''
        }`}
      >
        <button
          className={`${CLASS_WISHLIST_TOGGLE_ELEMENT} ${CLASS_WISHLIST_TOGGLE_CAROUSEL_ELEMENT} product__wishlist-action product__wishlist-action--carousel rec-item__add-to-favorites rec-headed-item__add-to-favorites`}
          ref={el => {
            super.favButton = el;
          }}
          data-wishlist-add-text={window._i18n.carousel.addedToWishlist}
          data-wishlist-remove-text={window._i18n.carousel.removedFromWishlist}
          aria-label={window._i18n.carousel.addedToWishlist}
        >
          <span className="wishlist-tooltip headed-wishlist-tooltip">
            <span className="headed-wishlist-tooltip--helper" />
            <i className="i-wishlist-headed" />
            <span className="wishlist-tooltip__text wishlist-tooltip__text--top">
              <span className="wishlist-tooltip__add">{window._i18n.carousel.addToWishlist}</span>
              <span className="wishlist-tooltip__remove">{window._i18n.carousel.addedToWishlist}</span>
            </span>
          </span>
        </button>
      </span>
    );

    return (
      <a
        className={`rec-item product__preview--double js-product ${
          this.props.isCurrentProduct ? 'rec-item--active' : ''
        }`}
        onMouseEnter={e => handleProductMouseIn(e.currentTarget, this.props.el)}
        onMouseLeave={e => handleProductMouseOut(e.currentTarget, this.props.el)}
        onClick={e => handleProductClick(e.currentTarget, this.props.el)}
        href={getDefaultUrl({
          url: this.props.url,
          id: this.props.id
        })}
        data-product-id={this.props.id}
      >
        {image}
        <div className="rec-headed-item-details">
          <div className="rec-headed-item__description">
            <div className="rec-item__data">
              {designNameInfo}
              {artistInfo}
              {productTypeInfo}
              {priceInfo}
            </div>
            {wishlistButton}
          </div>
        </div>
      </a>
    );
  }
}
