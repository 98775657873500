import React from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import BrushIcon from '../../../images/icons/svg/brush-active.svg';
import DeliveryIcon from '../../../images/icons/svg/delivery-active.svg';
import WallartIcon from '../../../images/icons/svg/wallart-active.svg';

const UspBar = () => {
  const { t } = useTranslation();

  return (
    <div className="usp-bar">
      <div className="usp-bar__item">
        <img className="usp-bar__icon" src={BrushIcon} alt="brush icon" width="20px" />
        {t('gen-usp-artists')}
      </div>

      <span className="usp-bar__separator">•</span>

      <div className="usp-bar__item">
        <img className="usp-bar__icon" src={DeliveryIcon} alt="delivery icon" width="30px" />
        {t('gen-usp-panel-shipping')}
      </div>

      <div className="usp-bar__item usp-bar__item--hidden-mobile">
        <img className="usp-bar__icon" src={WallartIcon} alt="wallrt icon" width="20px" />
        {t('gen-usp-ready-to-hang')}
      </div>
    </div>
  );
};

const container = document.getElementById('usp-bar');

if (container) {
  ReactDOM.render(<UspBar />, container);
}
