'use strict';

export const URL_HOSTNAME = window.location.hostname;
export const URL_WISHLIST_ADD = '/wishlist/add';
export const URL_WISHLIST_REMOVE = '/wishlist/remove';
export const URL_WISHLIST_SELECTION_ADD = '/wishlist-selection/add/';
export const URL_WISHLIST_SELECTION_REMOVE = '/wishlist-selection/remove/';
export const URL_WISHLIST_PAGE = '/wishlist';
export const URL_CART_ADD = '/cart/add';
export const URL_CART_ADD_SELECTION = '/cart/addSelection';
export const URL_CART_PAGE = '/checkout/cart';
export const URL_UPSELL_REQUEST_OPTIONS = '/api/upsell/product';
export const URL_CART_REPLACE_OPTIONS = '/api/cart/replace';
export const URL_NPS_FEEDBACK = '/npsSurvey';