import global from 'global';
import domHelper from 'Helpers/domHelper';


function CustomSelectModel(selectEl) {
  this.el = selectEl;
}

CustomSelectModel.prototype.serialize = function () {
  var selectData = {},
      selectedOption;

  selectedOption = this._getSelected(); // assume that we work only with single select dropdowns.

  if (selectedOption) {
    selectData.selected = {
      caption: selectedOption.text,
      placeholder: selectedOption.disabled
    };
  }

  selectData.options = domHelper.map(this.el.options, function (option) {
    return {
      caption: option.text,
      value: option.value,
      disabled: option.disabled,
      selected: option.selected,
      badge: option.getAttribute('data-select-badge')
    };
  });

  return selectData;
};

CustomSelectModel.prototype.setSelected = function (fakeOption) {
  var value = fakeOption.getAttribute('data-value');

  for (var i = 0; i < this.el.options.length; i++) {
    if (global.isLteIE9) {
      if (this.el.options[i].value === value) {
        this.el.options[i].setAttribute('selected', 'selected');
      } else {
        this.el.options[i].removeAttribute('selected');
      }
    } else {
      this.el.options[i].selected = this.el.options[i].value === value;
    }
  }

  domHelper.dispatchEvent(this.el, 'change');
};

CustomSelectModel.prototype._getSelected = function () {
  var selectedOption;

  selectedOption = this.el.selectedOptions ?
      this.el.selectedOptions[0] :
      domHelper.findOne(
          this.el.options,
          function (option) {
            return option.selected === true;
          }
      );

  return selectedOption;
};

export default CustomSelectModel;

