import * as Sentry from '@sentry/browser';

let body = document.body,
    release,
    environment;
if(body) {
  release = body.getAttribute('data-release');
  environment = body.getAttribute('data-environment');
}
Sentry.init({
  dsn: 'https://8923989fc66c49f18cf1ab606dc7f2eb@sentry.io/1377642',
  attachStacktrace: true,
  release: release,
  enabled: true,
  environment: environment
});

Sentry.configureScope(scope => {
  scope.addEventProcessor((event, hint) => {
    // Add anything to the event here
    // returning null will drop the event
    if(!!event.extra) {
      return event;
    }
    // Prevent Sentry from logging the error twice (by default is captures the error and log it + our custom error logging)
    return null;
  });
});


window.onerror = function(msg, url, lineNo, columnNo, error) {
  var isScriptError = msg.toLowerCase().indexOf('script error') === 0;
  var isInternalError = url.indexOf(window.location.origin) >= 0;
  var message = isScriptError ? {
    'Message': 'Script Error: See Browser Console for Detail',
    'URL': document.URL,
    'Is script error': isScriptError
  } : {
    'Message': msg,
    'URL': url,
    'Line': lineNo,
    'Column': columnNo,
    'Error object': error,
    'Is script error': isScriptError,
    'Is internal error': isInternalError,
  };
  Sentry.captureMessage(message);
};
