'use strict';

import React from 'react';
import ProductsList from './ProductsList';

export default class MultiProductsLists extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeVerticalId: 0,
      activeCarouselItems: this.props.verticals[0].items
    };
  }

  verticalClickHandler(id, items) {
    if (this.state.activeVerticalId !== id) {
      this.setState({
        activeVerticalId: id,
        activeCarouselItems: items
      })
    }
  }

  componentDidMount() {
    const {activeProductId, verticals} = this.props;

    const activeProductItems = verticals.filter((item) => {
      return item.id === activeProductId
    });

    this.setState({
      activeVerticalId: activeProductId,
      activeCarouselItems: activeProductItems[0].items
    });
  }

  render() {
    const tabTitles = this.props.verticals.map((vert, i) => (
      <button className={`other-products__nav-item ${this.state.activeVerticalId === vert.id ? 'is-active' : ''}`}
              key={i}
              onClick={() => this.verticalClickHandler(vert.id, vert.items)}>
        <span className="other-products__nav-item__wrp">{vert.name}</span>
      </button>
    ));

    return (<div className="other-products">
      <div className="pdp-section__title">
        {this.props.title}
      </div>
      <div className="other-products__nav">
        {tabTitles}
      </div>
      <div className="other-products__carousel">
        <ProductsList products={this.state.activeCarouselItems} />
      </div>
    </div>);
  }
}
