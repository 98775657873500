import domHelper from 'Helpers/domHelper';
import stickyHelper from 'Common/sticky/stickyHelper';


var backgroundElementIds,
    backgroundElements,
    cachedScrollTop = 0;

function init() {
  backgroundElementIds = ['header', 'main', 'footer']; // elements to hide
  _cacheDom();
}

function _cacheDom() {
  backgroundElements = backgroundElementIds.map(function (id) {
    return document.getElementById(id);
  });
}

function prepareOverlay() {
  stickyHelper.changeStateToNegative(stickyHelper.frozenHeaderStateId);
  cachedScrollTop = stickyHelper.getScrollTop();
  window.scrollTo(0, 0);


  backgroundElements.forEach(function (el) {
    domHelper.addClass(el, 'hide-under-overlay');
  });
}

function cleanupOverlay() {
  backgroundElements.forEach(function (el) {
    domHelper.removeClass(el, 'hide-under-overlay');
  });

  window.scrollTo(0, cachedScrollTop);
}

export default {
  init: init,
  prepare: prepareOverlay,
  cleanup: cleanupOverlay
};

