import global from 'global';
import domHelper from 'Helpers/domHelper';

var menu,
    menuList,
    flyout,
    closeTimeout,
    menuListLength,
    cartMobile,
    wishListMobile;

function init() {
  _cacheDom();
  _bindEvents();

  _executeOnDomReady();

}

function _cacheDom() {
  menu = document.querySelector('.js-menu__wrp');
  wishListMobile = document.querySelector('.header__wishlist_nav-bar');
  cartMobile = document.querySelector('.ajax-cart_nav-bar');
  wishListMobile.remove();
  cartMobile.remove();

  if(menu) {
    menuList = menu.querySelectorAll('.js-menu__item--top');
    flyout = menu.querySelector('.js-menu__desktop-flyout');
    menuListLength = menuList.length;
  }
}

function _bindEvents() {
  if(menu) {
    for (var i = 0; i < menuListLength; i++) {
      menuList[i].addEventListener('mouseenter', _onMenuItemMouseEnter);
    }

    flyout.addEventListener('mouseenter', _cancelClose);
    menu.addEventListener('mouseleave', _onMenuMouseLeave);
  }
}

function _onMenuItemMouseEnter(e, el) {
  const currentEl = el || e.currentTarget;
  const flyoutContent = currentEl.querySelector('.js-menu-category-wrapper') && currentEl.querySelector('.js-menu-category-wrapper').innerHTML;
  if (e && (domHelper.closest(e.target, '.no-flyout') || domHelper.hasClass(e.target, 'no-flyout')) || !flyoutContent) {
    domHelper.addClass(menu, 'menu__wrp--no-flyout');
    return;
  } else {
    domHelper.removeClass(menu, 'menu__wrp--no-flyout');
  }
  _cancelClose();
  !!flyout && (flyout.innerHTML = flyoutContent);
}

function _cancelClose() {
  closeTimeout && clearInterval(closeTimeout);
}

function _onMenuMouseLeave(e) {
  // Added 300ms delay for event listener just for content not to disappear before the animation ends
  closeTimeout = setTimeout(function () {
    !!flyout && (flyout.innerHTML = "");
  }, 300);
}

function _executeOnDomReady() {
  var interval = setInterval(function () {
    if (document.readyState === 'complete') {
      clearInterval(interval);
      _onReady();
    }
  }, 100);
}

function _onReady() {
  if (document.querySelector('.menu__link-l1:hover')) {
    _onMenuItemMouseEnter(null, document.querySelector('.menu__link-l1:hover').parentNode);
  }
}

export default {
  init: init
};

