import DOM from 'Helpers/domHelper';
import PubSub from 'pubsub';
import { insertHeadedProductCarousel } from 'Common/productsCarousel/productsCarousel';
import { EVENT_DY_RECOMMENDATIONS_INIT } from 'Constants/event';

// priceAsString is on format `from 6.99` in dy feed
const priceWithCurrency = (priceAsString, currency) => {
  const getPriceAsNumber = priceAsString => {
    const onlyNumbersRegex = /[+-]?\d+(\.\d+)?/g;
    const hasComma = priceAsString.includes(',');
    const joinChar = hasComma ? '.' : '';

    return Number(priceAsString.match(onlyNumbersRegex).join(joinChar));
  };
  const price = getPriceAsNumber(priceAsString);

  switch (currency) {
    case 'EUR':
      return `€ ${price}`;
    case 'CHF':
      return `CHF ${price}`;
    case 'GBP':
      return `£${price}`;
    case 'SEK':
      return `${price} kr`;
    case 'DKK':
      return `${price} kr`;
  }
};

const prepareParameters = params => ({
  title: params.title,
  targetId: params.targetId,
  productTypeDisplayed: params.productTypeDisplayed,
  products: params.items.map(el => {
    const artistDisplayed = el.artistDisplayed !== 'false';
    return {
      id: el.id,
      url: el.url,
      image: el.image,
      designName: el.name,
      category_ids: el.category_ids,
      price: `${window._i18n.pdpEmbedded.from} ${priceWithCurrency(el.price, el.currency)}`,
      artistDisplayed,
      artist: el.artist && artistDisplayed ? el.artist : false,
      productType: el.productType && el.productTypeDisplayed === 'true' ? el.productType : false,
      productTypeCompactDisplay: el.productTypeCompactDisplay !== 'false'
    };
  }),
  headerInfo: {
    title: params.title,
    subTitle: params.subTitle,
    description: params.description,
    seeMore: params.seeMore
  }
});

const init = params => {
  if (params) {
    const targetId = `dy-recs-${window._pageMeta.pageAlias}-${params.targetBlock}`;
    // eslint-disable-next-line no-param-reassign
    params.targetId = targetId;
    const options = prepareParameters(params);
    const initCarousel = insertHeadedProductCarousel.bind(null, options);

    DOM.waitForElement(`#${targetId}`).then(initCarousel);
  }
};

PubSub.subscribe(EVENT_DY_RECOMMENDATIONS_INIT, init);
