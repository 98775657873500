import global from 'global';

const ATTRIBUTES = {
  desktop: 'data-jnq-desktop-src',
  mobile: 'data-jnq-mobile-src'
};

function _replaceFakeImageSource(image, attrName) {
  let imageSrc = image.getAttribute(attrName);
  image.setAttribute('src', imageSrc);
  image.removeAttribute(attrName);
}

function init() {
  // load desktop images
  let targetDeviceAttr = global.isDesktop ? ATTRIBUTES.desktop: ATTRIBUTES.mobile;
  [].forEach.call(document.querySelectorAll(`[${targetDeviceAttr}]`), item => _replaceFakeImageSource(item, targetDeviceAttr));
}

export default {
  init: init
}