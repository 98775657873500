import tpl from './emailTheCart.mst.html';
import PubSub from 'pubsub';
import {
  EVENT_DY_EMAIL_THE_CART_AJAX,
  EVENT_ATC_OVERLAY_RENDER,
  EVENT_AJAX_CART_OVERLAY_RENDER,
  EVENT_DY_INIT_ATC
} from 'Constants/event';
import HelpPopover from 'Common/helpPopover/helpPopover';
import atc from 'DynamicYield/addToCartOverlay/addToCartOverlay';
import Validate from 'Common/validate';
import Toast from 'Common/toast';
import CookieHelper from 'Helpers/cookieHelper';

class EmailTheCart {
  constructor(config) {
    // populate config with additional fields
    config.formId = 'email-the-cart__form' + parseInt(Math.random() * 100);
    config.translation = window._i18n.emailTheCart;
    let elementToInsert = document.querySelector(config.placeholder);
    if (!elementToInsert) return;
    elementToInsert.innerHTML = tpl(config);
    this.config = config;
    this.element = elementToInsert;
    // add popover
    new HelpPopover({
      placeholder: this.element.querySelector('.email-the-cart__popover-placeholder'),
      headerText: window._i18n.emailTheCart.tooltipHeader,
      contentText: window._i18n.emailTheCart.tooltipContent
    });
    // enable validation
    this.validation = new Validate(config.formId, [{
      name: 'email',
      rules: 'validEmail'
    }]);
    this._bindEvents();
  }
  _bindEvents() {
    let self = this;
    this.element.querySelector('.email-the-cart__form').addEventListener('submit', function (e) {
      e.preventDefault();
      if (self.validation.isOk()) {
        let userEmail = this.email.value;
        CookieHelper.setCookie('email_captured', userEmail);
        new Toast({
          content: `<div class="pdp__email-cart-toast">
              <i class="i-mail pdp__email-cart-toast-icon"></i>
              <div>
                <span class="pdp__email-cart-title">${self.config.translation.toastHeader}</span>
                <span class="pdp__email-cart-content">${self.config.translation.toastContent}</span>
              </div>
            </div>`,
          position: 'bottom',
          deadline: 2000,
          type: 'emailTheCart'
        });
        this.email.value = '';
        // before dispose actions
        if (self.config.type === 'AJAX_CART_OVERLAY') {
          document.querySelector(self.config.placeholder).innerHTML = '';
        }
        if (self.config.type === 'ATC_OVERLAY') {
          atc.close();
        }
      }
    });
  }
}

function _init(config, dyEventName) {
  let isCookieSet = CookieHelper.getCookie('email_captured');
  if (!Boolean(parseInt(config.emailTheCart))) return;
  if (dyEventName === EVENT_DY_INIT_ATC) {
    PubSub.subscribe(EVENT_ATC_OVERLAY_RENDER, () => {
      if (isCookieSet) return;
      new EmailTheCart({
        type: 'ATC_OVERLAY',
        placeholder: '#emailTheCartATCPlaceholder',
        themeClassName: 'email-the-cart--atc-overlay'
      });
    });
  }
  if (dyEventName === EVENT_DY_EMAIL_THE_CART_AJAX) {
    PubSub.subscribe(EVENT_AJAX_CART_OVERLAY_RENDER, () => {
      if (isCookieSet) return;
      new EmailTheCart({
        type: 'AJAX_CART_OVERLAY',
        placeholder: '#emailTheCartAjaxCartPlaceholder',
        themeClassName: 'email-the-cart--ajax-cart-overlay'
      });
    });
  }
}

PubSub.subscribe(EVENT_DY_INIT_ATC, config => {
  _init(config, EVENT_DY_INIT_ATC);
});

PubSub.subscribe(EVENT_DY_EMAIL_THE_CART_AJAX, config => {
  _init(config, EVENT_DY_EMAIL_THE_CART_AJAX);
});
