import domHelper from './domHelper';
import Mustache from 'mustache';


var tpls = {};

function _process(tplName, jsonView, callback) {
  let tpl,
      tplKey = tplName + (jsonView ? JSON.stringify(jsonView) : '');
  if (!tpls[tplKey]) {
    domHelper.ajax('/mustache/' + tplName, {
      json: false,
      data: jsonView,
      success: function (rawTpl) {
        tpls[tplKey] = rawTpl;
        tpl = Mustache.render(rawTpl, jsonView);

        callback(tpl, jsonView);
      }
    });
  } else {
    tpl = Mustache.render(tpls[tplKey], jsonView);
    callback(tpl, jsonView);
  }
}

export default {
  process: _process
};

