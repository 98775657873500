import Slider from './slider';
import global from 'global';
import j from 'Helpers/domHelper';
import jf from 'Helpers/funcHelper';
import VideoSlides from './videoSlides';


// the same slider but with simplified set up
// needed html setup markup is simplified:
/*
  <div class="js-jslider" data-main-class=""> // data-main-class - to set the class on the main "root" element
    <div></div>
    <div></div>
    ...
  </div>
*/

function init(targetClassName, options) {
  var targets = document.getElementsByClassName(targetClassName);
  var els,
      slider;

  for (var i = 0; i < targets.length; i++) {
    _markItems(targets[i]);

    els = _wrap(targets[i]);

    slider = new Slider(jf.mixin({
      mainEl: els.mainEl,
      container: els.containerEl,
      navClass: 'jslider-nav',
      navItemClass: 'jslider-nav__item',
      navItemActiveClass: 'jslider-nav__item--active',
      draggable: true
    }, options));
    slider.init();

    $(targets[i]).show();
  }
}

function _markItems(target) {
  if (global.isMobile) {
    VideoSlides.handle(target.children);
  }

  j.forEach(target.children, function (item) {
    j.addClass(item, 'swipe__item');
  });
}

function _wrap(target) {
  j.addClass(target, 'swipe__wrapper');

  var containerEl = document.createElement('div');
  containerEl.className = 'swipe__container';

  target.parentNode.insertBefore(containerEl, target);
  containerEl.appendChild(target);

  var mainEl = document.createElement('div');

  if (target.hasAttribute('data-main-class')) {
    mainEl.className = target.getAttribute('data-main-class');
  }

  containerEl.parentNode.insertBefore(mainEl, containerEl);
  mainEl.appendChild(containerEl);

  return {
    mainEl: mainEl,
    containerEl: containerEl
  };
}

export default {
  init: init
};

