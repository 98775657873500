'use strict';

/**
 * Login actions module.
 * @module common/login/login
 */

// TODO: refactor and comment methods

import DOM from 'Helpers/domHelper';
import FUNC from 'Helpers/funcHelper';
import WishlistElements from 'Common/wishlist/elements';
import AjaxCart from 'Common/headerPopovers/ajaxCart';
import Wishlist from 'Common/headerPopovers/wishlist';
import pubsub from 'pubsub';
import Promise from 'bluebird';
import dynamicYieldTracker from 'Common/dynamicYield/dynamicYieldTracker';
import CookieHelper from 'Helpers/cookieHelper';
import global from 'global';
import sha256 from 'Common/cryptography/sha256';

import AccountWidget from 'npm-account-module';
import React from 'react';
import ReactDOM from 'react-dom';

const EMAIL_CAPTURED = CookieHelper.getCookie('email_captured') || '';
const T = window._i18n.accountPopUp;
const T_V = window._i18n.validation;
let WIDGET_STATIC_CONFIG = {
  messages: {
    'required': T_V.required,
    'notEmailAddress': T_V.invalidEmailAddress,
    'minLen6': T_V.minLen6,
    'minLen2': T_V.minLen2
  },
  fields: {
    'email': {label: T.emailField.label, placeholder: T.emailField.placeholder, value: EMAIL_CAPTURED},
    'password': {label: T.passwordField.label, placeholder: T.passwordField.placeholder},
    'loginTab': {label: T.signIn.label, headerLabel: T.signIn.headerLabel},
    'registerTab': {label: T.signUp.label, headerLabel: T.signUp.headerLabel},
    'newsletterSubscription': {label: T.newsletterSubscription.label},
    'privacyPolicy': {label: T.privacyPolicy.label},
    'forgotPassword': {label: T.forgotPasswordField.label},
    'loginBtn': {label: T.signIn.label},
    'registerBtn': {label: T.signUp.label}
  },
  onLogin: _onLoginHandler,
  onRegister: _onRegisterHandler
};
const WIDGET_WRAPPER = '.jnq-modal--login .jnq-modal__body';
// API urls
const API_URL_CUSTOMER_INFO = '/api/customer/info';
const API_URL_LOGIN = '/customer/account/ajaxlogin';
const API_URL_REGISTER = '/customer/account/register';

let accountModal;

function _onSuccessRegister(response, userEmail) {
  dynamicYieldTracker.triggerEvent('Signup', dynamicYieldTracker._getUserData('signup-v1', userEmail));
  _updateUserRelatedUI();
  accountModal.close();
}

function _onSuccessLogin(response, userEmail) {
  window.isUserLogged = true;
  let customerId = response.customerId;
  let csrfToken = response.csrfToken;

  updateDataLayer(customerId);
  updateCsrfToken(csrfToken);

  dynamicYieldTracker.triggerEvent('Login', dynamicYieldTracker._getUserData('login-v1', userEmail));

  _updateUserRelatedUI();

  Promise.all([
    AjaxCart
      .refresh(),
    Wishlist
      .refresh()
      .then(function (data) {
        var items = data.items,
          wishlilistContainer = document.querySelector('#wishlilist__container');

        WishlistElements.highlightAdded(items.map(item => item.entityId));
        pubsub.publish('login:wishlist:success');

        /*Refreshing the wish list content when there are some existing items*/
        if (!!wishlilistContainer && items && items.length > 0) {
          var wishListHtml = data.wishListHeader.replace(/(?:\r\n|\r|\n)/g, '');

          /*Get the html content for evry wishlist item*/
          items.forEach(function (item) {
            wishListHtml += item.htmlRepr;
          });

          wishlilistContainer.innerHTML = wishListHtml;
        }

      })
  ]).then(function () {
    accountModal.close();
  }).catch(function () {
    location.reload();
  });
}

function updateDataLayer(visitorId) {
  window.dataLayer[1].visitorId = visitorId;
}

function updateCsrfToken(csrfToken) {

  let elements = document.getElementsByName("csrfToken");
  let l = elements.length;

  if (l !== undefined) {
    for (let i = 0; i < l; i++) {
      elements[i].setAttribute("value", csrfToken);
    }
  }

}

function _updateUserRelatedUI() {
  DOM.addClass(document.getElementsByClassName('js-header__my-account')[0], 'is-logged');
  // change user icon popover to links list
  DOM.addClass(document.getElementsByClassName('js-header-popover__sign-up')[0], 'is-hidden');
  DOM.removeClass(document.getElementsByClassName('js-header-popover__my-account')[0], 'is-hidden');
  DOM.addClass(document.querySelector('#js-mobile-header-icons'), 'is-active');
  if (global.isMobile) {
    const accountPageLink = document.querySelector('[data-modal="register"]');
    accountPageLink.setAttribute('href', accountPageLink.getAttribute('data-account-ref'));
    accountPageLink.removeAttribute('data-modal');
    accountPageLink.outerHTML = accountPageLink.outerHTML;
  }
}

function _beforeRequest() {
  document.querySelector('.jnq-form-field__btn-submit').setAttribute('disabled', 'disabled');
}

function _afterRequest() {
  document.querySelector('.jnq-form-field__btn-submit').removeAttribute('disabled');
}

function _onRegisterHandler(payload) {
  let data = {
    email: payload.email,
    password: payload.password,
    confirmation: payload.password,
  };
  if (payload.isSubscribed) {
    data.is_subscribed = 'on';
  }
  _beforeRequest();
  DOM.ajax(API_URL_REGISTER, {
    type: 'POST',
    success: function (response) {
      _onSuccessRegister(response, data.email);
      DY.API("event", { name: "Login OR Signup", properties: {hashedEmail: sha256.hash(data.email.toLowerCase())}});
    },
    error: function () {
      const errorString = T.serverError.label;
      _afterRequest();
      if (accountModal.type === 'ask-for-signup') {
        _renderWidgetToAskForSignup('registerTab', accountModal, errorString);
      } else {
        _renderWidget('registerTab', accountModal, errorString);
      }
    },
    data: data
  });
}

function _onLoginHandler(payload) {
  const data = {
    email: payload.email,
    password: payload.password
  };
  _beforeRequest();
  DOM.ajax(API_URL_LOGIN, {
    type: 'POST',
    data: data,
    success: function (response) {
      _onSuccessLogin(response, data.email);
      DY.API("event", { name: "Login OR Signup", properties: {hashedEmail: sha256.hash(data.email.toLowerCase())}});
    },
    error: function (response) {
      // construct error message string from backend object
      const res = response.data;
      let errorString = '';
      for (var name in response.data) {
        if (name === '') {
          errorString = response.data[''][0];
        }
      }
      _afterRequest();
      if (accountModal.type === 'ask-for-signup') {
        _renderWidgetToAskForSignup('loginTab', accountModal, errorString);
      } else {
        _renderWidget('loginTab', accountModal, errorString);
      }
    }
  });
}

function _renderWidget(activeTabName, modal, serverError = null) {
  accountModal = modal;
  accountModal.type = 'regular-signup';
  ReactDOM.render(<AccountWidget
    {...WIDGET_STATIC_CONFIG}
    serverErrorMessage={serverError}
    activeTabName={activeTabName}
  />, document.querySelector(WIDGET_WRAPPER));
}

function _renderWidgetToAskForSignup(activeTabName, modal, serverError = null) {
  accountModal = modal;
  accountModal.type = 'ask-for-signup';
  let askForSignupConfig = FUNC.deepClone(WIDGET_STATIC_CONFIG);
  askForSignupConfig.fields.loginTab = window.askForSignupWidgetConfig.loginTab;
  askForSignupConfig.fields.registerTab = window.askForSignupWidgetConfig.registerTab;
  ReactDOM.render(<AccountWidget
    {...askForSignupConfig}
    serverErrorMessage={serverError}
    activeTabName={activeTabName}
  />, document.querySelector('.login-widget'));
}

function _destroyWidget() {
  ReactDOM.unmountComponentAtNode(document.querySelector(WIDGET_WRAPPER));
}

export default {
  renderWidget: _renderWidget,
  renderWidgetToAskForSignup: _renderWidgetToAskForSignup,
  destroyWidget: _destroyWidget
};
