'use strict';

/**
 * DY tracking hooks.
 * @module
 */

import sha256 from 'Common/cryptography/sha256';
import FUNC from 'Helpers/funcHelper';

export default class DYTracker {
  static _getUserData(type, email) {
    let properties = {
      dyType: type,
      hashedEmail: sha256.hash(email.toLowerCase())
    };

    if (window.dataLayer[1] && window.dataLayer[1].visitorId) {
      properties.cuid = window.dataLayer[1].visitorId;
    }

    return properties;
  }

  static triggerLogin() {
    const LOGIN_SUCCESS = document.body.hasAttribute('data-successful-login');
    const SIGNUP_SUCCESS = document.body.hasAttribute('data-successful-signup');
    const CUSTOMER_EMAIL = document.body.getAttribute('data-customer-email');

    if (LOGIN_SUCCESS) {
      DYTracker.triggerEvent('Login', DYTracker._getUserData('login-v1', CUSTOMER_EMAIL));
    }

    if (SIGNUP_SUCCESS) {
      DYTracker.triggerEvent('Signup', DYTracker._getUserData('signup-v1', CUSTOMER_EMAIL));
    }
  }

  static triggerEvent(eventName, options) {
    FUNC.waitForGlobalVar('DY').then(DY => {
      try {
        DY.API("event", {
          name: eventName,
          properties: options
        });
      } catch (e) {
        console.error(e);
      }
    });
  }
}
