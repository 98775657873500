import global from 'global';
import domHelper from 'Helpers/domHelper';
import stickyHelper from './stickyHelper';
import stickyThingsDesktop from './stickyThingsDesktop';
import stickyThingsMobile from './stickyThingsMobile';
import funcHelper from 'Helpers/funcHelper';


// TODO: Refactor this hell :D

var up = stickyHelper.up;

var headerUpscrollingStateId = stickyHelper.frozenHeaderStateId;

var stickyThings = {
  cacheDom: function cacheDom() {
    this.$body = document.body;
    this.promoBar = document.getElementById('js-promotion-bar');
    this.filterBtn = document.getElementById('js-mob-open-filter');
    this.header = document.getElementsByClassName('header')[0];
    this.searchField = document.querySelector('.search-el');
    this.headerWrapper = document.querySelector('.header__wrapper');
    this.selectedFiltersBar = document.querySelector('.selected-filters-wrap');
  },
  bindEvents: function bindEvents() {
    window.addEventListener('scroll', funcHelper.throttle(this.scrollHandler, 100));
  },
  toggleStickyState: function toggleStickyState(el, className, triggerOffset, extraOnActions, extraOffActions) {
    var noop = function () {
        },
        scrollDelta = stickyHelper.getScrollTop();

    extraOnActions = extraOnActions || noop;
    extraOffActions = extraOffActions || noop;

    stickyHelper.triggerOnStateChange(className, scrollDelta >= triggerOffset, function (state) {
      if (state) {
        extraOnActions();
      } else {
        extraOffActions();
      }
      domHelper.toggleClass(el, className, state);
    });
  },
  showHeaderOnUpscroll: function showHeaderOnUpscroll() {
    stickyHelper.triggerOnStateChange(headerUpscrollingStateId, stickyHelper.getScrollDirection() === up, function (state) {
      if (state) {
        // don't show header when scroll up if filter's bar is present
        !this.selectedFiltersBar && domHelper.addClass(this.header, 'is-shown');
      } else {
        domHelper.removeClass(this.header, 'is-shown');
        global.isMobile && domHelper.removeClass(this.headerWrapper, 'header__wrapper--search-on');
      }
    }.bind(this));
  }
};

export default global.runMQModule(stickyThingsMobile, stickyThingsDesktop, {
  mobile: stickyThings,
  desktop: stickyThings
});
