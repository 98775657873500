import PubSub from 'pubsub';
import {
  EVENT_INSERT_CONTENT_IN_CATEGORY_PAGES,
  EVENT_INSERT_CONTENTS_IN_CATEGORY_PAGES,
  EVENT_INSERT_CONTENT_AFTER_PRICE_IN_CATEGORY_PAGES
} from 'Constants/event';
import axios from 'axios';
import domHelper from 'Helpers/domHelper';

function insertSingleCMSBlock(params) {
  const {cmsBlockID} = params;
  const url = `/api/cmsblock/${cmsBlockID}`;

  axios.get(url)
      .then((response) => {
        const {data} = response;
        const result = {data, ...params};

        insertContent(result);
      })
      .catch((error) => console.log('error: ', error));
}

function insertMultiCMSBlock(params) {
  const promises = Object.keys(params)
      .filter(key => params[key].cmsBlockID.length > 0)
      .map(key => {
        const param = params[key];
        const {cmsBlockID} = param;
        const url = `/api/cmsblock/${cmsBlockID}`;

        return axios.get(url).then((response) => {
          const {data} = response;

          return {data, ...param}
        });
      });

  axios.all(promises)
      .then(axios.spread((...results) => results.forEach(result => insertContent(result))))
      .catch(error => console.log('error: ', error));
}

function insertContent({cmsBlockID, cmsBlockPosition, position, data}) {
  const insertContentId = `insert-content-${parseInt(position)}`;
  const insertContent = document.querySelector(`#${insertContentId}`);
  // check if the placeholder exists, then replace it with the cms block content
  if (insertContent) {
    let strategy;
    switch (cmsBlockPosition) {
      case 'before': {
        strategy = 'beforebegin';
        break;
      }
      case 'after': {
        strategy = 'afterend';
        break;
      }
    }

    if (strategy) {
      insertContent.insertAdjacentHTML(strategy, data);
    }
  }
}

function appendSaleStyle(params) {
  const {content} = params;
  const style = `
      .product__price-tag {
        position: relative;
      }
      .product__price-tag:after {
        color: red;
        content: "${content}";
        position: absolute;
        margin: 0 0 0 5px;
    }`;
  domHelper.appendStyle(style);
}

PubSub.subscribe(EVENT_INSERT_CONTENT_IN_CATEGORY_PAGES, function (params) {
  insertSingleCMSBlock(params);
});

PubSub.subscribe(EVENT_INSERT_CONTENTS_IN_CATEGORY_PAGES, function (params) {
  insertMultiCMSBlock(params);
});

PubSub.subscribe(EVENT_INSERT_CONTENT_AFTER_PRICE_IN_CATEGORY_PAGES, function (params) {
  appendSaleStyle(params);
});
