import j from 'Helpers/domHelper';

let headerWrapper;
let searchToggleButton;

function cacheDom() {
  headerWrapper = document.querySelector('.header__wrapper');
  searchToggleButton = document.querySelector('#search-toggle-button');
}

function bindEvents() {
  if(searchToggleButton) {
    searchToggleButton.addEventListener('click', toggleHeaderState);
  }
}

function toggleHeaderState(e) {
  e.preventDefault();
  e.stopPropagation();

  j.toggleClass(headerWrapper, 'header__wrapper--search-on');
}

function init() {
  cacheDom();
  bindEvents();
}

export default {
  init: init
}
