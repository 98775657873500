import domHelper from 'Helpers/domHelper';

function init() {
  const hashed = domHelper.getUrlParameter("hashed");
  if(hashed) {
    localStorage.setItem('jnq_newletter_hashed_email', hashed);
  }
}

export default {
  init: init
}
