'use strict';

import {
  STORAGE_NAMESPACE_MAIN
} from 'Constants/misc';

/**
 * Storage helper module.
 * @module helpers/storageHelper
 */

/** Class representing set of static methods to work with a storage implementation (currently localStorage is used) */
export default class StorageProvider {
  /**
   * Create a storage provider.
   * @param {string} [prefix=_jnq_] - Namespace of the storage provider ("_jnq_" is default)
   */
  constructor(prefix = STORAGE_NAMESPACE_MAIN) {
    this._namespace = prefix;
  }

  /**
   * Get the key namespace
   * @param {string} key - The key to the value in storage
   * @returns {string} The key which can be used for localStorage
   * @private
   */
  _getKeyWithNamespace(key) {
    if (!key) {
      throw 'Storage value get exception: key is not provided';
    }

    return `${this._namespace}${key}`;
  }

  /**
   * Get the value from storage by it's key
   * @param {string} key - The key to the value in storage
   * @returns A value from storage
   */
  get(key) {
    if (!key) {
      throw 'Storage value get exception: key is not provided';
    }

    const data = window.localStorage.getItem(this._getKeyWithNamespace(key));

    return (data !== null) ? JSON.parse(data) : null;
  }

  /**
   * Write new (or rewrite existing) entry in the storage.
   * @param {string} key - The key to the value in the storage
   * @param [value] - Data which should be written in the storage
   * @returns {boolean} True if saving was successful, false if not
   */
  set(key, value = '') {
    if (!key) {
      throw 'Storage value set exception: key is not provided';
    }

    let sValue = value.toString(); // Contains a prepared for saving data

    switch (typeof value) {
      case 'string':
        sValue = `"${value}"`;
        break;
      case 'object':
        sValue = JSON.stringify(value);
        break;
      case 'undefined':
        sValue = '';
        break;
    }

    window.localStorage.setItem(this._getKeyWithNamespace(key), sValue);

    return this.get(key) === value;
  }

  /**
   * Removes an entry from the storage by it's key
   * @param {string} key - The key to the value in storage
   * @returns {boolean} True if removal was successful, false if not
   */
  remove(key) {
    if (!key) {
      throw 'Storage value remove exception: key is not provided';
    }

    window.localStorage.removeItem(this._getKeyWithNamespace(key));

    return this.get(key) === null;
  }
}