'use strict';

import domHelper from 'Helpers/domHelper';
import AjaxCart from 'Common/headerPopovers/ajaxCart';
import Wishlist from 'Common/headerPopovers/wishlist';
import {
  ID_CART_POPOVER,
  ID_WISHLIST_POPOVER
} from 'Constants/id';

const DEFAULT_TIMEOUT = 10000;

function showOverlay(overlay, timeout, containerId) {
  let time = parseInt(timeout) || DEFAULT_TIMEOUT;
  let headerPopoverProducts = document.querySelectorAll('.header-popover__product');

  if (headerPopoverProducts.length) {
    overlay.open(false, true, time);
  }

  this.overlayContainerId = containerId;
}

function showCartOverlay(timeout) {
  this.showOverlay(AjaxCart, timeout, ID_CART_POPOVER);
}

function showWishListOverlay(timeout) {
  this.showOverlay(Wishlist, timeout, ID_WISHLIST_POPOVER);
}

function hideCartOverlay() {
  this.hideOverlay(AjaxCart);
}

function hideWishListOverlay() {
  this.hideOverlay(Wishlist);
}

function hideOverlay(overlay) {
  overlay.close(true);
}

function initClickListener() {
  var self = this;

  document.addEventListener('click', function (event) {
    var specifiedElement = document.getElementById(self.overlayContainerId);

    if (specifiedElement) {
      var isClickInside = specifiedElement.contains(event.target);
      var closeCartIcon = specifiedElement.querySelector('.minicart__close');
      var isCloseButtonClick = event.target === closeCartIcon;

      if (!isClickInside || isCloseButtonClick) {
        if (self.overlayContainerId === ID_CART_POPOVER) {
          self.hideCartOverlay();
        }
        else {
          self.hideWishListOverlay();
        }
      }

      if (isCloseButtonClick) {
        let elemId = self.overlayContainerId === ID_CART_POPOVER ? 'ajax-cart' : 'wish-list-cart',
            elem = document.getElementById(elemId);
        domHelper.addClass(elem, 'closed');
        setTimeout(() => {
          domHelper.removeClass(elem, 'closed');
        }, 1000);
      }
    }
  });
}


function init() {
  /*Should be accessible in the window context in order to be used by DY*/
  this.overlayContainerId = '';
  this.initClickListener();
}

export default {
  showOverlay: showOverlay,
  showCartOverlay: showCartOverlay,
  showWishListOverlay: showWishListOverlay,
  initClickListener: initClickListener,
  hideOverlay: hideOverlay,
  hideCartOverlay: hideCartOverlay,
  hideWishListOverlay: hideWishListOverlay,
  init: init
};
