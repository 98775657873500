import PubSub from 'pubsub';

import global from 'global';
import DOM from 'Helpers/domHelper';
import ReactSiema from 'siema';
import React from 'react';
import truncateText from 'Common/truncateText';
import { EVENT_DY_PRICE_DISCOUNT } from 'Constants/event';
import HeadedProductPane from './HeadedProductPane';

import { getPriceDiscountByCategory } from '../../../dy/priceDiscount/priceDiscount';

const ITEMS_NUMBER_BREAKPOINT_DESKTOP = 4;
const NEXT_DIRECTION_ID = 'next';
const PREV_DIRECTION_ID = 'prev';
const CLASS_DISABLED_NAV_BUTTON = 'rect-headed-carousel__button--disabled';
const CLASS_DISABLED_NAV_BUTTON_ICON = 'rect-headed-carousel__button_icon--disabled';

class HeadedProductsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { priceDiscount: props.priceDiscount };
  }

  componentDidMount() {
    truncateText.init(this.props.targetNode);

    PubSub.subscribe(EVENT_DY_PRICE_DISCOUNT, priceDiscount => {
      this.setState({ priceDiscount });
    });
  }

  static getDerivedStateFromProps(nextProps) {
    const perPageAmount = nextProps.perPageAmount || ITEMS_NUMBER_BREAKPOINT_DESKTOP;
    return {
      products: nextProps.products,
      itemsNumber: nextProps.products.length,
      isCarouselInited: global.isDesktop && nextProps.products.length > perPageAmount,
      isDesktop: global.isDesktop
    };
  }

  // eslint-disable-next-line react/sort-comp
  moveSlider(direction) {
    const perPageAmount = this.props.perPageAmount || ITEMS_NUMBER_BREAKPOINT_DESKTOP;
    if (this.carouselInstance) {
      // eslint-disable-next-line default-case
      switch (direction) {
        case NEXT_DIRECTION_ID:
          DOM.removeClass(this.previousCarouselButton, CLASS_DISABLED_NAV_BUTTON);
          DOM.removeClass(this.previousCarouselButtonIcon, CLASS_DISABLED_NAV_BUTTON_ICON);
          this.carouselInstance.next(perPageAmount, () => {
            if (this.carouselInstance.currentSlide + perPageAmount === this.state.itemsNumber) {
              DOM.addClass(this.nextCarouselButton, CLASS_DISABLED_NAV_BUTTON);
              DOM.addClass(this.nextCarouselButtonIcon, CLASS_DISABLED_NAV_BUTTON_ICON);
            }
          });
          break;
        case PREV_DIRECTION_ID:
          DOM.removeClass(this.nextCarouselButton, CLASS_DISABLED_NAV_BUTTON);
          DOM.removeClass(this.nextCarouselButtonIcon, CLASS_DISABLED_NAV_BUTTON_ICON);
          this.carouselInstance.prev(perPageAmount, () => {
            if (this.carouselInstance.currentSlide === 0) {
              DOM.addClass(this.previousCarouselButton, CLASS_DISABLED_NAV_BUTTON);
              DOM.addClass(this.previousCarouselButtonIcon, CLASS_DISABLED_NAV_BUTTON_ICON);
            }
          });
          break;
      }
    }
  }

  getCarouselItem() {
    const { premiumPosterTypeId, handleProductMouseIn, handleProductMouseOut, handleProductClick } = this.props;

    const { priceDiscount } = this.state;

    return this.state.products.map((el, i) => {
      const ids = el.category_ids;
      const categoryIds = ids && ids.length > 0 ? ids.split('|').map(id => Number(id)) : [];
      const priceDiscountByCategory = getPriceDiscountByCategory(categoryIds, priceDiscount);

      return (
        <HeadedProductPane
          key={i}
          el={el}
          premiumPosterTypeId={premiumPosterTypeId}
          priceDiscount={priceDiscountByCategory}
          handleProductMouseOut={handleProductMouseOut}
          handleProductMouseIn={handleProductMouseIn}
          handleProductClick={handleProductClick}
          type={this.props.headerInfo.type}
          {...el}
        />
      );
    });
  }

  render() {
    if (this.state.itemsNumber === 0) {
      return null;
    }
    const perPageAmount = this.props.perPageAmount || ITEMS_NUMBER_BREAKPOINT_DESKTOP;
    let pageIndex = 0;
    // default buttons state for all carousels
    let leftArrowActive = false;
    let rightArrowActive = true;
    if (this.props.scrollToActive) {
      let activeItemIndex;
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.props.products) {
        if (this.props.products.hasOwnProperty(key)) {
          const item = this.props.products[key];
          if (item.isCurrentProduct) activeItemIndex = key;
        }
      }
      if (activeItemIndex > 1) {
        // decreasing to 1 to
        activeItemIndex -= 1;
        pageIndex = activeItemIndex;
        leftArrowActive = true;
        // we have reached the end of carousel
        if (Number(activeItemIndex) + (perPageAmount - 1) >= Number(this.props.products.length) - 1) {
          pageIndex = this.props.products.length - perPageAmount;
          rightArrowActive = false;
        }
      }
    }

    const items = this.getCarouselItem();
    const header = <HeaderSection {...this.props} isDesktop={this.state.isDesktop} carouselSize={items.length} />;

    const navigationSeeMore = this.props.headerInfo.seeMore ? (
      // eslint-disable-next-line react/jsx-no-target-blank
      <a className="rect-list__navigation__seemore" href={this.props.headerInfo.seeMore.url} target="_blank">
        {this.props.headerInfo.seeMore.label.toUpperCase()}
      </a>
    ) : null;
    const navigation = this.state.isCarouselInited ? (
      <div className="rect-list__navigation">
        <div>
          <button
            type="button"
            className={`rect-headed-carousel__button rect-headed-carousel__button--prev ${
              !leftArrowActive ? CLASS_DISABLED_NAV_BUTTON : ''
            }`}
            ref={el => {
              this.previousCarouselButton = el;
            }}
            onClick={this.moveSlider.bind(this, PREV_DIRECTION_ID)}
          >
            <i
              className={`i-headed-navigation-prev ${!leftArrowActive ? CLASS_DISABLED_NAV_BUTTON_ICON : ''}`}
              ref={el => {
                this.previousCarouselButtonIcon = el;
              }}
            />
          </button>
          <button
            type="button"
            className={`rect-headed-carousel__button rect-headed-carousel__button--next ${
              !rightArrowActive ? CLASS_DISABLED_NAV_BUTTON : ''
            }`}
            ref={el => {
              this.nextCarouselButton = el;
            }}
            onClick={this.moveSlider.bind(this, NEXT_DIRECTION_ID)}
          >
            <i
              className={`i-headed-navigation-next ${!rightArrowActive ? CLASS_DISABLED_NAV_BUTTON_ICON : ''}`}
              ref={el => {
                this.nextCarouselButtonIcon = el;
              }}
            />
          </button>
        </div>
        {navigationSeeMore}
      </div>
    ) : null;

    const carousel = this.state.isCarouselInited ? (
      <ReactSiema
        draggable={false}
        duration={500}
        startIndex={pageIndex}
        perPage={perPageAmount}
        ref={el => {
          this.carouselInstance = el;
        }}
      >
        {items}
      </ReactSiema>
    ) : (
      items
    );

    return (
      <div className="rect-list__headed--carousel">
        <div className="rect-headed__container">
          {header}
          {navigation}
        </div>
        <div className={`rec-list rec-list__headed ${this.state.isCarouselInited ? 'rec-list--carousel' : ''}`}>
          <div className="rec-list__carousel">
            <div className="rec-list__items">{carousel}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default HeadedProductsList;

// eslint-disable-next-line react/prefer-stateless-function
class HeaderSection extends React.Component {
  render() {
    const { isDesktop, headerInfo, carouselSize } = this.props;

    const picture = headerInfo.imageUrl ? (
      <div className="rec-header__picture">
        <img src={headerInfo.imageUrl} className="img-circle full-width" alt="" />
      </div>
    ) : null;
    const title = headerInfo.title ? <div className="rec-header__title">{headerInfo.title}</div> : null;
    const subTitle = headerInfo.subTitle ? (
      <span className="rec-header__subtitle">{headerInfo.subTitle.toUpperCase()}</span>
    ) : null;
    const description = headerInfo.description ? (
      <div className="read-more-less rec-header__desc collapsed">{headerInfo.description}</div>
    ) : null;

    const section = isDesktop ? (
      <div className="rec-header">
        <div className="rec-header__container">
          {picture}
          <div>
            {title}
            {headerInfo.seeMore && headerInfo.seeMore.hasOwnProperty('url') ? (
              <a href={headerInfo.seeMore.url} target="_blank" rel="noreferrer">
                {subTitle}
              </a>
            ) : (
              <>{subTitle}</>
            )}
            {description}
          </div>
        </div>
      </div>
    ) : (
      <div className="rec-header">
        <div className={`rec-header__container ${carouselSize > 0 ? 'rec-header__container__with-margin' : ''}`}>
          {picture}
          <div className="rect-header__info">
            {title}
            {headerInfo.seeMore && headerInfo.seeMore.hasOwnProperty('url') ? (
              <a href={headerInfo.seeMore.url} target="_blank" rel="noreferrer">
                {subTitle}
                <span className="i-next-dark rect-header_seeMore" />
              </a>
            ) : (
              <>{subTitle}</>
            )}
          </div>
        </div>
        {description}
      </div>
    );

    return <>{section}</>;
  }
}
