import global from 'global';
import j from 'Helpers/domHelper';
import stickyHelper from './stickyHelper';
import FrozenHeader from './frozenHeader';
import backToTop from './backToTop';


var parent,
    headerWrapper,
    mobileHeaderOffsetTop,
    mobileFilterOffsetTop,
    promoBarOffset,
    originalHeaderHeight,
    hideStickyHeaderTimeout,
    hideStickyHeaderToken,
    headerEl,
    offsetTopAttributeName = 'data-offset-top';

function init(stickyCommon) {
  var initer = document.getElementById('back-to-wishlist') ?
      function () {
      } :
      realInit;

  parent = stickyCommon;

  return {
    init: initer,
    showHeader: showHeader
  };
}

function realInit() {
  parent.cacheDom();

  headerEl = document.getElementsByClassName('header')[0];
  if(headerEl) {
    headerWrapper = document.querySelector('.header__wrapper');
    parent.scrollHandler = _scrollHandler;

    hideStickyHeaderTimeout = 4000;

    parent.isHeaderSticky = false;
    originalHeaderHeight = parent.header.getBoundingClientRect().height;
    mobileHeaderOffsetTop = _getHeaderOffsetTop();
    mobileFilterOffsetTop = _getFilterOffsetTop();

    if (_isPhonePromoBar()) {
      promoBarOffset = _getPromoBarOffset();
    }

    FrozenHeader.init({
      headerEl: parent.header,
      searchEl: parent.searchField
    });

    _setInitialState();
    parent.bindEvents();
  }
}

function _isPhonePromoBar() {
  return global.isPhone && parent.promoBar;
}

function _setInitialState() {
  j.addClass(document.body, 'is-mobile');
  _scrollHandler();
}

function _scrollHandler() {
  mobileHeaderOffsetTop = _getHeaderOffsetTop();
  parent.toggleStickyState(parent.$body, 'is-sticky', mobileHeaderOffsetTop, _stickHeaderActions, _unstickHeaderActions);

  parent.showHeaderOnUpscroll();
  if (parent.isHeaderSticky) {
    _hideStickyHeaderIfUserIsStill();
  }

  if (_isPhonePromoBar()) {
    _switchPhonePromoBar();
  }

  // Toggle back to top button
  backToTop.toggleBackToTop(parent.$body);
}

function _hideStickyHeaderIfUserIsStill() {
  _stopStickyHeaderHideTimer();

  hideStickyHeaderToken = setTimeout(function () {
    if (!_searchFieldIsFocused()) {
      j.removeClass(parent.header, 'is-shown');
      stickyHelper.changeStateToNegative(stickyHelper.frozenHeaderStateId);
    }
  }, hideStickyHeaderTimeout);
}

function _searchFieldIsFocused() {
  return j.hasClass(headerWrapper, 'header__wrapper--search-on');
}

function _stopStickyHeaderHideTimer() {
  if (hideStickyHeaderToken !== undefined) {
    clearTimeout(hideStickyHeaderToken);
  }
}

function _stickHeaderActions() {
  parent.isHeaderSticky = true;
  if (parent.promoBar && !j.hasClass(parent.promoBar, 'is-floating')) {
    parent.$body.style.paddingTop = parent.promoBar.offsetHeight + originalHeaderHeight + 'px';
  } else {
    parent.$body.style.paddingTop = originalHeaderHeight + 'px';
  }
}

function _unstickHeaderActions() {
  parent.isHeaderSticky = false;

  if (parent.promoBar && !j.hasClass(parent.promoBar, 'is-floating')) {
    parent.$body.style.paddingTop = parent.promoBar.offsetHeight + 'px';
  } else {
    parent.$body.style.paddingTop = 0;

  }

  j.removeClass(parent.header, 'is-shown');
}

function _switchPhonePromoBar() {
  var scrollTop = stickyHelper.getScrollTop();
}

function _getHeaderOffsetTop() {
  var dataScrollOffset = getOffsetTopDataAttribute();

  // Check if the header has the view port offset saved as data attribute
  if(dataScrollOffset) {
    return dataScrollOffset;
  }
  // Calculate the view port offset of the header
  setOffsetTopDataAttribute();

  return getOffsetTopDataAttribute();
}

function setOffsetTopDataAttribute() {
  var scrollOffset = stickyHelper.getScrollOffset(headerEl);
  headerEl.setAttribute(offsetTopAttributeName, scrollOffset);
}

function getOffsetTopDataAttribute() {
  return headerEl.getAttribute(offsetTopAttributeName);
}

function _getFilterOffsetTop() {
  var offset;
  if (parent.filterBtn) {
    offset = stickyHelper.getScrollOffset(parent.filterBtn);
  }
  return offset;
}

function _getPromoBarOffset() {
  return stickyHelper.getScrollOffset(parent.promoBar);
}

function showHeader() {
  if (parent.isHeaderSticky) {
    j.addClass(parent.header, 'is-shown');
    stickyHelper.changeStateToPositive(stickyHelper.frozenHeaderStateId);
    _hideStickyHeaderIfUserIsStill();
  }
}

export default {
  init: init,
  setOffsetTopDataAttribute: setOffsetTopDataAttribute,
};

