'use strict';

/**
 * Wishlist helper module.
 * @module common/wishlist/elements
 */

import DOM from 'Helpers/domHelper';
import {
  CLASS_SEE_SIMILAR_DESIGNS,
} from 'Constants/class';

/**
 * Class representing set of static methods to work with "See Similar Designs" buttons
 */
export default class SeeSimilarDesigns {
  /**
   * Click handler for "See Similar Designs" buttons
   * @param {Event} e - Event object captured by handler
   * @private
   */
  static _seeSimilarDesignsClickHandler(e) {
    const seeSimilarDesigns = e.target.closest(`.${CLASS_SEE_SIMILAR_DESIGNS}`);

    if(seeSimilarDesigns) {
      e.preventDefault();
      let dataHref = seeSimilarDesigns.getAttribute('data-href');
      if(dataHref) {
        window.location.href = dataHref;
      }
    }
  }
}