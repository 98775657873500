var state = {},
    lastScrollTop = 0,
    frozenHeaderStateId = 'headerStickyUpscroll';

var up = 'up',
    down = 'down';

function triggerOnStateChange(stateId, stateChangeCondition, fn) {
  var localState = stateChangeCondition;

  state[stateId] = (typeof state[stateId] === 'boolean') ? state[stateId] : false;

  if (state[stateId] !== localState) {
    fn(localState);
    state[stateId] = localState;
  }
}

function _changeState(stateId, newState) {
  if (typeof state[stateId] === 'boolean') {
    state[stateId] = newState;
  }
}

function changeStateToNegative(stateId) {
  _changeState(stateId, false);
}

function changeStateToPositive(stateId) {
  _changeState(stateId, true);
}

function getScrollOffset(el) {
  return el.offsetTop + el.offsetHeight;
}

function getScrollTop() {
  return window.scrollY || document.documentElement.scrollTop;
}

function getScrollDirection() {
  var direction,
      currentScrollTop;

  currentScrollTop = getScrollTop();

  direction = (currentScrollTop > lastScrollTop) ? down : up;

  lastScrollTop = currentScrollTop;

  return direction;
}

export default {
  up: up,
  down: down,
  frozenHeaderStateId: frozenHeaderStateId,

  triggerOnStateChange: triggerOnStateChange,
  changeStateToNegative: changeStateToNegative,
  changeStateToPositive: changeStateToPositive,
  getScrollOffset: getScrollOffset,
  getScrollTop: getScrollTop,
  getScrollDirection: getScrollDirection
};

