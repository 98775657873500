import domHelper from 'Helpers/domHelper';


function init() {
  var MAX_TIMEOUT = 10000;
  var TICKER = 100;

  var currentTimeout = 0;
  var criteoAlert;

  var hideCriteoInterval = setInterval(function () {
    criteoAlert = document.querySelector('#cto_cookies_header_message');
    currentTimeout += TICKER;

    if (criteoAlert || currentTimeout >= MAX_TIMEOUT) {
      clearInterval(hideCriteoInterval);

      criteoAlert && domHelper.addClass(criteoAlert, 'collapsed');
    }
  }, TICKER);
}

export default {
  init: init
}
