let videos;

function init() {
  _cacheDom();
  _bindEvents();

  for (let i = 0; i < videos.length; i++) {
    videos[i].setAttribute('loop', '');
    videos[i].play();
  }
}

function _cacheDom() {
  videos = document.getElementsByClassName('video-cms');
}

function _bindEvents() {
  for (let i = 0; i < videos.length; i++) {
    videos[i].parentNode.style.height = videos[i].clientHeight + 'px';
    videos[i].addEventListener('ended', restartVideoPlay);
  }
}

function restartVideoPlay(event) {
  //event.currentTarget.load();
  //event.currentTarget.play();
}

export default {
  init: init
}