import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import NewsletterSubscribe from 'Common/newsletterSubscribe';
import { theme } from '../../common/theme';
import config from '../../config';

const choiceItems = ['gen-unsub-1', 'gen-unsub-2', 'gen-unsub-3'];

export const UnsubscribeFeedbackForm = () => {
  const { t } = useTranslation();
  const [selectedAnswer, setsSelectedAnswer] = useState('');

  useEffect(() => {
    const unsubscribeButton = document.getElementById('js-unsubscription__button');

    unsubscribeButton.addEventListener('click', submitFeedback);

    return () => unsubscribeButton.removeEventListener('click', submitFeedback);
  });

  const handleAnswerSelect = answer => {
    setsSelectedAnswer(answer);
  };

  const submitFeedback = e => {
    e.preventDefault();
    NewsletterSubscribe.getNewsletterUnsubscribe(e);

    if (selectedAnswer) {
      axios.post(config.unsubscribeReasonUrl, { option: selectedAnswer }).catch(err => {
        console.error('Can not submit feedback', err);
      });
    }
  };

  return (
    <div className="unsubscribe-feedback">
      <p className="unsubscribe-feedback__header">{t('gen-unsub-header')}</p>

      <div className="unsubscribe-feedback__options">
        <FormControl component="fieldset">
          <FormGroup>
            <RadioGroup>
              {choiceItems.map((it, idx) => (
                <FormControlLabel
                  className="unsubscribe-feedback__label"
                  key={it}
                  control={<Radio checked={selectedAnswer === idx + 1} onChange={() => handleAnswerSelect(idx + 1)} />}
                  label={
                    <Typography variant="subtitle1" style={{ paddingLeft: '6px' }}>
                      {t(it)}
                    </Typography>
                  }
                />
              ))}
            </RadioGroup>
          </FormGroup>
        </FormControl>
      </div>
    </div>
  );
};

const formRoot = document.getElementById('unsubscribe-feedback-container');

if (formRoot) {
  ReactDOM.render(
    <MuiThemeProvider theme={theme}>
      <UnsubscribeFeedbackForm />
    </MuiThemeProvider>,
    formRoot
  );
}
