"use strict";

import Modal from 'Common/modal/modal';
import AccountManager from 'Common/login/login';

import DOM from 'Helpers/domHelper';

let accountModal;
let _onCloseHandlers = [];
let triggers;

function init() {
  _cacheDom();
  _initModal();
}

function _cacheDom() {
  triggers = document.querySelectorAll('[data-modal="login"], [data-modal="register"]');
}

function _initModal() {
  if (triggers) {
    accountModal = new Modal({
      triggers: triggers,
      className: 'jnq-modal--login',
      body: '',
      onOpen: _onModalOpen,
      onClose: _onClose
    });

    accountModal.addOnCloseHandler = function (handler) {
      _onCloseHandlers.push(handler);
    };
  }
}

function _onModalOpen(modal, target, serverErrorMessage) {
  let modalTrigger = target.getAttribute('data-modal') || DOM.closest(target, '[data-modal]').getAttribute('data-modal');
  const activeTabName = modalTrigger === 'register' ? 'registerTab': 'loginTab';
  AccountManager.renderWidget(activeTabName, modal);
}

function _onClose() {
  _onCloseHandlers.forEach(function (handler) {
    handler();
  });
  AccountManager.destroyWidget();
}

document.addEventListener('DOMContentLoaded', init);

