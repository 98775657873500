import global from 'global';
import funcHelper from './funcHelper';
import httpHelper from './httpHelper';
import Promise from 'bluebird';
import cookieHelper from './cookieHelper';
import { ALIGNMENT_TOP, ALIGNMENT_MIDDLE, ALIGNMENT_BOTTOM } from 'Constants/misc';

// TODO: Create jHelper which will be a mixin of all other helpers.

/*
 Donors:
 https://github.com/julienw/dollardom/blob/master/src/dollardom.js
 http://youmightnotneedjquery.com/
 */

/*
 Add class to a dom element
 */
function addClass(el, className) {
  if (el) {
    if (!hasClass(el, className)) {
      if (el.classList) {
        el.classList.add(className);
      } else {
        el.className += ' ' + className;
      }
    }
  }

  return el;
}

/*
 Check if dom element has a class
 */
function hasClass(el, className) {
  if (el) {
    if (el.classList) {
      return el.classList.contains(className);
    }

    return new RegExp('(^| )' + className + '( |$)', 'gi').test(el.className);
  }
}

/*
 Remove class of dom element
 */
function removeClass(el, className) {
  if (hasClass(el, className)) {
    if (el.classList) {
      el.classList.remove(className);
    } else {
      el.className = el.className.replace(new RegExp('(^|\\s)' + className + '(\\s|$)'), ' ').replace(/\s$/, '');
    }
  }

  return el;
}

/*
 Get height of the whole page (not viewport)
 */
function getPageHeight() {
  var body = document.body,
    html = document.documentElement;

  var height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);

  return height;
}

/*
 Crossbrowser check the checkbox
 */
function check(el) {
  el.checked = true;
  el.setAttribute('checked', 'checked');
  return el;
}

/*
 Crossbrowser uncheck the checkbox
 */
function uncheck(el) {
  el.checked = false;
  el.removeAttribute('checked');
  return el;
}

/*
 Toggle class of dom element
 */
function toggleClass(el, className, comp) {
  var comparator = typeof comp === 'boolean' ? comp : !hasClass(el, className);

  if (comparator) {
    addClass(el, className);
  } else {
    removeClass(el, className);
  }

  return el;
}

/*
 Creates DOM element with set params
 */
function newNode(tag, attrs = {}, text) {
  let node = document.createElement(tag);

  for (let prop in attrs) {
    node.setAttribute(prop, attrs[prop]);
  }

  if (text) {
    prependHTML(node, text);
  }

  return node;
}

/*
 Run some css-styling function + suppress the css transition
 */
function doWithoutCSSTransitions(el, fn) {
  el.style.display = 'none';
  fn(el);
  el.style.display = 'block';
}

/*
 Find closest element. Searching upwards.
 */
function closest(el, selector) {
  var matchesFn, parent;

  ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].some(function (
    fn
  ) {
    if (typeof document.body[fn] === 'function') {
      matchesFn = fn;
      return true;
    }
    return false;
  });

  while (el !== null) {
    parent = el.parentElement;
    if (parent !== null && parent[matchesFn](selector)) {
      return parent;
    }
    el = parent;
  }

  return null;
}

/*
 Insert text in dom element if "text" param is set
 !OR Get text out of element if "text" is not set
 */
function innerText(el, text) {
  var get = text === undefined;

  if (el.textContent || el.textContent === '') {
    if (get) {
      text = el.textContent;
    } else {
      el.textContent = text;
    }
  } else {
    if (get) {
      text = el.innerText;
    } else {
      el.innerText = text;
    }
  }

  return get ? text : el;
}

/*
 Insert HTML in dom element on the last position
 */
function appendHTML(el, html) {
  el.insertAdjacentHTML('beforeend', html);
  return el;
}

/*
 Insert HTML in dom element on the first position
 */
function prependHTML(el, html) {
  if (el) {
    el.insertAdjacentHTML('afterbegin', html);
  }
  return el;
}

/*
 Remove dom element
 */
function remove(el) {
  if (el && el.parentNode) {
    return el.parentNode.removeChild(el);
  }
}

/*
 Remove previous sibling
 */
function removePreviousSibling(el) {
  let previous = el.previousSibling;

  while (previous && previous.nodeType !== 1) {
    previous = previous.previousSibling;
  }

  if (previous) {
    previous.parentNode.removeChild(previous);
  }
}

/*
 Converts "Array like" object into real Array object
 */
function toArray(nodeList) {
  return Array.prototype.slice.call(nodeList);
}

/*
 Iterate dom elements of HTMLCollection
 */
function forEach(collection, fn) {
  Array.prototype.forEach.call(collection, fn);
}

/*
 Creates a new array based on fn called for each element of passed collection
 */
function map(collection, fn) {
  return Array.prototype.map.call(collection, fn);
}

/*
 Check if condition works for every element in collection
 */
function every(collection, fn) {
  return Array.prototype.every.call(collection, fn);
}

/*
 Find and return all elements in collection which matches the given condition (set in callback function)
 */
function find(collection, fn, one) {
  var results = [],
    i,
    l;

  for (i = 0, l = collection.length; i < l; i++) {
    if (fn(collection[i]) === true) {
      results.push(collection[i]);
      if (one === true) {
        break;
      }
    }
  }

  return one ? results[0] : results;
}

/*
 Find and return the single element in collection which matches the given condition (set in callback function)
 */
function findOne(collection, fn) {
  return find(collection, fn, true);
}

/*
 "Manually" dispatch event on the element
 */
function dispatchEvent(el, event) {
  var evt;

  if (el) {
    if (global.isIE) {
      evt = document.createEvent('Event');
      evt.initEvent(event, true, false);
    } else {
      evt = new Event(event);
    }
    el.dispatchEvent(evt);
  }
}

/*
 Add placeholder support for browsers which doesn't support it
 */
function attachPlaceholder(el, text) {
  el.value = text;
  el.addEventListener('focus', _onFocus, false);
  el.addEventListener('blur', _onBlur, false);

  function _onFocus(e) {
    if (e.target.value === text) {
      e.target.value = '';
    }
  }

  function _onBlur(e) {
    if (e.target.value === '') {
      e.target.value = text;
    }
  }
}

// make a pause after running fn
function pause(fn, delay) {
  return new Promise(function (resolve, reject) {
    try {
      fn();
    } catch (e) {
      reject(e);
    }

    setTimeout(function () {
      resolve();
    }, delay);
  });
}

/*
 Prepare object or stringified JSON for insertion to data attribute.
 */
function stringifyJsonForData(obj) {
  return encodeURI(JSON.stringify(obj));
}

/*
 Parse JSON with single quotes
 */
function parseJsonFromData(str) {
  return JSON.parse(decodeURI(str));
}

/*
 Forcing redraw of the element
 */
function redraw(element, timeout = 20) {
  if (!element) {
    return;
  }

  let n = document.createTextNode(' ');
  let disp = element.style.display; // don't worry about previous display style

  element.appendChild(n);
  element.style.display = 'none';

  setTimeout(function () {
    element.style.display = disp;
    n.parentNode.removeChild(n);
  }, timeout); // you can play with this timeout to make it as short as possible
}

/**
 * Smooth scroll to given element
 *
 * @param element: DOM element to scroll to
 * @param to (default: 0): top offset comparing to element
 * @param duration (default: 500): duration of animation
 */
function scrollTo(element, to = 0, duration = 500) {
  if (duration <= 0) return;

  let difference = to - element.scrollTop;
  let perTick = (difference / duration) * 15;

  setTimeout(function () {
    element.scrollTop = element.scrollTop + perTick;
    if (element.scrollTop == to) return;
    scrollTo(element, to, duration - 15);
  }, 15);
}

function getPageScrollTop() {
  return window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
}

// all position values are stored inside constants/misc
function scrollToElement(elem, position = 'top') {
  let body = document.body,
    html = document.documentElement,
    elemRect = elem.getBoundingClientRect(),
    clientHeight = html.clientHeight,
    documentHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    ),
    maxScrollPosition = documentHeight - clientHeight,
    scrollPosition;
  if (position === ALIGNMENT_BOTTOM) {
    scrollPosition = elemRect.bottom - clientHeight;
  } else if (position === ALIGNMENT_MIDDLE) {
    scrollPosition = elemRect.bottom - clientHeight / 2 - elemRect.height / 2;
  } else {
    scrollPosition = elemRect.top;
  }
  scrollPosition = Math.min(scrollPosition + window.pageYOffset, maxScrollPosition);
  window.scrollTo(0, scrollPosition);
}

function isWholeElementInViewport(el) {
  if (!el) return false;
  var rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

function isElementInViewport(el) {
  if (!el) return false;
  let top = el.offsetTop,
    left = el.offsetLeft,
    width = el.offsetWidth,
    height = el.offsetHeight;

  while (el.offsetParent) {
    el = el.offsetParent;
    top += el.offsetTop;
    left += el.offsetLeft;
  }

  return (
    top < window.pageYOffset + window.innerHeight &&
    left < window.pageXOffset + window.innerWidth &&
    top + height > window.pageYOffset &&
    left + width > window.pageXOffset
  );
}

function loadScript(url, callback, elementToAttach = document.head) {
  var scriptTag = document.createElement('script');
  scriptTag.src = url;

  scriptTag.onload = callback;
  scriptTag.onreadystatechange = callback;

  elementToAttach.appendChild(scriptTag);
}

function decodeHtml(input) {
  var e = document.createElement('div');
  e.innerHTML = input;
  // handle case of empty input
  return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
}

/**
 * Returns a promise, which is resolved once an element matching input query appears in DOM.
 * @param query
 */
function waitForElement(query) {
  return new Promise(resolve => {
    const waiter = () => {
      const element = document.querySelector(query);

      if (element) {
        resolve(element);
      } else {
        window.requestAnimationFrame(waiter);
      }
    };
    waiter();
  });
}
// function params (inspired by DY)
// e = selector: valid css selector identifying the DOM elements to look for
// t = callback: function to execute
// n = minElements (optional, default: 1): minimum number of selected resources rendered before executing the function
// r = interval (optional, default: 100): how often the page will be rechecked for the elements (in milliseconds)
// o = maximumRetries (optional, default: Infinity): maximum number of rechecking the page for the elements before giving up
function waitForConditionalElement(e, t, n, r, o, i) {
  function a() {
    var l = document.querySelectorAll(e);
    c++,
      (u = null),
      l.length >= n
        ? ((s = 'FOUND'), t(l), i(null, l))
        : 0 !== o--
        ? (u = setTimeout(a, r))
        : ((s = 'NOT_FOUND'), i(new Error('Only found ' + l.length + ' elements of ' + n + ' expected')));
  }
  'undefined' == typeof n && (n = 1),
    'undefined' == typeof r && (r = 100),
    'undefined' == typeof o && (o = -1),
    'undefined' == typeof i && (i = function () {});
  var s = 'LOOPING',
    c = 0,
    u = null;
  return (
    a(),
    {
      status: function () {
        return s;
      },
      count: function () {
        return c;
      },
      cancelWait: function () {
        return null !== u ? (clearTimeout(u), !0) : !1;
      }
    }
  );
}

/**
 * Object-fit emulation for IE/Edge
 */
function objectFit() {
  if ('objectFit' in document.documentElement.style === false) {
    document.addEventListener('DOMContentLoaded', function () {
      Array.prototype.forEach.call(document.querySelectorAll('img[data-object-fit]'), function (image) {
        (image.runtimeStyle || image.style).background =
          'url("' +
          image.src +
          '") no-repeat 50%/' +
          (image.currentStyle ? image.currentStyle['object-fit'] : image.getAttribute('data-object-fit'));
        image.src =
          "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='" +
          image.width +
          "' height='" +
          image.height +
          "'%3E%3C/svg%3E";
      });
    });
  }
}

function updateQueryStringParameter(uri, key, value) {
  var re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
  var separator = uri.indexOf('?') !== -1 ? '&' : '?';
  if (uri.match(re)) {
    return uri.replace(re, '$1' + key + '=' + value + '$2');
  } else {
    return uri + separator + key + '=' + value;
  }
}

function getUrlParameter(name) {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  var results = regex.exec(location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

function scrollToPositionSmoothly(to, callback, duration) {
  const requestAnimFrame =
    window.requestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    function (callback) {
      window.setTimeout(callback, 1000 / 60);
    };
  // handy math func
  function easeInOutQuad(t, b, c, d) {
    t /= d / 2;
    if (t < 1) {
      return (c / 2) * t * t + b;
    }
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  }
  function easeInCubic(t, b, c, d) {
    var tc = (t /= d) * t * t;
    return b + c * tc;
  }
  function inOutQuintic(t, b, c, d) {
    var ts = (t /= d) * t,
      tc = ts * t;
    return b + c * (6 * tc * ts + -15 * ts * ts + 10 * tc);
  }
  // because it's so fucking difficult to detect the scrolling element, just move them all
  function move(amount) {
    document.documentElement.scrollTop = amount;
    document.body.parentNode.scrollTop = amount;
    document.body.scrollTop = amount;
  }
  function position() {
    return document.documentElement.scrollTop || document.body.parentNode.scrollTop || document.body.scrollTop;
  }
  var start = position(),
    change = to - start,
    currentTime = 0,
    increment = 20;
  duration = typeof duration === 'undefined' ? 500 : duration;
  var animateScroll = function () {
    // increment the time
    currentTime += increment;
    // find the value with the quadratic in-out easing function
    var val = easeInOutQuad(currentTime, start, change, duration);
    // move the document.body
    move(val);
    // do the animation unless its over
    if (currentTime < duration) {
      requestAnimFrame(animateScroll);
    } else {
      if (callback && typeof callback === 'function') {
        // the animation is done so lets callback
        callback();
      }
    }
  };
  animateScroll();
}

function appendStyle(styles) {
  let css = document.createElement('style');
  css.type = 'text/css';
  css.appendChild(document.createTextNode(styles));
  document.getElementsByTagName('head')[0].appendChild(css);
}

export default funcHelper.mixin(
  {
    addClass: addClass,
    hasClass: hasClass,
    removeClass: removeClass,
    toggleClass: toggleClass,
    doWithoutCSSTransitions: doWithoutCSSTransitions,

    closest: closest,

    newNode: newNode,
    innerText: innerText,
    appendHTML: appendHTML,
    prependHTML: prependHTML,
    remove: remove,
    removePreviousSibling: removePreviousSibling,

    getPageHeight: getPageHeight,
    check: check,
    uncheck: uncheck,

    toArray: toArray,
    forEach: forEach,
    map: map,
    every: every,
    find: find,
    findOne: findOne,

    dispatchEvent: dispatchEvent,
    waitForElement: waitForElement,

    pause: pause,

    attachPlaceholder: attachPlaceholder,

    stringifyJsonForData: stringifyJsonForData,
    parseJsonFromData: parseJsonFromData,

    redraw: redraw,
    objectFit: objectFit,
    scrollTo: scrollTo,
    getPageScrollTop: getPageScrollTop,
    scrollToElement: scrollToElement,
    scrollToPositionSmoothly: scrollToPositionSmoothly,
    isElementInViewport: isElementInViewport,
    loadScript: loadScript,
    decodeHtml: decodeHtml,
    waitForConditionalElement: waitForConditionalElement,
    isWholeElementInViewport: isWholeElementInViewport,
    updateQueryStringParameter: updateQueryStringParameter,
    getUrlParameter: getUrlParameter,
    appendStyle: appendStyle
  },
  httpHelper,
  cookieHelper
);
