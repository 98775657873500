import global from 'global';
import domHelper from 'Helpers/domHelper';
import OverlayMobile from 'Common/overlayMobile';

let toggles;
let togglesMobile;

function init() {
  OverlayMobile.init();
  _cacheDom();
  _bindEvents();
}

function _cacheDom() {
  toggles = document.querySelectorAll('[data-toggle]');
  togglesMobile = document.querySelectorAll('[data-toggle_mob]');
}

function _bindEvents() {
  if(toggles) {
    [].forEach.call(toggles, function(el) {
      el.addEventListener('click', _triggerClass);
    });
  }
  if(togglesMobile && global.isMobile) {

    [].forEach.call(togglesMobile, function(el) {
      el.addEventListener('click', _triggerClass);
    });
  }
}

function _triggerClass(e) {
  let element = e.currentTarget;
  let target = document.querySelector(element.getAttribute('data-target') || element.getAttribute('href'));
  let isClickable = element.hasAttribute('data-mobile-clickable');

  if(!isClickable) {
    e.preventDefault();
  }

  domHelper.toggleClass(element, 'is-open');
  target && domHelper.toggleClass(target, 'in');
}

export default {
  init
};
