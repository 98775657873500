import global from 'global';
import domHelper from 'Helpers/domHelper';


function init() {
  if (!domHelper.getCookie('deviceType')) {
    domHelper.setCookie('deviceType', global.deviceTypeName);
  }
}

export default {
  init: init
};

