import httpHelper from 'Helpers/httpHelper';
import CookieHelper from 'Helpers/cookieHelper';
import Modal from 'Common/modal/modal';
import overlayTemplate from './overlayTemplate.mst.html';
import successText from './successText.mst.html';
import domHelper from 'Helpers/domHelper';
import 'global';
import Promise from 'bluebird';
import sha256 from 'Common/cryptography/sha256';

const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const IS_HIDDEN_CLASS = 'is-hidden';

let input;
let subscribeBtn;
let unsubscribeLink;
let almostDone;
let unsubscriptionError;
let unsubscriptionContent;

function init() {
  _cacheDom();
  _bindEvents();
}

function _cacheDom() {
  input = document.querySelector('#footer-subscrb__input');
  subscribeBtn = document.querySelector('#footer-subscrb__button');
  unsubscribeLink = document.querySelector('#js-unsubscription__button');
  almostDone = document.getElementsByClassName('almost-done');
  unsubscriptionError = document.querySelector('#js-unsubscription__error');
  unsubscriptionContent = document.querySelector('#js-unsubscription__content');
}

function _bindEvents() {
  subscribeBtn.addEventListener('click', onSubscribeClick);
}

function onSubscribeClick(event) {
  event.preventDefault();
  postNewsletterSubscribe(input.value, 'footer').then(function () {
    DY.API('event', {
      name: 'Newsletter Subscription',
      properties: { dyType: 'newsletter-subscription-v1', hashedEmail: sha256.hash(input.value.toLowerCase()) }
    });
    // Empty the email input
    input.value = '';
  });
}

function postNewsletterSubscribe(email, source) {
  return new Promise(function (resolve, reject) {
    if (EMAIL_REGEX.test(email)) {
      // TODO: pass in source to this function if we start using it elsewhere
      httpHelper.postJSON('/newsletter/subscribe', JSON.stringify({ email, source })).then(response => {
        let overlayTemplateParams;
        if (response.success) {
          overlayTemplateParams = window._i18n.newsletterSubscription.success;
        } else {
          overlayTemplateParams = window._i18n.newsletterSubscription.error;
        }

        const modal = new Modal({
          className: 'modal--newsletter',
          body: overlayTemplate(overlayTemplateParams)
        });
        modal.open();

        resolve();
      });
    }
  });
}

function showError() {
  domHelper.removeClass(unsubscriptionError, IS_HIDDEN_CLASS);
}

function getNewsletterUnsubscribe(e) {
  e.preventDefault();

  httpHelper
    .postJSON(unsubscribeLink.href, {})
    .then(response => {
      if (response.success) {
        unsubscriptionContent.innerHTML = successText({
          title: window._i18n.newsletterUnsubscription.successMsg
        });
      } else {
        showError();
      }
    })
    .catch(function () {
      showError();
    });
}

export default {
  init,
  postNewsletterSubscribe,
  getNewsletterUnsubscribe
};
