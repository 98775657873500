import React from 'react';
import { createTheme, createStyles as muiCreateStyles } from '@material-ui/core';

export { withStyles } from '@material-ui/core';

const radioButtonIcon = {
  width: 18,
  height: 18,
  border: 'solid 1px #e8e8e8',
  borderRadius: '50%'
};

const radioButtonCheckedIcon = {
  width: 18,
  height: 18,
  backgroundColor: '#000',
  border: 'solid 1px #fff',
  borderRadius: '50%',
  display: 'block',
  // svg loader doesn't work here properly for some reason, so base64 fore now
  // eslint-disable-next-line max-len
  backgroundImage: `url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMSIgdmlld0JveD0iMCAwIDEzIDExIj4KICAgIDxwYXRoIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlPSIjRkZGIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMiIgZD0iTTEgNS40NzFsMy44MyAzLjY5NiA2LjU4Ny03LjUiLz4KPC9zdmc+Cg==)`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center'
};

export const themeGlobals = {
  props: {
    MuiRadio: {
      icon: <span style={radioButtonIcon} />,
      checkedIcon: <span style={radioButtonCheckedIcon} />
    },
    MuiCheckbox: {
      icon: <span style={radioButtonIcon} />,
      checkedIcon: <span style={radioButtonCheckedIcon} />
    }
  },
  '@global': {
    '*::-webkit-scrollbar': {
      width: 6,
      height: 6,
      backgroundColor: 'transparent'
    },

    '*::-webkit-scrollbar-track': {
      backgroundColor: 'transparent'
    },

    '*::-webkit-scrollbar-thumb': {
      backgroundColor: '#ccc'
    }
  },
  palette: {
    primary: {
      main: '#0000ff'
    },
    secondary: {
      main: '#25282d'
    }
  },
  overrides: {
    MuiTypography: {
      h6: {
        fontFamily: "'Poppins', sans-serif",
        fontSize: 16,
        fontWeight: 500,
        lineHeight: '24px',
        padding: '15px 10px 8px'
      },
      h5: {
        fontFamily: "'Poppins', sans-serif",
        fontSize: 18,
        fontWeight: 600,
        lineHeight: '24px',
        padding: '30px 10px 8px'
      },
      body1: {
        fontFamily: "'Poppins', sans-serif",
        fontSize: 12
      }
    },
    MuiButton: {
      root: {
        borderRadius: 0,
        padding: '5px 15px'
      },
      label: {
        fontFamily: "'Poppins', sans-serif",
        justifyContent: 'space-between'
      }
    },
    MuiFormControl: {
      root: {
        padding: '2px 0'
      }
    },
    MuiInputLabel: {
      shrink: {
        transform: 'translate(0, 11px) scale(0.75)'
      }
    },
    MuiFormLabel: {
      root: {
        marginLeft: 10,
        color: '#999b9f',
        fontFamily: 'Poppins',
        fontSize: 16,
        lineHeight: 1,
        '&$focused': {
          color: '#999b9f'
        },
        '@media (min-width:768px)': {
          fontSize: 14
        }
      }
    },
    MuiInput: {
      input: {
        margin: '7px 0 5px 10px',
        padding: '4px 0 2px 0 ',
        color: '#25282d',
        fontFamily: 'Poppins',
        fontSize: 16,
        width: '100%',
        '@media (min-width:768px)': {
          fontSize: 14
        }
      },
      underline: {
        '&:hover:not(.Mui-disabled):before': {
          borderBottom: '1px solid #e8e8e8'
        },
        '&&:before': {
          borderBottom: '1px solid #e8e8e8'
        },
        '&&:after': {
          borderBottom: '1px solid #e8e8e8'
        },
        '&$error': {
          borderBottom: '1px solid red'
        }
      }
    },
    MuiFormHelperText: {
      root: {
        fontFamily: "'Poppins', sans-serif",
        paddingLeft: 10
      }
    },
    MuiAutocomplete: {
      input: {
        '&:first-child': {
          padding: '0 0 6px !important'
        }
      }
    },
    MuiDialog: {
      paper: {
        margin: 10
      }
    },
    MuiPaper: {
      rounded: {
        borderRadius: 'none'
      }
    }
  }
};

export const theme = createTheme(themeGlobals);

export const createStyles = cb => muiCreateStyles(cb);
