'use strict';

/**
 * Bundles category page scripts.
 * @module pages/selections/grid
 */

// TODO: refactor and comment methods

import Masonry from 'masonry-layout';
import Filters from 'Shared/filters/filters';
import domHelper from 'Helpers/domHelper';
import funcHelper from 'Helpers/funcHelper';
import pubsub from 'pubsub';
import {
  EVENT_WISHLIST_REMOVE
} from 'Constants/event';

let debouncedCalculateFunc;

function _calculatePlaceholderWidth() {
  let tile = document.querySelector('.selections-card__content');
  if (!tile) return;
  let tileWidth = tile.getBoundingClientRect().width;
  [].forEach.call(document.querySelectorAll('.selection-card__placeholder'), item => {
    let aspectRatio = item.getAttribute('data-aspect-ratio');
    item.style.width = tileWidth + 'px';
    item.style.height = Math.floor(tileWidth / aspectRatio) + 'px';
  });
  if (window.msnryInstance) window.msnryInstance.layout();
}

function _bindEventListeners() {
  window.addEventListener('resize', debouncedCalculateFunc);
  if (window.location.pathname === '/wishlist') {
    pubsub.subscribe(EVENT_WISHLIST_REMOVE, selectionId => {
      let elem = document.querySelector(`[data-product-selection-id='${selectionId}']`);
      if (elem) {
        window.msnryInstance.remove(elem);
        window.msnryInstance.layout();
      }
      if (!document.querySelector('.selections-card__content')) {
        location.reload();
      }
    });
  }
}

function init() {
  debouncedCalculateFunc = funcHelper.debounce(_calculatePlaceholderWidth, 100);

  _bindEventListeners();
  _calculatePlaceholderWidth();

  let selectionsGrid = document.querySelector('.selections-grid');
  domHelper.addClass(selectionsGrid, 'selections-grid--visible');
  window.msnryInstance = new Masonry(selectionsGrid, {
    // options
    itemSelector: '.selections-card',
    transitionDuration: 0,
    horizontalOrder: true,
    percentPosition: true,
  });

  // start filters
  Filters.init();
}

function addMoreProducts(items) {
  _calculatePlaceholderWidth();
  let selectionsGrid = document.querySelector('.selections-grid');
  domHelper.addClass(selectionsGrid, 'selections-grid--visible');
  window.msnryInstance.appended(items);
}

export default {
  init: init,
  addMoreProducts: addMoreProducts
}