import JVideoModal from './jVideoModal';


var videoModal;

function init() {
  var targets = _getTargetVideos(),
      thumb;

  if (targets.length) {
    for (var i = 0; i < targets.length; i++) {
      if (targets[i].getAttribute('data-inited') !== 'true') { // if we already inited, then skip.
        targets[i].setAttribute('data-inited', 'true');
        if (targets[i].getAttribute('data-nopreview') === 'true') {
          thumb = targets[i].querySelector('video');
          _bindEvents(targets[i], thumb.cloneNode(true));
        }
        else {
          thumb = _replaceWithThumb(targets[i]);
          _bindEvents(thumb, targets[i]);
        }
      }

    }

    videoModal = new JVideoModal();
  }
}

function _getTargetVideos() {
  return document.getElementsByClassName('js-j-video');
}

function _replaceWithThumb(videoEl) {
  var thumb;

  videoEl.style.visibility = 'hidden';
  videoEl.style.display = '';

  thumb = _createThumb(videoEl.poster);

  videoEl.poster = '';
  videoEl.style.display = 'none';
  videoEl.style.visibility = '';

  videoEl.parentNode.insertBefore(thumb, videoEl);

  return thumb;
}

function _createThumb(thumbImg) {
  var img = new Image();
  img.src = thumbImg;

  var thumb = document.createElement('div');
  thumb.className = 'j-video-thumb';

  var playIcon = document.createElement('i');
  playIcon.className = 'icon icon-play-circle j-video-thumb__icon';

  thumb.appendChild(img);
  thumb.appendChild(playIcon);

  return thumb;
}

function _bindEvents(thumbEl, videoEl) {
  thumbEl.addEventListener('click', _onThumbClick.bind(null, videoEl));
  videoEl.addEventListener('ended', _onVideoEnded);
}

function _onThumbClick(videoEl, e) {
  e.preventDefault();
  videoModal.embed(videoEl);
  videoModal.open();
  videoEl.style.display = '';
  videoModal.play();

  videoModal.onClose(function () {
    videoModal.pause();
    videoModal.clean();
  });
}

function _onVideoEnded() {
  videoModal.close();
}

export default {init: init};

