'use strict';

import global from 'global';
import DOM from 'Helpers/domHelper';
import ReactSiema from 'siema';
import React from 'react';
import ProductPane from './ProductPane';

const ITEMS_NUMBER_BREAKPOINT_DESKTOP = 5;
const NEXT_DIRECTION_ID = 'next';
const PREV_DIRECTION_ID = 'prev';
const CLASS_DISABLED_NAV_BUTTON = 'rec-list__nav--disabled';

class ProductsList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  static getDerivedStateFromProps(nextProps) {
    const perPageAmount = nextProps.perPageAmount || ITEMS_NUMBER_BREAKPOINT_DESKTOP;
    return {
      products: nextProps.products,
      itemsNumber: nextProps.products.length,
      isCarouselInited: global.isDesktop && nextProps.products.length > perPageAmount
    }
  }

  moveSlider(direction) {
    const perPageAmount = this.props.perPageAmount || ITEMS_NUMBER_BREAKPOINT_DESKTOP;
    if (this.carouselInstance) {
      switch (direction) {
        case NEXT_DIRECTION_ID:
          DOM.removeClass(this.previousCarouselButton, CLASS_DISABLED_NAV_BUTTON);
          this.carouselInstance.next(perPageAmount, () => {
            if (this.carouselInstance.currentSlide + perPageAmount === this.state.itemsNumber) {
              DOM.addClass(this.nextCarouselButton, CLASS_DISABLED_NAV_BUTTON);
            }
          });
          break;
        case PREV_DIRECTION_ID:
          DOM.removeClass(this.nextCarouselButton, CLASS_DISABLED_NAV_BUTTON);
          this.carouselInstance.prev(perPageAmount, () => {
            if (this.carouselInstance.currentSlide === 0) {
              DOM.addClass(this.previousCarouselButton, CLASS_DISABLED_NAV_BUTTON);
            }
          });
          break;
      }
    }
  }

  render() {
    if (this.state.itemsNumber === 0) {
      return null;
    }
    const showSeeSimilarDesigns = !(this.props.showSeeSimilarDesigns === false);
    const perPageAmount = this.props.perPageAmount || 5;
    let pageIndex = 0;
    // default buttons state for all carousels
    let leftArrowActive = false;
    let rightArrowActive = true;
    if (this.props.scrollToActive) {
      let activeItemIndex;
      for (let key in this.props.products) {
        if (this.props.products.hasOwnProperty(key)) {
          let item = this.props.products[key];
          if (item.isCurrentProduct) activeItemIndex = key;
        }
      }
      if (activeItemIndex > 1) {
        // decreasing to 1 to
        activeItemIndex -= 1;
        pageIndex = activeItemIndex;
        leftArrowActive = true;
        // we have reached the end of carousel
        if ((Number(activeItemIndex) + (perPageAmount - 1)) >= (Number(this.props.products.length) - 1)) {
          pageIndex = this.props.products.length - perPageAmount;
          rightArrowActive = false;
        }
      }
    }
    const handleProductMouseIn = this.props.handleProductMouseIn || function() {};
    const handleProductMouseOut = this.props.handleProductMouseOut || function() {};
    const handleProductClick = this.props.handleProductClick || function() {};
    const title = this.props.title ?
      (<h3 className="rec-list__title">{this.props.title}</h3>) :
      null;
    const premiumPosterTypeId = this.props.premiumPosterTypeId;
    const items = this.state.products.map((el, i) => (
        <ProductPane
            el={el}
            handleProductMouseOut={handleProductMouseOut}
            handleProductMouseIn={handleProductMouseIn}
            handleProductClick={handleProductClick}
            premiumPosterTypeId={premiumPosterTypeId}
            key={i}
            showSeeSimilarDesigns={showSeeSimilarDesigns}
            {...el}
        />
    ));
    const navigationButtons = this.state.isCarouselInited ?
      (<div className="rec-list__nav-buttons">
        <button type="button" className={`rec-list__nav rec-list__nav--next ${!rightArrowActive ? CLASS_DISABLED_NAV_BUTTON : ''}`}
                ref={el => {
                  this.nextCarouselButton = el;
                }}
                onClick={this.moveSlider.bind(this, NEXT_DIRECTION_ID)}>
          <i className="rec-list__nav-icon rec-list__nav-icon--next"/>
        </button>
        <button type="button" className={`rec-list__nav rec-list__nav--prev ${!leftArrowActive ? CLASS_DISABLED_NAV_BUTTON : ''}`}
                ref={el => {
                  this.previousCarouselButton = el;
                }}
                onClick={this.moveSlider.bind(this, PREV_DIRECTION_ID)}>
          <i className="rec-list__nav-icon rec-list__nav-icon--prev"/>
        </button>
      </div>) :
      null;
    const carousel = this.state.isCarouselInited ?
      (<ReactSiema draggable={false}
                   duration={500}
                   startIndex={pageIndex}
                   perPage={perPageAmount}
                   ref={el => {
                     this.carouselInstance = el;
                   }}>
        {items}
      </ReactSiema>) :
      items;

    return (<div className={`rec-list ${this.state.isCarouselInited ? 'rec-list--carousel' : ''}`}>
      {title}
      <div className="rec-list__carousel">
        {navigationButtons}
        <div className="rec-list__items">
          {carousel}
        </div>
      </div>
    </div>)
  }
}

export default ProductsList;
