'use strict';

import headerPopover from './headerPopover';
import domHelper from 'Helpers/domHelper';
import headerFastPopover from './headerFastPopover';
import wishlistTemplate from './wishlistTemplate.mst.html';

export default headerFastPopover({
  'moduleName': 'wishlist',
  items: [],
  tplUrl: '/wishlist/get',
  parentId: 'wish-list-cart',
  template: wishlistTemplate,
  containerId: 'wish-list-cart-popover',
  popoverContentUrl: 'wishlist'
});