import domHelper from 'Helpers/domHelper';
import backToTop from './backToTop';
import stickyHelper from './stickyHelper';

var parent,
  navOffsetTop,
  originalHeaderHeight,
  headerEl,
  offsetTopAttributeName = 'data-offset-top';

function init(stickyCommon) {
  parent = stickyCommon;

  return {
    init: realInit
  };
}

function realInit() {
  parent.cacheDom();

  headerEl = document.querySelector('#header');
  if (headerEl) {
    parent.scrollHandler = _scrollHandler;

    parent.isHeaderSticky = false;
    originalHeaderHeight = parent.header.offsetHeight;
    navOffsetTop = _getNavOffsetTop();

    _setInitialState();
    parent.bindEvents();

    _setHidingOffsetForFirstLevelMenu();
  }
}

function _setHidingOffsetForFirstLevelMenu() {
  let menuCategories = document.querySelector('.menu__categories');

  if (menuCategories) {
    let height = menuCategories.getBoundingClientRect().height;
    document.querySelector('.menu__collapse').style.top = -height - 1;
  }
}

function _setInitialState() {
  _scrollHandler();
}

function _scrollHandler() {
  navOffsetTop = _getHeaderOffsetTop();
  parent.toggleStickyState(
    parent.$body,
    'is-sticky',
    navOffsetTop,
    function () {
      parent.isHeaderSticky = true;
    },
    function () {
      parent.isHeaderSticky = false;
      domHelper.removeClass(parent.header, 'is-shown');
    }
  );
  if (parent.isHeaderSticky) {
    parent.showHeaderOnUpscroll();
  }

  // Toggle back to top button
  backToTop.toggleBackToTop(parent.$body);
}

function _getNavOffsetTop() {
  var navOffsetTop;
  navOffsetTop =
    parent.$body.querySelector('#header__container').offsetTop +
    document.getElementById('header__container').clientHeight;
  if (parent.promoBar) {
    navOffsetTop -= parent.promoBar.offsetHeight;
  }

  return navOffsetTop;
}

function _getHeaderOffsetTop() {
  var dataScrollOffset = getOffsetTopDataAttribute();

  // Check if the header has the view port offset saved as data attribute
  if (dataScrollOffset) {
    return dataScrollOffset;
  }
  // Calculate the view port offset of the header
  setOffsetTopDataAttribute();

  return getOffsetTopDataAttribute();
}

function setOffsetTopDataAttribute() {
  var navOffsetTop = _getNavOffsetTop();
  headerEl.setAttribute(offsetTopAttributeName, navOffsetTop);
}

function getOffsetTopDataAttribute() {
  return headerEl.getAttribute(offsetTopAttributeName);
}

export default {
  init: init,
  setOffsetTopDataAttribute: setOffsetTopDataAttribute
};
