'use strict';

import PubSub from 'pubsub';

import {
  EVENT_DY_REDIRECT_TO_MINI_PDP
} from 'Constants/event';

function updateSimilarDesignsLinks() {
  document.addEventListener('DOMContentLoaded', () => {
    const linksToSimilarDesignsPdp = document.querySelectorAll('.js-see-more-like__link');
    [].forEach.call(linksToSimilarDesignsPdp, function (link) {
      let href = link.getAttribute('href').split('?')[0];

      // replace the href by the default link (without '?similar')
      link.setAttribute('href', href);
    });
  });
}

PubSub.subscribe(EVENT_DY_REDIRECT_TO_MINI_PDP, updateSimilarDesignsLinks);

// redirect all pdps to /filter/ID: FE rendered templates
export const getDefaultUrl = (setting) => {
  return setting.url;
};

