import domHelper from 'Helpers/domHelper';


// Based on http://rickharrison.github.io/validate.js/
// -- Trimmed and customized

var numericRegex = /^[0-9]+$/,
    ruleRegex = /^(.+?)\[(.+)\]$/,
    emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
    numericDashRegex = /^[\d\-\s]+$/,
    urlRegex = /^((http|https):\/\/(\w+:{0,1}\w*@)?(\S+)|)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?$/,
    addressNumberRegex = /\d+/,
    addressLettersRegex = /[a-zA-Z]{3,}/,
    kosovoRegex = /kosovo|kosova/,
    zipRegex = {
      'AT': /^[0-9]{4}$/, // Austria 
      'BE': /^[0-9]{4}$/, // Belgium 
      'DK': /^[0-9]{4}$/, // Denmark 
      'FI': /^[0-9]{5}$/, // Finland
      'FR': /^[0-9]{5}$/, // France 
      'DE': /^[0-9]{5}$/, // Germany 
      'GB': /^[A-Z]{1,2}[0-9R][0-9A-Z]? [0-9][ABD-HJLNP-UW-Z]{2}$/, // Great Britain
      'IT': /^[0-9]{5}$/, // Italy 
      'LU': /^[0-9]{4}$/, // Luxembourg 
      'ES': /^[0-9]{5}$/, // Spain 
      'CH': /^[0-9]{4}$/, // Switzerland 
      'SE': /^[0-9]{3} [0-9]{2}$/, // Sweden 
      'NL': /^([0-9]{4} [a-z|A-Z]{2})$/ // Netherlands 
    };

var handlers = {
  keyup: function (e) {
    this._removeError(e.currentTarget);
    this.fields[e.currentTarget.getAttribute('name')].value = e.currentTarget.value;
  },
  focus: function (e) {
    this._removeError(e.currentTarget);
  },
  blur: function (e) {
    this.fields[e.currentTarget.getAttribute('name')].value = e.currentTarget.value;
    this._removeError(e.currentTarget);
    this._validateField(this.fields[e.currentTarget.getAttribute('name')]);
  },
  change: function (e) {
    var fieldName = e.currentTarget.getAttribute('name'),
        el = this.form[fieldName],  // element on the page
        field = this.fields[fieldName]; // item in object


    field.value = attributeValue(el, 'value');

    if ((field.type === 'checkbox') || (field.type === 'radio')) {
      if ((field.checked = attributeValue(el, 'checked'))) {
        // field.checked = true;
        this._removeError(el);
      } else {
        // field.checked = false;
        this._addError(el);
      }
    }

    this._validateField(field);
  },
  submit: function (e) {
    this._validateForm();
    if (this.errors.length) {
      e.preventDefault();
    }
  }
};

function attributeValue(element, attributeName) {
  var i, elLength;

  if ((element.length > 0) && (element[0].type === 'radio' || element[0].type === 'checkbox')) {
    for (i = 0, elLength = element.length; i < elLength; i++) {
      if (element[i].checked) {
        return element[i][attributeName];
      }
    }

    return;
  }

  return element[attributeName];
}

function Validation(formId, fields) {
  this.form = document.getElementById(formId);
  this.errors = [];
  this.fields = {};

  var field;

  for (var j = 0, fieldsLength = fields.length; j < fieldsLength; j++) {
    field = fields[j];
    this._addField(field, field.name);
    this._bindFieldEvents(field.name);
  }

  this.form.addEventListener('submit', handlers.submit.bind(this));
}

Validation.prototype._addField = function (field, nameValue) {
  this.fields[nameValue] = {
    name: nameValue,
    display: field.display || nameValue,
    rules: field.rules,
    depends: field.depends,
    id: null,
    element: null,
    type: this.form[nameValue].type,
    value: null,
    checked: (this.form[nameValue].checked) || null
  };
};

Validation.prototype._bindFieldEvents = function (nameValue) {
  var field = this.form[nameValue],
      fields,
      i,
      fieldLength;

  fields = (field.length) ? field : [field]; // radio buttons

  i = 0;
  fieldLength = fields.length;
  while (i < fieldLength) {
    field = (field.tagName === 'SELECT') ? (i = fieldLength) && fields : fields[i];
    field.addEventListener('keyup', handlers.keyup.bind(this));
    field.addEventListener('focus', handlers.focus.bind(this));
    field.addEventListener('blur', handlers.blur.bind(this));
    field.addEventListener('change', handlers.change.bind(this));
    i++;
  }
};

Validation.prototype._validateForm = function () {
  this.errors = [];

  for (var key in this.fields) {
    if (this.fields.hasOwnProperty(key)) {
      var field = this.fields[key] || {},
          element = this.form[field.name];

      if (element && element !== undefined) {
        field.type = (element.length > 0) ? element[0].type : element.type;
        field.value = attributeValue(element, 'value');
        field.checked = attributeValue(element, 'checked');

        /*
         * Run through the rules for each field.
         * If the field has a depends conditional, only validate the field
         * if it passes the custom function
         */

        if (field.depends && typeof field.depends === 'function') {
          if (field.depends.call(this, field)) {
            this._validateField(field);
          }
        } else if (field.depends && typeof field.depends === 'string' && this.conditionals[field.depends]) {
          if (this.conditionals[field.depends].call(this, field)) {
            this._validateField(field);
          }
        } else {
          this._validateField(field);
        }
      }
    }
  }
};

Validation.prototype._validateField = function (field) {
  var parts,
      param,
      element,
      method,
      fieldValid = true,
      rules = field.rules.split('|');

  for (var i = 0, ruleLength = rules.length; i < ruleLength && fieldValid; i++) {
    method = rules[i];
    element = null;
    parts = ruleRegex.exec(method);

    if (parts) {
      method = parts[1];
      param = parts[2];
    }

    if (method.charAt(0) === '!') {
      method = method.substring(1, method.length);
    }

    element = this.form[field.name];
    if (!this._hooks[method].apply(this, [field, param])) {
      this._addError(element, method);
      fieldValid = false;
    }
  }

  if (fieldValid) {
    this._removeError(element);
  }
};


Validation.prototype._hooks = {
  required: function (field) {
    var value = field.value;

    if ((field.type === 'checkbox') || (field.type === 'radio')) {
      return (field.checked === true);
    }

    return (value !== null && value !== '');
  },
  matches: function (field, matchName) {
    var el = this.form[matchName];

    if (el) {
      return field.value === el.value;
    }

    return false;
  },

  validEmail: function (field) {
    return emailRegex.test(field.value);
  },

  validEmails: function (field) {
    var result = field.value.split(/\s*,\s*/g);

    for (var i = 0, resultLength = result.length; i < resultLength; i++) {
      if (!emailRegex.test(result[i])) {
        return false;
      }
    }

    return true;
  },

  minLength: function (field, length) {
    if (!numericRegex.test(length)) {
      return false;
    }

    return (field.value.length >= parseInt(length, 10));
  },

  maxLength: function (field, length) {
    if (!numericRegex.test(length)) {
      return false;
    }

    return (field.value.length <= parseInt(length, 10));
  },

  validUrl: function (field) {
    return (urlRegex.test(field.value));
  },

  validKosovo: function (field) {
    return !kosovoRegex.test(field.value.toLowerCase());
  },

  validAddress: function (field) {
    var input = document.querySelector('[name="' + field.name + '"]');
    var dependsOn = document.querySelector('[name="' + input.getAttribute('data-depends') + '"]');
    var lettersTest = addressLettersRegex.test(field.value)

    // On UK we validate only presence of letters in address
    if (dependsOn && dependsOn.value === 'GB') {
      return lettersTest;
    }

    // Otherwise presence of numbers also needed
    return lettersTest && addressNumberRegex.test(field.value);
  },

  validZip: function (field) {
    var input = document.querySelector('[name="' + field.name + '"]');
    var dependsOn = document.querySelector('[name="' + input.getAttribute('data-depends') + '"]');

    if (dependsOn && dependsOn.value) {
      return (zipRegex[dependsOn.value].test(field.value));
    }

    return Boolean(field.value.trim());
  },

  validCreditCard: function (field) {
    // Luhn Check Code from https://gist.github.com/4075533
    // accept only digits, dashes or spaces
    if (!numericDashRegex.test(field.value)) {
      return false;
    }

    // The Luhn Algorithm. It's so pretty.
    var nCheck = 0, nDigit = 0, bEven = false;
    var strippedField = field.value.replace(/\D/g, '');

    for (var n = strippedField.length - 1; n >= 0; n--) {
      var cDigit = strippedField.charAt(n);
      nDigit = parseInt(cDigit, 10);
      if (bEven) {
        if ((nDigit *= 2) > 9) {
          nDigit -= 9;
        }
      }

      nCheck += nDigit;
      bEven = !bEven;
    }

    return (nCheck % 10) === 0;
  }
};

Validation.prototype._addError = function (el, validationMethod) {
  el = (el.length && el.nodeName !== 'SELECT') ? el[0] : el; // radio buttons

  var container = domHelper.closest(el, '.jnq-field') || el.parentNode.parentNode, // if we did not find jnq-field
      name = el.getAttribute('name');

  domHelper.addClass(container, 'error');

  var validationMethodError = container.querySelector('[data-error="' + validationMethod + '"]');
  if (validationMethodError) {
    validationMethodError.style.display = 'block';
  }

  if (this.errors.indexOf(name) === -1) {
    this.errors.push(name);
  }

};

Validation.prototype._removeError = function (el) {
  el = el.length ? el[0] : el; // radio buttons

  var container = domHelper.closest(el, '.jnq-field'),
      name = el.getAttribute('name'),
      errorIndex,
      validationMethodErrors,
      i;

  if (domHelper.hasClass(container, 'error')) {

    domHelper.removeClass(container, 'error');

    validationMethodErrors = container.querySelectorAll('[data-error]');
    for (i = 0; i < validationMethodErrors.length; i++) {
      validationMethodErrors[i].style.display = 'none';
    }

    errorIndex = this.errors.indexOf(name);
    this.errors.splice(errorIndex, 1);
  }

};

Validation.prototype.isOk = function () {
  return !this.errors.length;
};

export default Validation;

