import global from 'global';
import domHelper from 'Helpers/domHelper';
import CustomSelectModel from './customSelectModel';
import CustomSelectView from './customSelectView';


window.customSelects = [];

function init() {

  /*
  * It fires the change event on host select element.
  * WARNING: if event subscription was hanged on the
  *          form or common parent for several select
  *          elements - it (event handler) won't be triggered
  * */
  if (window.customSelects.length > 0) return;
  var selects = _targetElements();
  _wrapElements(selects);
}

function _targetElements() {
  return document.querySelectorAll('.select select');
}

function _wrapElements(selects) {
  domHelper.forEach(selects, function (selectEl) {
    var model = new CustomSelectModel(selectEl),
        view = new CustomSelectView(model);
    view.render();

    window.customSelects.push(view);
  });
}

function renderAll() {
  window.customSelects.forEach(function (customSelect) {
    customSelect.render();
  });
}

function find(select) {
  return domHelper.findOne(window.customSelects, function (customSelect) {
    return customSelect.model.el === select;
  });
}

function findAndRender(select) {
  var found = find(select);
  if (found) {
    found.render();
  }
}

export default {
  init: init,
  elements: window.customSelects,
  find: find,
  findAndRender: findAndRender,
  renderAll: renderAll
};

