import PubSub from 'pubsub';
import { EVENT_DY_TWO_CLICK_ATC, EVENT_DY_CAROUSEL_LOADED } from 'Constants/event';
import { getProductLinkFromDyConfig, getSKUfromImgUrl, isWallartBySKU } from './utils';

let productLinkDyConfigCache = null;

export const getProductLinkDyConfigCache = () => productLinkDyConfigCache;

// Apllies discount to Hero Banner carousel
function transformDyCarouselUrls(container) {
  const links = container.querySelectorAll('.dy-recommendation-product');
  [...links].forEach(linkEl => {
    const img = linkEl.querySelector('.dy-recommendation-product__image');
    const imgUrl = img.getAttribute('src');
    const productSku = getSKUfromImgUrl(imgUrl);

    if (!isWallartBySKU(productSku)) {
      return;
    }

    const href = linkEl.getAttribute('href');

    const { productType, frameId } = linkEl.dataset;
    const productData = { productType, frameId, productSku };

    try {
      const newUrl = getProductLinkFromDyConfig(href, productLinkDyConfigCache, productData);
      linkEl.setAttribute('href', newUrl);
    } catch (e) {
      console.log('CAN NOT CONSTRUCT PRODUCT URL WITH DY CONFIG');
    }
  });
}

function applyStepConfigToDYCarousel({ id }) {
  const container = document.getElementById(`dy-recommendations-${id}`);
  transformDyCarouselUrls(container);
}

function applyStepConfigToAllDyCarousels() {
  const containers = document.querySelectorAll('div[id^="dy-recommendations-"]');
  [...containers].forEach(c => transformDyCarouselUrls(c));
}

const init = () => {
  PubSub.subscribe(EVENT_DY_TWO_CLICK_ATC, dyData => {
    if (!productLinkDyConfigCache) {
      productLinkDyConfigCache = dyData;
    }

    applyStepConfigToAllDyCarousels();
  });

  PubSub.subscribe(EVENT_DY_CAROUSEL_LOADED, dyData => {
    if (productLinkDyConfigCache) {
      applyStepConfigToDYCarousel(dyData);
    }
  });
};

document.addEventListener('DOMContentLoaded', init);
