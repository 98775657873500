var isIOS = /iPad|iPhone|iPod/.test(navigator.platform),
    isLteIE9 = !!(document.all && !window.atob),
    isIE = navigator.appVersion.indexOf('MSIE') !== -1 || _isIE11();

function _isIE11() {
  return !(window.ActiveXObject) && 'ActiveXObject' in window;
}

export default {
  isIOS: isIOS,
  isLteIE9: isLteIE9,
  isIE: isIE
};

