import domHelper from 'Helpers/domHelper';

const readMoreText = window._i18n.readMore;
const readLessText = window._i18n.readLess;
const triggerBtnTemplate = `<a class="read-more-less__trigger is-hidden" href="#">${readMoreText}</a>`;

function _handleTriggerClick(e) {
  e.preventDefault();
  const triggerBtn = e.currentTarget;
  const parentSection = domHelper.closest(triggerBtn, '.read-more-less');
  if (domHelper.hasClass(parentSection, 'collapsed')) {
    domHelper.removeClass(parentSection, 'collapsed');
    domHelper.addClass(triggerBtn, 'read-more-less__trigger--inline');
    triggerBtn.innerText = readLessText;
  } else {
    domHelper.addClass(parentSection, 'collapsed');
    domHelper.removeClass(triggerBtn, 'read-more-less__trigger--inline');
    triggerBtn.innerText = readMoreText;
  }
}

function init(rootNode = document) {
  [].forEach.call(rootNode.querySelectorAll('.read-more-less'), parent => {
    // if parent element is empty don't need to do anything
    if (parent.clientHeight === 0) return;
    const hasSeoTagRequirements = parent.classList.contains('js-add-seo-tag');
    parent.innerHTML = hasSeoTagRequirements
      ? '<span itemprop="description">' + parent.innerText + '</span>'
      : parent.innerText;
    // insert trigger button
    parent.insertAdjacentHTML('beforeend', triggerBtnTemplate);
    // find it and add event listener to it
    const triggerBtn = parent.querySelector('.read-more-less__trigger');
    triggerBtn.addEventListener('click', _handleTriggerClick);
    if (parent.scrollHeight > parent.clientHeight + 5) {
      domHelper.removeClass(triggerBtn, 'is-hidden');
    }
  });
}

export default {
  init
};
