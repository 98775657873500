'use strict';

import global from 'global';
import j from 'Helpers/domHelper';
import cookieHelper from 'Helpers/cookieHelper';
import funcHelper from 'Helpers/funcHelper';
import overlayTemplate from './promoBarGeneral.mst.html';
import PubSub from 'pubsub';
import {
  PAGE_FAQ,
  PAGE_CART,
  PAGE_CHECKOUT,
  PAGE_MYACCOUNT,
  PAGE_REFER_FRIEND,
  PAGE_RETURNS,
  PAGE_NEWSLETTER_SUCCESS,
  PAGE_NOT_FOUND
} from 'Constants/page';
import { EVENT_PROMOBAR_RENDER, EVENT_DY_PROMOBAR_INIT_V6_GENERAL } from 'Constants/event';
import { TIME_SECOND, TIME_MINUTE, TIME_HOUR, TIME_DAY, TIME_YEAR } from 'Constants/misc';

const BASE_CLASS = 'agrsv-promo';
const STATES = {
  SIMPLE: `${BASE_CLASS}--simple`,
  INITIAL: `${BASE_CLASS}--init`,
  INITIAL_MINI: `${BASE_CLASS}--init-mini`,
  SUBMITTED: `${BASE_CLASS}--submitted`,
  FINAL: `${BASE_CLASS}--final`,
  HIDDEN: `is-hidden`
};
const IS_PROHIBITED_PAGE =
  PAGE_CHECKOUT ||
  PAGE_FAQ ||
  PAGE_MYACCOUNT ||
  PAGE_REFER_FRIEND ||
  PAGE_RETURNS ||
  PAGE_NEWSLETTER_SUCCESS ||
  (PAGE_NOT_FOUND && global.isMobile);
const COOKIE_PREFIX = 'jnq_pb_voucherCode';

// Variables from DY
let dyVars;
let translations;
let getVars = [];
let targetNode = document.querySelector('.header');
let promo = {
  wrapperNode: undefined,
  timerNode: undefined,
  closeButton: undefined,
  termsPopup: undefined,
  termsCloseButton: undefined,
  termsBackground: undefined,
  videoPopup: undefined,
  videoContent: undefined,

  inject: function () {
    let tempNode = document.createElement('div');
    let compiledOptions = funcHelper.mixin(
      dyVars,
      translations,
      dyVars[dyVars.renderPromo][global.isDesktop ? 'desktop' : 'mobile']
    );
    let contentText;

    let overlayDivId = compiledOptions.overlayDivId;
    let overlayContentElement = document.querySelector(`#${overlayDivId}`);
    compiledOptions.overlayContent = overlayContentElement ? overlayContentElement.innerHTML : '';
    contentText = overlayTemplate(compiledOptions);

    const urlHashAsObject = url =>
      url
        .split('#')
        .slice(1)
        .map(hash =>
          hash.split('&').reduce((pv, cv) => {
            const [key, value] = cv.split('=');
            return { ...pv, [key]: value };
          }, {})
        )
        .pop() || {};
    const hashParams = urlHashAsObject(window.location.hash);

    const getCookieValue = key => cookieHelper.getCookie(`${COOKIE_PREFIX}-${key}`) || '';
    const setCookieValue = (key, value) => value && cookieHelper.setCookie(`${COOKIE_PREFIX}-${key}`, value);

    if ('promo' in hashParams) {
      setCookieValue('promo', hashParams['promo']);
      setCookieValue('name', hashParams['name']);
    }
    contentText = contentText.replace('{promo}', getCookieValue('promo'));
    contentText = contentText.replace('{name}', getCookieValue('name'));

    tempNode.innerHTML = contentText;

    targetNode.parentNode.insertBefore(tempNode.firstChild, targetNode);

    if (!global.isMobile) {
      document.body.appendChild(j.newNode('style', {}, '.agrsv-promo { z-index: 6; }'));
    }

    j.addClass(document.body, 'promobar-shown');
    PubSub.publish(EVENT_PROMOBAR_RENDER);
  },
  show: function () {
    j.removeClass(this.wrapperNode, 'is-hidden');
    j.addClass(document.body, 'promobar-shown');
  },
  hide: function () {
    let simplePromo = document.querySelector('.agrsv-promo__block--simple');

    if (simplePromo && simplePromo.getAttribute('data-display') === 'INITIAL') {
      simplePromo.setAttribute('data-display', 'INITIAL_MINI');
    } else {
      this.state('HIDDEN');
      j.removeClass(document.body, 'promobar-shown');
    }
  },
  state: function (state) {
    if (state) {
      // Setter
      if (STATES[state]) {
        this.wrapperNode.className = `${BASE_CLASS} ${STATES[state]}`;
        this.wrapperNode.setAttribute('data-promo-state', state);
      }
      if (!STATES[state]) {
        console.warn(`State '${state}' is not found. Please, check the code`);
      }
    } else {
      // Getter
      return this.wrapperNode.getAttribute('data-promo-state');
    }
  },
  isExpired: function () {
    return dyVars.isExpiring === 'true' && now >= end;
  },
  preventVideoClick: function (e) {
    e.stopPropagation();
  },
  showVideoOverlay: function (e) {
    if (!j.hasClass(e.currentTarget, 'agrsv-promo__mini-cta') || j.hasClass(e.currentTarget, 'agrsv-promo__mini-cta')) {
      j.removeClass(this.videoPopup, 'is-hidden');
    }
  },
  hideVideoOverlay: function () {
    let iframe = promo.videoContent.querySelector('iframe');
    iframe.src = iframe.src;
    j.addClass(this.videoPopup, 'is-hidden');
  },
  openTerms: function (e) {
    e.preventDefault();
    e.stopPropagation();
    j.removeClass(this.termsPopup, 'is-hidden');
  },
  closeTerms: function (e) {
    if (!e.target.getAttribute('href')) {
      e.preventDefault();
      e.stopPropagation();
      j.addClass(this.termsPopup, 'is-hidden');
    }
  }
};
let now = new Date();
let end;
let voucherTimer;

function init() {
  try {
    promo.inject(); // NOT NEEDED WHEN MOVED TO DY

    _cacheDom();
    _bindEvents();

    if (dyVars.isClosable === 'true' && promo.closeButton) {
      promo.closeButton.style.display = 'block';
    }

    if (dyVars.isExpiring !== 'true') {
      promo.wrapperNode.setAttribute('data-not-expiring', true);
    }

    promo.state('SIMPLE');

    if (dyVars.isExpiring === 'true' && !promo.isExpired()) {
      _showRemainingTime();
      voucherTimer = setInterval(_showRemainingTime, TIME_SECOND);
    }
  } catch (e) {
    console.log('e: ', e);
  }
}

function _cacheDom() {
  promo.uspBarNode = document.getElementById('usp-bar');
  promo.wrapperNode = document.querySelector(`.${BASE_CLASS}`);
  promo.timerNode = promo.wrapperNode.querySelector(`.${BASE_CLASS}__timer-time`);
  promo.closeButton = promo.wrapperNode.querySelector(`.${BASE_CLASS}__close-promo-btn`);

  if (dyVars.isTermsPopup === 'cmsBlockOverlay') {
    promo.termsPopup = document.querySelector(`.${BASE_CLASS}__terms-popup`);
    promo.termsCloseButton = document.querySelector(`.${BASE_CLASS}__terms-close`);
    promo.termsBackground = document.querySelector(`.${BASE_CLASS}__terms-background`);
  }

  if (dyVars.videoCode && dyVars.isTermsPopup === 'video') {
    promo.videoPopup = document.querySelector(`.${BASE_CLASS}__video-overlay`);
    promo.videoContent = promo.videoPopup.querySelector(`.${BASE_CLASS}__video-wrp`);
  }
}

function _bindEvents() {
  promo.closeButton && promo.closeButton.addEventListener('click', promo.hide.bind(promo));

  if (dyVars.isSticky === 'true' && global.isDesktop) {
    window.addEventListener('scroll', _stickPromo);
  }

  if (dyVars.videoCode) {
    [].forEach.call(promo.wrapperNode.querySelectorAll('.jp-play-video'), el => {
      el.addEventListener('click', promo.showVideoOverlay.bind(promo));
    });
    promo.videoPopup.addEventListener('click', promo.hideVideoOverlay.bind(promo));
    promo.videoContent.addEventListener('click', promo.preventVideoClick.bind(promo));
  }

  if (dyVars.isTermsPopup === 'cmsBlockOverlay') {
    promo.wrapperNode && promo.wrapperNode.addEventListener('click', promo.openTerms.bind(promo));
    promo.termsCloseButton && promo.termsCloseButton.addEventListener('click', promo.closeTerms.bind(promo));
    promo.termsBackground && promo.termsBackground.addEventListener('click', promo.closeTerms.bind(promo));
  }
}

function _getEndDateTime(string) {
  let endDate;

  if (dyVars.isExpiring === 'true') {
    let splitCharacter = dyVars.isTimer === 'true' ? ' ' : '/';
    let dateSplitted = string.split(splitCharacter);
    let timeToEnd = 0;
    let timeDefiner;
    let timeValue;

    if (dyVars.isTimer === 'true') {
      dateSplitted.forEach(function (el) {
        timeValue = parseInt(el.slice(0, -1));
        timeDefiner = el.slice(-1);

        switch (timeDefiner) {
          case 'd':
            timeValue *= TIME_DAY;
            break;
          case 'h':
            timeValue *= TIME_HOUR;
            break;
          case 'm':
            timeValue *= TIME_MINUTE;
            break;
          case 's':
            timeValue *= TIME_SECOND;
            break;
        }

        timeToEnd += timeValue;
      });

      endDate = new Date(Date.now() + timeToEnd);
    } else {
      // If we have strictly set end date we just return it
      endDate = new Date(dateSplitted[2], dateSplitted[1] - 1, dateSplitted[0]);
    }
  } else {
    // If promotion has no end date – setting it to a year
    endDate = new Date(now.getTime() + TIME_YEAR);
  }

  return endDate;
}

function _showRemainingTime(callback) {
  if (dyVars.isExpiring === 'true') {
    let currentTime = new Date();
    let timeLeft = end - currentTime;
    let days = Math.floor(timeLeft / TIME_DAY);
    let hours = Math.floor((timeLeft % TIME_DAY) / TIME_HOUR);
    let minutes = Math.floor((timeLeft % TIME_HOUR) / TIME_MINUTE);
    if (minutes == 0) {
      minutes = 1;
    }
    let newTimerValue;

    if (timeLeft < 0) {
      clearInterval(voucherTimer);
      promo.hide();
      return;
    }
    if (dyVars.counterPlaceholder && days > 1) {
      newTimerValue = dyVars.counterPlaceholder;
    } else if (days > 0) {
      if (days == 1) {
        newTimerValue = window._i18n.promoBar.timerOneDayPlaceholder;
      } else {
        newTimerValue = funcHelper.getTransaltion(window._i18n.promoBar.timerDaysPlaceholder, days);
      }
    } else if (hours > 0) {
      if (hours == 1) {
        newTimerValue = window._i18n.promoBar.timerOneHourPlaceholder;
      } else {
        newTimerValue = funcHelper.getTransaltion(window._i18n.promoBar.timerHoursPlaceholder, hours);
      }
    } else {
      if (timeLeft == 1) {
        newTimerValue = window._i18n.promoBar.timerOneMinutePlaceholder;
      } else {
        newTimerValue = funcHelper.getTransaltion(window._i18n.promoBar.timerMinutesPlaceholder, minutes);
      }
    }
    promo.timerNode.innerHTML = newTimerValue;
  }

  callback && callback();
}

function _stickPromo(e) {
  if (window.scrollY === 0) {
    promo.wrapperNode.removeAttribute('data-promobar-fixed');
    document.body.style.paddingTop = 0;
    document.body.removeAttribute('data-promo-sticky');
    return;
  }

  if (window.scrollY >= promo.wrapperNode.offsetTop) {
    if (promo.wrapperNode.getAttribute('data-promobar-fixed') === '') return;

    let promoHeight = promo.wrapperNode.offsetHeight,
      headerWrapper = document.querySelector('.js-header-wrapper'),
      headerTopNew = promoHeight;
    if (headerWrapper) {
      headerTopNew -= headerWrapper.offsetHeight;
    }

    // window.removeEventListener('scroll', _stickPromo);

    document.body.appendChild(
      j.newNode(
        'style',
        {},
        `
    @media (min-width: 1200px) {
      .is-sticky .header__container { top: ${headerTopNew}px; }
      .is-sticky .header__container--no-nav { top: -74px; }
      .is-sticky .header.is-shown .header__container { top: ${promoHeight}px; }
    }
    `
      )
    );
    document.body.style.paddingTop = `${promoHeight}px`;
    document.body.setAttribute('data-promo-sticky', '');
    promo.wrapperNode.setAttribute('data-promobar-fixed', '');
  }
}

function _init(data) {
  window.GENERAL_PB_VARS = data;

  // Do not render pb with DY navbar
  const navbarSession = cookieHelper.getCookie('ab_test_navbar');
  if (navbarSession === 'dy') return;

  if (targetNode) {
    window.location.search
      .replace('?', '')
      .split('&')
      .forEach(el => {
        let splitted = el.split('=');
        getVars[splitted[0]] = splitted[1];
      });

    if (data) {
      dyVars = data;
      translations = PAGE_CART
        ? j.parseJsonFromData(document.querySelector('.cart').getAttribute('data-promo-strings'))
        : window._i18n.promoBar;
      end = _getEndDateTime(dyVars.endDateTime);

      dyVars.renderPromo = 'simplePromo';

      if (dyVars.videoURL) {
        dyVars.videoCode = dyVars.videoURL.split('?v=').pop();
      }

      // Set default value for "Timer Icon Color"
      dyVars.counterIconColor = dyVars.counterIconColor || 'black';

      if (!promo.isExpired() && !IS_PROHIBITED_PAGE) {
        init();
      }
    }
  }
}

PubSub.subscribe(EVENT_DY_PROMOBAR_INIT_V6_GENERAL, _init);
