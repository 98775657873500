import Promise from 'bluebird';
// TDOD: This helper is outdated. It will be replaced by Axios

/*
 Sends ajax request
 params:
 - request url
 - options:
 type (GET | POST, default: GET)
 data (object | string)
 isAsync (bool, default: true)
 json (bool, default: true)
 before (callback fn)
 success (callback fn)
 error (callback fn)
 finally (callback fn)
 */
function ajax(url, options) {
  let _noop = function () {
  };
  let type = (typeof options.type === 'undefined') ? 'GET' : options.type.toUpperCase();
  let data = (typeof options.data === 'object') ? _serializeObject(options.data) : options.data;
  if (data && type === 'GET') {
    data = encodeURIComponent(data);
  }
  let isAsync = (typeof options.isAsync === 'undefined') ? true : options.isAsync;
  let json = (typeof options.json === 'undefined') ? true : options.json;
  let beforeCallback = options.before || _noop;
  let successCallback = options.success || _noop;
  let errorCallback = options.error || _noop;
  let finallyCallback = options.finally || _noop;
  let responseData = '';
  let request;
  let contentType = (typeof options.contentType === 'undefined') ? 'application/x-www-form-urlencoded; charset=UTF-8' : options.contentType;

  request = new XMLHttpRequest();

  if (type === 'GET' && data) {
    url = url + '?' + data;
    data = null;
  }

  request.open(type, url, isAsync);

  if (type === 'POST') {
    request.setRequestHeader('X-Requested-With', 'juniqe');
    request.setRequestHeader('Content-Type', contentType);
  }

  if (options.headers) {
    for (let key in options.headers) {
      if (options.headers.hasOwnProperty(key)) {
        request.setRequestHeader(key, options.headers[key]);
      }
    }
  }

  // Prevent IE caching GET requests...
  request.setRequestHeader('If-Modified-Since', new Date().toUTCString());
  request.withCredentials = true;
  request.onreadystatechange = function () {
    if (request.readyState === 4) {
      try {
        responseData = json && request.responseText !== '' ?
            JSON.parse(request.responseText) :
            request.responseText;
      } catch (err) {
      }

      if (request.status === 200 || request.status === 201) {
        successCallback(responseData);
      } else {
        errorCallback({
          data: responseData,
          status: request.status
        });
      }

      finallyCallback();
    }
  };

  beforeCallback();
  request.send(data);
}

function _serializeObject(obj) {
  var str = '';

  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      str += prop + '=' + encodeURIComponent(obj[prop]) + '&';
    }
  }

  return str.slice(0, -1); // remove last "&"
}

// promisified ajax request
function _pAjax(type, url, data, json, headers) {
  return new Promise(function (resolve, reject) {
    var options = {
      type: type,
      data: data,
      json: json,
      success: resolve,
      error: reject,
      headers: headers
    };
    if (json) {
      options.contentType = 'application/json';
    }

    ajax(url, options);
  });
}

function get(url, data, headers = {}) {
  return _pAjax('GET', url, data, false, headers);
}

function post(url, data) {
  return _pAjax('POST', url, data, false);
}

function getJSON(url, data, headers = {}) {
  return _pAjax('GET', url, data, true, headers);
}

function postJSON(url, data) {
  return _pAjax('POST', url, data, true);
}

export default {
  ajax: ajax,
  get: get,
  post: post,
  getJSON: getJSON,
  postJSON: postJSON
};

