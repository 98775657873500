import PubSub from 'pubsub';
import { EVENT_NEW_CHECKOUT_PAGE, EVENT_OLD_CHECKOUT_PAGE, EVENT_STOP_NEW_CHECKOUT_PAGE } from 'Constants/event';
import CookieHelper from 'Helpers/cookieHelper';

const CHECKOUT_COOKIE_KEY = 'new_checkout_page';
const CHECKOUT_ENTRY = '/apps/checkout';

// cookie value login explanation
// (CHECKOUT_COOKIE_KEY === 1) => new cart
// (CHECKOUT_COOKIE_KEY === 0) => old cart
// (CHECKOUT_COOKIE_KEY is not set) => decision is not made for this user

const newCartConfig = {
  apiUrl: '/api/checkout/v1/' + document.body.getAttribute('data-jnq-attribute-storeCode') + '/cart',
  goToCartLink: `${CHECKOUT_ENTRY}`,
  goToShipmentLink: `${CHECKOUT_ENTRY}/shipment/`,
  goToAccountLink: `${CHECKOUT_ENTRY}/account/`,
  oldGoToCartLink: '/checkout/cart'
};

const isNewCart = () => {
  return Number(CookieHelper.getCookie(CHECKOUT_COOKIE_KEY)) === 1;
};

const modifyOverlayLink = () => {
  let overlayLink = isNewCart() ? newCartConfig.goToCartLink : newCartConfig.oldGoToCartLink;
  document.querySelector('.js-ajax-cart-link').setAttribute('href', overlayLink);
}

function _activateNewCart() {
  CookieHelper.setCookie(CHECKOUT_COOKIE_KEY, 1, 1000);
  modifyOverlayLink();
}

function _activateOldCart() {
  CookieHelper.setCookie(CHECKOUT_COOKIE_KEY, 0, 1000);
  modifyOverlayLink();
}

function _rollbackToOldCart() {
  CookieHelper.deleteCookie(CHECKOUT_COOKIE_KEY);
  modifyOverlayLink();
}

// in case we set this cookie value by hands
if (isNewCart()) {
  modifyOverlayLink();
}

PubSub.subscribe(EVENT_NEW_CHECKOUT_PAGE, _activateNewCart);

PubSub.subscribe(EVENT_OLD_CHECKOUT_PAGE, _activateOldCart);

PubSub.subscribe(EVENT_STOP_NEW_CHECKOUT_PAGE, _rollbackToOldCart);

export default function () {
  return isNewCart() && newCartConfig;
};
