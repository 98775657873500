import PubSub from 'pubsub';
import {EVENT_MEDIUM_POPOVER, EVENT_MEDIUM_POPOVER_HIDE, EVENT_DY_CLOSE_POPOVER} from 'Constants/event';
import domHelper from "Helpers/domHelper";
let popover;
let eventIdentifier;
let localStorageItemName;
const CONTAINER_CSS_CLASS = 'header-popover__container';

function closePopover() {
  domHelper.remove(popover);
  localStorage.setItem(localStorageItemName, true);
  // Trigger DY event
  window.DY.API('event', {
    name: EVENT_DY_CLOSE_POPOVER,
    type: eventIdentifier,
  });
}

function showPopver(options) {
  const POPOVER_ELEMENT_ID = 'js-jnq-medium-popover' + + new Date();
  eventIdentifier = options.identifier;
  localStorageItemName = `medium-popover-${eventIdentifier}`;
  let localStorageItem = localStorage.getItem(localStorageItemName);
  // Check if the popover has cenn closed already
  if(!localStorageItem) {
    domHelper.waitForConditionalElement(options.parentSelector, () => {
      let parent = document.querySelector(options.parentSelector);
      let customStyle = options.customStyle || '';
      let popoverHtmContent = options.popoverHtmContent || '';
      let parentHighlightCssClass = options.parentHighlightCssClass || '';
      let showIcon = options.showIcon == 1 || false;
      let additionalClass = options.display == 'hover' ? 'is-hidden' : '';
      let additionalStyle = options.display == 'hover'
        ? `${options.parentSelector}:hover .${CONTAINER_CSS_CLASS} {display: flex};`
        : '';
      customStyle += additionalStyle;

      // Append style to head
      customStyle && (domHelper.appendStyle(customStyle));

      // Create popover container
      let popoverHtml = `
          <div class="${CONTAINER_CSS_CLASS} jnq-medium-popover jnq-medium-popover--${options.position} ${additionalClass}" id="${POPOVER_ELEMENT_ID}">`
            + (showIcon ? `<span class="jnq-medium-popover__icon">!</span>` : '')
            + ` <div class="jnq-medium-popover__content">${popoverHtmContent}</div>
            <span class="jnq-medium-popover__close js-jnq-medium-popover__close"></span>
          </div>`;
      domHelper.appendHTML(parent, popoverHtml);
      parentHighlightCssClass && (domHelper.addClass(parent, parentHighlightCssClass));

      // Set popover style
      popover = document.querySelector(`#${POPOVER_ELEMENT_ID}`);
      let topMargin = parseInt(options.topMargin);
      let rightMargin = parseInt(options.rightMargin);
      let bottomMargin = parseInt(options.bottomMargin);
      let leftMargin = parseInt(options.leftMargin);

      !!topMargin && (popover.style.marginBottom = topMargin + 'px');
      !!rightMargin && (popover.style.marginLeft = rightMargin + 'px');
      !!bottomMargin && (popover.style.marginTop = bottomMargin + 'px');
      !!leftMargin && (popover.style.marginRight = leftMargin + 'px');

      // Attach events
      popover.addEventListener('click', (event) => {
        let target = event.target;
        if(target.matches('.js-jnq-medium-popover__close')) {
          // Hide the popover
          closePopover();
        }
      });

    }, 1, 100, 30);
  }
}

PubSub.subscribe(EVENT_MEDIUM_POPOVER_HIDE, () => {
  domHelper.addClass(popover, 'is-hidden');
});

// TODO: reset position on orientationchange
PubSub.subscribe(EVENT_MEDIUM_POPOVER, (options) => {
  document.addEventListener('DOMContentLoaded', () => {
    showPopver(options);
  });
});
