'use strict';

import WishlistElements from 'Common/wishlist/elements';
import SeeSimilarDesigns from 'Common/productsCarousel/seeSimilarDesigns';
import LabelsRedirect from 'Pages/category/labelsRedirect';

/**
 * Class representing set of static methods to work with wishlist triggers and elements on the page.
 */
export default class clickHandler {
  /**
   * Click handler for main container
   * @param {Event} e - Event object captured by handler
   * @private
   */
  static _eventListener(e) {
    WishlistElements._addToWishlistClickHandler(e);
    SeeSimilarDesigns._seeSimilarDesignsClickHandler(e);
    LabelsRedirect._productLabelsClickHandler(e);
  }

  /**
   * Initialization of capturing clicks on the page to pass them to handler for triggers
   */
  static initTriggers() {
    const $CONTAINER = document.querySelector('#main');

    if ($CONTAINER) {
      $CONTAINER.addEventListener('click', clickHandler._eventListener);
    }
  }
}