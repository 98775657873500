'use strict';

import headerFastPopover from './headerFastPopover';
import cartTemplate from './ajaxCartTemplate.mst.html';

export default headerFastPopover({
  'moduleName': 'cart',
  tplUrl: '/cart/get',
  parentId: 'ajax-cart',
  template: cartTemplate,
  containerId: 'ajax-cart-popover',
  popoverContentUrl: 'ajaxCart'
});