import FUNC from 'Helpers/funcHelper';
import { URL_HOSTNAME } from './url';

// device detection
export const DEVICE_IOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

// upselling campaign types
export const ID_UPSELLING_SIZE = 'size';
export const ID_UPSELLING_TYPE = 'productType';

// element on page alignments
export const ALIGNMENT_TOP = 'alignmentTop';
export const ALIGNMENT_MIDDLE = 'alignmentMiddle';
export const ALIGNMENT_BOTTOM = 'alignmentBottom';

// accordion constants
export const ACC_MODE_ONE_AT_TIME = 'oneAtTime';
export const ACC_MODE_MULTIPLE = 'multiple';

// time definition variables
export const TIME_SECOND = 1000;
export const TIME_MINUTE = TIME_SECOND * 60;
export const TIME_HOUR = TIME_MINUTE * 60;
export const TIME_DAY = TIME_HOUR * 24;
export const TIME_YEAR = TIME_DAY * 365;

// useful regexps
export const REGEXP_EMAIL =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// localisation specific
export const DOMAIN_PREFERENCES = {
  de: {
    currencyCode: 'EUR'
  },
  com: {
    currencyCode: 'EUR'
  },
  fr: {
    currencyCode: 'EUR'
  },
  nl: {
    currencyCode: 'EUR'
  },
  ch: {
    currencyCode: 'CHF'
  },
  uk: {
    currencyCode: 'GBP'
  },
  se: {
    currencyCode: 'SEK'
  },
  dk: {
    currencyCode: 'DKK'
  },
  es: {
    currencyCode: 'EUR'
  },
  it: {
    currencyCode: 'EUR'
  }
};

function safeReplace(value, find, replace) {
  if (value !== undefined && value != null) {
    return value.toString().replace(find, replace);
  }
  return '';
}
export const CURRENCIES_LIST = {
  EUR: {
    symbol: '€',
    displayPrice: price => {
      const parsedPrice = FUNC.parsePrice(price);
      return `${parsedPrice.fromLabel} € ${safeReplace(parsedPrice.value, '.', ',')}`.trim();
    }
  },
  CHF: {
    symbol: 'CHF',
    displayPrice: price => {
      const parsedPrice = FUNC.parsePrice(price);
      return `${parsedPrice.fromLabel} CHF ${safeReplace(parsedPrice.value, '.', ',')}`.trim();
    }
  },
  GBP: {
    symbol: '£',
    displayPrice: price => {
      const parsedPrice = FUNC.parsePrice(price);
      return `${parsedPrice.fromLabel} £${safeReplace(parsedPrice.value, ',', '.')}`.trim();
    }
  },
  SEK: {
    symbol: 'kr',
    displayPrice: price => {
      const parsedPrice = FUNC.parsePrice(price);
      return `${parsedPrice.fromLabel} ${safeReplace(parsedPrice.value, '.', ',')} kr`.trim();
    }
  },
  DKK: {
    symbol: 'kr',
    displayPrice: price => {
      const parsedPrice = FUNC.parsePrice(price);
      return `${parsedPrice.fromLabel} ${safeReplace(parsedPrice.value, '.', ',')} kr`.trim();
    }
  }
};
// Put "de" value of you want to test it accessing app through IP address
export const LOCAL_DOMAIN = URL_HOSTNAME.split('.').pop();
export const DOMAIN_CURRENCY_CODE = DOMAIN_PREFERENCES[LOCAL_DOMAIN].currencyCode;
export const LOCAL_CURRENCY = CURRENCIES_LIST[DOMAIN_PREFERENCES[LOCAL_DOMAIN].currencyCode];

// storage namespaces
export const STORAGE_NAMESPACE_MAIN = '_jnq_';
export const STORAGE_NAMESPACE_DY = `${STORAGE_NAMESPACE_MAIN}dy_`;

// storage keys
export const STORAGE_KEY_ATC = 'atcOptions';

// product type ids
export const ID_CANVAS = 53;
export const ID_ACRYLIC = 54;
export const ID_ALUMINIUM = 93;
