'use strict';

import domHelper from 'Helpers/domHelper';
import global from 'global';
import filterMobileOverlay from './filters_overlay.mst.html';

const EVENT_CLICK = 'click';
const OPENED_CLASS = 'filters-overlay--opened';

const FILTER_FETCH_URLS = {
  category: 'category-filter/',
  search: 'search-filter',
  selections: 'bundle-filter/'
};

const DATA_ID = {
  category: 'data-category-id',
  selections: 'data-category-id',
  artist: 'data-artist-id',
  search: ''
};

const ADD_EVENT_NAME = 'addEventListener';
const REMOVE_EVENT_NAME = 'removeEventListener';
const filterResultDisplayTimeout = 5000;

let controls;
let mobileRightFilter;
let filterMainOverlay;
let categoryId = '';
let applyBtn;
let rightFilterCloseBtn;
let options;
let page;
let triggerFilterBtns;
let filters;
let btnResetFilters;
let scrollTop;
let expandedSections = [];
let scrollYPosition;
let filterItemsScrollableInBarView;
let filterResultDropdown;
let filterProductTypesDropdownList;
let selectedFilters;
let appliedFilterState;

function init(opts) {
  // remove not related to current device piece of html
  if (global.isMobile) {
    const desktopContainer = document.querySelector('.desktop-filters-wrapper');
    desktopContainer && (desktopContainer.outerHTML = '');
  } else {
    const mobileContainer = document.querySelector('.mobile-filters-wrapper');
    mobileContainer && (mobileContainer.outerHTML = '');
  }
  let filter = _getFilterElement();

  options = opts || {};
  _cacheDom(filter);
  _bindEvents();
  if (document.querySelector('#filters-ajax-right')) {
    appliedFilterState = document.querySelector('#filters-ajax-right').innerHTML;
  }
}

function _getFilterElement() {
  return document.querySelector('.filters-overlay--filters');
}

function _cacheDom(filter) {
  let wrapper = document.querySelector('.category-wrapper');

  if (domHelper.hasClass(wrapper, 'artist-wrapper')) {
    page = 'artist';
  } else if (domHelper.hasClass(wrapper, 'selections-wrapper')) {
    page = 'selections';
  } else if (domHelper.hasClass(wrapper, 'search-wrapper')) {
    page = 'search';
  } else {
    page = 'category';
  }

  filterMainOverlay = document.querySelector('#filters-right-select');
  controls = document.querySelector('.filter__controls');
  mobileRightFilter = document.querySelector('#filters-ajax-right');
  applyBtn = document.querySelector('[data-apply-filters]');
  rightFilterCloseBtn = document.querySelector('#filters-right-select-close');
  filters = document.querySelectorAll('.filter__section');
  btnResetFilters = document.querySelector('.filter-footer__btn-clear');
  triggerFilterBtns = document.querySelectorAll('.filter-trigger, .js-show-filter-overlay');
  filterItemsScrollableInBarView = document.querySelector('.filter-items-scrollable--in-bar-view');
  filterResultDropdown = document.querySelector('.filter-nav-overlay--results');
  filterProductTypesDropdownList = document.querySelectorAll('.filter-nav-overlay--product-types');
  selectedFilters = document.querySelector('.selected-filters');
  if (DATA_ID[page] && document.querySelector(`[${DATA_ID[page]}]`)) {
    categoryId = document.querySelector(`[${DATA_ID[page]}]`).getAttribute(DATA_ID[page]);
  }
}

function _bindEvents() {
  // attach main filter overlay events
  if (filterMainOverlay) {
    filterMainOverlay[ADD_EVENT_NAME](EVENT_CLICK, _toggleFilter);
    [].forEach.call(triggerFilterBtns, triggerFilterBtn => {
      triggerFilterBtn.addEventListener('click', _toggleRightFiltersSection);
    });
    _toggleDynamicFilterButtonsEvents(ADD_EVENT_NAME);
  }
  let filteredItemsCount = 0;
  if (filterResultDropdown) {
    filteredItemsCount = Number(filterResultDropdown.getAttribute('data-filter-items-size'));
  }
  // don't display dropdown if there are no items inside
  if (global.isDesktop) {
    if (filteredItemsCount > 0) {
      _showFilterDropdown(filterResultDropdown);
      setTimeout(() => _hideFilterDropdown(filterResultDropdown), filterResultDisplayTimeout);
      filterResultDropdown.addEventListener('mouseenter', () => _showFilterDropdown(filterResultDropdown));
      filterResultDropdown.addEventListener('mouseleave', () => _hideFilterDropdown(filterResultDropdown));
    }
    [].forEach.call(filterProductTypesDropdownList, elem => {
      elem.addEventListener('mouseenter', () => _showFilterDropdown(elem));
      elem.addEventListener('mouseleave', () => _hideFilterDropdown(elem));
    });
  }
  if (global.isMobile) {
    [].forEach.call(filterProductTypesDropdownList, elem => {
      elem.addEventListener('click', () => {
        // remember scroll position
        scrollYPosition = window.pageYOffset;
        domHelper.addClass(document.body, 'stop-scrolling');
        // add overlay to the page
        const content = elem.querySelector('.filter-nav-overlay__dropdown').cloneNode(true);
        const overlayHeader = window._i18n.selectProductType;
        domHelper.addClass(content, 'filter-nav-overlay__dropdown--inline');
        document.body.insertAdjacentHTML(
          'beforeend',
          filterMobileOverlay({ content: content.outerHTML, header: overlayHeader })
        );
        // attach event listener to close button
        document.querySelector('#filter-mobile-overlay-close').addEventListener('click', () => {
          domHelper.removeClass(document.body, 'stop-scrolling');
          window.scrollTo(0, scrollYPosition);
          document.querySelector('.filter-mobile-overlay').outerHTML = '';
        });
      });
    });

    _showScrollDirection();
  }
}

function _showScrollDirection() {
  const scrollContent = document.querySelector('.mobile-filters-wrapper');

  if (!scrollContent) return;

  const SCROLL_DISTANCE = 200;

  const leftArrow = document.createElement('button');
  const rightArrow = document.createElement('button');

  leftArrow.classList.add('filter-items-scrollable__arrow', 'filter-items-scrollable__arrow--left');
  rightArrow.classList.add('filter-items-scrollable__arrow', 'filter-items-scrollable__arrow--right');

  const scrollParent = scrollContent.parentNode;
  scrollParent.appendChild(leftArrow);
  scrollParent.appendChild(rightArrow);

  function showButtons() {
    const isScrolledLeft = scrollContent.scrollLeft === 0;
    const isScrolledRight =
      Math.ceil(scrollContent.scrollWidth - scrollContent.scrollLeft) === scrollContent.clientWidth;

    if (scrollContent.scrollWidth <= scrollContent.offsetWidth) {
      leftArrow.style.display = 'none';
      rightArrow.style.display = 'none';
    } else {
      leftArrow.style.display = isScrolledLeft ? 'none' : 'block';
      rightArrow.style.display = isScrolledRight ? 'none' : 'block';
    }
  }

  leftArrow.addEventListener('click', function () {
    scrollContent.scrollTo({
      left: scrollContent.scrollLeft - SCROLL_DISTANCE,
      behavior: 'smooth'
    });
  });

  rightArrow.addEventListener('click', function () {
    scrollContent.scrollTo({
      left: scrollContent.scrollLeft + SCROLL_DISTANCE,
      behavior: 'smooth'
    });
  });

  scrollContent.addEventListener('scroll', showButtons);
  window.addEventListener('resize', showButtons);

  showButtons();
}

function _showFilterDropdown(element) {
  domHelper.addClass(element, 'filter-nav-overlay--opened');
  domHelper.addClass(document.querySelector('.selected-filters'), 'selected-filters--opened');
  filterItemsScrollableInBarView.style.overflow = 'visible';
}

function _hideFilterDropdown(element) {
  domHelper.removeClass(element, 'filter-nav-overlay--opened');
  domHelper.removeClass(document.querySelector('.selected-filters'), 'selected-filters--opened');
  filterItemsScrollableInBarView.style.cssText = '';
}

function _handleSeeAllClick(e) {
  e.preventDefault();
  const elem = e.target;
  const foldableElem = document.querySelector(
    `#filters-ajax-right [data-filter-foldable=${elem.getAttribute(
      'data-target'
    )}], .footer-suggested-themes [data-filter-foldable=${elem.getAttribute('data-target')}]`
  );
  const foldableItemType = foldableElem.getAttribute('data-filter-foldable');
  if (domHelper.hasClass(foldableElem, 'filter-items-scrollable--expanded')) {
    delete expandedSections[foldableItemType];
    domHelper.removeClass(foldableElem, 'filter-items-scrollable--expanded');
    domHelper.removeClass(elem, 'filter-see-all--expanded');
    elem.innerText = elem.getAttribute('data-collapsed-text');
  } else {
    expandedSections[foldableItemType] = foldableItemType;
    domHelper.addClass(foldableElem, 'filter-items-scrollable--expanded');
    domHelper.addClass(elem, 'filter-see-all--expanded');
    elem.innerText = elem.getAttribute('data-expanded-text');
  }
}

function _toggleDynamicFilterButtonsEvents(eventType) {
  if (!global.isMobile) {
    [].forEach.call(document.querySelectorAll('.filter-see-all'), seeAllLink => {
      seeAllLink[eventType]('click', _handleSeeAllClick);
    });
    [].forEach.call(
      document.querySelectorAll('#filters-ajax-right .filter-items, .footer-suggested-themes .filter-items'),
      filterItem => {
        const threshold = filterItem.closest('[data-filter-type="themes"]') ? 240 : 120;
        if (filterItem.getBoundingClientRect().height > threshold) {
          const seeAll = domHelper.closest(filterItem, '.filter__section').querySelector('.filter-see-all');
          domHelper.removeClass(seeAll, 'is-hidden');
        }
      }
    );
  }
  document.querySelector('#filters-right-select-close') &&
    document.querySelector('#filters-right-select-close')[eventType](EVENT_CLICK, _closeRightFiltersSection);
}

function _toggleRightFiltersSection() {
  // define pageYPosition
  let pageTopOffset = window.pageYOffset;
  scrollYPosition = window.pageYOffset;
  if (!global.isPhone) {
    document.body.style.top = `-${pageTopOffset}px`;
    filterMainOverlay.style.top = pageTopOffset + 'px';
    filterMainOverlay.style.height = window.innerHeight + 'px';
  }
  // move filter element to the body
  document.body.appendChild(filterMainOverlay);
  _addOverlay();
  domHelper.addClass(filterMainOverlay, OPENED_CLASS);
  domHelper.addClass(document.body, 'stop-scrolling');
}

function _restorePreviouslyAppliedFilterState() {
  document.querySelector('#filters-ajax-right').innerHTML = appliedFilterState;
  _toggleDynamicFilterButtonsEvents(REMOVE_EVENT_NAME);
  _toggleDynamicFilterButtonsEvents(ADD_EVENT_NAME);
}

function _closeRightFiltersSection() {
  domHelper.removeClass(filterMainOverlay, OPENED_CLASS);
  if (appliedFilterState) _restorePreviouslyAppliedFilterState();
  document.body.removeChild(filterMainOverlay);
  domHelper.removeClass(document.body, 'stop-scrolling');
  if (!global.isPhone) {
    document.body.style.top = '';
  }
  window.scrollTo(0, scrollYPosition);
  _removeOverlay();
}

function _addOverlay() {
  let overlayElement = domHelper.newNode('div', { class: 'filters-overlay__background' });
  document.body.appendChild(overlayElement);
  overlayElement.addEventListener('click', () => {
    _closeRightFiltersSection();
  });
}

function _removeOverlay() {
  let overlay = document.querySelector('.filters-overlay__background');
  if (overlay) {
    overlay.outerHTML = '';
  }
}

function _toggleFilterHandler(formControlElement) {
  let queryParams = formControlElement.getAttribute('data-filter__query');
  let urlParams = queryParams ? '?' + queryParams : '';

  let filterUrl = FILTER_FETCH_URLS[page];
  // on artist page filters are applied immediately (without "apply filters" button)
  if (!filterUrl) {
    document.location.href = urlParams;
    return;
  }

  let urlPath = options.fetchURL || `/${filterUrl}` + categoryId;
  let requestUrl = urlPath + urlParams;
  domHelper.ajax(requestUrl, {
    type: 'GET',
    json: false,
    success: function (html) {
      // remove listeners from old controls
      _toggleDynamicFilterButtonsEvents(REMOVE_EVENT_NAME);
      _replaceControlsAndRebindEvents(html, mobileRightFilter);
      // add new listeners
      _toggleDynamicFilterButtonsEvents(ADD_EVENT_NAME);
      _emphasizeSelectedFilters();
    }
  });
}

function _emphasizeSelectedFilters() {
  if (global.isMobile) {
    [].forEach.call(document.querySelectorAll('#filters-ajax-right .filter-item--active'), elem => {
      // scroll parent horizontally to display selected filter
      // might be removed once we have active item in a first position in a list
      var parent = domHelper.closest(elem, '.filter-items-scrollable'),
        parentComputedStyle = window.getComputedStyle(parent, null),
        parentBorderLeftWidth = parseInt(parentComputedStyle.getPropertyValue('border-left-width')),
        overLeft = elem.offsetLeft - parent.offsetLeft < parent.scrollLeft,
        overRight =
          elem.offsetLeft - parent.offsetLeft + elem.clientWidth - parentBorderLeftWidth >
          parent.scrollLeft + parent.clientWidth;

      if (overLeft || overRight) {
        parent.scrollLeft =
          elem.offsetLeft - parent.offsetLeft - parent.clientWidth / 2 - parentBorderLeftWidth + elem.clientWidth / 2;
      }
    });
  } else {
    for (let key in expandedSections) {
      if (expandedSections.hasOwnProperty(key)) {
        const section = document.querySelector(`#filters-ajax-right [data-filter-type=${expandedSections[key]}]`);
        // always check if section exists because it might be hidden by other applied filter results
        if (section) {
          const seeAllBtn = section.querySelector('[data-target]');
          if (seeAllBtn && !domHelper.hasClass(seeAllBtn, 'is-hidden')) {
            seeAllBtn.click();
          }
        }
      }
    }
  }
  // apply scrolling position after expanding corresponding sections
  document.querySelector('.filter__controls').scrollTop = scrollTop;
}

function _replaceControlsAndRebindEvents(html, targetContainer) {
  // remember scroll position
  let filterControls = document.querySelector('.filter__controls');
  scrollTop = filterControls ? filterControls.scrollTop : 0;
  targetContainer.outerHTML = html;
  _cacheDom(_getFilterElement());
}

function _toggleFilter(e) {
  let closestCommonFilterElement = e.target.hasAttribute('data-filter-item')
    ? e.target
    : domHelper.closest(e.target, '[data-filter-item]');

  if (closestCommonFilterElement) {
    let formControlElement = closestCommonFilterElement.querySelector('[data-filter__url]');
    if (formControlElement) {
      _toggleFilterHandler(formControlElement);
    }
  }
}

export default {
  init: init
};
