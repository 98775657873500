import domHelper from 'Helpers/domHelper';


var activeTabNavClass = 'is-active',
    activeTabClass = 'is-visible';

function Tabs(options) {
  var tabs = (typeof options.el === 'string') ? document.querySelector(options.el) : options.el;
  this.tabHead = tabs.querySelector('[data-tabs__head]');
  this.tabNavItems = domHelper.toArray(tabs.querySelectorAll('[data-tabs__link]'));
  this.tabItems = tabs.querySelectorAll('[data-tabs__item]');
  this.onInit = options.onInit;
  this.onChange = options.onChange;

  this.currentTabIndex = this.tabNavItems.indexOf(domHelper.findOne(this.tabNavItems, function (tab) {
    return domHelper.hasClass(tab, activeTabNavClass);
  }));

  this.init();
  if (this.onInit) {
    this.onInit();
  }
}

Tabs.prototype._changeTab = function (el) {
  //change prev/current state
  this.prevTabIndex = this.currentTabIndex;
  this.currentTabIndex = el.getAttribute('data-tabs__link');

  domHelper.removeClass(this.tabNavItems[this.prevTabIndex], activeTabNavClass);
  domHelper.removeClass(this.tabItems[this.prevTabIndex], activeTabClass);
  domHelper.addClass(this.tabNavItems[this.currentTabIndex], activeTabNavClass);
  domHelper.addClass(this.tabItems[this.currentTabIndex], activeTabClass);
  if (this.tabItems[this.currentTabIndex].querySelector('[data-echo]')) {
    var topY = window.scrollY;
    window.scrollTo(0, (++topY));
  }

  if (this.onChange) {
    this.onChange();
  }
};

Tabs.prototype._tabClick = function (e) {
  var el = e.target;
  e.preventDefault();

  if (!el.hasAttribute('data-tabs__link')) {
    el = domHelper.closest(el, '[data-tabs__link]');
  }

  if (el && !domHelper.hasClass(el, activeTabNavClass)) {
    this._changeTab(el);
  }
};

Tabs.prototype.init = function () {
  this.tabHead.addEventListener('click', this._tabClick.bind(this));

};

export default Tabs;

