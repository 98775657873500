/* eslint-disable eqeqeq */
/* eslint-disable no-unused-expressions */
import global from 'global';
import j from 'Helpers/domHelper';
import cookieHelper from 'Helpers/cookieHelper';
import funcHelper from 'Helpers/funcHelper';
import httpHelper from 'Helpers/httpHelper';
import PubSub from 'pubsub';
import { PAGE_CATALOG } from 'Constants/page';
import { EVENT_PROMOBAR_RENDER, EVENT_DY_PROMOBAR_INIT_V6_CATALOG_EMAIL } from 'Constants/event';
import { REGEXP_EMAIL, TIME_SECOND, TIME_MINUTE, TIME_HOUR, TIME_DAY, TIME_YEAR } from 'Constants/misc';
import { URL_HOSTNAME } from 'Constants/url';
import overlayTemplate from './promoBarCatalogEmail.mst.html';

const BASE_CLASS_CATEGORY_EMAIL = 'agrsv-promo-category-email';
const BASE_CLASS = 'agrsv-promo';
const STATES = {
  SIMPLE: `${BASE_CLASS}--simple`,
  INITIAL: `${BASE_CLASS}--init`,
  INITIAL_MINI: `${BASE_CLASS}--init-mini`,
  SUBMITTED: `${BASE_CLASS}--submitted`,
  FINAL: `${BASE_CLASS}--final`,
  HIDDEN: `is-hidden`
};
const COOKIE_PREFIX = 'jnq_pb_';
const ALLOWED_PAGE = PAGE_CATALOG;

// Variables from DY
let dyVars;
let translations;
const getVars = [];

let targetNode = document.querySelector('.products-grid--category');
const promo = {
  wrapperNode: undefined,
  emailFormNode: undefined,
  emailInputNode: undefined,
  emailSubmitButtonNode: undefined,
  codeCopiedNode: undefined,
  timerNode: undefined,
  closeButton: undefined,
  idInputNode: undefined,
  termsPopup: undefined,
  termsCloseButton: undefined,
  termsBackground: undefined,
  videoPopup: undefined,
  videoContent: undefined,
  overlayTriggers: undefined,

  inject() {
    const tempNode = document.createElement('div');
    const compiledOptions = funcHelper.mixin(
      dyVars,
      translations,
      dyVars[dyVars.renderPromo][global.isDesktop ? 'desktop' : 'mobile']
    );
    let contentText;

    compiledOptions.overlayContent = '';
    compiledOptions.backgroundColor = compiledOptions.backgroundColor || '#EEE';
    contentText = overlayTemplate(compiledOptions);

    if (getVars.source === 'raf') {
      contentText = contentText.replace('{referee_email}', getVars.referee_email);
      contentText = contentText.replace('{referee_voucher}', getVars.referee_voucher);
      contentText = contentText.replace('{referer_name}', getVars.referer_name);
    }

    tempNode.innerHTML = contentText;

    const refChild = targetNode.querySelector(`#insert-content-${compiledOptions.catalog.position}`);
    targetNode.insertBefore(tempNode.firstChild, refChild);

    if (!global.isMobile) {
      document.body.appendChild(j.newNode('style', {}, '.agrsv-promo { z-index: 6; }'));
    }

    j.addClass(document.body, 'promobar-shown');

    const wrapperNode = targetNode.querySelector(`.${BASE_CLASS_CATEGORY_EMAIL}`);
    wrapperNode.setAttribute('id', 'category-email');

    PubSub.publish(EVENT_PROMOBAR_RENDER);
  },
  show() {
    j.removeClass(this.wrapperNode, 'is-hidden');
    j.addClass(document.body, 'promobar-shown');
  },
  hide() {
    this.state('HIDDEN');
    j.removeClass(document.body, 'promobar-shown');
  },
  minimize(e) {
    e.preventDefault();
    e.stopPropagation();

    this.state('INITIAL_MINI');
  },
  maximize(e) {
    e.preventDefault();
    e.stopPropagation();

    this.state('INITIAL');
  },
  submitEmail(e) {
    e.preventDefault();
    e.stopPropagation();

    const email = this.emailInputNode.value;
    const source = 'promo_block';

    const emarsysData = funcHelper.mixin(
      dyVars,
      translations,
      dyVars[dyVars.renderPromo][global.isDesktop ? 'desktop' : 'mobile']
    );

    const { artist, campaignVoucher, campaign, productVertical, productType, theme } = emarsysData;
    const campaignData = {
      artist,
      campaign,
      productVertical,
      productType,
      theme,
      campaignVoucher
    };

    if (REGEXP_EMAIL.test(email)) {
      httpHelper.ajax('/newsletter/subscribe', {
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify({
          email,
          source,
          campaignData
        }),
        success() {
          window.DY.API('event', { name: 'Subscription_bar', properties: { email } });
          promo.state('SUBMITTED');
          setTimeout(promo.state.bind(promo, 'FINAL'), 3000);
        }
      });
    } else {
      this.showEmailError();
    }
  },
  hideEmailError(e) {
    e.stopPropagation();

    j.removeClass(this.emailFormNode, 'agrsv-promo__subscribe-form--invalid');
  },
  showEmailError() {
    j.addClass(this.emailFormNode, 'agrsv-promo__subscribe-form--invalid');
  },
  cookieState(state) {
    if (state) {
      // Setter
      if (STATES[state]) {
        cookieHelper.setCookie(`${COOKIE_PREFIX}${dyVars.code}`, state, end, URL_HOSTNAME);
      } else {
        // eslint-disable-next-line no-console
        console.warn(`State '${state}' is not found. Please, check the code`);
      }
    } else {
      // Getter
      if (cookieHelper.getCookie(`${COOKIE_PREFIX}newsletter`)) {
        return 'HIDDEN';
      }

      const cookieState = cookieHelper.getCookie(`${COOKIE_PREFIX}${dyVars.code}`);

      // Prevent minimize state if other promobar set it
      return cookieState === 'INITIAL_MINI' ? 'INITIAL' : cookieState;
    }
  },
  state(state) {
    if (state) {
      // Setter
      if (STATES[state]) {
        this.wrapperNode.className = `${BASE_CLASS} ${BASE_CLASS_CATEGORY_EMAIL} ${STATES[state]}`;
        this.wrapperNode.setAttribute('data-promo-state', state);

        this.cookieState(state);
      }
      if (!STATES[state]) {
        // eslint-disable-next-line no-console
        console.warn(`State '${state}' is not found. Please, check the code`);
      }
    } else {
      // Getter
      return this.wrapperNode.getAttribute('data-promo-state') || dyVars.initialState;
    }
  },
  isUsed() {
    return Boolean(cookieHelper.getCookie(`${COOKIE_PREFIX}${dyVars.code}_is_used`));
  },
  isExpired() {
    return dyVars.isExpiring === 'true' && now >= end;
  },
  isSubscribed() {
    return cookieHelper.getCookie('email_subscribed_status') === 'subscribed';
  },
  preventVideoClick(e) {
    e.stopPropagation();
  },
  showVideoOverlay(e) {
    if (
      !j.hasClass(e.currentTarget, 'agrsv-promo__mini-cta') ||
      (j.hasClass(e.currentTarget, 'agrsv-promo__mini-cta') &&
        !cookieHelper.getCookie(`${COOKIE_PREFIX}_videoAutoOpened_${dyVars.code}`))
    ) {
      j.removeClass(promo.maximizeButtonNode, 'jp-play-video');
      cookieHelper.setCookie(`${COOKIE_PREFIX}_videoAutoOpened_${dyVars.code}`, 'true', end, URL_HOSTNAME);
      j.removeClass(this.videoPopup, 'is-hidden');
    }
  },
  hideVideoOverlay() {
    j.addClass(this.videoPopup, 'is-hidden');
  },
  openTerms(e) {
    e.preventDefault();
    e.stopPropagation();
    j.removeClass(this.termsPopup, 'is-hidden');
  },
  closeTerms(e) {
    if (!e.target.getAttribute('href')) {
      e.preventDefault();
      e.stopPropagation();
      j.addClass(this.termsPopup, 'is-hidden');
    }
  }
};
let now = new Date();
let end;
let voucherTimer;

function init() {
  try {
    let state = promo.cookieState();

    promo.inject();

    _cacheDom();

    // Prevents handlers dublication if init called twice.
    _unbindEvents();
    _bindEvents();

    if (dyVars.isClosable === 'true' && promo.closeButton) {
      promo.closeButton.style.display = 'block';
    }

    if (dyVars.isExpiring !== 'true') {
      promo.wrapperNode.setAttribute('data-not-expiring', true);
    }

    if (!state) {
      state = dyVars.initialState;
    }

    promo.state(state);

    if (dyVars.isExpiring === 'true' && !promo.isExpired()) {
      _showRemainingTime(() => {});
      voucherTimer = setInterval(_showRemainingTime, TIME_SECOND);
    }

    window.DY.API('event', { name: 'PromoServe', properties: { Voucher_code: dyVars.code } });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('e: ', e);
  }
}

function _cacheDom() {
  promo.wrapperNode = document.getElementById('category-email');
  promo.codeCopiedNode = promo.wrapperNode.querySelector(`.${BASE_CLASS}__copy-code`);
  promo.timerNode = promo.wrapperNode.querySelector(`.${BASE_CLASS}__timer-time`);
  promo.closeButton = promo.wrapperNode.querySelector(`.${BASE_CLASS}__close-promo-btn`);

  promo.emailFormNode = promo.wrapperNode.querySelector(`.${BASE_CLASS}__subscribe-form`);
  promo.emailInputNode = promo.wrapperNode.querySelector(`.${BASE_CLASS}__subscribe-field`);
  promo.idInputNode = promo.wrapperNode.querySelector(`.${BASE_CLASS}__subscribe-list`);
  promo.emailSubmitButtonNode = promo.wrapperNode.querySelector(`.${BASE_CLASS}__subscribe-btn`);
  promo.overlayTriggers = promo.wrapperNode.querySelectorAll(`.${BASE_CLASS}__overlay-trigger`);

  if (dyVars.isTermsPopup === 'cmsBlockOverlay') {
    promo.termsPopup = document.querySelector(`.${BASE_CLASS}__terms-popup`);
    promo.termsCloseButton = document.querySelector(`.${BASE_CLASS}__terms-close`);
    promo.termsBackground = document.querySelector(`.${BASE_CLASS}__terms-background`);
  } else if (dyVars.videoCode && dyVars.isTermsPopup === 'video') {
    promo.videoPopup = document.querySelector(`.${BASE_CLASS}__video-overlay`);
    promo.videoContent = promo.videoPopup.querySelector(`.${BASE_CLASS}__video-wrp`);
  }
}

const hideHandler = promo.hide.bind(promo);
const submitHandler = promo.submitEmail.bind(promo);
const hideEmailErrorHandler = promo.hideEmailError.bind(promo);
const closeTermsHandler = promo.closeTerms.bind(promo);
const openTermsHandler = promo.openTerms.bind(promo);

function _bindEvents() {
  promo.closeButton && promo.closeButton.addEventListener('click', hideHandler);

  promo.emailSubmitButtonNode.addEventListener('click', submitHandler);
  promo.emailInputNode.addEventListener('focus', hideEmailErrorHandler);

  if (dyVars.videoCode) {
    [].forEach.call(promo.wrapperNode.querySelectorAll('.jp-play-video'), el => {
      el.addEventListener('click', promo.showVideoOverlay.bind(promo));
    });
    promo.videoPopup.addEventListener('click', promo.hideVideoOverlay.bind(promo));
    promo.videoContent.addEventListener('click', promo.preventVideoClick.bind(promo));
  }

  if (dyVars.isTermsPopup === 'cmsBlockOverlay' && promo.overlayTriggers.length) {
    [].forEach.call(promo.overlayTriggers, function (overlayTrigger) {
      overlayTrigger.addEventListener('click', openTermsHandler);
    });
    promo.termsCloseButton && promo.termsCloseButton.addEventListener('click', closeTermsHandler);
    promo.termsBackground && promo.termsBackground.addEventListener('click', closeTermsHandler);
  }
}

function _unbindEvents() {
  promo.closeButton.removeEventListener('click', hideHandler);
  promo.emailSubmitButtonNode.removeEventListener('click', submitHandler);
  promo.emailInputNode.removeEventListener('focus', hideEmailErrorHandler);

  promo.overlayTriggers.length &&
    [].forEach.call(promo.overlayTriggers, function (overlayTrigger) {
      overlayTrigger.removeEventListener('click', openTermsHandler);
    });
  promo.termsCloseButton && promo.termsCloseButton.removeEventListener('click', closeTermsHandler);
  promo.termsBackground && promo.termsBackground.removeEventListener('click', closeTermsHandler);
}

function _getEndDateTime(string) {
  let endDate;

  if (dyVars.isExpiring === 'true') {
    const splitCharacter = dyVars.isTimer === 'true' ? ' ' : '/';
    const dateSplitted = string.split(splitCharacter);
    let timeToEnd = 0;
    let timeDefiner;
    let timeValue;

    if (dyVars.isTimer === 'true') {
      dateSplitted.forEach(function (el) {
        timeValue = parseInt(el.slice(0, -1), 10);
        timeDefiner = el.slice(-1);

        switch (timeDefiner) {
          case 'd':
            timeValue *= TIME_DAY;
            break;
          case 'h':
            timeValue *= TIME_HOUR;
            break;
          case 'm':
            timeValue *= TIME_MINUTE;
            break;
          case 's':
            timeValue *= TIME_SECOND;
            break;
          default:
            throw new Error('Invalid time format: ', timeDefiner);
        }

        timeToEnd += timeValue;
      });

      endDate = new Date(Date.now() + timeToEnd);
    } else {
      // If we have strictly set end date we just return it
      endDate = new Date(dateSplitted[2], dateSplitted[1] - 1, dateSplitted[0]);
    }
  } else {
    // If promotion has no end date – setting it to a year
    endDate = new Date(now.getTime() + TIME_YEAR);
  }

  return endDate;
}

function _showRemainingTime(callback) {
  if (dyVars.isExpiring === 'true') {
    const currentTime = new Date();
    const timeLeft = end - currentTime;
    const days = Math.floor(timeLeft / TIME_DAY);
    const hours = Math.floor((timeLeft % TIME_DAY) / TIME_HOUR);
    let minutes = Math.floor((timeLeft % TIME_HOUR) / TIME_MINUTE);
    if (minutes == 0) {
      minutes = 1;
    }
    let newTimerValue;

    if (timeLeft < 0) {
      clearInterval(voucherTimer);
      promo.hide();
      return;
    }
    if (dyVars.counterPlaceholder && days > 1) {
      newTimerValue = dyVars.counterPlaceholder;
    } else if (days > 0) {
      if (days == 1) {
        newTimerValue = window._i18n.promoBar.timerOneDayPlaceholder;
      } else {
        newTimerValue = getTransaltion(window._i18n.promoBar.timerDaysPlaceholder, days);
      }
    } else if (hours > 0) {
      if (hours == 1) {
        newTimerValue = window._i18n.promoBar.timerOneHourPlaceholder;
      } else {
        newTimerValue = getTransaltion(window._i18n.promoBar.timerHoursPlaceholder, hours);
      }
    } else if (timeLeft == 1) {
      newTimerValue = window._i18n.promoBar.timerOneMinutePlaceholder;
    } else {
      newTimerValue = getTransaltion(window._i18n.promoBar.timerMinutesPlaceholder, minutes);
    }
    promo.timerNode.innerHTML = newTimerValue;
  }

  callback && callback();
}

function getTransaltion(translationText, ...args) {
  if (!translationText) return '';
  // find all scala "way" parameters and replace them with provided params
  return translationText.replace(/{\d}/g, function (match) {
    // cut off brackets '{' and '}'
    const paramNumber = Number(match.replace(/[{}'']/g, ''));
    return args[paramNumber] || '';
  });
}

function _init(data) {
  const { cms } = data;

  let cmsNode;
  if (cms && cms.id !== '') {
    cmsNode = document.querySelector(`#dy-cms-${cms.id}`);
  }
  if (targetNode || cmsNode) {
    window.location.search
      .replace('?', '')
      .split('&')
      .forEach(el => {
        const splitted = el.split('=');
        // eslint-disable-next-line prefer-destructuring
        getVars[splitted[0]] = splitted[1];
      });

    if (data) {
      dyVars = data;
      translations = window._i18n.promoBar;
      end = _getEndDateTime(dyVars.endDateTime);

      dyVars.renderPromo = 'newsletterPromo';
      dyVars.initialState = 'INITIAL';

      if (dyVars.videoURL) {
        dyVars.videoCode = dyVars.videoURL.split('?v=').pop();
      }

      // Set default value for "Timer Icon Color"
      dyVars.counterIconColor = dyVars.counterIconColor || 'black';

      if (!promo.isExpired() && !promo.isUsed() && !promo.isSubscribed() && (ALLOWED_PAGE || cmsNode)) {
        if (cmsNode) {
          targetNode = cmsNode;
        }
        init();
        promo.show();
      }
    }
  }
}

PubSub.subscribe(EVENT_DY_PROMOBAR_INIT_V6_CATALOG_EMAIL, _init);
