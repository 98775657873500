'use strict';

import global from 'global';
import j from 'Helpers/domHelper';
import cookieHelper from 'Helpers/cookieHelper';
import funcHelper from 'Helpers/funcHelper';
import httpHelper from 'Helpers/httpHelper';
import overlayTemplate from './promoBarNps.mst.html';
import PubSub from 'pubsub';
import {
  PAGE_FAQ,
  PAGE_CART,
  PAGE_CHECKOUT,
  PAGE_MYACCOUNT,
  PAGE_REFER_FRIEND,
  PAGE_RETURNS,
  PAGE_NEWSLETTER_SUCCESS,
  PAGE_NOT_FOUND
} from 'Constants/page';
import {
  EVENT_PROMOBAR_RENDER,
  EVENT_DY_PROMOBAR_INIT_V6_NPS
} from 'Constants/event';
import {
  TIME_SECOND,
  TIME_MINUTE,
  TIME_HOUR,
  TIME_DAY,
  TIME_YEAR
} from 'Constants/misc';
import {
  URL_HOSTNAME,
  URL_NPS_FEEDBACK
} from 'Constants/url';

const BASE_CLASS = 'agrsv-promo';
const STATES = {
  SIMPLE: `${BASE_CLASS}--simple`,
  INITIAL: `${BASE_CLASS}--init`,
  INITIAL_MINI: `${BASE_CLASS}--init-mini`,
  SUBMITTED: `${BASE_CLASS}--submitted`,
  FINAL: `${BASE_CLASS}--final`,
  HIDDEN: `is-hidden`,
  NPS_INITIAL: `${BASE_CLASS}--nps-init`,
  NPS_RESPONSE: `${BASE_CLASS}--nps-response`,
  NPS_FINAL: `${BASE_CLASS}--nps-final`
};
const COOKIE_PREFIX = 'jnq_pb_';
const IS_PROHIBITED_PAGE = PAGE_CHECKOUT || PAGE_FAQ || PAGE_MYACCOUNT || PAGE_REFER_FRIEND || PAGE_RETURNS || PAGE_NEWSLETTER_SUCCESS || (PAGE_NOT_FOUND && global.isMobile);

// Variables from DY
let dyVars;
let translations;
let getVars = [];
let targetNode = document.querySelector('.header');
let promo = {
  wrapperNode: undefined,
  maximizeButtonNode: undefined,
  codeCopiedNode: undefined,
  timerNode: undefined,
  closeButton: undefined,
  termsPopup: undefined,
  termsCloseButton: undefined,
  termsBackground: undefined,
  videoPopup: undefined,
  videoContent: undefined,

  inject: function () {
    let tempNode = document.createElement('div');
    let compiledOptions = funcHelper.mixin(dyVars, translations, dyVars[dyVars.renderPromo][global.isDesktop ? 'desktop' : 'mobile']);
    let contentText;

    if (cookieHelper.getCookie(`${COOKIE_PREFIX}${dyVars.code}_collapsed`)) {
      compiledOptions.initialState = 'INITIAL_MINI';
    }
    else {
      compiledOptions.initialState = (promo.cookieState() === 'HIDDEN' || promo.cookieState() === 'NPS_FINAL') ? 'HIDDEN' : 'NPS_INITIAL';
    }

    contentText = overlayTemplate(compiledOptions);

    if (getVars['source'] === 'raf') {
      contentText = contentText.replace('{referee_email}', getVars['referee_email']);
      contentText = contentText.replace('{referee_voucher}', getVars['referee_voucher']);
      contentText = contentText.replace('{referer_name}', getVars['referer_name']);
    }

    tempNode.innerHTML = contentText;

    targetNode.parentNode.insertBefore(tempNode.firstChild, targetNode);

    if (!global.isMobile) {
      document.body.appendChild(j.newNode('style', {}, '.agrsv-promo { z-index: 6; }'));
    }

    j.addClass(document.body, 'promobar-shown');
    PubSub.publish(EVENT_PROMOBAR_RENDER);
  },
  show: function () {
    j.removeClass(this.wrapperNode, 'is-hidden');
    j.addClass(document.body, 'promobar-shown');
  },
  hide: function () {
    this.state('HIDDEN');
    j.removeClass(document.body, 'promobar-shown');
  },
  cookieState: function (state) {
    if (state) {
      // Setter
      if (STATES[state]) {
        cookieHelper.setCookie(`${COOKIE_PREFIX}${dyVars.code}`, state, end, URL_HOSTNAME);
      }
      else {
        console.warn(`State '${state}' is not found. Please, check the code`);
      }
    }
    else {
      // Getter
      return cookieHelper.getCookie(`${COOKIE_PREFIX}${dyVars.code}`);
    }
  },
  state: function (state) {
    if (state) {
      // Setter
      if (STATES[state]) {
        this.wrapperNode.className = `${BASE_CLASS} ${STATES[state]}`;
        this.wrapperNode.setAttribute('data-promo-state', state);

        if ((PAGE_CART && state === 'HIDDEN') || !PAGE_CART) {
          this.cookieState(state);
        }
      }
      if (!STATES[state]) {
        console.warn(`State '${state}' is not found. Please, check the code`);
      }
    }
    else {
      // Getter
      return this.wrapperNode.getAttribute('data-promo-state') || dyVars.initialState;
    }
  },
  isUsed: function () {
    return Boolean(cookieHelper.getCookie(`${COOKIE_PREFIX}${dyVars.code}_is_used`));
  },
  isExpired: function () {
    return (dyVars.isExpiring === 'true') && (now >= end);
  },
  preventVideoClick: function (e) {
    e.stopPropagation();
  },
  showVideoOverlay: function (e) {
    if (!j.hasClass(e.currentTarget, 'agrsv-promo__mini-cta') || (j.hasClass(e.currentTarget, 'agrsv-promo__mini-cta') && !cookieHelper.getCookie(`${COOKIE_PREFIX}_videoAutoOpened_${dyVars.code}`))) {
      j.removeClass(promo.maximizeButtonNode, 'jp-play-video');
      cookieHelper.setCookie(`${COOKIE_PREFIX}_videoAutoOpened_${dyVars.code}`, 'true', end, URL_HOSTNAME);
      j.removeClass(this.videoPopup, 'is-hidden');
    }
  },
  hideVideoOverlay: function () {
    let iframe = promo.videoContent.querySelector('iframe');
    iframe.src = iframe.src;
    j.addClass(this.videoPopup, 'is-hidden');
  }
};
let now = new Date();
let end;
let voucherTimer;

function init() {
  try {
    let state = promo.cookieState();

    promo.inject(); // NOT NEEDED WHEN MOVED TO DY

    _cacheDom();
    _bindEvents();

    if (dyVars.isClosable === 'true' && promo.closeButton) {
      promo.closeButton.style.display = 'block';
    }

    if (dyVars.isExpiring !== 'true') {
      promo.wrapperNode.setAttribute('data-not-expiring', true);
    }

    if (state === 'NPS_FINAL' || state === 'HIDDEN') {
        state = 'HIDDEN';
    } else {
        state = 'NPS_INITIAL';
    }

    promo.state(state);

  } catch(e) {
    console.log('e: ', e);
  }
}

function _cacheDom() {
  promo.wrapperNode = document.querySelector(`.${BASE_CLASS}`);
  promo.codeCopiedNode = promo.wrapperNode.querySelector(`.${BASE_CLASS}__copy-code`);
  promo.timerNode = promo.wrapperNode.querySelector(`.${BASE_CLASS}__timer-time`);
  promo.closeButton = promo.wrapperNode.querySelector(`.${BASE_CLASS}__close-promo-btn`);

  promo.npsNumbersButtons = j.toArray(promo.wrapperNode.querySelectorAll('.agrsv-promo__nps-grade-radio'));
  promo.npsMessageSubmitButtons = j.toArray(promo.wrapperNode.querySelectorAll('.agrsv-promo__nps-comment-button'));

  if (dyVars.videoCode && dyVars.isTermsPopup === 'video') {
    promo.videoPopup = document.querySelector(`.${BASE_CLASS}__video-overlay`);
    promo.videoContent = promo.videoPopup.querySelector(`.${BASE_CLASS}__video-wrp`);
  }
}

function _bindEvents() {
  promo.closeButton && promo.closeButton.addEventListener('click', promo.hide.bind(promo));

  if (dyVars.isSticky === 'true' && dyVars.initialState === 'INITIAL_MINI' && global.isDesktop) {
    window.addEventListener('scroll', _stickPromo);
  }

  promo.npsNumbersButtons.forEach((el) => {
    el.addEventListener('click', _npsNumberClick);
  });
  promo.npsMessageSubmitButtons.forEach((el) => {
    el.addEventListener('click', _npsSubmitMessage);
  });

  if (dyVars.videoCode) {
    [].forEach.call(promo.wrapperNode.querySelectorAll('.jp-play-video'), (el) => {
      el.addEventListener('click', promo.showVideoOverlay.bind(promo))
    });
    promo.videoPopup && promo.videoPopup.addEventListener('click', promo.hideVideoOverlay.bind(promo));
    promo.videoContent && promo.videoContent.addEventListener('click', promo.preventVideoClick.bind(promo));
  }
}

function _npsNumberClick(e) {
  promo.state('NPS_RESPONSE');
}

function _npsSubmitMessage(e) {
  let message = j.hasClass(e.target, 'agrsv-promo__nps-comment-skip') ? '' : promo.wrapperNode.querySelector('.agrsv-promo__nps-comment').value;
  let grade = promo.wrapperNode.querySelector('.agrsv-promo__nps-grade-radio:checked').value;

  httpHelper.ajax(URL_NPS_FEEDBACK, {
    type: 'POST',
    data: {
      recommend: grade,
      message: message
    },
    success: () => {
      promo.state('NPS_FINAL');

      setTimeout(() => promo.state('HIDDEN'), 5000);
    }
  });
}

function _getEndDateTime(string) {
  let endDate;

  if (dyVars.isExpiring === 'true') {
    let splitCharacter = (dyVars.isTimer === 'true') ? ' ' : '/';
    let dateSplitted = string.split(splitCharacter);
    let timeToEnd = 0;
    let timeDefiner;
    let timeValue;

    if (dyVars.isTimer === 'true') {
      dateSplitted.forEach(function (el) {
        timeValue = parseInt(el.slice(0, -1));
        timeDefiner = el.slice(-1);

        switch (timeDefiner) {
          case 'd':
            timeValue *= TIME_DAY;
            break;
          case 'h':
            timeValue *= TIME_HOUR;
            break;
          case 'm':
            timeValue *= TIME_MINUTE;
            break;
          case 's':
            timeValue *= TIME_SECOND;
            break;
        }

        timeToEnd += timeValue;
      });

      endDate = new Date(Date.now() + timeToEnd);
    }
    else {
      // If we have strictly set end date we just return it
      endDate = new Date(dateSplitted[2], dateSplitted[1] - 1, dateSplitted[0]);
    }
  }
  else {
    // If promotion has no end date – setting it to a year
    endDate = new Date(now.getTime() + TIME_YEAR);
  }

  return endDate;
}

function getTransaltion(translationText, ...args) {
  if (!translationText) return '';
  // find all scala "way" parameters and replace them with provided params
  return translationText.replace(/{\d}/g, function(match) {
    // cut off brackets '{' and '}'
    const paramNumber = Number(match.replace(/[{}'']/g, ''));
    return args[paramNumber] || '';
  });
}

function _stickPromo(e) {
  if (window.scrollY >= promo.wrapperNode.offsetTop) {
    let promoHeight = promo.wrapperNode.offsetHeight,
        headerWrapper = document.querySelector('.js-header-wrapper'),
        headerTopNew = promoHeight;
    if (headerWrapper) {
      headerTopNew -= headerWrapper.offsetHeight;
    }

    window.removeEventListener('scroll', _stickPromo);

    document.body.appendChild(j.newNode('style', {}, `
    @media (min-width: 1200px) {
      .is-sticky .header__container { top: ${headerTopNew}px; }
      .is-sticky .header__container--no-nav { top: -74px; }
      .is-sticky .header.is-shown .header__container { top: ${promoHeight}px; }
    }
    `));
    document.body.style.paddingTop = `${promoHeight}px`;
    document.body.setAttribute('data-promo-sticky', '');
    promo.wrapperNode.setAttribute('data-promobar-fixed', '');
  }
}

function _init(data) {
  if(targetNode) {
    window.location.search.replace('?', '').split('&').forEach((el) => {
      let splitted = el.split('=');
      getVars[splitted[0]] = splitted[1];
    });

    if (data) {
      dyVars = data;
      translations = PAGE_CART ? j.parseJsonFromData(document.querySelector('.cart').getAttribute('data-promo-strings')) : window._i18n.promoBar;
      end = _getEndDateTime(dyVars.endDateTime);

      dyVars.renderPromo = 'npsPromo';

      dyVars.initialState = dyVars[dyVars.renderPromo][global.isDesktop ? 'desktop' : 'mobile'].initialState;

      if (dyVars.videoURL) {
        dyVars.videoCode = dyVars.videoURL.split('?v=').pop();
      }

      //Set default value for "Timer Icon Color"
      dyVars.counterIconColor = dyVars.counterIconColor || 'black';

      if (!promo.isExpired() && !promo.isUsed() && !IS_PROHIBITED_PAGE) {
        init();
      }
    }
  }
}

PubSub.subscribe(EVENT_DY_PROMOBAR_INIT_V6_NPS, _init);
