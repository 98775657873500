import PubSub from 'pubsub';
import { 
  EVENT_WISHLIST_ADD,
  EVENT_CART_ADD,
  EVENT_DY_ASK_FOR_SIGNUP
} from 'Constants/event';
import AccountWidget from 'npm-account-module';
import Modal from 'Common/modal/modal';
import AccountManager from 'Common/login/login';
import tpl from './askForSignup.mst.html';
const T = window._i18n.accountPopUp;

let askForSignupModal;
let dyConfig;
let wishlistCount = 0;
let addToCartCount = 0;

function _onModalOpen(modal, target, serverErrorMessage) {
  window.askForSignupWidgetConfig = {
    'loginTab': {label: T.signIn.label, headerLabel: dyConfig.title, headerDescription: dyConfig.description},
    'registerTab': {label: T.signUp.label, headerLabel: dyConfig.title, headerDescription: dyConfig.description}
  };
  AccountManager.renderWidgetToAskForSignup('registerTab', modal);
}

function _onModalClose() {}

PubSub.subscribe(EVENT_DY_ASK_FOR_SIGNUP, (config) => {
  dyConfig = config;
  askForSignupModal = new Modal({
    className: 'jnq-modal--ask-for-signup',
    body: tpl({imgUrl: dyConfig.imgUrl}),
    onOpen: _onModalOpen,
    onClose: _onModalClose
  });
  if (window.isUserLogged) return;
  askForSignupModal.open();
});

export default {
  
};
