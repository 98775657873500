import domHelper from 'Helpers/domHelper';
import PubSub from 'pubsub';
import {EVENT_COOKIES_DISCLAIMER_INIT} from 'Constants/event';
import funcHelper from 'Helpers/funcHelper';

const LOCAL_STORAGE_VAR_NAME = 'cookiesDisclaimerAccepted';
let disclaimerContainer;

function _addEvents() {
  disclaimerContainer = document.querySelector('.js-cookies-disclaimer');

  [].forEach.call(disclaimerContainer.querySelectorAll('.js-cookies-disclaimer__close'), (el) => {
    el.addEventListener('click', function(event) {
      event.preventDefault();
      _closeDisclaimerOverlay();
    })
  });
}

function _closeDisclaimerOverlay() {

  if(disclaimerContainer) {
    domHelper.addClass(disclaimerContainer, 'is-hidden');
    localStorage.setItem(LOCAL_STORAGE_VAR_NAME, true);
    document.body.style.paddingBottom = '';
  }
}

function _showDisclaimerOverlay(params) {
  let disclaimerContainer = domHelper.newNode('div', {
        class: 'cookies-disclaimer js-cookies-disclaimer'
      },
      `<i class="i-cookies-disclamer cookies-disclaimer__icon"></i>
      <p class="cookies-disclaimer__text">${params.preLink} <a href="${params.linkUrl}" class="cookies-disclaimer__link">${params.linkText}</a>${params.postLink}</p>
      <a class="cookies-disclaimer__button btn btn-secondary-cta js-cookies-disclaimer__close" href="#">${params.ctaText}</a>
      <a class="cookies-disclaimer__close js-cookies-disclaimer__close">×</a>`);

  document.body.appendChild(disclaimerContainer);
}

function _setSticky() {
  let disclaimerHeight = disclaimerContainer.offsetHeight;

  document.body.style.paddingBottom = disclaimerHeight + 'px';
}

PubSub.subscribe(EVENT_COOKIES_DISCLAIMER_INIT, (params) => {
  if(!localStorage.getItem(LOCAL_STORAGE_VAR_NAME)) {
    window.addEventListener('resize', funcHelper.throttle(_setSticky, 100));
    _showDisclaimerOverlay(params);
    _addEvents();
    _setSticky();
  }
});
