import React, { useState } from 'react';
import { TextField, Checkbox } from '@material-ui/core';
import Cookies from 'js-cookie';
import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import ReactHtmlParser from 'react-html-parser';
import cn from 'classnames';
import isEmail from 'validator/es/lib/isEmail';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import PubSub from 'pubsub';
import { subscribeEmailRequest } from './api';
import { getIsLocalizationModalOpen } from '../localizationModal/localizationModal';
import { theme } from '../../common/theme';
import { Modal } from './modal';

const useTextFieldStyles = makeStyles(() => ({
  root: {
    width: '100%',
    backgroundColor: 'white',
    height: '40px',
    '&& p': {
      marginTop: '10px',
      paddingLeft: '0'
    }
  }
}));

const useInputStyles = makeStyles(_theme => ({
  root: {
    '&&': {
      marginTop: 0
    }
  },
  input: {
    fontSize: '16px',
    paddingLeft: '15px'
  },
  [_theme.breakpoints.up('tablet')]: {
    input: {
      fontSize: '14px'
    }
  },
  underline: {
    '&:hover:not(.Mui-disabled):before': {
      borderBottomColor: 'transparent'
    },
    '&&:before': {
      borderBottomColor: 'transparent'
    },
    '&&:after': {
      borderBottomColor: 'transparent'
    },
    '&&.Mui-error': {
      borderBottom: '2px solid red'
    }
  }
}));

const useLabelStyles = makeStyles({
  root: {
    fontSize: '10px',
    textTransform: 'none'
  },
  shrink: {
    transform: 'translate(0, -9px) scale(0.75)'
  }
});

const useCheckboxStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  icon: {
    borderRadius: 0,
    width: 24,
    height: 24,
    boxShadow: ({ error }) => `inset 0 0 0 2px ${error ? 'red' : '#000'}, inset 0 -1px 0 rgba(16,22,26,.1)`,
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5'
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)'
    }
  },
  checkedIcon: {
    backgroundColor: '#000',
    // backgroundImage: 'linear-gradient(180deg,rgba(0),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 24,
      height: 24,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""'
    },
    'input:hover ~ &': {
      backgroundColor: '#282828'
    }
  }
});

const emailSubscriptionStatus = {
  SUBSCRIBED: 'subscribed',
  NOT_SUBSCRIBED: 'not_subscribed',
  UNSUBSCRIBED: 'unsubscribed',
  PENDING_DOI: 'pending_DOI'
};

const SUBSCRIPTION_STATUS_COOKIE_NAME = 'email_subscribed_status';
const CLOSED_SESSION_COOKIE_NAME = 'email_full_popup_closed';
const SOURCE = 'fullscreen-popup';

const EmailSignupPopup = ({ dyProps }) => {
  const { moodImage, title, optInText, CTAtext, conditions, subscriptionSuccessMessage } = dyProps;

  const { t } = useTranslation('general');

  const isOpenByCookie = () => {
    const subscriptionStatusCookie = Cookies.get(SUBSCRIPTION_STATUS_COOKIE_NAME);
    const closedInThisSession = Cookies.get(CLOSED_SESSION_COOKIE_NAME);

    return (
      subscriptionStatusCookie !== emailSubscriptionStatus.SUBSCRIBED &&
      subscriptionStatusCookie !== emailSubscriptionStatus.PENDING_DOI &&
      !closedInThisSession
    );
  };

  const [isOpen, setOpen] = useState(isOpenByCookie);
  const [inputValue, setInputValue] = useState('');
  const [isChecked, setChecked] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);
  const [inputError, setInputError] = useState(false);
  const [optInError, setOptInError] = useState(false);

  const textFieldClasses = useTextFieldStyles();
  const inputClasses = useInputStyles();
  const labelClasses = useLabelStyles();
  const checkboxClasses = useCheckboxStyles({ error: optInError });

  const handleInputChange = e => {
    const { value } = e.target;

    if (inputError) {
      setInputError(false);
    }

    setInputValue(value?.toLowerCase()?.trim());
  };

  const handleCheckboxChange = ev => {
    setChecked(ev.target.checked);
    setOptInError(false);
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (inputError || optInError) {
      return;
    }

    if (!isEmail(inputValue)) {
      setInputError(true);
      return;
    }

    if (!isChecked) {
      setOptInError(true);
      return;
    }

    const { artist, campaign, campaignVoucher, productVertical, productType, theme: dyPropsTheme } = dyProps;

    const campaignData = {
      artist,
      campaign,
      campaignVoucher,
      productVertical,
      productType,
      theme: dyPropsTheme
    };

    subscribeEmailRequest(inputValue, SOURCE, campaignData)
      .then(() => {
        setSubmitted(true);

        setTimeout(() => {
          handleClose();
        }, 5000);
      })
      .catch(err => {
        console.error('Subscription request failed with error: ', err);
      });
  };

  const handleClose = () => {
    setOpen(false);
    Cookies.set('email_full_popup_closed', 'true');
  };

  if (!isOpen) return null;

  return (
    <Modal onClose={handleClose}>
      <div className="image-section">
        <img alt="" className="image-section__img hide-mobile" src={moodImage} />
      </div>

      <div className="info-section">
        {isSubmitted && (
          <p className="info-section__submitted">
            <span>{ReactHtmlParser(subscriptionSuccessMessage)}</span>
          </p>
        )}

        {!isSubmitted && (
          <>
            <h1 className="info-section__header">{ReactHtmlParser(title)}</h1>

            <form className="" onSubmit={handleSubmit} noValidate autoComplete="off">
              <div className="jnq-modal__input-wrapper">
                <TextField
                  autoFocus
                  autoComplete="off"
                  classes={textFieldClasses}
                  error={inputError}
                  InputProps={{ classes: inputClasses }}
                  InputLabelProps={{ classes: labelClasses, shrink: true }}
                  onChange={handleInputChange}
                  placeholder={t('gen-promobar-email')}
                  value={inputValue}
                  helperText={inputError && t('gen-email-validation')}
                />
              </div>

              <div className="jnq-modal__opt-in">
                <div className="jnq-modal__opt-in-checkbox">
                  <Checkbox
                    required
                    className={checkboxClasses.root}
                    color="default"
                    checkedIcon={<span className={checkboxClasses.checkedIcon} />}
                    icon={<span className={checkboxClasses.icon} />}
                    inputProps={{ 'aria-label': 'opt-in checkbox' }}
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                </div>

                <p className={cn('jnq-modal__opt-in-text', { 'jnq-modal--error': optInError })}>
                  {ReactHtmlParser(optInText)}
                </p>
              </div>

              <button type="button" className="btn btn-black jnq-modal__cta" onClick={handleSubmit}>
                {CTAtext}
              </button>

              <p className="jnq-modal__conditions">{ReactHtmlParser(conditions)}</p>
            </form>
          </>
        )}
      </div>
    </Modal>
  );
};

const dyPropsMock = {
  moodImage: 'https://cdn.dynamicyield.com/api/8766575/images/3706e09ee7f95__exit-intent-de_1.jpg',
  title: 'Subscribe to get 50% off your first order',
  optInText:
    'Now this quiet courtyard, Sunday afternoon, this girl with a ritual lack of urgency through the center of his closed left eyelid. <a href="#">Link</a>',
  CTAtext: 'FA DIN RABATKODE',
  conditions:
    'Case felt the edge of the Flatline as a construct, a hardwired ROM cassette replicating a dead man’s skills, obsessions, kneejerk responses.',
  subscriptionSuccessMessage: 'Succesfully subscribed'
};

const getRootElement = props => (
  <MuiThemeProvider theme={theme}>
    <EmailSignupPopup dyProps={props} />
  </MuiThemeProvider>
);

let savedDyProps = null;
let emailPopupOpen = false;
export const getEmailPopupOpen = () => emailPopupOpen;

const renderModal = dyProps => {
  // If localization modal open save dy props and don't open
  if (getIsLocalizationModalOpen()) {
    savedDyProps = dyProps;
    return;
  }

  emailPopupOpen = true;
  ReactDOM.render(getRootElement(dyProps), document.getElementById('modal-root'));
};

PubSub.subscribe('dy.emailSignupPopup', dyPprops => renderModal(dyPprops));
PubSub.subscribe('localizationModalClosed', () => {
  if (savedDyProps) {
    setTimeout(() => renderModal(savedDyProps), 2000);
  }
});

// Test only, remove for prod
// setTimeout(() => PubSub.publish('dy.emailSignupPopup', dyPropsMock), 3000);
// ReactDOM.render(getRootElement(dyPropsMock), document.getElementById('modal-root'));
