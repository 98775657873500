import DOM from 'Helpers/domHelper';
import global from 'global';
import sGrid from 'Pages/selections/grid';
import showMoreButton from 'DynamicYield/showMoreProductsButton/showMoreProductsButton';
import pubsub from 'pubsub';
import axios from 'axios';
import AjaxCart from 'Common/headerPopovers/ajaxCart';

import { EVENT_LOAD_MORE_PRODUCTS } from 'Constants/event';

let content;
let productsGrid;
let selectionsGrid;
const DEFAULT_MAX_LOADED_PAGES = 4;


function init() {
  _cacheDom();
  _bindEvents();
  // check if show more button should be displayed
  if (_isLoadMoreBtnEnabled()) {
    const showMoreBtn = document.querySelector('.js-products__show-more');
    showMoreBtn && DOM.removeClass(showMoreBtn, 'is-hidden');
  }
  //add class for main content if product grid and list carousel are empty
  if (!productsGrid && !document.querySelector('.rect-list__headed--carousel')) {
    const mainContent = document.getElementById("main");
    DOM.addClass(mainContent, "main-content_no-result")
  }
}

function _cacheDom() {
  content = document.querySelector('.content');
  productsGrid = document.querySelector('.products-grid');
  selectionsGrid = document.querySelector('.selections-grid');
}

function _bindEvents() {
  // attach event click on content
  content.addEventListener('click', function (event) {
    const target = event.target;
    // Button replacing the pagination
    let isShowMoreButton = DOM.hasClass(target, 'js-products__show-more');
    if(isShowMoreButton) {
      _loadMoreProducts(event);
    }
  });
}

function createElementFromHTML(htmlString) {
  var div = document.createElement('div');
  div.innerHTML = htmlString.trim();
  // Change this to div.childNodes to support multiple top-level nodes
  return div.childNodes;
}

function _postLoadMoreProducts(loadMoreBtn) {
  loadMoreBtn.removeAttribute('disabled');
  loadMoreBtn.blur();
}

function _isLoadMoreBtnEnabled() {
  let loadedPages = parseInt(DOM.getUrlParameter('p')) || 1;
  const showMoreConfig = showMoreButton.getConfig();
  let maxLoadedPages = parseInt(showMoreConfig.maxPages) || DEFAULT_MAX_LOADED_PAGES;
  return loadedPages < maxLoadedPages;
}

function _loadMoreProducts(event) {
  event.preventDefault();
  let target = event.target;
  let loadingUrl = target.getAttribute('data-laoding-url');
  let loadedPages = parseInt(DOM.getUrlParameter('p')) || 1;
  const showMoreConfig = showMoreButton.getConfig();
  let maxLoadedPages = parseInt(showMoreConfig.maxPages) || DEFAULT_MAX_LOADED_PAGES;
  if (loadedPages < maxLoadedPages) {
    const requestUrl = loadingUrl ? loadingUrl : `${location.origin}/product-listing${location.pathname}${location.search}`;
    const requestUrlWithPage = DOM.updateQueryStringParameter(requestUrl, 'p', loadedPages + 1);
    target.setAttribute('disabled', 'disabled');
    DOM.getJSON(requestUrlWithPage)
      .then((res) => {
        _postLoadMoreProducts(target);
        if (DOM.hasClass(target, 'js-products__show-more--selections')) {
          const htmlItems = createElementFromHTML(res.html);
          let elems = [];
          let fragment = document.createDocumentFragment();
          [].forEach.call(htmlItems, (item) => {
            fragment.appendChild(item);
            elems.push(item);
          });
          selectionsGrid.appendChild(fragment);
          sGrid.addMoreProducts(elems);
        } else {
          productsGrid.insertAdjacentHTML('beforeend', res.html);
          window.attachImagePlaceholders && (window.attachImagePlaceholders(document.querySelectorAll('.product__img[data-echo]:not(.product__img--secondary)')));
        }
        if (!res.showMore || loadedPages + 1 === maxLoadedPages) {
          DOM.addClass(target, 'is-hidden');
        }
        setTimeout(() => {
          window.$EchoJS.init({
            offset: window.innerHeight * 2, // also load images positioned on two next screens
            mobileMQBreakpoint: global.breakpoints.phone,
            debounce: false,
            throttle: 500
          });
        }, 0);
        // push new state
        const newUrl = DOM.updateQueryStringParameter(location.href, 'p', loadedPages + 1);
        history.pushState({}, null, newUrl);
        
        pubsub.publish(EVENT_LOAD_MORE_PRODUCTS)
      })
      .catch((e) => {
        _postLoadMoreProducts(target);
      });
  }

}

export default {
  init: init
};
