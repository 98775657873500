import global from 'global';


var cmsImgs = document.querySelectorAll('img[data-original]'),
    cmsImgsLength = cmsImgs.length;

for (var i = 0; i < cmsImgsLength; i++) {
  cmsImgs[i].setAttribute('data-echo', cmsImgs[i].getAttribute('data-original'));
  cmsImgs[i].removeAttribute('data-original');
}


EchoJS.init({
  offset: window.innerHeight * 2, // also load images positioned on two next screens
  mobileMQBreakpoint: global.breakpoints.phone,
  debounce: false,
  throttle: 500
});

window.$EchoJS = EchoJS;
