import j from 'Helpers/domHelper';


var backdrop;

export default {
  show: show,
  hideBackdrop: hideBackdrop,
  removeBackdrop: removeBackdrop,
  fadeIn: fadeIn
};

function show() {
  if (backdrop) {
    _showBackdrop();
  } else {
    _createBackdrop();
  }
}

function _showBackdrop() {
  backdrop.style.display = 'block';
}

function _createBackdrop() {
  backdrop = document.createElement('div');
  backdrop.className = 'modal-backdrop fade';
  document.body.appendChild(backdrop);
}

function hideBackdrop() {
  j.pause(function () {
    j.removeClass(backdrop, 'in');
  }, 150)
      .then(function () {
        backdrop.style.display = 'none';
      });
}

function removeBackdrop() {
  j.remove(backdrop);
  backdrop = null;
}

function fadeIn() {
  j.addClass(backdrop, 'in');
}

