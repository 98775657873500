'use strict';

/**
 * Wishlist helper module.
 * @module common/wishlist/elements
 */

import DOM from 'Helpers/domHelper';
import {
  CLASS_WISHLIST_TOGGLE_ELEMENT,
  CLASS_WISHLIST_REMOVE_ELEMENT,
  CLASS_WISHLIST_TOGGLE_CAROUSEL_ELEMENT,
  CLASS_TOGGLE_ACTIVE,
  CLASS_WISHLIST_ADDED
} from 'Constants/class';
import AddToWishlist from './addToWishlist';

/**
 * Class representing set of static methods to work with wishlist triggers and elements on the page.
 */
export default class WishlistElements {
  /**
   * Click handler for all add to wishlist triggers
   * @param {Event} e - Event object captured by handler
   * @private
   */
  static _addToWishlistClickHandler(e) {
    const wishlistButton = e.target.closest(`.${CLASS_WISHLIST_TOGGLE_ELEMENT}`);
    const wishlistRemoveButton = e.target.closest(`.${CLASS_WISHLIST_REMOVE_ELEMENT}`);

    if (wishlistButton) {
      e.preventDefault();
      e.stopPropagation();
      DOM.removeClass(wishlistButton, CLASS_WISHLIST_ADDED);
      setTimeout(function () {
        DOM.addClass(wishlistButton, CLASS_WISHLIST_ADDED);
      }, 0);
      AddToWishlist.init(wishlistButton, DOM.hasClass(wishlistButton, CLASS_WISHLIST_TOGGLE_CAROUSEL_ELEMENT));
    }

    if (wishlistRemoveButton) {
      e.preventDefault();
      e.stopPropagation();
      AddToWishlist.init(wishlistRemoveButton, false);
    }
  }

  /**
   * Searches the page for the nodes with products which are in wishlist
   * and marks the wishlist trigger element as active if the product is found.
   * @param {Array} items - Array of ID numbers of items in wishlist
   */
  static highlightAdded(items) {
    items.forEach(id => {
      [].forEach.call(document.querySelectorAll(`[data-product-id="${id}"], [data-bundle-id="${id}"]`), product => {
        [].forEach.call(product.querySelectorAll(`.${CLASS_WISHLIST_TOGGLE_ELEMENT}`), toggle => {
          DOM.addClass(toggle, CLASS_TOGGLE_ACTIVE);
        });
      });
    });
  }
}
