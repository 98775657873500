import { composeWithDevTools } from 'redux-devtools-extension';
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { alertMiddlewares } from '@juniqe/cart';
import rootReducer from './reducers';
import { wishListMiddleware } from './reduxMiddlewares/wishList';
import { redirectMiddleware } from './reduxMiddlewares/redirect';

export default createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk, wishListMiddleware, redirectMiddleware, alertMiddlewares.updateCart))
);
