"use strict";

import global from 'global';
import {
  EVENT_CLICK,
  EVENT_MOUSEDOWN
} from 'Constants/event';
import domHelper from 'Helpers/domHelper';
import stickyHelper from './stickyHelper';


var headerEl,
    searchEl,
    overlay;

function init(options) {
  headerEl = options.headerEl;
  searchEl = options.searchEl;
  overlay = document.getElementsByClassName('js-bg-overlay')[0];
  var pageHeight = domHelper.getPageHeight();
  overlay.style.height = pageHeight + 'px';

  _bindEvents();
}

function _bindEvents() {
  // searchEl.addEventListener('focus', _onSearchFocus);
  // searchEl.addEventListener('blur', _closeOverlayOnSearchBlur);
}

function _makeHeaderAbsolute(header) {
  header.style.position = 'absolute';
  header.style.top = stickyHelper.getScrollTop() + 'px';
  header.style.width = '100%';
}

function _onSearchFocus(e) {
  if (domHelper.hasClass(headerEl, 'is-shown')) {
    domHelper.addClass(overlay, 'is-active');
    domHelper.addClass(document.body, 'is-overlay--active');

    if (global.isIOS) {
      domHelper.doWithoutCSSTransitions(headerEl, function (el) {
        _makeHeaderAbsolute(el);

        setTimeout(function () {
          _makeHeaderAbsolute(el);
        }, 0);
      });
    }

    overlay.addEventListener(EVENT_MOUSEDOWN, _closeOverlayOnSearchBlur);
  }
}

function _closeOverlayOnSearchBlur(e) {
  e.preventDefault();
  e.stopPropagation();

  if (e.target !== headerEl && e.target !== domHelper.closest(e.target, 'header')) {
    domHelper.removeClass(overlay, 'is-active');
    domHelper.removeClass(document.body, 'is-overlay--active');

    // searchEl.blur();

    domHelper.doWithoutCSSTransitions(headerEl, function (el) {
      el.removeAttribute('style');
    });

    overlay.removeEventListener(EVENT_CLICK, _closeOverlayOnSearchBlur);
  }
}

export default {
  init: init
};


