export const domain = baseUrl => {
  const host = baseUrl || window.location.host;

  const reg = /juniqe\.([\w.]+)/;
  const exec = reg.exec(host);
  return exec !== null ? exec[1] : null;
};

export const isSubDomain = subDomainName => {
  return window.location.hostname.startsWith(subDomainName);
};

export const domainToStore = () => {
  const storeMap = {
    de: 'de',
    fr: 'fr',
    ch: 'de-ch',
    'fr.ch': 'fr-ch',
    'co.uk': 'en-gb',
    com: 'en',
    nl: 'nl',
    se: 'se',
    dk: 'dk',
    es: 'es',
    it: 'it'
  };

  const dom = isSubDomain('fr') ? 'fr.ch' : domain();

  return storeMap[dom];
};

export const isNoLandingPage = () => window.history.length > 2 && document.referrer !== '';

export const urlWithoutDomain = url => {
  const reg = /^.*\/\/[^/]+/;

  return url?.replace(reg, '');
};

export const urlHash = url => urlToObject(url, '#');

export const urlQuery = url => urlToObject(url, '?');

export const objectToKeyValueString = object => {
  return Object.entries(object)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
};

const urlToObject = (url, splitChar) => {
  return (
    url
      .split(splitChar)
      .slice(1)
      .map(hash =>
        hash.split('&').reduce((pv, cv) => {
          const [key, value] = cv.split('=');
          return { ...pv, [key]: value };
        }, {})
      )
      .pop() || {}
  );
};
