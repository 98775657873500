import sentry from 'Common/sentry/';
import './i18n/i18n';
import 'Common/polyfill';
import 'Common/uspBar/uspBar';

import dynamicYieldTracker from 'Common/dynamicYield/dynamicYieldTracker';
import global from 'global';
import defineNewSession from 'Common/defineNewSession';
import 'Common/lazyLoading';
import 'Common/login/loginModal';
import StickyThings from 'Common/sticky/stickyThings';
import BackToTop from 'Common/backToTop';
import SearchMobile from 'Common/headerPopovers/searchMobile';
import AjaxCart from 'Common/headerPopovers/ajaxCart';
import Wishlist from 'Common/headerPopovers/wishlist';
import MainMobileNavigation from 'Common/mainMobileNavigation';
import MainDesktopNavigation from 'Common/mainDesktopNavigation';
import Tabs from 'Common/tabs';
import Collapse from 'Common/collapse';
import CustomSelect from 'Common/customSelect/customSelect';
import jSlider from 'Common/slider/jslider';
import setDeviceTypeToCookie from 'Common/setDeviceTypeToCookie';
import jVideo from 'Common/jVideo/jVideo';
import criteo from 'Common/criteo/criteo';
import hideCriteo from 'Common/criteo/hideCriteo';
import VideoCms from 'Common/videoCms';
import cmsWidgets from 'Common/cmsWidgets';
import leftNav from 'Common/leftNav';
import Logout from 'Common/logout';
import NewsletterSubscribe from 'Common/newsletterSubscribe';
import DeviceDemandImageLoader from 'Common/deviceDemandImageLoader';
import productsGrid from 'Common/products-grid/products-grid';
import truncateText from 'Common/truncateText';
import mainClickHandler from 'Common/mainEvents/clickHandler';
import SearchTypeahead from 'Common/searchTypeahead/searchTypeahead';
import RedirectPopup from 'Common/redirectDomainPopup/redirectDomain';
import PreferredDomain from 'Common/preferredDomain/preferredDomain';
import './pages/artistApplication/artistApplication';
import './pages/unsubscribe/feedback';

// DY EXPERIMENTS START
import 'Common/navbar';
import 'DynamicYield/priceDiscount/priceDiscount';
import 'DynamicYield/promoBar/promobarGeneralV7';
import 'DynamicYield/promoBar/floatingEmailPopup';
import 'DynamicYield/promoBar/promoBarGeneral';
import 'DynamicYield/promoBar/promoBarEmail';
import 'DynamicYield/promoBar/promoBarCatalogEmail';
import 'DynamicYield/promoBar/promoBarFooterEmail';
import 'DynamicYield/promoBar/promoBarNps';
import 'DynamicYield/exitIntentModal/exitIntentModal';
import 'DynamicYield/emailSignupPopup/emailSignupPopup';
import 'DynamicYield/localizationModal/localizationModal';
import 'DynamicYield/catalogFeedback/catalogFeedback';
import 'DynamicYield/addToCartOverlay/atcInit';
import 'DynamicYield/returningVisitors/returningVisitorsInit';
import 'DynamicYield/emailTheCart/emailTheCart';
import 'DynamicYield/cookies-disclaimer/disclaimer';
import 'DynamicYield/recommendations/recommendations';
import 'DynamicYield/proxyEvents/eventsCart';
import 'DynamicYield/newCartConfig/newCartConfig';
import 'DynamicYield/categoryLPRedesign/';
import 'DynamicYield/popover/large/';
import 'DynamicYield/popover/medium';
import 'DynamicYield/pdpRecoPositionOnTop/pdpRecoPositionOnTop';
import 'DynamicYield/askForSignup/askForSignup';
import 'DynamicYield/productViewPage/productViewPage';
import 'DynamicYield/injectContent/';
import 'DynamicYield/positionNavBar/positionNavBar';
import 'DynamicYield/retails/retails';
import 'DynamicYield/productLinkConfig/productLinkConfig';

document.addEventListener('DOMContentLoaded', () => {
  PreferredDomain.init();
  mainClickHandler.initTriggers();

  defineNewSession.init();

  Logout.init();
  NewsletterSubscribe.init();
  // Disabled as it not functional with the new navbar - DEV-7448-shops
  // Review the implementation of stickenss if we need it in the future
  // StickyThings.init();
  // AjaxCart.init();
  // Wishlist.init();
  Collapse.init();
  setDeviceTypeToCookie.init();
  VideoCms.init();
  cmsWidgets.init();
  DeviceDemandImageLoader.init();

  jSlider.init('js-jslider', {
    arrows: true,
    continuous: true
  });

  if (global.isDesktop) {
    CustomSelect.init();
    // eslint-disable-next-line operator-assignment
    document.body.className = document.body.className + ' is-desktop';
  } else {
    jSlider.init('js-jslider-mobile');
  }

  BackToTop.init();
  jVideo.init();
  window.jVideoInit = jVideo; // to make it work though DY, remove this global variable if there is not DY experiment
  productsGrid.init();

  // signal - JS is in da house
  let bodyClassName = document.body.className;
  bodyClassName = bodyClassName.replace('before-js', '');
  document.body.className = bodyClassName;

  const tabs = document.querySelectorAll('[data-tabs]');
  if (tabs) {
    for (let i = 0; i < tabs.length; i++) {
      // eslint-disable-next-line no-new
      new Tabs({ el: tabs[i] });
    }
  }
  SearchTypeahead.init();
  RedirectPopup.init();
  leftNav.init();

  criteo.init();
  hideCriteo.init();

  dynamicYieldTracker.triggerLogin();
  truncateText.init();

  // make hero banner clickable everywhere
  const heroContainer = document.querySelector('.hero__container');
  const heroCtaBtnSelector = '.hero__cta a';
  if (heroContainer) {
    heroContainer.addEventListener('click', e => {
      const pageLink = e.currentTarget.querySelector(heroCtaBtnSelector);
      if (pageLink) {
        document.location.href = pageLink.getAttribute('href');
      }
    });
    heroContainer.addEventListener('mouseenter', e => {
      // check if there is a cta inside and change hero banner cursor
      if (e.target.querySelector(heroCtaBtnSelector)) {
        e.target.style.cursor = 'pointer';
      }
    });
  }
});
