import j from 'Helpers/domHelper';
import Modal from 'Common/modal/modal';


function PdpVideoModal(options) {
  options = options || {};
  options.className = 'modal--video';
  Modal.call(this, options);
}

function generateMethodObject(fn) {
  return {
    value: fn,
    enumerable: true,
    configurable: true,
    writable: true
  };
}

PdpVideoModal.prototype = Object.create(Modal.prototype, {
  embed: generateMethodObject(function (videoEl) {
    this.setBody('<i class="icon icon-play-circle modal--video__play"></i>');
    this.videoEl = videoEl;

    this.body.appendChild(videoEl);

    this.toggleVideoStateBound = toggleVideoState.bind(this);
    this.body.addEventListener('click', this.toggleVideoStateBound);
    document.addEventListener('keyup', this.toggleVideoStateBound);
  }),
  clean: generateMethodObject(function () {
    this.setBody('');
    this.body.removeEventListener('click', this.toggleVideoStateBound);
    document.removeEventListener('keyup', this.toggleVideoStateBound);
  }),
  play: generateMethodObject(function () {
    this.videoEl.play();
    j.removeClass(this.modal, 'modal--video-paused');
  }),
  pause: generateMethodObject(function () {
    this.videoEl.pause();
    j.addClass(this.modal, 'modal--video-paused');
  }),
});

PdpVideoModal.prototype.constructor = PdpVideoModal;

function toggleVideoState(e) {
  /* jshint validthis: true */
  // 32 -> keyCode for Space keyboard button.
  if (e.type === 'click' || (e.type === 'keyup' && e.keyCode === 32)) {
    if (this.videoEl.paused) {
      this.play();
    } else {
      this.pause();
    }
  }
}

export default PdpVideoModal;

