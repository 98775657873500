import j from 'Helpers/domHelper';
import template from './modal.mst.html';
import Backdrop from './backdrop';


function _f() {
}

function Modal(options) {
  this.inited = false;
  this.options = options;
  this.options.onCreate = options.onCreate || _f;
  this.options.onOpen = options.onOpen || _f;
  this.options.onClose = options.onClose || _f;

  this.onCloseQueue = [];

  this.template = template({
    heading: this._getHeader(options.body),
    className: options.className,
    body: options.body
  });

  this.init();
}

Modal.prototype.init = function () {
  if (this.options.trigger) {
    this._onTriggerBound = this._onTrigger.bind(this);
    this.options.trigger.addEventListener('click', this._onTriggerBound);
  }
  if (this.options.triggers) {
    this._onTriggerBound = this._onTrigger.bind(this);
    [].forEach.call(this.options.triggers, trigger => trigger.addEventListener('click', this._onTriggerBound));
  }

  this._createModal();
};

Modal.prototype.open = function (target) {
  var self = this;

  self._showModal();

  self._onCloseBound = function (e) {
    if (clickedOnCloseArea(e.target) || e.keyCode === 27) {
      e.preventDefault();
      self.close();
    }
  };

  document.addEventListener('click', this._onCloseBound);
  document.addEventListener('keyup', this._onCloseBound);

  this.options.onOpen(this, target);
};

function clickedOnCloseArea(target) {
  return j.hasClass(target, 'jnq-modal') ||
      target.hasAttribute('data-modal-close') ||
      j.closest(target, '[data-modal-close]');
}

Modal.prototype.close = function () {
  var self = this;
  j.pause(self._cleanClasses.bind(self), 150)
      .then(function () {
        self.modal.style.display = 'none';
        document.removeEventListener('click', self._onCloseBound);
        document.removeEventListener('keyup', self._onCloseBound);
        self.options.onClose(self);
        self._flushCloseQueue();
      });
  Backdrop.hideBackdrop();
};

Modal.prototype.onClose = function (handler) {
  this.onCloseQueue.push(handler);
};

Modal.prototype._flushCloseQueue = function () {
  while (this.onCloseQueue.length) {
    this.onCloseQueue.shift()();
  }
};

Modal.prototype.destroy = function () {
  this.close();
  this._removeModal();
  Backdrop.removeBackdrop();
  if (this.options.trigger) {
    this.options.trigger.removeEventListener('click', this._onTriggerBound);
  }
  if (this.options.triggers) {
    [].forEach.call(this.options.triggers, trigger => trigger.removeEventListener('click', this._onTriggerBound));
  }
  this.inited = false;
};

Modal.prototype.getBody = function () {
  return this.body;
};

Modal.prototype.clearBody = function () {
  this.body.innerHTML = '';
};

Modal.prototype.setBody = function (data) {
  this.body.innerHTML = data;
};

Modal.prototype.showPreloader = function () {
  j.addClass(this.modal.querySelector('.jnq-modal__dialog'), 'preloader');
};

Modal.prototype.hidePreloader = function () {
  j.removeClass(this.modal.querySelector('.jnq-modal__dialog'), 'preloader');
};


Modal.prototype._getHeader = function (tpl) {
  var modalHeader,
      tempEl = document.createElement('div');
  tempEl.innerHTML = tpl;
  modalHeader = tempEl.querySelector('[data-modal-header]');
  
  // check do we have header or not.
  if (modalHeader) {
    return modalHeader.getAttribute('data-modal-header');
  } else {
    return false;
  }
};

Modal.prototype._onTrigger = function (e) {
  e.preventDefault();
  e.stopPropagation();
  this.open(e.target);
};

Modal.prototype._showModal = function () {
  Backdrop.show();
  this._addClasses();
  this.modal.style.display = 'block';
};

Modal.prototype._createModal = function () {
  j.appendHTML(document.body, this.template);
  this.modal = document.getElementsByClassName(this.options.className)[0];
  this.body = this.modal.getElementsByClassName('jnq-modal__body')[0];
  this.inited = true;

  this.options.onCreate(this);
};

Modal.prototype._addClasses = function () {
  var self = this;

  j.addClass(document.body, 'is-modal-open');

  setTimeout(function () {
    j.addClass(self.modal, 'in');
    Backdrop.fadeIn();
  }, 150);
};

Modal.prototype._cleanClasses = function () {
  j.removeClass(document.body, 'is-modal-open');
  j.removeClass(this.modal, 'in');
};

Modal.prototype._removeModal = function () {
  j.remove(this.modal);
};

/*
  Callbacks:
    - onCreate
    - onClose
* */
export default Modal;

