'use strict';

import global from 'global';
import DOM from 'Helpers/domHelper';
// import owlCarousel from 'owl';
import overlayTemplate from './addToCartOverlay.mst.html';
import Upselling from './upselling';
import {
  CLASS_BODY_STOP_SCROLLING
} from 'Constants/class';
import {
  ID_UPSELLING_SIZE,
  ID_UPSELLING_TYPE
} from 'Constants/misc';
import {
  EVENT_ATC_OVERLAY_RENDER
} from 'Constants/event';
import PubSub from 'pubsub';
import newCartConfig from 'DynamicYield/newCartConfig/newCartConfig';

const OVERLAY_CONTENT_SELECTOR = '.cart-overlay__inner';
const OVERLAY_FILL_SELECTOR = '.cart-overlay__content';
const OVERLAY_IMAGE_SELECTOR = '.cart-overlay__image';
const CLOSE_OVERLAY_SELECTOR = '.cart-overlay__close-button';
const CONTINUE_SHOPPING_SELECTOR = '.cart-overlay__continue-shopping-button';
const VIEW_IN_ROOM_NAME = 'view_in_room';

let ENTITY;

let templateOptions;
let temporaryNode = document.createElement('div');

let overlay;
let overlayContent;
let overlayFill;
let overlayImage;
let closeOverlayButton;
let continueShoppingButton;
let imageCode;
let designNameSelector;
let productDescriptionSelector;
let productTypeSelector;
let optionsSelector;
let recommendationsId;
let productTypeId;
let form;
let upsellingType;
let isBundle;
let productSliderItems;
let firstProductSliderItem;
let viewInRoomIsFirst;
let productImageSlider;

function init(options) {
  ENTITY = document.querySelector('#entity-id');
  form = ENTITY.querySelector('#add-to-cart-form');

  isBundle = ENTITY.hasAttribute('data-bundle-id');
  recommendationsId = parseInt(options.recommendationWidgetId);
  productTypeId = parseInt(ENTITY.getAttribute('data-product-type-id'));
  upsellingType = options.promoContent;
  productSliderItems = ENTITY.querySelectorAll('.pdp-slider__item');
  firstProductSliderItem = productSliderItems[0];
  viewInRoomIsFirst = firstProductSliderItem && !!firstProductSliderItem.querySelector(`img[src*="${VIEW_IN_ROOM_NAME}"], img[data-echo*="${VIEW_IN_ROOM_NAME}"]`);

  // Find the first slide item that doesn't contain a view in room image
  if(viewInRoomIsFirst && productSliderItems.length > 1) {
    productImageSlider = productSliderItems[1];
  } else {
    productImageSlider = firstProductSliderItem;
  }
  imageCode = isBundle ? ENTITY.querySelector('.pdp__image').outerHTML : (productImageSlider ? productImageSlider.innerHTML : '');
  designNameSelector = isBundle ? '.pdp-heading--mobile' : '.pdp-heading';
  productDescriptionSelector = isBundle ? '.pdp__selection-quantity' : '.pdp-heading--second';
  productTypeSelector = isBundle ? productDescriptionSelector : '.pdp-heading__product-type';
  optionsSelector = '.pdp__options:not(.pdp__options--links)';

  _getTemplateOptions(options.overlayLayout);
  _renderTemplate(options);
  _cacheDom();
  _postRender();
  _bindEvents();
}

function _bindEvents() {
  overlayContent.addEventListener('click', _onOverlayClick);
  overlay.addEventListener('click', _close);
  closeOverlayButton.addEventListener('click', _close);
  continueShoppingButton.addEventListener('click', _close);
}

function _cacheDom() {
  overlayContent = overlay.querySelector(OVERLAY_CONTENT_SELECTOR);
  overlayFill = overlay.querySelector(OVERLAY_FILL_SELECTOR);
  overlayImage = overlay.querySelector(OVERLAY_IMAGE_SELECTOR);
  closeOverlayButton = overlay.querySelector(CLOSE_OVERLAY_SELECTOR);
  continueShoppingButton = overlay.querySelector(CONTINUE_SHOPPING_SELECTOR);
}

function _getTemplateOptions(layout) {
  templateOptions = Object.assign({}, window._i18n.addToCartOverlay);

  templateOptions.promoContent = recommendationsId ? 'reco' : 'other';
  templateOptions.isFashion = Boolean(document.querySelector('.pdp--fashion'));
  templateOptions.additionalOverlayClass = layout;
  templateOptions.imageCode = imageCode;
  templateOptions.designName = ENTITY.querySelector(designNameSelector).innerText;
  templateOptions.productDescription = ENTITY.querySelector(productTypeSelector).innerText;
  let selectionPriceElem = ENTITY.querySelector('.pdp-price__atc-display-value');
  let priceValue;
  if (selectionPriceElem) {
    priceValue = selectionPriceElem.innerHTML;
  } else {
    priceValue = ENTITY.querySelector(`[data-pdp-option="price"]${global.isDesktop ? '' : '[data-device="mobile"]'}`).innerHTML;
  }
  templateOptions.productOptions = [{
    name: templateOptions.price,
    value: priceValue,
    id: '-1'
  }];

  if (!isBundle && form.querySelectorAll('input[type="radio"]').length > 1) {
    // Getting options of the product from the selectboxes
    [].forEach.call(ENTITY.querySelectorAll(optionsSelector), (select) => {
      let checkedOption = select.querySelector(':checked');
      let value;
      let label;

      if (productTypeId === 52 && checkedOption.hasAttribute('data-wooden-size')) { //Wood Frame
        value = checkedOption.getAttribute('data-wooden-size');
      } else if (productTypeId === 210 && checkedOption.hasAttribute('data-plastic-size')) { // Plastic Frame
        value = checkedOption.getAttribute('data-plastic-size');
      } else {
        value = checkedOption.getAttribute('data-label-original');
      }

      if (global.isPhone) {
        label = DOM.closest(select, '.pdp__option-container').querySelector('.pdp__option-name').innerText;
      }
      else {
        label = DOM.closest(checkedOption, '.pdp__options').getAttribute('data-field-label');
      }

      templateOptions.productOptions.push({
        name: label,
        value: value,
        id: checkedOption.getAttribute('data-super-attribute')
      });
    });

    templateOptions.compiledOptions = DOM.stringifyJsonForData({
      imageCode: templateOptions.imageCode,
      productOptions: templateOptions.productOptions
    });
  }
}

function _renderTemplate(options) {
  if (newCartConfig()) {
    templateOptions.goToCartUrl = newCartConfig().goToCartLink;
  } else {
    templateOptions.goToCartUrl = '/checkout/cart';
  }
  temporaryNode.innerHTML = overlayTemplate(templateOptions);
  overlay = temporaryNode.firstChild;

  document.body.appendChild(overlay);
  DOM.addClass(document.body, CLASS_BODY_STOP_SCROLLING);

  if ((upsellingType === ID_UPSELLING_TYPE || upsellingType === ID_UPSELLING_SIZE) && global.isDesktop) {
    Upselling.init(options);
  }
  PubSub.publish(EVENT_ATC_OVERLAY_RENDER, {});
  if (recommendationsId && global.isDesktop) {
    let waitForDy = setInterval(() => {
      if (window.DYO) {
        clearInterval(waitForDy);

        window.DYO.recommendationWidget(recommendationsId, {target: '#dy-overlay-upselling'});
        DOM.waitForConditionalElement('#dy-overlay-upselling .product', () => {
          let recsNumber = document.querySelectorAll('#dy-overlay-upselling .product').length;

          if (recsNumber > 4) {
            // TODO: reimplement this carousel with siema if DY upselling experiment is ever activated
            // $('#dy-overlay-upselling #dy-recs').owlCarousel({
            //   loop: true,
            //   margin: 0,
            //   navText: [
            //     '<i class="icon-chevron-left"></i>',
            //     '<i class="icon-chevron-right"></i>'
            //   ],
            //   items: 4,
            //   dots: false,
            //   nav: true
            // })
          }
        });
      }
    }, 50);
  }
}

function _postRender() {
  if (overlayImage.children[0].tagName === 'IMG') {
    let fillStyle = getComputedStyle(overlayFill);

    overlayImage.style.height = `${overlayFill.offsetHeight - parseInt(fillStyle.paddingTop) - parseInt(fillStyle.paddingBottom)}px`;
  }
}

function _onOverlayClick(e) {
  e.stopPropagation();
}

function _close(e) {
  e && e.preventDefault();

  document.body.removeChild(overlay);
  DOM.removeClass(document.body, CLASS_BODY_STOP_SCROLLING);
}

export default { 
  init: init,
  close: _close
}