'use strict';

import PubSub from 'pubsub';
import StorageProvider from 'Helpers/storageHelper';
import {
  EVENT_DY_INIT_ATC
} from 'Constants/event';
import {
  STORAGE_NAMESPACE_DY,
  STORAGE_KEY_ATC
} from 'Constants/misc';

const STORAGE = new StorageProvider(STORAGE_NAMESPACE_DY);
const _cacheExperiment = (options) => {
  STORAGE.set(STORAGE_KEY_ATC, Object.assign({
    productTypes: options.upsellingTypeIds.split(',').map(id => Number(id)).filter(id => Boolean(id))
  }, options));
};

// Cleaning settings from previously visited page(s)
STORAGE.remove(STORAGE_KEY_ATC);

PubSub.subscribe(EVENT_DY_INIT_ATC, _cacheExperiment);