import domHelper from 'Helpers/domHelper';
import Swipe from 'swipe';
import navTemplate from './sliderNavigation.mst.html';


function _noop() {
}

function Slider(options) {
  this.currentSlideIndex = 0;
  this.prevSlideIndex = 0;
  this.isInited = false;

  this.options = {};

  this.options.mainEl = (typeof options.mainEl === 'string') ?
      document.querySelector(options.mainEl) :
      options.mainEl;
  this.options.container = (typeof options.container === 'string') ?
      this.options.mainEl.querySelector(options.container) :
      options.container;

  // navigation handlers
  this.options.arrows = options.arrows || false;
  this.options.nextArrowClass = options.nextArrowClass || 'swipe__arrow--next';
  this.options.prevArrowClass = options.prevArrowClass || 'swipe__arrow--previous';

  //navigation template
  this.options.navTemplate = options.navTemplate || navTemplate;
  this.options.navTemplateHTML = options.navTemplateHTML || false;

  //classes
  this.options.slideClass = options.slideClass || 'swipe__item';
  this.options.navClass = options.navClass || 'swipe__nav';
  this.options.navItemClass = options.navItemClass || 'swipe__nav__item';
  this.options.navItemActiveClass = options.navItemActiveClass || 'is-active';

  this.options.onSlide = options.onSlide || _noop;
  this.options.onTransitionEnd = options.onTransitionEnd || _noop;
  this.options.verticalAlign = options.verticalAlign || 'top';
  this.options.continuous = options.continuous || false;
  this.options.autoplay = options.autoplay || false;
  this.options.playSpeed = options.playSpeed || 8000;
  this.options.speed = options.speed || 300;

  this.options.onInit = options.onInit || _noop;
}

Slider.prototype.init = function () {
  this._cacheDom();

  this._runSwipe({
    callback: this._onSlide.bind(this),
    transitionEnd: this._onTransitionEnd.bind(this),
    continuous: this.options.continuous,
    verticalAlign: this.options.verticalAlign,
    speed: this.options.speed
  });

  this._renderNavigation();
  this._bindNavigationEvents();
  if (this.options.autoplay) {
    this._startAutoPlay();
  }
  this.options.onInit();

  this.isInited = true;
};

Slider.prototype._cacheDom = function () {
  this.slides = this.options.container.getElementsByClassName(this.options.slideClass);
};

Slider.prototype._runSwipe = function (params) {
  this.swipe = new Swipe(this.options.container, params);
};

Slider.prototype._renderNavigation = function () {
  var template = this.options.navTemplateHTML ||
      this.options.navTemplate(this._getPreparedTemplateData());

  this.options.mainEl
      .insertAdjacentHTML('beforeend', template);

  this.navigation = document.getElementsByClassName(this.options.navClass).item(0);
  this.navigationItems = this.navigation.getElementsByClassName(this.options.navItemClass);
};

Slider.prototype._getPreparedTemplateData = function () {
  var i, templateData;

  templateData = {navItems: []};

  for (i = 0; i < this.swipe.getNumSlides(); i++) {
    templateData.navItems.push({
      index: i
    });
  }

  if (templateData.navItems.length) {
    templateData.navItems[0].first = true;
  }

  templateData.navClass = this.options.navClass;
  templateData.navItemClass = this.options.navItemClass;
  templateData.navItemActiveClass = this.options.navItemActiveClass;
  templateData.arrows = this.options.arrows;
  templateData.nextArrowClass = this.options.nextArrowClass;
  templateData.prevArrowClass = this.options.prevArrowClass;
  templateData.isMobileNavigation = !(templateData.navItems.length > 1); //if there is only one item don't show navigation


  return templateData;
};

Slider.prototype._bindNavigationEvents = function () {
  var buttons;
  this.navigation.addEventListener('click', this._navigationClicked.bind(this), false);
  if (this.options.arrows) {

    buttons = this.options.mainEl.querySelectorAll('[data-arrow]');
    for (var i = 0; i < buttons.length; i++) {
      buttons[i].addEventListener('click', this._moveSlide.bind(this), false);
    }
  }
};

Slider.prototype._navigationClicked = function (e) {
  var index;
  e.preventDefault();

  if (e.target.className === this.options.navItemClass || e.target.tagName === 'IMG') {

    index = e.target.hasAttribute('data-nav-index') ?
        e.target.getAttribute('data-nav-index') :
        domHelper.closest(e.target, '[data-nav-index]').getAttribute('data-nav-index');

    index = parseInt(index, 10);

    this.swipe.slide(index);
  }
};

Slider.prototype._moveSlide = function (e) {
  var method = e.currentTarget.getAttribute('data-arrow');
  e.preventDefault();

  this.swipe[method]();
  if (this.options.autoplay) {
    this._restartAutoPlay();
    this._reactivateNavigationItems();
  }
};

Slider.prototype._onSlide = function (index) {
  this.prevSlideIndex = this.currentSlideIndex;
  this.currentSlideIndex = index;

  this._reactivateNavigationItems();
  this.options.onSlide(this.prevSlideIndex, index);
};

Slider.prototype._onTransitionEnd = function (index) {
  this.options.onTransitionEnd(this.prevSlideIndex, index);
};

Slider.prototype._reactivateNavigationItems = function () {
  domHelper.removeClass(this.navigationItems[this.prevSlideIndex], this.options.navItemActiveClass);
  domHelper.addClass(this.navigationItems[this.currentSlideIndex], this.options.navItemActiveClass);
};

// "public" methods
Slider.prototype.getIndex = function () {
  return this.swipe.getPos();
};

Slider.prototype.setIndex = function (index) {
  this.swipe.slide(index);
  this.currentSlideIndex = index;
  this._reactivateNavigationItems();
};

Slider.prototype.getPrevIndex = function () {
  return this.prevSlideIndex;
};

Slider.prototype._startAutoPlay = function () {
  this.autoPlayToken = setInterval(function () {
    this.swipe.next();
  }.bind(this), this.options.playSpeed);

};

Slider.prototype._restartAutoPlay = function () {
  clearInterval(this.autoPlayToken);
  this._startAutoPlay();
};

Slider.prototype.destroy = function () {
  [].forEach.call(document.querySelectorAll('.' + this.options.navClass), (el) => {
    el.outerHTML = '';
  });
  this.swipe.kill();
  this.isInited = false;
};

/*
  Event callbacks:
    - options.onSlide
    - options.onTransitionEnd
*/
export default Slider;

