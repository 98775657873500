import React from 'react';
import ReactDOM from 'react-dom';
import PubSub from 'pubsub';
import Cookies from 'js-cookie';
import { Promobar } from '@juniqe/promobar';
import { EVENT_DY_PROMOBAR_INIT_V7_GENERAL, EVENT_DY_PROMOBAR_INIT_V7_GENERAL_GOOGLE_PRICE } from 'Constants/event';
// import { dyPromobarV7 } from '../../__mocks__/dy';

const googleDynamicPriceToken = Cookies.get('gDiscount');

export const getGoogleDynamicDiscountPromobarData = (dyData, decodedToken) => {
  const { payload } = decodedToken;
  const percentTemplate = '{dp}';
  const discountCodeTemplate = '{dc}';

  const { bodyCopyDesktop, bodyCopyDesktopMinimal, bodyCopyMobile } = dyData;
  const newBodyCopyDesktop = bodyCopyDesktop
    .replace(percentTemplate, payload.dp)
    .replace(discountCodeTemplate, payload.dc);

  const newBodyCopyDesktopMinimal = bodyCopyDesktopMinimal
    .replace(percentTemplate, payload.dp)
    .replace(discountCodeTemplate, payload.dc);
  const newBodyCopyMobile = bodyCopyMobile
    .replace(percentTemplate, payload.dp)
    .replace(discountCodeTemplate, payload.dc);

  return {
    ...dyData,
    bodyCopyDesktop: newBodyCopyDesktop,
    bodyCopyDesktopMinimal: newBodyCopyDesktopMinimal,
    bodyCopyMobile: newBodyCopyMobile,
    promocode: payload.dc
  };
};

const renderPromobar = dyProps => {
  ReactDOM.render(<Promobar dyProps={dyProps} />, document.getElementById('promobar'));
};

if (googleDynamicPriceToken) {
  PubSub.subscribe(EVENT_DY_PROMOBAR_INIT_V7_GENERAL_GOOGLE_PRICE, dyData => {
    const decodedToken = JSON.parse(googleDynamicPriceToken);
    const decoratedDyData = getGoogleDynamicDiscountPromobarData(dyData, decodedToken);
    renderPromobar(decoratedDyData);

    console.log('SUBSCRIBED TO GOOGLE PROMOBAR');
  });
} else {
  PubSub.subscribe(EVENT_DY_PROMOBAR_INIT_V7_GENERAL, renderPromobar);
}

// Remove for production
// renderPromobar(dyPromobarV7);
