'use strict';

import Modal from 'Common/modal/modal';
import locModalTmpl from './exitIntentModal.mst.html';
import PubSub from 'pubsub';

function init(config) {
  let modal,
      body = document.body,
      overlay,
      urlRedirect,
      btnClose,
      btnStay;

  function _onModalCreate() {
    overlay = document.querySelector('.modal--localization');
    urlRedirect = body.getAttribute('data-geo-redirect');
    btnClose = overlay.querySelector('[data-modal-close]');
    btnStay = overlay.querySelector('[data-modal-stay]');
    if (btnStay) {
      btnStay.addEventListener('click', () => { modal.close() });
    }
  }

  modal = new Modal({
    className: 'modal--localization',
    body: locModalTmpl(config),
    onCreate: _onModalCreate
  });

  modal.open();
}

PubSub.subscribe('dy.exitIntentPopup', config => {
  init(config);
});
