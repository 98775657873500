import catalogFeedbackTmpl from './catalogFeedback.mst.html';
import domHelper from "Helpers/domHelper";
import httpHelper from "Helpers/httpHelper";
import PubSub from 'pubsub';
import CustomSelect from 'Common/customSelect/customSelect';
import global from 'global';
import { EVENT_DY_SEARCH_FEEDBACK } from 'Constants/event';

const DEFAULT_CONFIG = {
  placeholder: '#feedbackPlaceholder'
};

class Feedback {
  constructor(config) {
    this.config = config;
    this.element = document.querySelector(config.placeholder);
    if (!this.element) return;
    this.element.innerHTML = this.render();
    this.cacheDom();
    this.bindListeners();
  }

  render() {
    let fakeDiv = document.createElement('div');
    fakeDiv.innerHTML = catalogFeedbackTmpl(window._i18n.searchFeedback);
    return fakeDiv.innerHTML;
  }

  cacheDom() {
    this.steps = [].slice.call(this.element.querySelectorAll('[data-jnq-step]'));
    this.selectBox = this.element.querySelector('select#categoryCode');
    this.btnYes = this.element.querySelector('.catalog-feedback__btn--yes');
    this.btnNo = this.element.querySelector('.catalog-feedback__btn--no');
    this.form = this.element.querySelector('.catalog-feedback__form');

    if (global.isDesktop) {
      CustomSelect.init();
    }
  }

  bindListeners() {
    this.btnYes.addEventListener('click', () => this.gotoStep(2, 'step-yes'));
    this.btnNo.addEventListener('click', () => this.gotoStep(2, 'step-no'));
    this.selectBox.addEventListener('change', () => {
      this.element.querySelector('.optional-block').style.display = 'block';
    });
    this.form.addEventListener('submit', e => {
      e.preventDefault();
      let formElements = e.target.elements,
        categoryCodeValue = formElements.namedItem("categoryCode").value,
        answerTextValue = formElements.namedItem("answerText").value,
        payload = { resultsFound: !Boolean(categoryCodeValue) };

      if (answerTextValue) payload.answerText = answerTextValue;
      if (categoryCodeValue) payload.categoryCode = categoryCodeValue;

      httpHelper.ajax('/api/feedback/productResults', {
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify(payload),
        success: () => {
          this.gotoStep(3);
        }
      });
    });
  }

  gotoStep(number, className) {
    this.steps.forEach(step => {
      if (Number(step.getAttribute('data-jnq-step')) === number) {
        domHelper.removeClass(step, 'is-hidden');
        className && domHelper.addClass(step, className);
      } else {
        domHelper.addClass(step, 'is-hidden');
      }
    })
  }
}

PubSub.subscribe(EVENT_DY_SEARCH_FEEDBACK, () => {
  new Feedback(DEFAULT_CONFIG);
});

