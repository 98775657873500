import ReactDOM from "react-dom";
import React, { useEffect, useState } from "react";
import { EVENT_DY_RETAILS_DISPLAY } from "../../constants/event";
import DOM from 'Helpers/domHelper';
import cookieHelper from 'Helpers/cookieHelper';
import funcHelper from "Helpers/funcHelper";

const COOKIE_NAME = 'retailDisplay';

const TUICampaign = ({ landingPage, idleTimer, countdownTimer, isActive }) => {
  const [position, setPosition] = useState([0, 0]);
  const [rel, setRel] = useState([0, 0, 0, 0]);
  const [dragging, setDragging] = useState(false)

  const [tick, setTick] = useState(0)
  const [idle, setIdle] = useState(0)
  const [countdown, setCountdown] = useState(0)

  useEffect(() => {
    document.addEventListener('keypress', resetTimer)
    document.addEventListener('mousedown', resetTimer)
    document.addEventListener('mousemove', onMouseMove)
    document.addEventListener('touchstart', resetTimer)

    return () => {
      document.removeEventListener('keypress', resetTimer)
      document.removeEventListener('mousedown', resetTimer)
      document.removeEventListener('mousemove', onMouseMove)
      document.removeEventListener('touchstart', resetTimer)
    }
  })

  useEffect(() => {
    if (!isActive) {
      return;
    }
    const interval = setInterval(() => setTick(v => v + 1), 1000)
    return () => clearInterval(interval)
  }, [isActive]);

  useEffect(() => {
    if (!isActive) {
      return;
    }

    if (countdown > 0) {
      setCountdown(v => v - 1)

      if (countdown === 1) {
        cleanupAndRedirect()
      }
    } else {
      if (idle === parseInt(idleTimer) && countdown === 0) {
        setIdle(0)
        setCountdown(parseInt(countdownTimer))
      } else {
        setIdle(v => v + 1)
      }
    }
  }, [tick, isActive]);

  const resetTimer = () => {
    if (idle !== 0) setIdle(0)
    if (countdown !== 0) setCountdown(0)
  }

  const cleanupAndRedirect = () => {
    DOM
      .get("/retails/cleanAll")
      .then(_ => location.href = `${location.origin}${landingPage}`)
  }

  const onMouseDown = (e) => {
    if (e.button !== 0) return

    e.stopPropagation()
    e.preventDefault()

    const x = e.pageX - e.clientX + e.nativeEvent.offsetX
    const y = e.pageY - e.clientY + e.nativeEvent.offsetY
    const w = e.target.offsetWidth
    const h = e.target.offsetHeight

    setRel([x, y, w, h])
    setDragging(true)
  }

  const onMouseUp = (e) => {
    e.stopPropagation()
    e.preventDefault()

    setDragging(false)

    const x = e.pageX - e.clientX + e.nativeEvent.offsetX
    const y = e.pageY - e.clientY + e.nativeEvent.offsetY

    if (x === rel[0] && y === rel[1]) {
      cleanupAndRedirect()
    }
  }

  const onMouseMove = (e) => {
    e.stopPropagation()
    e.preventDefault()
    resetTimer()

    if (dragging) {
      const [x, y, w, h] = rel
      const dx = e.pageX - x
      const dy = e.pageY - y

      const xx = Math.min(Math.max(dx, 0), window.innerWidth - w - 15)
      const yy = Math.min(Math.max(dy, 0), window.innerHeight - h)

      setPosition([xx, yy])
    }
  }

  const details = (countdown) => {
    const { retails } = window._i18n;

    if (countdown > 0) {
      return funcHelper.getTransaltion(retails.sessionEnds, countdown)
    } else {
      return retails.removeSession
    }
  }

  const touchHandler = (event) => {
    const touches = event.changedTouches;
    const first = touches[0];
    let type = "";
    switch (event.type) {
      case "touchstart":
        type = "mousedown";
        break;
      case "touchmove":
        type = "mousemove";
        break;
      case "touchend":
        type = "mouseup";
        break;
      default:
        return;
    }

    const simulatedEvent = document.createEvent("MouseEvent");
    simulatedEvent.initMouseEvent(type, true, true, window, 1,
      first.screenX, first.screenY,
      first.clientX, first.clientY, false,
      false, false, false, 0/*left*/, null);

    first.target.dispatchEvent(simulatedEvent);
    event.preventDefault();
  }

  return (
    <div
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onTouchStart={touchHandler}
      onTouchMove={touchHandler}
      onTouchEnd={touchHandler}
      className="retails"
      style={{ left: position[0], top: position[1] }}
    >
      <div className="retailsInner">
        <div className="border"/>
        <div className="details">
          <i className="removeIcon i-retails-bin"/>
          <span className="info">{details(countdown)}</span>
        </div>
        <div className="border"/>
      </div>
    </div>
  )
}

const init = () => {
  const params = JSON.parse(cookieHelper.getCookie(COOKIE_NAME))
  const { landingPage } = params
  const currentPage = location.href.split(location.origin).join('')

  const wrapperDiv = document.createElement("div");
  ReactDOM.render(
    <TUICampaign {...params} isActive={currentPage !== landingPage} />,
    wrapperDiv
  );
  document.body.appendChild(wrapperDiv);
}

if (cookieHelper.getCookie(COOKIE_NAME)) {
  init()
} else {
  PubSub.subscribe(EVENT_DY_RETAILS_DISPLAY, (params) => {
    cookieHelper.setCookie(COOKIE_NAME, JSON.stringify(params))
    init()
  });
}
