'use strict';

/**
 * Functional helper, a toolchain to work with arrays/objects and misc browser APIs.
 * @module helpers/funcHelper
 */

import Promise from 'bluebird';
let copyToClipboardTimeout;

function mixin() {
  let resultingObject = {};

  for (let i = 0; i < arguments.length; i++) {
    for (let key in arguments[i]) {
      if (arguments[i].hasOwnProperty(key)) {
        resultingObject[key] = arguments[i][key];
      }
    }
  }

  return resultingObject;
}

function deepClone(obj) {
  var clone = {};
  for (var i in obj) {
    if (obj[i] != null && typeof obj[i] == 'object') {
      clone[i] = cloneObject(obj[i]);
    } else {
      clone[i] = obj[i];
    }
  }
  return clone;
}

function copyToClipboard(text) {
  let textArea = document.createElement('textarea');
  let result;

  textArea.style.position = 'fixed';
  textArea.style.top = 0;
  textArea.style.left = 0;
  textArea.style.width = '2em';
  textArea.style.height = '2em';
  textArea.style.opacity = '0';

  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.select();

  try {
    document.execCommand('copy');
    result = true;
  } catch (err) {
    console.warn("Browser doesn't support copying to clipboard");
    result = false;
  }

  document.body.removeChild(textArea);

  return result;
}

function equalArrays(arr1, arr2) {
  let containsAll = (a, b) => b.every(bItem => a.includes(bItem));

  return containsAll(arr1, arr2) && containsAll(arr2, arr1);
}

function throttle(func, wait, options) {
  var context, args, result;
  var timeout = null;
  var previous = 0;
  if (!options) options = {};
  var later = function () {
    previous = options.leading === false ? 0 : Date.now();
    timeout = null;
    result = func.apply(context, args);
    if (!timeout) context = args = null;
  };
  return function () {
    var now = Date.now();
    if (!previous && options.leading === false) previous = now;
    var remaining = wait - (now - previous);
    context = this;
    args = arguments;
    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      previous = now;
      result = func.apply(context, args);
      if (!timeout) context = args = null;
    } else if (!timeout && options.trailing !== false) {
      timeout = setTimeout(later, remaining);
    }
    return result;
  };
}

function debounce(fn, delay) {
  var timer = null;
  return function () {
    var context = this,
      args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  };
}

const parsePrice = str => {
  switch (typeof str) {
    case 'string':
      let split = str.split(' ');
      let number = Number(split.pop());
      return {
        value: Number.isInteger(number) ? number : number.toFixed(2),
        isFrom: split.length > 1,
        fromLabel: split.length > 0 ? split[0] : ''
      };
    case 'number':
      return {
        value: Number.isInteger(str) ? str : str.toFixed(2),
        isFrom: false,
        fromLabel: ''
      };
    default:
      throw 'Input is not a string or a number';
  }
};

const normalizePrice = price => {
  // extract numbers from a price e.g. "€ 59,99" => "59.99"
  const priceAsString = price
    ? price
        .toString()
        .replace(/,/g, '.')
        .replace(/[^..\d]*/g, '')
        .replace(/^(\d+)(\.?)(\d+)(\.)(\d+)$/g, '$1$3.$5')
    : '';

  return parseFloat(priceAsString) || 0;
};

const waitForGlobalVar = name =>
  new Promise(resolve => {
    const waiter = setInterval(() => {
      const element = window[name];

      if (typeof element !== 'undefined') {
        clearInterval(waiter);
        resolve(element);
      }
    }, 100);
  });

const cloneObject = source => JSON.parse(JSON.stringify(source));

function handleCopyToClipboard(textToCopy, callback) {
  let viewport = document.querySelector('meta[name=viewport]');
  let originalContent = viewport.getAttribute('content');
  // Prevent iOS from zooming on small screen devices
  viewport.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1');
  if (copyToClipboard(textToCopy)) {
    if (typeof callback == 'function') {
      callback();
    }
    viewport.setAttribute('content', originalContent);
  }
}

function getTransaltion(translationText, ...args) {
  if (!translationText) return '';
  // find all scala "way" parameters and replace them with provided params
  return translationText.replace(/{\d}/g, function (match) {
    // cut off brackets '{' and '}'
    const paramNumber = Number(match.replace(/[{}'']/g, ''));
    return args[paramNumber] || '';
  });
}

export default {
  mixin: mixin,
  equalArrays: equalArrays,
  copyToClipboard: copyToClipboard,
  handleCopyToClipboard: handleCopyToClipboard,
  parsePrice: parsePrice,
  normalizePrice,
  throttle: throttle,
  debounce: debounce,
  waitForGlobalVar: waitForGlobalVar,
  cloneObject: cloneObject,
  deepClone,
  getTransaltion
};
