import global from 'global';
import j from 'Helpers/domHelper';
import tplHelper from 'Helpers/tplHelper';
import domHelper from 'Helpers/domHelper';
import defaultProductTpl from './defaultProduct.mst.html';
import pubsub from 'pubsub';


var openedTimeout = null;

// config will be bouded to this function as context
function init() {
  /* jshint validthis: true */
  _cookPopover(this);
}

function refresh() {
  /* jshint validthis: true */
  return _cookPopover(this);
}

function _cookPopover(config) {
  return _fetchDataForTemplate(config)
      .then(function (data) {
        render.bind(config)(data);
        return data;
      });
}

function _fetchDataForTemplate(config) {
  return j.getJSON(config.tplUrl)
      .then(function (data) {
        if (data.items.length) {
          pubsub.publish(config.moduleName + ':fetched', data.items);
        }
        config.items = data.items;
        return data;
      });
}

// config will be bouded to this function as context
function render(json) {
  /* jshint validthis: true */
  var self = this;

  json = _prepareTplData(json);

  tplHelper.process(self.popoverContentUrl, json, function (tpl) {
    var popoverContainer = document.getElementById(self.containerId);
    popoverContainer.innerHTML = tpl;

    _setState(self, json);

    setTimeout(function () {
      window.$EchoJS.render();
    }, 0);
  });
}

function _setState(config, json) {
  var parentEl = document.getElementById(config.parentId),
      amountEl = parentEl.getElementsByClassName('js-header__amount')[0];

  if (json.items.length) {
    j.addClass(parentEl, 'is-active');
    j.removeClass(amountEl, 'is-hidden');
  } else {
    j.removeClass(parentEl, 'is-active');
    j.addClass(amountEl, 'is-hidden');
  }

  j.innerText(amountEl, json.numberOfItems);
}

function _prepareTplData(data) {
  data.items = data.items.map(function (product) {
    product.currency = global.shopCurrencyCode;
    product.tpl = defaultProductTpl(product);

    return product;
  });

  return data;
}

function open(cb, isDY, timeout) {
  timeout = parseInt(timeout) || 3000;
  isDY = isDY || false;
  /* jshint validthis: true */
  var el = document.getElementById(this.parentId),
      isOpenCssClass = (isDY) ? "js-is-opened__dy" : "is-opened";

  j.addClass(el, isOpenCssClass);

  /* jshint -W030 */
  cb && cb(el);

  if (openedTimeout) {
    clearTimeout(openedTimeout);
  }

  openedTimeout = setTimeout(function () {
    j.removeClass(el, isOpenCssClass);
  }, timeout);
}

function close(isDY) {
  isDY = isDY || false;
  var el = document.getElementById(this.parentId),
      isOpenCssClass = (isDY) ? "js-is-opened__dy" : "is-opened";

  j.removeClass(el, isOpenCssClass);
}

function toggleActiveState() {
  /* jshint validthis: true */
  j.toggleClass(
      document.getElementById(this.parentId),
      'is-active');
}

function contains(productId) {
  /* jshint validthis: true */
  return this.items.some(function (item) {
    return productId === item.entityId.toString();
  });
}

function showOverlay() {
  /* jshint validthis: true */
  return this.items.some(function (item) {
    return productId === item.entityId.toString();
  });
}

export default function (config) {
  return {
    init: init.bind(config),
    refresh: refresh.bind(config),
    render: render.bind(config),
    open: open.bind(config),
    toggleActiveState: toggleActiveState.bind(config),
    contains: contains.bind(config),
    close: close.bind(config)
  };
};

