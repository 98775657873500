import domHelper from 'Helpers/domHelper';

const MIN_STICKY_PERCENTAGE = 33.33;
const BACK_TO_TOP_CLASS = 'show-back-to-top';
const BACK_TO_TOP_CLASS_PDP = 'show-back-to-top--pdp';

/* Toggle back to top button*/
function toggleBackToTop(bodyElement) {
  let scrollPercentage = getScrollPercentage();

  let toggleCssClass = (!!document.querySelector('#add-to-cart')) ? BACK_TO_TOP_CLASS_PDP : BACK_TO_TOP_CLASS;

  domHelper.toggleClass(bodyElement, toggleCssClass, scrollPercentage > MIN_STICKY_PERCENTAGE);
}
/* Get scrolling percentage*/
function getScrollPercentage() {
  let documentElement = document.documentElement;
  let documentBody = document.body;
  let scrollTop = 'scrollTop';
  let scrollHeight = 'scrollHeight';

  return (documentElement[scrollTop]||documentBody[scrollTop]) / ((documentElement[scrollHeight]||documentBody[scrollHeight]) - documentElement.clientHeight) * 100;
}

export default {
  getScrollPercentage: getScrollPercentage,
  toggleBackToTop: toggleBackToTop,
};

