import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import Cookies from 'js-cookie';
import PubSub from 'pubsub';
import domHelper from 'Helpers/domHelper';
import { NavigationContainer, PopupComponent, router } from '@juniqe/navigation';
import { EVENT_DY_NAVBAR_AB_TEST, EVENT_DY_PROMOBAR_INIT_V6_GENERAL } from 'Constants/event';
import { ConnectedCartPopup, AlertsContainer } from '@juniqe/cart';
import { MuiThemeProvider } from '@material-ui/core/styles';
import StickyThings from 'Common/sticky/stickyThings';
import AjaxCart from 'Common/headerPopovers/ajaxCart';
import Wishlist from 'Common/headerPopovers/wishlist';
import SearchMobile from 'Common/headerPopovers/searchMobile';
import MainMobileNavigation from 'Common/mainMobileNavigation';
import MainDesktopNavigation from 'Common/mainDesktopNavigation';
import global from 'global';
import { Processing } from './processing';
import { theme } from '../theme';
import store from '../../reduxStore';
import config from '../../config';

let fallbackTimeout = null;
const header = document.getElementById('header');
const SESSION_NAVBAR_KEY = 'ab_test_navbar';
const DY_NAVBAR_TYPE = 'dy';

const categoryData = window?._pageMeta?.categoryDataLayerV2;

const getActiveVerticalId = catData => {
  if (catData?.categoryType === 'gifting') {
    return 192;
  }

  return Number(categoryData?.productVerticalId) || 189;
};

const activeVerticalId = getActiveVerticalId(categoryData);

const Navbar = () => {
  return (
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <Processing />
        <AlertsContainer />
        <PopupComponent activeVerticalId={activeVerticalId} appName="Shop" router={router} />
        <ConnectedCartPopup appName="Shop" className="cart-popup" overlayColor="rgba(255, 255, 255, 0.0)" centered />

        <div style={{ margin: '0 auto', maxWidth: '1400px' }}>
          <NavigationContainer
            productId={3308930}
            activeVerticalId={activeVerticalId}
            dyApiKey={8766575}
            router={router}
          />
        </div>
      </MuiThemeProvider>
    </Provider>
  );
};

const renderMagentoNavbar = () => {
  domHelper.removeClass(header, 'header--hidden');

  StickyThings.init();
  AjaxCart.init();
  Wishlist.init();

  if (global.isDesktop) {
    MainDesktopNavigation.init();
  } else {
    SearchMobile.init();
    MainMobileNavigation.init();
  }
};

const renderDYnavbar = () => {
  const { body } = document;
  const firstEl = body.firstElementChild;

  // Remove general Promobar
  if (domHelper.hasClass(firstEl, 'agrsv-wrapper')) {
    console.log('General promobar removed'); // eslint-disable-line
    body.removeChild(firstEl);
  }

  ReactDOM.render(<Navbar />, header);
  domHelper.removeClass(header, 'header--hidden');

  // Hide logo on mobile as we already have it on the new promobar
  const mobileLogo = document.querySelector('.home-mobile-logo');
  domHelper.addClass(mobileLogo, 'home-mobile-logo--hidden');
  // Republish promobar if it was published before navbar was rendered;
  setTimeout(() => {
    if (window.GENERAL_PB_VARS) {
      console.log('GENERAL PROMOBAR REPUBLISHED'); // eslint-disable-line
      PubSub.publish(EVENT_DY_PROMOBAR_INIT_V6_GENERAL, window.GENERAL_PB_VARS);
    }
  }, 1000);
};

const setNavbarCookie = ({ navbar } = {}) => {
  console.log('SET NEW NAVBAR SESSION COOKIE: ', navbar);
  Cookies.set(SESSION_NAVBAR_KEY, navbar);
};

const renderNavbarByType = type => {
  if (type === DY_NAVBAR_TYPE) {
    renderDYnavbar();
  } else {
    renderMagentoNavbar();
  }
};

const renderNavbarOnEvent = ({ navbar } = {}) => {
  clearTimeout(fallbackTimeout);
  renderNavbarByType(navbar);
};

if (config.dyNavbarABtest) {
  const navbarSession = Cookies.get(SESSION_NAVBAR_KEY);

  // Always override cookie for the next session if navbar type changed
  // DY event can be published later after fallback was render, so do not be tempted to set the cookie just from the renderNavbarOnEvent function
  PubSub.subscribe(EVENT_DY_NAVBAR_AB_TEST, setNavbarCookie);

  if (navbarSession) {
    console.log('RENDER NAVBAR FROM COOKIE SESSION: ', navbarSession); // eslint-disable-line
    renderNavbarByType(navbarSession);
  } else {
    console.log('SUBSCRIBED TO NAVBAR DY EVENT'); // eslint-disable-line

    fallbackTimeout = setTimeout(() => {
      console.log('RENDER MAGENTO NAVBAR FALLBACK'); // eslint-disable-line
      renderMagentoNavbar();
    }, 3000);

    PubSub.subscribe(EVENT_DY_NAVBAR_AB_TEST, renderNavbarOnEvent);
  }
} else {
  renderMagentoNavbar();
}
