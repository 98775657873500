import domHelper from 'Helpers/domHelper';

function getBreadcrumbs() {
  let breadcrumbTemplate = document.querySelector('.breadcrumb-template');

  if (breadcrumbTemplate) {
    let urlKey = breadcrumbTemplate.getAttribute('data-url-key');

    if (urlKey) {
      let getBreadcrumbsApiUrl = `/api/article/breadcrumbs/${urlKey}`;

      domHelper.ajax(getBreadcrumbsApiUrl, {
        json: false,
        error: function (err) {
          console.log('error = ', err);
        },
        success: function (response) {
          breadcrumbTemplate.innerHTML = response;
        }
      });
    }
  }
}

function getArticleTags() {
  let tagsTemplate = document.querySelector('.article-tags');

  if (tagsTemplate) {
    let urlKey = tagsTemplate.getAttribute('data-url-key');

    if (urlKey) {
      let getArticleTagsApiUrl = `/api/article/tags/${urlKey}`;

      domHelper.ajax(getArticleTagsApiUrl, {
        json: false,
        error: function (err) {
          console.log('error = ', err);
        },
        success: function (response) {
          tagsTemplate.innerHTML = response;
        }
      });
    }
  }
}

function _init() {
  getBreadcrumbs();
// TODO MER-794 to implement styling uncomment the line below
//  getArticleTags();
}


export default {
  init: _init
}