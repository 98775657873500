import React, { useState } from 'react';
import axios from 'axios';
import isEmail from 'validator/es/lib/isEmail';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { domainToStore } from '../../helpers/domain';
import config from '../../config';

const ArtistApplicationForm = () => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm();

  const [isSubmitted, setSubmitted] = useState(false);
  const [isSubmitError, setSubmitError] = useState(false);

  const onSubmit = data => {
    setSubmitError(false);

    const postData = { ...data, store: domainToStore() };

    axios
      .post(config.emailCollectionUrl, postData)
      .then(() => {
        setSubmitted('true');
      })
      .catch(e => {
        console.error('Request failed', e);
        setSubmitError(true);
      });
  };

  return isSubmitted ? (
    <p className="artist-app-success">{t('gen-form-success')}</p>
  ) : (
    <form onSubmit={handleSubmit(onSubmit)} className="artist-app-form">
      {/* Artist name */}
      <div className="artist-app-form__field-container">
        <label htmlFor="artist-name" className="artist-app-form__label">
          {t('gen-first-name').replace(':', '*')}
        </label>
        <input
          id="artist-name"
          className="artist-app-form__field"
          placeholder="Sophia"
          {...register('name', {
            maxLength: { value: 50, message: t('checkout-max-characters-count-validation', { limit: 50 }) },
            required: t('checkout-name-validation')
          })}
        />
        {errors?.name && <span className="artist-app-field__error">{errors.name?.message}</span>}
      </div>

      {/* Artist email */}
      <div className="artist-app-form__field-container">
        <label htmlFor="artist-email" className="artist-app-form__label">
          {t('gen-promobar-email')}
        </label>

        <input
          type="text"
          id="artist-email"
          className="artist-app-form__field"
          placeholder="sophia.coppola@email.com"
          {...register('email', {
            validate: v => isEmail(v) || t('checkout-email-validation')
          })}
        />
        {errors?.email && <span className="artist-app-field__error">{errors.email?.message}</span>}
      </div>

      <input type="submit" className="artist-app-form__submit" value={t('gen-email-submit')} />
      {isSubmitError && <p className="artist-app-form__error">{t('checkout-server-request-error')}</p>}
    </form>
  );
};

const formRoot = document.getElementById('artist-app-form-root');

if (formRoot) {
  ReactDOM.render(<ArtistApplicationForm />, formRoot);
}
