'use strict';

import global from 'global';
import j from 'Helpers/domHelper';
import cookieHelper from 'Helpers/cookieHelper';
import funcHelper from 'Helpers/funcHelper';
import httpHelper from 'Helpers/httpHelper';
import overlayTemplate from './promoBarEmail.mst.html';
import stickyThingsMobile from 'Common/sticky/stickyThingsMobile';
import stickyThingsDesktop from 'Common/sticky/stickyThingsDesktop';
import PubSub from 'pubsub';
import sha256 from 'Common/cryptography/sha256';
import {
  PAGE_FAQ,
  PAGE_CART,
  PAGE_CHECKOUT,
  PAGE_MYACCOUNT,
  PAGE_REFER_FRIEND,
  PAGE_RETURNS,
  PAGE_NEWSLETTER_SUCCESS,
  PAGE_NOT_FOUND
} from 'Constants/page';
import {
  EVENT_PROMOBAR_RENDER,
  EVENT_DY_PROMOBAR_INIT_V6_EMAIL
} from 'Constants/event';
import {
  REGEXP_EMAIL,
  TIME_SECOND,
  TIME_MINUTE,
  TIME_HOUR,
  TIME_DAY,
  TIME_YEAR
} from 'Constants/misc';
import {
  URL_HOSTNAME,
} from 'Constants/url';

const BASE_CLASS = 'agrsv-promo';
const STATES = {
  SIMPLE: `${BASE_CLASS}--simple`,
  INITIAL: `${BASE_CLASS}--init`,
  INITIAL_MINI: `${BASE_CLASS}--init-mini`,
  SUBMITTED: `${BASE_CLASS}--submitted`,
  FINAL: `${BASE_CLASS}--final`,
  HIDDEN: `is-hidden`
};
const COOKIE_PREFIX = 'jnq_pb_';
const IS_PROHIBITED_PAGE = PAGE_CHECKOUT || PAGE_FAQ || PAGE_MYACCOUNT || PAGE_REFER_FRIEND || PAGE_RETURNS || PAGE_NEWSLETTER_SUCCESS || (PAGE_NOT_FOUND && global.isMobile);

// Variables from DY
let dyVars;
let translations;
let getVars = [];

let targetNode = document.querySelector('.header');
let promo = {
  wrapperNode: undefined,
  minimizeButtonNode: undefined,
  maximizeButtonNode: undefined,
  emailFormNode: undefined,
  emailInputNode: undefined,
  emailSubmitButtonNode: undefined,
  codeCopiedNode: undefined,
  timerNode: undefined,
  closeButton: undefined,
  idInputNode: undefined,
  termsPopup: undefined,
  termsCloseButton: undefined,
  termsBackground: undefined,
  videoPopup: undefined,
  videoContent: undefined,
  overlayTriggers: undefined,

  inject: function () {
    let tempNode = document.createElement('div');
    let compiledOptions = funcHelper.mixin(dyVars, translations, dyVars[dyVars.renderPromo][global.isDesktop ? 'desktop' : 'mobile']);
    let contentText;

    if (cookieHelper.getCookie(`${COOKIE_PREFIX}${dyVars.code}_collapsed`)) {
      compiledOptions.initialState = 'INITIAL_MINI';
    }
    let overlayDivId = compiledOptions.overlayDivId;
    let overlayContentElement = document.querySelector(`#${overlayDivId}`);
    compiledOptions.overlayContent = (overlayContentElement) ? overlayContentElement.innerHTML : '';
    compiledOptions.backgroundColor = compiledOptions.backgroundColor || '#EEE';
    contentText = overlayTemplate(compiledOptions);

    if (getVars['source'] === 'raf') {
      contentText = contentText.replace('{referee_email}', getVars['referee_email']);
      contentText = contentText.replace('{referee_voucher}', getVars['referee_voucher']);
      contentText = contentText.replace('{referer_name}', getVars['referer_name']);
    }

    tempNode.innerHTML = contentText;

    targetNode.parentNode.insertBefore(tempNode.firstChild, targetNode);

    if (!global.isMobile) {
      document.body.appendChild(j.newNode('style', {}, '.agrsv-promo { z-index: 6; }'));
    }

    j.addClass(document.body, 'promobar-shown');
    PubSub.publish(EVENT_PROMOBAR_RENDER);
  },
  show: function () {
    j.removeClass(this.wrapperNode, 'is-hidden');
    j.addClass(document.body, 'promobar-shown');
    this.setHeaderOffsetTop();
  },
  hide: function () {
    this.state('HIDDEN');
    j.removeClass(document.body, 'promobar-shown');
    this.setHeaderOffsetTop();
  },
  minimize: function (e) {
    e.preventDefault();
    e.stopPropagation();

    this.state('INITIAL_MINI');
    this.setHeaderOffsetTop();
  },
  maximize: function (e) {
    e.preventDefault();
    e.stopPropagation();

    this.state('INITIAL');
    this.setHeaderOffsetTop();
  },
  submitEmail: function (e) {
    e.preventDefault();
    e.stopPropagation();

    let email = this.emailInputNode.value;
    let source = 'promo_bar';

    let emarsysData = funcHelper.mixin(dyVars, translations, dyVars[dyVars.renderPromo][global.isDesktop ? 'desktop' : 'mobile']);
    let artist = emarsysData.artist;
    let campaign = emarsysData.campaign;
    let productVertical = emarsysData.productVertical;
    let productType = emarsysData.productType;
    let theme = emarsysData.theme;
    let campaignVoucher = emarsysData.campaignVoucher;
    let campaignData = {
       artist,
       campaign,
       productVertical,
       productType,
       theme,
       campaignVoucher
    };

    if (REGEXP_EMAIL.test(email)) {
      //TODO: use the /newsletter/subscribe endpoint instead like in newsletterSubscribe.js
      httpHelper.ajax('/newsletter/subscribe', {
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify({
          email,
          source,
          campaignData
        }),
        success: function () {
          DY.API("event", {name: 'Promobar-NL-Opted-in', properties: {hashedEmail: sha256.hash(email.toLowerCase())}});
          promo.state('SUBMITTED');
          setTimeout(promo.state.bind(promo, 'FINAL'), 3000);
        }
      });
    }
    else {
      this.showEmailError();
    }
  },
  hideEmailError: function (e) {
    e.stopPropagation();

    j.removeClass(this.emailFormNode, 'agrsv-promo__subscribe-form--invalid');
  },
  showEmailError: function () {
    j.addClass(this.emailFormNode, 'agrsv-promo__subscribe-form--invalid');
  },
  cookieState: function (state) {
    if (state) {
      // Setter
      if (STATES[state]) {
        cookieHelper.setCookie(`${COOKIE_PREFIX}${dyVars.code}`, state, end, URL_HOSTNAME);
      }
      else {
        console.warn(`State '${state}' is not found. Please, check the code`);
      }
    }
    else {
      // Getter
      if (cookieHelper.getCookie(`${COOKIE_PREFIX}newsletter`)) {
        return 'HIDDEN';
      }

      return cookieHelper.getCookie(`${COOKIE_PREFIX}${dyVars.code}`);
    }
  },
  state: function (state) {
    if (state) {
      // Setter
      if (STATES[state]) {
        this.wrapperNode.className = `${BASE_CLASS} ${STATES[state]}`;
        this.wrapperNode.setAttribute('data-promo-state', state);

        if ((PAGE_CART && state === 'HIDDEN') || !PAGE_CART) {
          this.cookieState(state);
        }

        if (state === 'INITIAL' && global.isDesktop) {
          this.emailInputNode.focus();
        }
      }
      if (!STATES[state]) {
        console.warn(`State '${state}' is not found. Please, check the code`);
      }
    }
    else {
      // Getter
      return this.wrapperNode.getAttribute('data-promo-state') || dyVars.initialState;
    }
  },
  isUsed: function () {
    return Boolean(cookieHelper.getCookie(`${COOKIE_PREFIX}${dyVars.code}_is_used`));
  },
  isExpired: function () {
    return (dyVars.isExpiring === 'true') && (now >= end);
  },
  isSubscribed: function () {
    return cookieHelper.getCookie('email_subscribed_status') === 'subscribed';
  },
  preventVideoClick: function (e) {
    e.stopPropagation();
  },
  showVideoOverlay: function (e) {
    if (!j.hasClass(e.currentTarget, 'agrsv-promo__mini-cta') || (j.hasClass(e.currentTarget, 'agrsv-promo__mini-cta') && !cookieHelper.getCookie(`${COOKIE_PREFIX}_videoAutoOpened_${dyVars.code}`))) {
      j.removeClass(promo.maximizeButtonNode, 'jp-play-video');
      cookieHelper.setCookie(`${COOKIE_PREFIX}_videoAutoOpened_${dyVars.code}`, 'true', end, URL_HOSTNAME);
      j.removeClass(this.videoPopup, 'is-hidden');
    }
  },
  hideVideoOverlay: function () {
    let iframe = promo.videoContent.querySelector('iframe');
    iframe.src = iframe.src;
    j.addClass(this.videoPopup, 'is-hidden');
  },
  setHeaderOffsetTop: function () {
    if(global.isMobile) {
      stickyThingsMobile.setOffsetTopDataAttribute()
    } else {
      stickyThingsDesktop.setOffsetTopDataAttribute();
    }
  },
  openTerms: function (e) {
    e.preventDefault();
    e.stopPropagation();
    j.removeClass(this.termsPopup, 'is-hidden');
  },
  closeTerms: function (e) {
    if (!e.target.getAttribute('href')) {
      e.preventDefault();
      e.stopPropagation();
      j.addClass(this.termsPopup, 'is-hidden');
    }
  }
};
let now = new Date();
let end;
let voucherTimer;

function init() {
  try {
    let state = promo.cookieState();

    promo.inject(); // NOT NEEDED WHEN MOVED TO DY
    
    _cacheDom();
    _bindEvents();

    if (dyVars.isClosable === 'true' && promo.closeButton) {
      promo.closeButton.style.display = 'block';
    }

    if (dyVars.isExpiring !== 'true') {
      promo.wrapperNode.setAttribute('data-not-expiring', true);
    }

    if (!state) {
      state = dyVars.initialState;
    }

    promo.state(state);

    if ((dyVars.isExpiring === 'true') && !promo.isExpired()) {
      _showRemainingTime(() => {});
      voucherTimer = setInterval(_showRemainingTime, TIME_SECOND);
    }

  } catch(e) {
    console.log('e: ', e);
  }
}

function _cacheDom() {
  promo.wrapperNode = document.querySelector(`.${BASE_CLASS}`);
  promo.codeCopiedNode = promo.wrapperNode.querySelector(`.${BASE_CLASS}__copy-code`);
  promo.timerNode = promo.wrapperNode.querySelector(`.${BASE_CLASS}__timer-time`);
  promo.closeButton = promo.wrapperNode.querySelector(`.${BASE_CLASS}__close-promo-btn`);

  promo.minimizeButtonNode = promo.wrapperNode.querySelector(`.${BASE_CLASS}__close-btn`);
  promo.maximizeButtonNode = promo.wrapperNode.querySelector(`.${BASE_CLASS}__mini-cta`);
  promo.emailFormNode = promo.wrapperNode.querySelector(`.${BASE_CLASS}__subscribe-form`);
  promo.emailInputNode = promo.wrapperNode.querySelector(`.${BASE_CLASS}__subscribe-field`);
  promo.idInputNode = promo.wrapperNode.querySelector(`.${BASE_CLASS}__subscribe-list`);
  promo.emailSubmitButtonNode = promo.wrapperNode.querySelector(`.${BASE_CLASS}__subscribe-btn`);
  promo.overlayTriggers = promo.wrapperNode.querySelectorAll(`.${BASE_CLASS}__overlay-trigger`);

  if (dyVars.isTermsPopup === 'cmsBlockOverlay') {
    promo.termsPopup = document.querySelector(`.${BASE_CLASS}__terms-popup`);
    promo.termsCloseButton = document.querySelector(`.${BASE_CLASS}__terms-close`);
    promo.termsBackground = document.querySelector(`.${BASE_CLASS}__terms-background`);
  } else if (dyVars.videoCode && dyVars.isTermsPopup === 'video') {
    promo.videoPopup = document.querySelector(`.${BASE_CLASS}__video-overlay`);
    promo.videoContent = promo.videoPopup.querySelector(`.${BASE_CLASS}__video-wrp`);
  }
}

function _bindEvents() {

  promo.closeButton && promo.closeButton.addEventListener('click', promo.hide.bind(promo));

  promo.minimizeButtonNode.addEventListener('click', promo.minimize.bind(promo));
  promo.maximizeButtonNode.addEventListener('click', promo.maximize.bind(promo));
  promo.emailSubmitButtonNode.addEventListener('click', promo.submitEmail.bind(promo));
  promo.emailInputNode.addEventListener('focus', promo.hideEmailError.bind(promo));

  if (dyVars.videoCode) {
    [].forEach.call(promo.wrapperNode.querySelectorAll('.jp-play-video'), (el) => {
      el.addEventListener('click', promo.showVideoOverlay.bind(promo))
    });
    promo.videoPopup.addEventListener('click', promo.hideVideoOverlay.bind(promo));
    promo.videoContent.addEventListener('click', promo.preventVideoClick.bind(promo));
  }

  if (dyVars.isTermsPopup === 'cmsBlockOverlay' && promo.overlayTriggers.length) {
    [].forEach.call(promo.overlayTriggers, function(overlayTrigger) {
      overlayTrigger.addEventListener('click', promo.openTerms.bind(promo));
    });
    promo.termsCloseButton && promo.termsCloseButton.addEventListener('click', promo.closeTerms.bind(promo));
    promo.termsBackground && promo.termsBackground.addEventListener('click', promo.closeTerms.bind(promo));
  }
}

function _getEndDateTime(string) {
  let endDate;

  if (dyVars.isExpiring === 'true') {
    let splitCharacter = (dyVars.isTimer === 'true') ? ' ' : '/';
    let dateSplitted = string.split(splitCharacter);
    let timeToEnd = 0;
    let timeDefiner;
    let timeValue;

    if (dyVars.isTimer === 'true') {
      dateSplitted.forEach(function (el) {
        timeValue = parseInt(el.slice(0, -1));
        timeDefiner = el.slice(-1);

        switch (timeDefiner) {
          case 'd':
            timeValue *= TIME_DAY;
            break;
          case 'h':
            timeValue *= TIME_HOUR;
            break;
          case 'm':
            timeValue *= TIME_MINUTE;
            break;
          case 's':
            timeValue *= TIME_SECOND;
            break;
        }

        timeToEnd += timeValue;
      });

      endDate = new Date(Date.now() + timeToEnd);
    }
    else {
      // If we have strictly set end date we just return it
      endDate = new Date(dateSplitted[2], dateSplitted[1] - 1, dateSplitted[0]);
    }
  }
  else {
    // If promotion has no end date – setting it to a year
    endDate = new Date(now.getTime() + TIME_YEAR);
  }

  return endDate;
}

function _showRemainingTime(callback) {
  if (dyVars.isExpiring === 'true') {
    let currentTime = new Date();
    let timeLeft = end - currentTime;
    let days = Math.floor(timeLeft / TIME_DAY);
    let hours = Math.floor((timeLeft % TIME_DAY) / TIME_HOUR);
    let minutes = Math.floor((timeLeft % TIME_HOUR) / TIME_MINUTE);
    if(minutes == 0) {
      minutes = 1;
    }
    let newTimerValue;

    if (timeLeft < 0) {
      clearInterval(voucherTimer);
      promo.hide();
      return;
    }
    if(dyVars.counterPlaceholder && days > 1) {
      newTimerValue = dyVars.counterPlaceholder;
    } else if(days > 0) {
      if(days == 1) {
        newTimerValue = window._i18n.promoBar.timerOneDayPlaceholder;
      } else {
        newTimerValue = getTransaltion(window._i18n.promoBar.timerDaysPlaceholder, days);
      }
    } else if(hours > 0) {
      if(hours == 1) {
        newTimerValue = window._i18n.promoBar.timerOneHourPlaceholder;
      } else {
        newTimerValue = getTransaltion(window._i18n.promoBar.timerHoursPlaceholder, hours);
      }
    } else {
      if(timeLeft == 1) {
        newTimerValue = window._i18n.promoBar.timerOneMinutePlaceholder;
      } else {
        newTimerValue = getTransaltion(window._i18n.promoBar.timerMinutesPlaceholder, minutes);
      }
    }
    promo.timerNode.innerHTML = newTimerValue;
  }

  callback && callback();
}

function getTransaltion(translationText, ...args) {
  if (!translationText) return '';
  // find all scala "way" parameters and replace them with provided params
  return translationText.replace(/{\d}/g, function(match) {
    // cut off brackets '{' and '}'
    const paramNumber = Number(match.replace(/[{}'']/g, ''));
    return args[paramNumber] || '';
  });
}

function _init(data) {
  if(targetNode) {
    window.location.search.replace('?', '').split('&').forEach((el) => {
      let splitted = el.split('=');
      getVars[splitted[0]] = splitted[1];
    });

    if (data) {
      dyVars = data;
      translations = PAGE_CART ? j.parseJsonFromData(document.querySelector('.cart').getAttribute('data-promo-strings')) : window._i18n.promoBar;
      end = _getEndDateTime(dyVars.endDateTime);

      dyVars.renderPromo = 'newsletterPromo';

      dyVars.initialState = dyVars[dyVars.renderPromo][global.isDesktop ? 'desktop' : 'mobile'].initialState;

      if (dyVars.videoURL) {
        dyVars.videoCode = dyVars.videoURL.split('?v=').pop();
      }

      //Set default value for "Timer Icon Color"
      dyVars.counterIconColor = dyVars.counterIconColor || 'black';

      if (!promo.isExpired() && !promo.isUsed() && !promo.isSubscribed() && !IS_PROHIBITED_PAGE) {
        init();
      }
    }
  }
}

PubSub.subscribe(EVENT_DY_PROMOBAR_INIT_V6_EMAIL, _init);
