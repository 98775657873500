/* eslint-disable no-restricted-syntax */
import PubSub from 'pubsub';
import funcHelper from 'Helpers/funcHelper';

import {
  EVENT_DY_PRICE_DISCOUNT,
  EVENT_LOAD_MORE_PRODUCTS,
  EVENT_MINICART_LOADED,
  EVENT_DY_CAROUSEL_LOADED
} from 'Constants/event';
import { TIME_SECOND, TIME_MINUTE, TIME_HOUR, TIME_DAY, TIME_YEAR, LOCAL_CURRENCY } from 'Constants/misc';
import { getPriceAsNumber } from '../../pages/filterByDesign/pdp/common/pdpUtils';

let priceDiscountCache;
let priceDiscountUpdateInterval;

let endDateTimeCache;

const insertDataIntoSelectedElements = (els, data) =>
  [].map.call(els, item => {
    // eslint-disable-next-line no-param-reassign
    item.innerHTML = data;
    return item;
  });

function getPriceDiscountCache() {
  return priceDiscountCache;
}

function getPriceDiscountByCategory(categoryIds, priceDiscount) {
  // eslint-disable-next-line no-param-reassign
  priceDiscount = priceDiscount || priceDiscountCache;
  if (!priceDiscount || categoryIds.length === 0) return null;

  for (const key of Object.keys(priceDiscount)) {
    const product = priceDiscount[key];
    if (typeof product === 'object') {
      const { includedCategoryIDs, excludedCategoryIDs } = product;

      const includedIDs =
        includedCategoryIDs && includedCategoryIDs.length > 0
          ? includedCategoryIDs.split(',').map(id => Number(id))
          : [];
      const excludedIDs =
        excludedCategoryIDs && excludedCategoryIDs.length > 0
          ? excludedCategoryIDs.split(',').map(id => Number(id))
          : [];

      const hasIncludedIDs = categoryIds.some(id => includedIDs.includes(id));
      const hasExcludedIDs = categoryIds.some(id => excludedIDs.includes(id));
      const hasDiscount = hasIncludedIDs && !hasExcludedIDs;

      if (hasDiscount) {
        const { counterPlaceholder, endDateTime, isExpiring, isTimer } = priceDiscount;
        const { text, discount, categoryID } = product;

        return {
          counterPlaceholder,
          endDateTime,
          isExpiring,
          isTimer,
          text,
          discount,
          categoryID
        };
      }
    }
  }

  return null;
}

function doesRemainingOneDayOrLess() {
  const dayInMillis = 86400000;
  const diffInMillis = diffInMillisFunc();
  return diffInMillis > 0 && diffInMillis < dayInMillis;
}

function applyPriceDiscountOnDYProducts() {
  const container = document.querySelector('.js-products-grid__placeholder');
  if (!container) return;
  const dyProducts = Array.prototype.slice.call(container.querySelectorAll('.js-catalog-product'));

  const updateTickFunctions = [];
  const updateFunctions = [];

  dyProducts.forEach(item => {
    const element = item.querySelector('.product__price');
    const oldPrice = element.getAttribute('data-price-formated');

    const prefix = 'js-cat-';
    const categoryIds = [...item.classList]
      .filter(id => id.startsWith(prefix))
      .map(id => Number(id.slice(prefix.length)));
    const priceDiscount = getPriceDiscountByCategory(categoryIds);

    if (priceDiscount) {
      const func = () => insertDataIntoSelectedElements([element], getPriceDiscountHTML(priceDiscount, oldPrice, true));
      updateTickFunctions.push(func);
    }
  });

  applyPriceDiscount(updateTickFunctions, updateFunctions);
}

function applyPriceDiscountOnWishlistAndAddToCardProducts() {
  const containers = Array.prototype.slice.call(document.querySelectorAll('.header-popover__product-list'));
  const dyProducts = containers.flatMap(el =>
    Array.prototype.slice.call(el.querySelectorAll('.header-popover__product'))
  );

  const updateTickFunctions = [];
  const updateFunctions = [];

  dyProducts.forEach(item => {
    const element = item.querySelector('.js-product__price');
    const oldPrice = element.getAttribute('data-price-formated');

    const prefix = 'js-cat-';
    const categoryIds = [...item.classList]
      .filter(id => id.startsWith(prefix))
      .map(id => Number(id.slice(prefix.length)));
    const priceDiscount = getPriceDiscountByCategory(categoryIds);

    if (priceDiscount) {
      const func = () => insertDataIntoSelectedElements([element], getPriceDiscountHTML(priceDiscount, oldPrice, true));
      updateFunctions.push(func);
    }
  });
  applyPriceDiscount(updateTickFunctions, updateFunctions);
}

// Apllies discount to Hero Banner carousel
function applyPriceDiscountToDyCarousel(container) {
  const detailes = container.querySelectorAll('.dy-recommendation-product__details');
  [...detailes].forEach(el => {
    const categoryIds = el.dataset.categoryIds?.split('|')?.map(Number) || [];
    const basePrice = getPriceWithCurrency(el.dataset.price);

    const priceDiscount = getPriceDiscountByCategory(categoryIds);

    const oldPriceEl = el.querySelector('.dy-recommendation-product__detail--price');
    const newPriceEl = document.createElement('div');
    newPriceEl.classList.add('product__price');
    newPriceEl.innerHTML = getPriceDiscountHTML(priceDiscount, basePrice, true);

    oldPriceEl.replaceWith(newPriceEl);
  });
}

function applyPriceDiscountToDyCarouselWithId({ id }) {
  const container = document.getElementById(`dy-recommendations-${id}`);
  applyPriceDiscountToDyCarousel(container);
}

function applyPriceDiscountToAllDyCarousels() {
  const containers = document.querySelectorAll('div[id^="dy-recommendations-"]');
  [...containers].forEach(c => applyPriceDiscountToDyCarousel(c));
}

function applyPriceDiscount(updateTickFunctions, updateFunctions) {
  stopPriceDiscountTimer();

  const updateTickFunctionsFunc = () => updateTickFunctions.forEach(func => func());
  const updateFunctionsFunc = () => updateFunctions.forEach(func => func());

  startPriceDiscountTimer(updateTickFunctionsFunc, updateFunctionsFunc);
}

function stopPriceDiscountTimer() {
  clearInterval(priceDiscountUpdateInterval);
}

function startPriceDiscountTimer(updateTickFunctionsFunc, updateFunctionsFunc) {
  stopPriceDiscountTimer();

  if (priceDiscountCache && priceDiscountCache.isExpiring === 'true') {
    const updateInInterval = () => {
      const isExpired = diffInMillisFunc() <= 0;
      if (isExpired) {
        stopPriceDiscountTimer();
        priceDiscountCache = null;

        updateTickFunctionsFunc();
        updateFunctionsFunc();
      } else {
        updateTickFunctionsFunc();
      }
    };

    if (doesRemainingOneDayOrLess()) {
      priceDiscountUpdateInterval = setInterval(updateInInterval, TIME_SECOND);
    }
  }

  updateTickFunctionsFunc();
  updateFunctionsFunc();
}

function diffInMillisFunc() {
  return endDateTimeCache.getTime() - Date.now();
}

function getPriceDiscountHTML(priceDiscount, oldPrice, withFromLabel) {
  const fromLabel = window._i18n.pdpEmbedded.from;

  if (priceDiscountCache && priceDiscount) {
    const discountAmount = Number(priceDiscount.discount);

    if (discountAmount > 0) {
      return `
        <span class="pdp_product_new-price">
          ${withFromLabel ? `<span class="pdp__product-info__from">${fromLabel}</span>` : ''}
          <span data-pdp-option="price">${calcDiscountPrice(oldPrice, priceDiscount).newPriceWithCurrency}</span>
        </span>
        <span class="pdp_product_discount-area">
          <span class="pdp__product-info__discount-old-price pdp_product_old-price">${oldPrice}</span>
          <span class="flex pdp__product-info__discount-description">${calcDiscountRemainingTime(priceDiscount)}</span>
        </span>
      `;
    }

    return `
        <span class="pdp_product_regular-price">
          ${withFromLabel ? `<span class="pdp__product-info__from">${fromLabel}</span>` : ''}
          <span data-pdp-option="price">${oldPrice}</span>
        </span>
        <span class="pdp_product_discount-area">
          <span class="pdp__product-info__discount-old-price">&nbsp;</span>
          <span class="flex pdp__product-info__discount-description">${calcDiscountRemainingTime(priceDiscount)}</span>
        </span>
      `;
  }

  return `
        <span class="pdp_product_regular-price">
          ${withFromLabel ? `<span class="pdp__product-info__from">${fromLabel}</span>` : ''}
          <span data-pdp-option="price">${oldPrice}</span>
        </span>
        <span class="pdp_product_discount-area">
          <span class="pdp__product-info__discount-old-price">&nbsp;</span>
          <span class="flex pdp__product-info__discount-description"></span>
        </span>
      `;
}

function getPriceWithCurrency(p) {
  return LOCAL_CURRENCY.displayPrice(p);
}

function calcDiscountPrice(oldPriceAsString, priceDiscount) {
  const discount = Number(priceDiscount.discount);

  const oldPriceAsNumber = getPriceAsNumber(oldPriceAsString);
  const newPriceAsNumber = `${((oldPriceAsNumber * (100 - discount)) / 100).toFixed(2)}`;
  const newPriceWithCurrency = getPriceWithCurrency(newPriceAsNumber);
  const oldPriceWithCurrency = getPriceWithCurrency(oldPriceAsString);

  return {
    oldPriceAsNumber,
    newPriceAsNumber,
    newPriceWithCurrency,
    oldPriceWithCurrency
  };
}

function calcDiscountEndDateTime(priceDiscount) {
  let endDate;

  if (priceDiscount.isExpiring === 'true') {
    const [dayMonthYear, hours = 0] = priceDiscount.endDateTime.split(' ');

    const splitCharacter = priceDiscount.isTimer === 'true' ? ' ' : '/';
    const dateSplitted = dayMonthYear.split(splitCharacter);
    let timeToEnd = 0;
    let timeDefiner;
    let timeValue;

    if (priceDiscount.isTimer === 'true') {
      dateSplitted.forEach(function (el) {
        timeValue = parseInt(el.slice(0, -1), 10);
        timeDefiner = el.slice(-1);

        // eslint-disable-next-line default-case
        switch (timeDefiner) {
          case 'd':
            timeValue *= TIME_DAY;
            break;
          case 'h':
            timeValue *= TIME_HOUR;
            break;
          case 'm':
            timeValue *= TIME_MINUTE;
            break;
          case 's':
            timeValue *= TIME_SECOND;
            break;
        }

        timeToEnd += timeValue;
      });

      endDate = new Date(Date.now() + timeToEnd);
    } else {
      // If we have strictly set end date we just return it
      endDate = new Date(dateSplitted[2], dateSplitted[1] - 1, dateSplitted[0], hours);
    }
  } else {
    // If promotion has no end date – setting it to a year
    endDate = new Date(Date.now() + TIME_YEAR);
  }

  return endDate;
}

function calcDiscountRemainingTime(priceDiscount) {
  const { promoBar } = window._i18n;

  const labels = [];
  if (priceDiscount.text) labels.push(priceDiscount.text);
  if (priceDiscount.isExpiring === 'true' && priceDiscount.text) {
    const currentTime = new Date();
    const timeLeft = endDateTimeCache - currentTime;
    const days = Math.floor(timeLeft / TIME_DAY);
    const hours = Math.floor((timeLeft % TIME_DAY) / TIME_HOUR);
    const minutes = Math.floor((timeLeft % TIME_HOUR) / TIME_MINUTE);
    let newTimerValue;

    if (timeLeft <= 0) {
      newTimerValue = '';
    } else if (priceDiscount.counterPlaceholder && days > 1) {
      newTimerValue = priceDiscount.counterPlaceholder;
    } else if (days > 0) {
      if (days === 1) {
        newTimerValue = promoBar.timerOneDayPlaceholder;
      } else {
        newTimerValue = funcHelper.getTransaltion(promoBar.timerDaysPlaceholder, days);
      }
    } else if (hours > 0) {
      if (hours === 1) {
        newTimerValue = promoBar.timerOneHourPlaceholder;
      } else {
        newTimerValue = funcHelper.getTransaltion(promoBar.timerHoursPlaceholder, hours);
      }
    } else if (timeLeft === 1) {
      newTimerValue = promoBar.timerOneMinutePlaceholder;
    } else {
      newTimerValue = funcHelper.getTransaltion(promoBar.timerMinutesPlaceholder, minutes);
    }

    if (newTimerValue) labels.push(newTimerValue.toUpperCase());
  }

  return labels.join(' ');
}

PubSub.subscribe(EVENT_DY_PRICE_DISCOUNT, priceDiscount => {
  if (priceDiscountCache) return; // to prevent multi price discount event

  priceDiscountCache = priceDiscount;
  endDateTimeCache = calcDiscountEndDateTime(priceDiscountCache);

  const { pageId } = window._pageMeta;
  if (
    pageId === 'catalog' ||
    pageId === 'artist' ||
    pageId === 'gifting' ||
    pageId === 'recommendations' ||
    pageId === 'recently-viewed' ||
    pageId === 'wishlist' ||
    pageId === 'filter_by_design' ||
    pageId === 'bundle_pdp'
  ) {
    applyPriceDiscountOnDYProducts();
  }

  if (pageId === 'homepage') {
    applyPriceDiscountToAllDyCarousels();
  }
});

PubSub.subscribe(EVENT_LOAD_MORE_PRODUCTS, () => {
  if (priceDiscountCache) {
    applyPriceDiscountOnDYProducts();
  }
});

PubSub.subscribe(EVENT_MINICART_LOADED, () => {
  if (priceDiscountCache) {
    setTimeout(applyPriceDiscountOnWishlistAndAddToCardProducts, 1);
  }
});

PubSub.subscribe(EVENT_DY_CAROUSEL_LOADED, props => {
  if (priceDiscountCache) {
    applyPriceDiscountToDyCarouselWithId(props);
  }
});

export {
  calcDiscountRemainingTime,
  calcDiscountPrice,
  applyPriceDiscountOnDYProducts,
  getPriceDiscountCache,
  getPriceDiscountByCategory,
  doesRemainingOneDayOrLess
};
