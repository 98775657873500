export const productTypeIdToFrameIdMap = {
  Posters: {
    productTypeId: 51,
    frameId: false
  },
  PostersWithWoodenHangers: {
    productTypeId: 373,
    frameId: 374
  },
  PostersWithStandardFrame: {
    productTypeId: 210,
    frameId: 349
  },
  WallArtWithWoodenFrames: {
    productTypeId: 371,
    frameId: 349
  },
  PostersWithPremiumWoodenFrame: {
    productTypeId: 52,
    frameId: 349
  },
  PostersWithAluminiumFrames: {
    productTypeId: 235,
    frameId: 352
  },
  CanvasWallArt: {
    productTypeId: 53,
    frameId: false
  },
  AcrylicGlassWallArt: {
    productTypeId: 54,
    frameId: false
  },
  AluminiumPrints: {
    productTypeId: 93,
    frameId: false
  }
};

const getFrameIdFromMap = productTypeId => {
  return Object.values(productTypeIdToFrameIdMap).find(p => p.productTypeId === Number(productTypeId))?.frameId;
};

export const getProductLinkFromDyConfig = (currentUrl, dyVars, productData) => {
  if (!currentUrl) return;

  const { step, preselectMaterial, preselectSize, size, sizeSquare } = dyVars || {};
  const { productTypeId, plasticFrameProductId, productSku, frameId, productType } = productData || {};

  const numberProductTypeId = Number(productTypeId);

  const [path, hash] = currentUrl.split('#');

  const parts = hash?.split('&').map(it => it.split('=')) || [];

  const params = new Map(parts);

  if (step && step !== 'design') {
    params.set('step', step);
  }

  if (step === 'material' && !params.get('frameId')) {
    const frameIdFromMap = getFrameIdFromMap(numberProductTypeId);

    params.set('frameId', frameId || frameIdFromMap);
  }

  const isStepSizeFrameSelected = step === 'size' && productTypeId !== '51' && productType !== 'Poster';

  if (preselectMaterial || isStepSizeFrameSelected) {
    if (numberProductTypeId === productTypeIdToFrameIdMap.Posters.productTypeId) {
      params.set('productId', plasticFrameProductId);

      params.set('frameId', frameId || productTypeIdToFrameIdMap.PostersWithStandardFrame.frameId);
    } else {
      const frameIdFromMap = getFrameIdFromMap(numberProductTypeId);

      params.set('frameId', frameId || frameIdFromMap);
    }
  }

  if (preselectSize) {
    const isSquare = productSku.includes('X');

    if (isSquare && sizeSquare) {
      params.set('sizeId', sizeSquare);
    } else if (size) {
      params.set('sizeId', size);
    }
  }

  const newFragment = [...params].map(it => it.join('=')).join('&');

  return `${path}#${newFragment}`;
};

export const getSKUfromImgUrl = (url = '') => {
  const parts = url.split('/');

  return parts[parts.length - 1].replace('.jpg', '');
};

export const isSquareBySKU = (SKU = '') => {
  const targetPart = SKU.split('-')[2];

  if (targetPart) {
    return targetPart.includes('X');
  }

  return false;
};

/*
 * Some possible patterns for wallart:
 * 706-11-501P
 * 774-18-101X
 * 774-18-202P-Black
 * 774-18-203P-Copper-NM
 * 780-138-102P-Natur (poster hangers)
 * 606-7-501L
 */
export const isWallartBySKU = (SKU = '') => {
  const targetPart = SKU.split('-')[2];

  if (targetPart) {
    const orientations = ['P', 'L', 'X'];

    return orientations.includes(targetPart[3]);
  }

  return false;
};
