import {LOCAL_CURRENCY} from 'Constants/misc';

const wallArtId = 189;

const preview3dIds = [53, 54, 93];

const onlyNumbersRegex = /[+-]?\d+(\.\d+)?/g;

const productIdToNameMap = {
  51: {
    name: 'Poster_1',
    option: {}
  },
  52: {
    name: 'Wooden_3',
    option: {
      349: {
        name: "Wooden_3"
      },
      350: {
        name: "Wooden_2"
      },
      351: {
        name: "Wooden_4"
      }
    }
  },
  53: {
    name: 'Canvas_1',
    option: {}
  },
  54: {
    name: 'Acrilyc_1',
    option: {}
  },
  93: {
    name: 'AluDibond_1',
    option: {}
  },
  210: {
    name: 'Plastic_8',
    option: {
      349: {
        name: "Plastic_8"
      },
      350: {
        name: "Plastic_4"
      }
    }
  },
  235: {
    name: 'Aluminium_6',
    option: {
      352: {
        name: "Aluminium_3"
      },
      353: {
        name: "Aluminium_9_3"
      },
      354: {
        name: "Aluminium_9"
      },
      355: {
        name: "Aluminium_9_1"
      },
      356: {
        name: "Aluminium_6"
      },
      357: {
        name: "Aluminium_9_2"
      }
    }
  }
};

const firstLevelArray = () => {
  return ["select", "options"];
};

const secondLevelArray = (index) => {
  return ["select", "options", index, "select", "options"]
};

const thirdLevelArray = (index1, pdpSelectsModel) => {
  const numberOfLevels = pdpSelectsModel.numberOfLevels;
  return ["select", "options", index1, "select", "options"].slice(0, 3 * numberOfLevels - 1);
};

const getCommonProps = (pdpSelectsModel) => {
  const {pdpEmbedded} = window._i18n;
  const isPersonalPlannerFunc = (pdpSelectsModel) => (((pdpSelectsModel.select.options || {})[0] || {}).select || {}).hasOwnProperty('code');

  const code = pdpSelectsModel.select.code;
  const isPersonalPlanner = isPersonalPlannerFunc(pdpSelectsModel);
  const isWallArt = (code === "frame_mat_type" || (code === "dimension" && !isPersonalPlanner));
  const selectSizeLabel = code === 'set_size' ? pdpEmbedded.mobile_minipdp_select_nocards : pdpEmbedded.mobile_minipdp_select_size;

  return {
    isWallArt,
    selectSizeLabel
  }
};

const getFromPDPSelectsModel = (arr, pdpSelectsModel) => {
  const getInObject = (p, o) => p.reduce((xs, x) => (xs && xs[x]) ? xs[x] : null, o);
  return getInObject(arr, pdpSelectsModel);
};

const getSelectedProductIndex = (verticals) => wallArtProductTypes(verticals).findIndex(vertical => vertical.isCurrentProduct);

const getMeasureType = (item) => item.label.split('x').every(i => !isNaN(i)) ? 'cm' : '';

const getThumbnailImage = (typeId, optionId, dimension) => {
  const getTypeId = (typeId) => {
    const tId = Number(typeId);

    switch (tId) {
      case 120:
        return 52;
      case 224:
        return 210;
      case 341:
        return 235;
      default:
        return tId;
    }
  };

  const getName = (typeId, optionId) => {
    const tId = getTypeId(typeId);

    if (productIdToNameMap.hasOwnProperty(tId)) {
      if (optionId) {
        const oId = Number(optionId);

        return productIdToNameMap[tId].option[oId].name;
      } else {
        return productIdToNameMap[tId].name;
      }
    } else {
      return "";
    }
  };

  const name = getName(typeId, optionId);
  return `https://rndr.juniqe-production.juniqe.com/media/catalog/product/cache/x${dimension}/J/u/Juniqe_${name}.jpg`;
};

const getDefaultPrice = (pdpData) => LOCAL_CURRENCY.displayPrice(pdpData.pdpDataLayerV2.productPriceFrom);

function getMinPrice(items) {
  const min_by = (items) => items.reduce((seed, item) => (seed && seed.value < item.value) ? seed : item);
  const arr = items.map(item => {
    const {price} = item;

    if (price) {
      return {
        value: getPriceAsNumber(price),
        price
      }
    } else {
      return {
        value: -1,
        price: null
      }
    }
  });

  return min_by(arr).price;
}

function getPriceAsNumber(priceAsString) {
  const hasComma = priceAsString.includes(',');
  const joinChar = hasComma ? '.' : '';

  return Number(priceAsString.match(onlyNumbersRegex).join(joinChar));
}

const viewInRoomIndex = (items) => Math.max(items.findIndex(item => item.type === "view_in_room"), 0);

const wallArtProductTypes = (verticals) => verticals.find(vertical => vertical.id === wallArtId).items;

const has3DPreview = (typeId) => preview3dIds.includes(typeId);

export {
  firstLevelArray,
  secondLevelArray,
  thirdLevelArray,
  getCommonProps,
  getFromPDPSelectsModel,
  getSelectedProductIndex,
  getMeasureType,
  getThumbnailImage,
  getDefaultPrice,
  getMinPrice,
  getPriceAsNumber,
  viewInRoomIndex,
  wallArtProductTypes,
  has3DPreview
}
