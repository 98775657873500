import { wishListActionTypes } from '@juniqe/navigation';

const { GET_WISHLIST_SUCCESS, TOGGLE_WISHLIST_SUCCESS } = wishListActionTypes;

export const wishListMiddleware = () => next => action => {
  if (action.type === GET_WISHLIST_SUCCESS || action.type === TOGGLE_WISHLIST_SUCCESS) {
    clearToggles();

    action.payload.items.forEach(it => setToggleActive(it.entityId));
  }

  next(action);
};

const clearToggles = () => {
  const toggles = document.querySelectorAll('.js-add-to-wishlist');

  [...toggles].forEach(t => t.classList.remove('is-active'));
};

const setToggleActive = id => {
  const products = document.querySelectorAll(`[data-product-id="${id}"], [data-bundle-id="${id}"]`);

  [...products].forEach(p => {
    const toggle = p.querySelector('.js-add-to-wishlist');

    if (toggle) {
      toggle.classList.add('is-active');
    }
  });
};
