'use strict';

export const CLASS_BODY_IS_PDP = 'page-pdp';
export const CLASS_BODY_STOP_SCROLLING = 'stop-scrolling';
export const CLASS_UPSELL_CHOSEN_ELEMENT = 'upselling-item--chosen';
export const CLASS_WISHLIST_TOGGLE_ELEMENT = `js-add-to-wishlist`;
export const CLASS_WISHLIST_REMOVE_ELEMENT = 'js-remove-from-wishlist';
export const CLASS_WISHLIST_ADDED = `is-active-added`;
export const CLASS_WISHLIST_TOGGLE_CAROUSEL_ELEMENT = `${CLASS_WISHLIST_TOGGLE_ELEMENT}-carousel`;
export const CLASS_TOGGLE_ACTIVE = 'is-active';
export const CLASS_SEE_SIMILAR_DESIGNS = 'js-see-more-like';
export const CLASS_PRODUCT_LABEL = 'js-product__label';