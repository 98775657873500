import global from 'global';
import j from 'Helpers/domHelper';
import f from 'Helpers/funcHelper';
import { CURRENCIES_LIST, LOCAL_CURRENCY } from 'Constants/misc';
import defaultProductTpl from './defaultProduct.mst.html';
import pubsub from 'pubsub';
import { EVENT_AJAX_CART_OVERLAY_RENDER, EVENT_MINICART_LOADED } from 'Constants/event';

import newCartConfig from 'DynamicYield/newCartConfig/newCartConfig';

var openedTimeout = null;

// config will be bouded to this function as context
function init() {
  /* jshint validthis: true */
  f.waitForGlobalVar('DYO').then(() => {
    _cookPopover(this);
  });
}

function refresh() {
  /* jshint validthis: true */
  return _cookPopover(this);
}

function fetchData(options) {
  return _fetchDataForTemplate(options);
}

function _cookPopover(config) {
  return _fetchDataForTemplate(config).then(function (data) {
    render.bind(config)(data);
    return data;
  });
}

function _getCartApiUrl() {
  const newConfig = newCartConfig();
  return newConfig ? `${newCartConfig().apiUrl}` : '/cart/get';
}

function _fetchDataForTemplate(config) {
  if (config.moduleName === 'cart') {
    config.tplUrl = _getCartApiUrl();
  }
  return j.getJSON(config.tplUrl).then(function (data) {
    if (data.productsLeftMessage) {
      if (!window._i18n.returnUsers) {
        window._i18n.returnUsers = {};
      }

      switch (config.moduleName) {
        case 'cart':
          window._i18n.returnUsers.cartMessage = data.productsLeftMessage;
          break;
        case 'wishlist':
          window._i18n.returnUsers.wishlistMessage = data.productsLeftMessage;
          window._wishlist = {
            items: data.items.map(el => el.entityId)
          };
          break;
        default:
          break;
      }
    }
    pubsub.publish(config.moduleName + ':fetched', data.items);
    pubsub.publish(EVENT_MINICART_LOADED, data);
    config.items = data.items;
    return data;
  });
}

// config will be bouded to this function as context
function render(json) {
  /* jshint validthis: true */
  var self = this;
  const newConfig = newCartConfig();
  if (this.moduleName === 'cart') {
    json = newConfig ? _prepareTplDataNewCart(json, this.moduleName) : _prepareTplData(json, this.moduleName);
    json.goToCartLink = newConfig ? newConfig.goToCartLink : window.checkout.cartUrl;
    json.goToCheckoutLink = newConfig
      ? window.isUserLogged
        ? newConfig.goToShipmentLink
        : newConfig.goToAccountLink
      : '/checkout/onepage';
  } else {
    json = _prepareTplData(json, this.moduleName);
  }

  let html = this.template(json);
  var popoverContainer = document.getElementById(self.containerId);
  popoverContainer && (popoverContainer.innerHTML = html);

  _setState(self, json);

  setTimeout(function () {
    if (window.$EchoJS) {
      window.$EchoJS.render();
    }
  }, 0);

  PubSub.publish(EVENT_AJAX_CART_OVERLAY_RENDER, {});
}

function _setState(config, json) {
  let parentEl = document.getElementById(config.parentId);

  if (parentEl) {
    let amountEl = parentEl.getElementsByClassName('js-header__amount')[0];
    let checkoutHrefs = parentEl.getElementsByClassName('js-ajax-cart-link');
    let checkoutHref;
    if (checkoutHrefs.length > 0) {
      checkoutHref = checkoutHrefs[0];
    }

    if (json.items.length) {
      j.addClass(parentEl, 'is-active');
      j.removeClass(amountEl, 'is-hidden');
      if (checkoutHref !== undefined) checkoutHref.setAttribute('href', checkout.cartUrl);
    } else {
      j.removeClass(parentEl, 'is-active');
      j.addClass(amountEl, 'is-hidden');
    }

    j.innerText(amountEl, json.numberOfItems);
  }
}

function _prepareTplData(data, module) {
  data.items = data.items.map(function (product) {
    product.currency = global.shopCurrencyCode;
    product.tpl = defaultProductTpl(product);
    product.displayPrice = LOCAL_CURRENCY.displayPrice(f.normalizePrice(product.displayPrice));

    return product;
  });
  data.translations = window._i18n.popovers[module];

  data.totalPrice = LOCAL_CURRENCY.displayPrice(f.normalizePrice(data.totalPrice));
  data.totalDiscountedPrice = data.totalDiscountedPrice
    ? LOCAL_CURRENCY.displayPrice(f.normalizePrice(data.totalDiscountedPrice))
    : 0;

  return data;
}

function _getFormattedPrice(priceObject) {
  return CURRENCIES_LIST[priceObject.currency].displayPrice(priceObject.amount);
}

function _prepareTplDataNewCart(data, module) {
  let newData = data.cart;
  if (!newData) {
    newData = {
      items: []
    };
    newData.translations = window._i18n.popovers[module];
    return newData;
  }
  newData.numberOfItems = newData.items
    .map(item => item.quantity)
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  newData.totalPrice = _getFormattedPrice(newData.priceWithoutDiscountWithTax);
  if (
    newData.priceWithDiscountWithTax &&
    newData.priceWithDiscountWithTax.amount != newData.priceWithoutDiscountWithTax.amount
  ) {
    newData.totalDiscountedPrice = _getFormattedPrice(newData.priceWithDiscountWithTax);
  }
  newData.items = newData.items.map(product => {
    // TODO: change to be dynamic depending on configurable url
    product.thumbnail = `https://rndr.staging.juniqe.com/media/catalog/product/cache/x145${product.thumbnail}`;
    product.displayPrice = _getFormattedPrice(product.rowTotal);
    if (product.rowTotalWithDiscount) {
      product.displayDiscountedPrice = _getFormattedPrice(product.rowTotalWithDiscount);
    }
    product.tpl = defaultProductTpl(product);
    return product;
  });
  newData.translations = window._i18n.popovers[module];
  return newData;
}

function open(cb, isDY, timeout) {
  timeout = parseInt(timeout) || 3000;
  isDY = isDY || false;
  /* jshint validthis: true */
  var el = document.getElementById(this.parentId),
    isOpenCssClass = isDY ? 'js-is-opened__dy' : 'is-opened';

  j.addClass(el, isOpenCssClass);

  /* jshint -W030 */
  cb && cb(el);

  if (openedTimeout) {
    clearTimeout(openedTimeout);
  }

  openedTimeout = setTimeout(function () {
    j.removeClass(el, isOpenCssClass);
  }, timeout);
}

function close(isDY) {
  isDY = isDY || false;
  var el = document.getElementById(this.parentId),
    isOpenCssClass = isDY ? 'js-is-opened__dy' : 'is-opened';

  j.removeClass(el, isOpenCssClass);
}

function toggleActiveState() {
  /* jshint validthis: true */
  j.toggleClass(document.getElementById(this.parentId), 'is-active');
}

function contains(productId) {
  /* jshint validthis: true */
  return this.items.some(function (item) {
    return productId === item.entityId.toString();
  });
}

function showOverlay() {
  /* jshint validthis: true */
  return this.items.some(function (item) {
    return productId === item.entityId.toString();
  });
}

export default function (config) {
  return {
    init: init.bind(config),
    fetchData: fetchData,
    refresh: refresh.bind(config),
    render: render.bind(config),
    open: open.bind(config),
    toggleActiveState: toggleActiveState.bind(config),
    contains: contains.bind(config),
    close: close.bind(config)
  };
}
