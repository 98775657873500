import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// checkout name spaces
import { domain, isSubDomain } from 'Helpers/domain';

// checkout name spaces
import checkout_deDE from './lang/de/checkout.json';
import checkout_deCH from './lang/de-CH/checkout.json';
import checkout_enUS from './lang/en/checkout.json';
import checkout_enGB from './lang/en-GB/checkout.json';
import checkout_frFR from './lang/fr/checkout.json';
import checkout_frCH from './lang/fr-CH/checkout.json';
import checkout_itIT from './lang/it/checkout.json';
import checkout_esES from './lang/es/checkout.json';
import checkout_daDA from './lang/da/checkout.json';
import checkout_nlNL from './lang/nl/checkout.json';
import checkout_svSV from './lang/sv/checkout.json';

// pdp name spaces
import pdp_enUS from './lang/en/pdp.json';
import pdp_enGB from './lang/en-GB/pdp.json';
import pdp_deDE from './lang/de/pdp.json';
import pdp_deCH from './lang/de-CH/pdp.json';
import pdp_frFR from './lang/fr/pdp.json';
import pdp_frCH from './lang/fr-CH/pdp.json';
import pdp_itIT from './lang/it/pdp.json';
import pdp_esES from './lang/es/pdp.json';
import pdp_daDA from './lang/da/pdp.json';
import pdp_nlNL from './lang/nl/pdp.json';
import pdp_svSV from './lang/sv/pdp.json';

// general name spaces
// Example how to use keys for general :  {t("general:gen-error-404-description-1")}
import general_deDE from './lang/de/general.json';
import general_deCH from './lang/de-CH/general.json';
import general_enUS from './lang/en/general.json';
import general_enGB from './lang/en-GB/general.json';
import general_frFR from './lang/fr/general.json';
import general_frCH from './lang/fr-CH/general.json';
import general_itIT from './lang/it/general.json';
import general_esES from './lang/es/general.json';
import general_daDA from './lang/da/general.json';
import general_nlNL from './lang/nl/general.json';
import general_svSV from './lang/sv/general.json';

export function getLanguageName() {
  const countryName = domain();
  const defaultLanguage = 'enUS';

  if (countryName === 'com' || countryName === 'localhost' || !countryName) {
    return defaultLanguage;
  }

  if (countryName === 'co.uk') {
    return 'enGB';
  }

  if (countryName === 'ch') {
    return isSubDomain('fr') ? 'frCH' : 'deCH';
  }

  const languageName = countryName.toLowerCase() + countryName.toUpperCase();

  return languageName;
}

const i18NextOptions = {
  ns: ['checkout', 'pdp', 'general'],
  defaultNS: 'checkout',
  fallbackNS: ['general', 'pdp', 'checkout'],
  resources: {
    enUS: {
      checkout: checkout_enUS,
      general: general_enUS,
      pdp: pdp_enUS
    },
    enGB: {
      checkout: checkout_enGB,
      general: general_enGB,
      pdp: pdp_enGB
    },
    deDE: {
      checkout: checkout_deDE,
      general: general_deDE,
      pdp: pdp_deDE
    },
    deCH: {
      checkout: checkout_deCH,
      general: general_deCH,
      pdp: pdp_deCH
    },
    frFR: {
      checkout: checkout_frFR,
      general: general_frFR,
      pdp: pdp_frFR
    },
    frCH: {
      checkout: checkout_frCH,
      general: general_frCH,
      pdp: pdp_frCH
    },
    itIT: {
      checkout: checkout_itIT,
      general: general_itIT,
      pdp: pdp_itIT
    },
    esES: {
      checkout: checkout_esES,
      general: general_esES,
      pdp: pdp_esES
    },
    dkDK: {
      checkout: checkout_daDA,
      general: general_daDA,
      pdp: pdp_daDA
    },
    nlNL: {
      checkout: checkout_nlNL,
      general: general_nlNL,
      pdp: pdp_nlNL
    },
    seSE: {
      checkout: checkout_svSV,
      general: general_svSV,
      pdp: pdp_svSV
    }
  },
  lng: getLanguageName(),
  fallbackLng: {
    deCH: ['deDE'],
    frCH: ['frFR'],
    enGB: ['enUS'],
    default: ['enUS']
  },
  keySeparator: false, // we do not use keys in form messages.welcome
  nsSeparator: false,
  interpolation: {
    escapeValue: false // react already safes from xss
  }
};

i18n.use(initReactI18next).init(i18NextOptions);
