'use strict';

import {
    CLASS_PRODUCT_LABEL,
    CLASS_SEE_SIMILAR_DESIGNS,
} from 'Constants/class';

/**
 * Class representing set of static methods to work with "Labels on category pages"
 */
export default class LabelsRedirect {
  /**
   * Click handler for "Labels on category pages"
   * @param {Event} e - Event object captured by handler
   * @private
   */
  static _productLabelsClickHandler(e) {
    const productLabel = e.target.closest(`.${CLASS_PRODUCT_LABEL}`);

    if(productLabel) {
      e.preventDefault();
      let dataHref = productLabel.getAttribute('data-href');
      if(dataHref) {
        window.location.href = dataHref;
      }
    }
  }
}