'use strict';

/**
 * Toast notification for desktop.
 * @module common/toastDesktop/toastDesktop
 */

// TODO: refactor and comment methods

import DOM from 'Helpers/domHelper';
import FUNC from 'Helpers/funcHelper';
import toastDesktopTmpl from './toastDesktop.mst.html';

const TOAST_CLASSES = {
  wrapper:      'toast-desktop-wrapper',
  wrapperShown: 'toast-desktop-wrapper--shown',
  content:      'toast-desktop',
  closeBtn:     'toast-desktop-wrapper__close-btn'
};

const DEFAULT_CONFIG = {
  // todo: provide default notification image
  imageUrl: '',
  text: 'Here goes the notification text',
  isImageStretched: false,
  // todo: provide default notification icon
  iconClass: '',
  isIconActive: false,
  link: '/',
  animationDuration: 500,
  // amount of SECONDS notifications will be floating on the screen
  deadline: 3
};

class ToastDesktop {
  constructor(config = {}) {
    this.config = FUNC.mixin(DEFAULT_CONFIG, config);
    // debugger;
    this._safeDestroy = this.destroy.bind(this);

    this._render();
    this._bindEvents();

    if (this.config.deadline) {
      setTimeout(this._safeDestroy, this.config.deadline * 1000);
    }

    // Emulates object-fit for IE/Edge
    DOM.objectFit();
  }

  destroy(e) {
    e && e.preventDefault();
    this.closeButton.removeEventListener('click', this._safeDestroy);
    DOM.removeClass(this.wrapper, TOAST_CLASSES.wrapperShown);
    setTimeout(() => { DOM.remove(this.wrapper) }, this.config.animationDuration);
  }

  _render() {

    this.wrapper = DOM.newNode('div', { 'class': TOAST_CLASSES.wrapper });
    this.body = DOM.newNode('a', {
      'class': TOAST_CLASSES.content,
      'href': this.config.link,
      'target': '_blank'
    }, toastDesktopTmpl({imageUrl: this.config.imageUrl, text: this.config.text, isIconActive: this.config.isIconActive, isImageStretched: this.config.isImageStretched, iconClass: this.config.iconClass}));
    this.closeButton = DOM.newNode('i', { 'class': `i-close ${TOAST_CLASSES.closeBtn}` });

    this.wrapper.appendChild(this.body);
    this.wrapper.appendChild(this.closeButton);

    document.body.appendChild(this.wrapper);
    setTimeout(() => { DOM.addClass(this.wrapper, TOAST_CLASSES.wrapperShown) });
  }

  _bindEvents() {
    this.closeButton.addEventListener('click', this._safeDestroy);
  }
}

export default ToastDesktop;