import Cookies from 'js-cookie';
import axios from 'axios';
import domHelper from '../../helpers/domHelper';

const preferredDomainAttributeName = 'preferredDomain';
const juniqePreferredDomainAttributeName = 'juniqePreferredDomain';

const init = () => {
  // Disabled by https://app.clickup.com/t/4580628/JUN-11609
  // checkUrlParameters();
  // checkCustomerGeo();
  addLanguageSwitcherLinksEventListeners();
};

const checkUrlParameters = () => {
  if (domHelper.getUrlParameter(juniqePreferredDomainAttributeName)) {
    Cookies.set(juniqePreferredDomainAttributeName, true);
  } else if (domHelper.getUrlParameter(preferredDomainAttributeName)) {
    Cookies.remove(preferredDomainAttributeName);
  } else {
    const { hreflang, fallback } = JSON.parse(Cookies.get(preferredDomainAttributeName) || JSON.stringify({}));
    if (hreflang && fallback) {
      const redirectUrl = getRedirectUrl(hreflang, fallback);
      const notMatching = doesMatchedLinkDifferFromHostname(redirectUrl);

      if (notMatching) {
        redirectToPreferredDomain(redirectUrl, hreflang);
      }
    }
  }
};

const checkCustomerGeo = () => {
  const url = `/api/customer/geo`;
  const nonEuroCountries = {
    GB: {
      domain: 'co.uk',
      hreflang: 'en-gb'
    },
    CH: {
      domain: 'ch',
      hreflang: 'de-ch'
    }
  };
  const domain = baseUrl => {
    const reg = /juniqe\.([\w.]+)/;
    const exec = reg.exec(baseUrl);

    return exec !== null ? exec[1] : null;
  };
  const defaultCountry = baseUrl => {
    const d = (domain(baseUrl) || 'com').toLowerCase();
    if (d === 'com') return 'DE';
    if (d === 'co.uk') return 'GB';
    return d.toUpperCase();
  };

  axios.get(url).then(result => {
    const geo = result.data;
    const geoCountry = geo.country;
    const { origin } = window.location;
    const country = defaultCountry(origin);

    if (nonEuroCountries.hasOwnProperty(geoCountry) && geoCountry !== country) {
      const { domain, hreflang } = nonEuroCountries[geoCountry];
      const href = origin.substring(0, origin.indexOf('juniqe') + 'juniqe'.length + 1) + domain;

      setPreferredDomainAndRedirect(href, hreflang);
    }
  });
};

const addLanguageSwitcherLinksEventListeners = () => {
  const languageSwitcherLinks = document.querySelectorAll('#header-domain__dropdown-desktop li a');
  languageSwitcherLinks.forEach(link => {
    link.addEventListener('click', e => {
      e.preventDefault();

      const redirectUrl = e.currentTarget.href;
      const hreflang = e.currentTarget.attributes['data-lng'].value;
      setPreferredDomainAndRedirect(redirectUrl, hreflang);
    });
  });
};

const setPreferredDomainAndRedirect = (redirectUrl, hreflang) => {
  const matchedHref = redirectUrl.match(/^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/?\n]+)/)[0];
  const notMatching = doesMatchedLinkDifferFromHostname(matchedHref);

  if (notMatching) {
    if (!Cookies.get(juniqePreferredDomainAttributeName)) {
      Cookies.set(preferredDomainAttributeName, {
        hreflang,
        fallback: matchedHref
      });
    }

    redirectToPreferredDomain(redirectUrl, hreflang);
  }
};

const redirectToPreferredDomain = (redirectUrl, hreflang) => {
  const search = {
    ...urlQuery(window.location.search),
    [preferredDomainAttributeName]: hreflang
  };
  delete search[juniqePreferredDomainAttributeName];
  const newSearch = objectToKeyValueString(search);
  const newLocation = `${redirectUrl}?${newSearch}`;

  window.location.replace(newLocation);
};

const doesMatchedLinkDifferFromHostname = href => window.location.hostname !== new URL(href).hostname;

const alternateLinks = () => [...document.querySelectorAll("link[rel='alternate']")];

const getRedirectUrl = (hreflang, fallback) => {
  const links = alternateLinks();

  // link's hreflang attr for en language contain x-default value
  // ids contain correct languages for all alternate links
  const matchedLink = links.find(link => link.id === hreflang);

  return matchedLink ? matchedLink.href : fallback;
};

const objectToKeyValueString = object => {
  return Object.entries(object)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
};

const urlQuery = url => urlToObject(url, '?');

const urlToObject = (url, splitChar) => {
  return (
    url
      .split(splitChar)
      .slice(1)
      .map(hash =>
        hash.split('&').reduce((pv, cv) => {
          const [key, value] = cv.split('=');
          return { ...pv, [key]: value };
        }, {})
      )
      .pop() || {}
  );
};

export default {
  init,
  setPreferredDomainAndRedirect
};
