import Modal from 'Common/modal/modal';
import PubSub from 'pubsub';
import PreferredDomain from 'Common/preferredDomain/preferredDomain';
import locModalTmpl from './localizationModal.mst.html';
// eslint-disable-next-line import/no-cycle
import { getEmailPopupOpen } from '../emailSignupPopup/emailSignupPopup';

let isLocalizationModalOpen = false;
export const getIsLocalizationModalOpen = () => isLocalizationModalOpen;

function init(config) {
  let modal;
  const { body } = document;
  let overlay;
  let urlRedirect;
  let btnClose;
  let btnStay;

  function _onModalCreate() {
    overlay = document.querySelector('.modal--localization');
    urlRedirect = body.getAttribute('data-geo-redirect');
    btnClose = overlay.querySelector('[data-modal-close]');
    btnStay = overlay.querySelector('[data-modal-stay]');
    if (btnStay) {
      btnStay.addEventListener('click', () => {
        modal.close();
      });
    }
  }

  modal = new Modal({
    className: 'modal--localization',
    body: locModalTmpl(config),
    onCreate: _onModalCreate
  });

  modal.open();
  modal.onClose(() => {
    isLocalizationModalOpen = false;
    PubSub.publish('localizationModalClosed');
  });
  isLocalizationModalOpen = true;
}

PubSub.subscribe('dy.promoInit', config => {
  if (getEmailPopupOpen()) return;

  init(config);

  setTimeout(() => {
    document.querySelector('#redirect-link').addEventListener('click', function (e) {
      e.preventDefault();

      PreferredDomain.setPreferredDomainAndRedirect(this.href, 'en');
    });
  }, 0);
});
