import domHelper from 'Helpers/domHelper';
import funcHelper from 'Helpers/funcHelper';
import suggestionTpl from './suggestion.mst.html';
import global from 'global';
import Modal from 'Common/modal/modal';
import searchMobileOverlay from './searchMobileOverlay.mst.html';

let prevSearchValue = null;
let headerForm;

function searchChangeHandler(e) {
  // calculate proper height
  const searchBox = e.target.closest('.search-box');
  const searchBoxBounds = searchBox.getBoundingClientRect();
  const autocompleteBox = global.isMobile ? document.querySelector('.modal--search .suggestions-autocomplete--mobile') : searchBox.querySelector('.suggestions-autocomplete--desktop');
  const searchValue = e.target.value;

  if (global.isMobile) {
    // calculate search box height
    autocompleteBox.style.maxHeight = (window.innerHeight - searchBoxBounds.height - searchBoxBounds.y - 5) + 'px';
  }
  if (!searchValue || searchValue.length < 3) {
    autocompleteBox.innerHTML = '';
    prevSearchValue = null;
    return;
  }
  // don't perform any search in case search is still open and 
  if (searchValue === prevSearchValue) {
    return;
  }
  domHelper.ajax(`/suggestions?prefix=${searchValue}`, {
    type: 'GET',
    success: function (data) {
      let resultStr = '';
      data.forEach((item) => resultStr += suggestionTpl(item));
      autocompleteBox.innerHTML = resultStr;
      prevSearchValue = searchValue;
    }
  });
}

function cacheDom() {
  headerForm = document.querySelector(global.isMobile ? '#header__search--form--mobile' : '#header__search--form--desktop');
}

function bindEvents() {
  if(headerForm) {
    headerForm.removeEventListener('submit', onSearchFormSubmit);
    headerForm.addEventListener('submit', onSearchFormSubmit);
  }
}

function onSearchFormSubmit(event) {
  let searchInput = headerForm.querySelector('[name="q"]');

  if(searchInput) {
    DY.API("event", {
      name: "Keyword Search",
      properties: {
        dyType: "keyword-search-v1",
        keywords: searchInput.value
      }
    });
  }
}

function init() {
  if (global.isMobile) {
    [].forEach.call(document.querySelectorAll('.header-button__icon--search, .js-open-search-overlay'), (searchTrigger) => {
      searchTrigger.addEventListener('click', () => {
        showModal();
        cacheDom();
        bindEvents();
      });
    });
  } else {
    cacheDom();
    bindEvents();
    [].forEach.call(document.querySelectorAll('.search-box__text-field'), (searchElem) => {
      searchElem.addEventListener('keyup', funcHelper.debounce(searchChangeHandler, 200));
      searchElem.addEventListener('focus', searchChangeHandler);
    });
    window.addEventListener('click', (e) => {
      if (!e.target.closest('.search-box')) {
        hideAutocomplete();
      }
    });
  }
}

function showModal() {
  let modal;

  function _onModalCreate() {
    let btnClose = document.querySelector('.overlay-header__close-btn');

    if (btnClose) {
      btnClose.addEventListener('click', () => {
        modal.close();
      });
    }
    document.querySelector('.modal--search .search-box__text-field').addEventListener('keyup', funcHelper.debounce(searchChangeHandler, 200));
  }
  const bodyTpl = searchMobileOverlay({
    searchPath: window.searchModel.searchPath,
    placeholder: window.searchModel.placeholder,
    value: window.searchModel.value,
    label: window.searchModel.label
  });
  modal = new Modal({
    className: 'modal--search',
    body: bodyTpl,
    onCreate: _onModalCreate
  });

  modal.open();

  document.querySelector(".modal--search .search-box__text-field").focus();

}

function hideAutocomplete() {
  prevSearchValue = null;
  [].forEach.call(document.querySelectorAll('.suggestions-autocomplete'), (ac) => {
    ac.innerHTML = '';
  });
}

var _originalSize = window.innerWidth + window.innerHeight;
// set up the listener to track mobile keyboard appearance
// if width + height is changed => keyboard is shown
// if they are the same => keyboard is hidden
window.addEventListener('resize', () => {
  if (window.innerHeight + window.innerWidth != _originalSize) {
    // window.alert('keyboard show up');
  } else {
    const autocompleteMobile = document.querySelector('.modal--search .suggestions-autocomplete--mobile');
    if (autocompleteMobile) {
      autocompleteMobile.style.maxHeight = '';
    }
  }
});

export default {
  init,
  hideAutocomplete
}