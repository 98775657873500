var breakpoints = {
  phone: 768,
  tablet: 1200
};

var isDesktop, isMobile, isPhone, isTablet, deviceTypeName, isTouch;
isDesktop = isMobile = isPhone = isTablet = false;
isTouch = 'ontouchstart' in window || navigator.maxTouchPoints;

if (getDeviceType(breakpoints.phone)) {
  if (getDeviceType(breakpoints.tablet)) {
    isDesktop = true;
    deviceTypeName = 'Desktop';
  } else {
    isTablet = isMobile = true;
    deviceTypeName = 'Tablet';
  }
} else {
  isPhone = isMobile = true;
  deviceTypeName = 'Mobile';
}

function getDeviceType(breakpoint) {
  if (window.matchMedia) {
    return window.matchMedia('(min-width: ' + breakpoint + 'px)').matches;
  } else {
    return window.innerWidth >= breakpoint;
  }
}

// run appropriate module depending on screen width
function runMQModule(mobileModule, desktopModule, options) {
  options = options || {};

  if (isMobile) {
    return mobileModule.init(options.mobile);
  } else {
    return desktopModule.init(options.desktop);
  }
}

export default {
  isTouch: isTouch,
  isPhone: isPhone,
  isTablet: isTablet,
  isMobile: isMobile, // isPhone || isTablet
  isDesktop: isDesktop,
  breakpoints: breakpoints,
  runMQModule: runMQModule,
  deviceTypeName: deviceTypeName,
  checkDeviceWidth: getDeviceType
};

