import domHelper from 'Helpers/domHelper';
import customSelectTpl from './customSelect.mst.html';


var customSelectClass = 'select--custom',
    customSelectOptionClass = 'select__option',
    customSelectPlaceholderClass = 'select--placeholder',
    customSelectDisabledClass = 'select--disabled';

function CustomSelectView(model) {
  this.model = model;
  this.wrapperEl = domHelper.closest(model.el, '.select');
  this.closed = true;
  this.virgin = true;

  this._hideOriginalSelect();
}

CustomSelectView.prototype._hideOriginalSelect = function () {
  this.model.el.style.display = 'none';
};


CustomSelectView.prototype.open = function () {
  domHelper.addClass(this.wrapperEl, 'on');
  this.closed = false;
};

CustomSelectView.prototype.close = function () {
  domHelper.removeClass(this.wrapperEl, 'on');
  this._flush();
  this.closed = true;
};

CustomSelectView.prototype._flush = function () {
  if (this._boundCloseOnClickOutside) {
    document.removeEventListener('click', this._boundCloseOnClickOutside, true);
    this._boundCloseOnClickOutside = null;
  }
};


CustomSelectView.prototype.render = function () {
  this._remove();

  domHelper.addClass(this.wrapperEl, customSelectClass);
  domHelper.addClass(this.wrapperEl, customSelectPlaceholderClass);

  domHelper.toggleClass(this.wrapperEl, customSelectDisabledClass, this.model.el.disabled);

  domHelper.prependHTML(this.wrapperEl, customSelectTpl(this.model.serialize()));

  this.el = this.wrapperEl.getElementsByClassName('select__wrapper')[0];
  this._cacheDom();
  this._bindEvents();
};


CustomSelectView.prototype._remove = function () {
  if (this.el) {
    this._unbindEvents();
    domHelper.remove(this.el);
    this.virgin = true;
  }
};

CustomSelectView.prototype._cacheDom = function () {
  this.titleEl = this.wrapperEl.getElementsByClassName('select__title')[0];
};

CustomSelectView.prototype._bindEvents = function () {
  // open/close
  this.boundToggleCustomSelect = this._toggleCustomSelect.bind(this);
  this.wrapperEl.addEventListener('click', this.boundToggleCustomSelect);

  // select option
  this.boundOptionClicked = this._optionClicked.bind(this);
  this.wrapperEl.querySelector('.select__options').addEventListener('click', this.boundOptionClicked);
};

CustomSelectView.prototype._unbindEvents = function () {
  this.wrapperEl.removeEventListener('click', this.boundToggleCustomSelect);
  this.boundToggleCustomSelect = null;
  this.wrapperEl.querySelector('.select__options').removeEventListener('click', this.boundOptionClicked);
  this.boundOptionClicked = null;
};

CustomSelectView.prototype._toggleCustomSelect = function (e) {
  e.stopPropagation();

  if (!this.model.el.disabled) {
    if (this.closed) {
      this.open();

      this._boundCloseOnClickOutside = this._closeOnClickOutside.bind(this);
      document.addEventListener('click', this._boundCloseOnClickOutside, true);
    } else {
      this.close();
    }
  }
};

CustomSelectView.prototype._optionClicked = function (e) {
  var fakeOption = domHelper.hasClass(e.target, customSelectOptionClass) ?
      e.target :
      domHelper.closest(e.target, '.' + customSelectOptionClass);

  if (this.virgin) {
    this.virgin = false;
    domHelper.removeClass(this.wrapperEl, customSelectPlaceholderClass);
  }

  e.stopPropagation();

  if (fakeOption && (!domHelper.hasClass(fakeOption, 'disabled') && !domHelper.hasClass(fakeOption, 'selected'))) {
    this.model.setSelected(fakeOption);
    this._updateView(fakeOption);
  }

  this.close();
};

CustomSelectView.prototype._updateView = function (fakeOption) {
  this.titleEl.innerHTML = fakeOption.getAttribute('data-caption');
  domHelper.removeClass(this.wrapperEl.getElementsByClassName('selected')[0], 'selected');
  domHelper.addClass(fakeOption, 'selected');
};

CustomSelectView.prototype._closeOnClickOutside = function (e) {
  var targetAncestor = domHelper.closest(e.target, '.' + customSelectClass);
  if (!((targetAncestor && targetAncestor === this.wrapperEl) || e.target === this.wrapperEl)) {
    this.close();
  }
};

export default CustomSelectView;

