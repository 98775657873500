import PubSub from 'pubsub';
import {EVENT_LARGE_POPOVER, EVENT_DY_CLOSE_POPOVER} from 'Constants/event';
import global from 'global';
import domHelper from "Helpers/domHelper";
const POPOVER_ELEMENT_ID = 'js-jnq-popover';
let popoverContentDivId;
let localStorageItemName;

function closePopover(popover) {
  domHelper.remove(popover);
  localStorage.setItem(localStorageItemName, true);

  // Trigger DY event (Name: [CMS Block div ID])
  window.DY.API('event', {
    name: popoverContentDivId,
    type: EVENT_DY_CLOSE_POPOVER
  });
}

// TODO: reset position on orientationchange
PubSub.subscribe(EVENT_LARGE_POPOVER, (options) => {
  document.addEventListener('DOMContentLoaded', () => {
    popoverContentDivId = options.popoverContentDivId;
    localStorageItemName = `large-popover-${popoverContentDivId}`;
    let localStorageItem = localStorage.getItem(localStorageItemName);
    if(global.isDesktop && !localStorageItem) {
      let parent = document.querySelector(options.parentSelector);
      let popoverContentDiv = document.querySelector(`#${popoverContentDivId}`);
      let additionalClass = options.display == 'hover' ? 'is-hidden' : '';
      let additionalStyle = options.display == 'hover'
        ? `${options.parentSelector}:hover #${POPOVER_ELEMENT_ID} {visibility: visible; opacity: 1;}`
        : `${options.parentSelector} #${POPOVER_ELEMENT_ID} {visibility: visible; opacity: 1;}`;
      let customStyle = options.customStyle || '';
      customStyle += additionalStyle;

      // Append style to head
      customStyle && (domHelper.appendStyle(customStyle));

      // Create popover container
      let popoverHtml = `
        <div id="${POPOVER_ELEMENT_ID}" class="header-popover__container jnq-popover">
            <i class="jnq-popover__close js-jnq-popover__close"></i>
            ${popoverContentDiv.outerHTML}
            <a href="${options.bottomLinkUrl}" class="jnq-popover__continue js-jnq-popover__close ${additionalClass}">${options.bottomLinkText}</a>
        </div>`;
      domHelper.appendHTML(parent, popoverHtml);

      // Set popover style
      let popover = document.querySelector(`#${POPOVER_ELEMENT_ID}`);
      let topPosition = options.topPosition;
      let leftPosition = options.leftPosition;

      topPosition && (popover.style.top = parseInt(topPosition) + 'px');
      leftPosition && (popover.style.left = parseInt(leftPosition) + 'px');
      domHelper.addClass(popover, 'jnq-popover--visible');

      // Attach events
      popover.addEventListener('click', (event) => {
        let target = event.target;
        if(target.matches('.js-jnq-popover__close')) {
          closePopover(popover);
        }
      });
    }
  });
});
