import httpHelper from 'Helpers/httpHelper';
import 'global';


let headerLogoutBtn;
let myAccountLogoutBtn;

function init() {
  _cacheDom();
  _bindEvents();
}

function _cacheDom() {
  headerLogoutBtn = document.querySelector('#header-logout__button');
  myAccountLogoutBtn = document.querySelector('#myaccount-logout__button');
}

function _bindEvents() {
  if (headerLogoutBtn) {
    headerLogoutBtn.addEventListener('click', _postLogout);
  }

  if (myAccountLogoutBtn) {
    myAccountLogoutBtn.addEventListener('click', _postLogout);
  }

}

function _postLogout(e) {

  e.preventDefault();
  httpHelper.post('/customer/account/logout').then((data) => {
    window.location.href = JSON.parse(data).redirect;
  });

}

export default {
  init
};

